import { HierarchicalAccountData } from '../DInsights';

// Utility functions first
function calculateBaseMetrics(period: HierarchicalAccountData): BaseMetrics {
  const { sales, cogs, labor, operatingExpenses } = period.data;

  // Calculate revenue by category
  const revenueByCategory = Object.entries(sales.accounts).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {}
  );

  // Calculate EBITDA (simplified version)
  const ebitda = sales.total - cogs.total - labor.total - operatingExpenses.total;

  return {
    totalRevenue: sales.total,
    revenueChange: null, // Will be calculated when comparing periods
    revenuePerCategory: revenueByCategory,

    // Margin calculations
    grossMargin: ((sales.total - cogs.total) / sales.total) * 100,
    operatingMargin: ((sales.total - cogs.total - operatingExpenses.total) / sales.total) * 100,
    netMargin: (ebitda / sales.total) * 100,
    ebitda,

    // Efficiency metrics
    laborCostRatio: (labor.total / sales.total) * 100,
    cogRatio: (cogs.total / sales.total) * 100,
    operatingExpenseRatio: (operatingExpenses.total / sales.total) * 100,

    // Operational metrics
    averageTransactionValue: sales.total, // Would need transaction count for real calculation
    laborProductivity: labor.total > 0 ? sales.total / labor.total : 0,
  };
}

function calculateDataQuality(
  currentPeriod: HierarchicalAccountData,
  historicalData: HierarchicalAccountData[],
  config: MetricsConfig
): DataQualityMetrics {
  const { qualityParams } = config;

  // Check for missing or zero values
  const checkCompleteness = (data: any): string[] => {
    const missingFields: string[] = [];
    Object.entries(data).forEach(([key, value]) => {
      if (value === null || value === undefined || value === 0) {
        missingFields.push(key);
      }
    });
    return missingFields;
  };

  // Calculate volatility using coefficient of variation
  const calculateVolatility = (values: number[]): number => {
    const mean = values.reduce((sum, val) => sum + val, 0) / values.length;
    const variance = values.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / values.length;
    return Math.sqrt(variance) / Math.abs(mean);
  };

  // Detect outliers using z-score method
  const detectOutliers = (values: number[], threshold: number = 2): number[] => {
    const mean = values.reduce((sum, val) => sum + val, 0) / values.length;
    const stdDev = Math.sqrt(values.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / values.length);
    return values
      .map((value, index) => (Math.abs((value - mean) / stdDev) > threshold ? index : -1))
      .filter(index => index !== -1);
  };

  // Calculate metrics
  const missingDataPoints = checkCompleteness(currentPeriod.data);
  const volatility = calculateVolatility(historicalData.map(d => d.data.sales.total));
  const outliers = detectOutliers(historicalData.map(d => d.data.sales.total));

  return {
    completeness: 1 - missingDataPoints.length / Object.keys(currentPeriod.data).length,
    consistency: volatility < qualityParams.volatilityThreshold ? 1 : 0.5,
    volatility,
    outlierCount: outliers.length,
    missingDataPoints,
    anomalies: [], // Would be populated based on business rules
  };
}

function calculateTrends(metric: number[], config: MetricsConfig): TrendMetrics {
  const { trendSignificance } = config.forecastParams;

  const calculateTrendDirection = (values: number[]): 'increasing' | 'decreasing' | 'stable' => {
    const changes = values.slice(1).map((val, i) => val - values[i]);
    const avgChange = changes.reduce((sum, val) => sum + val, 0) / changes.length;
    return Math.abs(avgChange) < trendSignificance ? 'stable' : avgChange > 0 ? 'increasing' : 'decreasing';
  };

  const calculateMagnitude = (values: number[]): number => {
    const first = values[0];
    const last = values[values.length - 1];
    return ((last - first) / Math.abs(first)) * 100;
  };

  // Simple seasonality detection
  const detectSeasonality = (values: number[]): boolean => {
    // Would implement more sophisticated seasonality detection
    return false;
  };

  const shortTermValues = metric.slice(-3);
  const mediumTermValues = metric.slice(-6);

  return {
    shortTerm: {
      direction: calculateTrendDirection(shortTermValues),
      magnitude: calculateMagnitude(shortTermValues),
      confidence: 0.8, // Would be calculated based on data quality
    },
    mediumTerm: {
      direction: calculateTrendDirection(mediumTermValues),
      magnitude: calculateMagnitude(mediumTermValues),
      confidence: 0.7,
    },
    seasonality: {
      detected: detectSeasonality(metric),
      pattern: undefined,
      strength: undefined,
    },
  };
}

function generateForecast(metric: number[], config: MetricsConfig): ForecastMetrics {
  const { forecastParams } = config;

  // Simple exponential smoothing
  const calculateBaseline = (values: number[]): number => {
    const alpha = 0.3; // Smoothing factor
    return values.reduce((smooth, val, i) => (i === 0 ? val : alpha * val + (1 - alpha) * smooth));
  };

  const baseline = calculateBaseline(metric);
  const volatility = Math.sqrt(metric.reduce((sum, val) => sum + Math.pow(val - baseline, 2), 0) / metric.length);

  return {
    baseline: {
      value: baseline,
      confidence: 0.8,
      assumptions: ['Based on recent historical data', 'Assumes stable business conditions'],
    },
    scenarios: {
      conservative: {
        value: baseline * 0.9,
        probability: 0.25,
        assumptions: ['Accounts for potential market downturn', 'Includes historical volatility'],
      },
      likely: {
        value: baseline,
        probability: 0.5,
        assumptions: ['Based on current trends', 'Assumes normal business conditions'],
      },
      optimistic: {
        value: baseline * 1.1,
        probability: 0.25,
        assumptions: ['Assumes favorable market conditions', 'Based on best recent performance'],
      },
    },
    components: {
      trend: (metric[metric.length - 1] - metric[0]) / metric.length,
      seasonal: 0, // Would require longer time series
      cyclical: 0, // Would require longer time series
      random: volatility,
    },
  };
}

// Main calculation function
export function calculateUnifiedMetrics(data: HierarchicalAccountData[], config: MetricsConfig): UnifiedMetrics[] {
  return data.map((period, index) => {
    const previousPeriod = index > 0 ? data[index - 1] : null;
    const historicalData = data.slice(0, index + 1);

    // Calculate current period metrics
    const current = calculateBaseMetrics(period);

    // Add revenue change if we have a previous period
    if (previousPeriod) {
      const prevMetrics = calculateBaseMetrics(previousPeriod);
      current.revenueChange = ((current.totalRevenue - prevMetrics.totalRevenue) / prevMetrics.totalRevenue) * 100;
    }

    // Calculate historical comparisons
    const historical: UnifiedMetrics['historical'] = previousPeriod
      ? {
          previousPeriod: calculateBaseMetrics(previousPeriod),
          periodOverPeriod: {
            absolute: {} as Partial<BaseMetrics>,
            percentage: {} as Partial<BaseMetrics>,
          },
          runningAverage: {} as Partial<BaseMetrics>,
        }
      : {
          previousPeriod: {} as BaseMetrics,
          periodOverPeriod: {
            absolute: {} as Partial<BaseMetrics>,
            percentage: {} as Partial<BaseMetrics>,
          },
          runningAverage: {} as Partial<BaseMetrics>,
        };

    // Calculate trends for each metric
    const trends: Partial<Record<keyof BaseMetrics, TrendMetrics>> = {};
    Object.keys(current).forEach(key => {
      const metricHistory = historicalData.map(p => calculateBaseMetrics(p)[key as keyof BaseMetrics]);
      if (typeof metricHistory[0] === 'number') {
        trends[key as keyof BaseMetrics] = calculateTrends(metricHistory as number[], config);
      }
    });

    // Generate forecasts for each metric
    const forecasts: Partial<Record<keyof BaseMetrics, ForecastMetrics>> = {};
    Object.keys(current).forEach(key => {
      const metricHistory = historicalData.map(p => calculateBaseMetrics(p)[key as keyof BaseMetrics]);
      if (typeof metricHistory[0] === 'number' && metricHistory.length >= config.forecastParams.minimumDataPoints) {
        forecasts[key as keyof BaseMetrics] = generateForecast(metricHistory as number[], config);
      }
    });

    // Detect significant changes
    const significantChanges = previousPeriod
      ? Object.entries(current)
          .map(([metric, value]) => {
            const prevValue = calculateBaseMetrics(previousPeriod)[metric as keyof BaseMetrics];
            const threshold = config.changeThresholds[metric as keyof BaseMetrics];
            if (typeof value === 'number' && typeof prevValue === 'number' && threshold) {
              const change = ((value - prevValue) / Math.abs(prevValue)) * 100;
              if (Math.abs(change) >= threshold.percentage!) {
                return {
                  metric: metric as keyof BaseMetrics,
                  change,
                  changeType: 'percent' as const,
                  from: prevValue,
                  to: value,
                  confidence: 0.9,
                };
              }
            }
            return null;
          })
          .filter((change): change is NonNullable<typeof change> => change !== null)
      : [];

    return {
      period: {
        id: period.periodId,
        start: period.periodStart,
        end: period.periodEnd,
        type: 'weekly',
      },
      current,
      historical,
      quality: calculateDataQuality(period, historicalData, config),
      trends,
      forecasts,
      significantChanges,
    };
  });
}
