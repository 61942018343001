import './LabelSwitch.scss';
import { prefixClass } from 'lib/utils';
import classNames from 'classnames';
import LabelSwitchItem from './LabelSwitchItem';
import { useState } from 'react';

type LabelSwitchProps = {
  labels: string[];
  selected: string;
  onSelect: (value: string) => void;
  pulseField?: string;
  pulseInProgress?: boolean;
};

const LabelSwitch = ({ labels, selected, onSelect, pulseField, pulseInProgress }: LabelSwitchProps) => {
  const prefix = prefixClass('label-switch');
  const [selectedState, setSelectedState] = useState(selected);

  const handleSelect = (value: string) => {
    setSelectedState(value);
    onSelect(value);
  };

  return (
    <div className={prefix()}>
      {labels.map((label, index) => (
        <LabelSwitchItem
          key={index}
          label={label}
          selected={label === selectedState}
          onClick={() => handleSelect(label)}
          pulse={pulseInProgress && label === pulseField}
          badge={pulseInProgress && label === pulseField ? '+1' : undefined}
        />
      ))}
    </div>
  );
};

export default LabelSwitch;
