import Dashboard from 'presentation/components/Dashboard';
import KSHeader from 'presentation/components/KSHeader';

export default function DashboardViewNew(): JSX.Element {
  return (
    <div className="dashboard-view">
      <KSHeader />
      <Dashboard />
    </div>
  );
}
