import { Link, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AskRequest,
  AskRequestStatus,
  EntityLink,
  FlaggedTransactionComment,
  ResolvedLink,
} from '@hone-automation/common';

import { IconBack, IconDocumentOutline, IconRefreshDouble } from 'components/Icons';
import Chat from '../Chat/Chat';

import { currencyFormatter2Decimals, formatDate } from 'lib/utils';
import TransactionDetails from 'components/TransactionDetails';
import Loading from 'components/Loading';
import { useFlaggedTransactions, useReopenFlaggedTransactionMutation } from 'hooks/flaggedTransactions';
import { useQueryState } from 'hooks/useQueryState';
import { UIFlaggedTransaction } from 'types/askcustomer';
import { shallow } from 'zustand/shallow';

import React, { useCallback } from 'react';
import AttachmentModal from 'components/Attachments/AttachmentModal';
import useAttachmentsModal from 'hooks/useAttachmentsModal';
import { renderDate } from 'lib';
import useFetchLinks from 'hooks/useFetchLinks';
import { useAuthContext } from '../../../context/useAuthContext';

type AskCustomerSideViewProps = {
  transaction: AskRequest;
  onSubmit: (comment: string) => void;
  refetchTransactions?: any;
};

function AskCustomerSideView({ transaction, onSubmit, refetchTransactions }: AskCustomerSideViewProps): JSX.Element {
  const { locationId } = useParams();
  const queryClient = useQueryClient();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { isBookkeeperAdmin } = useAuthContext();
  const isFlaggedCell = transaction.type === 'Flagged Cell';
  const isCustomerRequest = transaction.type === 'Customer Request';
  const { status, transaction: refTransaction } = transaction;
  const [activeTab] = useQueryState<AskRequestStatus>('activeTab', 'Open');

  const { setActiveTransaction, setAnimateTransaction } = useFlaggedTransactions(
    state => ({
      setActiveTransaction: state.setActiveTransaction,
      setAnimateTransaction: state.setAnimatedTransaction,
    }),
    shallow
  );

  const trxDate = String(refTransaction?.date).split('T')[0];

  const linksEnabled = transaction.type !== 'Customer Request';

  const { data: links, status: linksStatus, refetch } = useFetchLinks(transaction, locationId);

  const showLoading = linksEnabled && linksStatus === 'pending';

  const { showAttachmentImage, attachmentsUrl, attachmentMetadata, handleCloseAttachmentImage, getAttachmentsUrl } =
    useAttachmentsModal();

  const postReopenFlaggedTransaction = useReopenFlaggedTransactionMutation(
    activeTab,
    locationId!,
    (trx: UIFlaggedTransaction | undefined) => {
      setActiveTransaction(undefined);
    }
  );

  const validateAndFormatDate = (date: string) => {
    const validDate = /^\d{4}-\d{2}-\d{2}$/;
    return validDate.test(date) ? renderDate(date) : date;
  };

  const getReportElementsFromLink = useCallback(() => {
    if (!links)
      return {
        title: '',
        breadcrumb: '',
        amount: 0,
      };

    const firstLink = (links as ResolvedLink<EntityLink>[])[0];

    if (firstLink?.valid) {
      const start = validateAndFormatDate(firstLink.dataDateRange?.start);
      const end = validateAndFormatDate(firstLink.dataDateRange?.end);
      const dateRange = end ? `${start} - ${end}` : start;

      return {
        title: `${firstLink.reportTitle} | ${dateRange}`,
        breadcrumb: firstLink.sectionPath?.replaceAll('|', ' > '),
        amount: (firstLink.resolvedEntity as any).transaction?.amount,
      };
    }
    return {
      title: '',
      breadcrumb: '',
      amount: 0,
    };
  }, [links]);

  const origin = (links as ResolvedLink<EntityLink>[])?.map((resolvedLink, idx) => {
    const { link, valid, reportTitle, dataDateRange, sectionPath } = resolvedLink;

    if (!valid) {
      return undefined;
    }

    return (
      <div key={idx}>
        <div>{reportTitle ?? ''}</div>
        {dataDateRange && (
          <div>
            {dataDateRange.start} {dataDateRange.end ? `- ${dataDateRange.end}` : ''}
          </div>
        )}

        {link && valid && sectionPath && (
          <Link
            target="_blank"
            title="Go to report"
            to={`/app/location/${locationId}/report/${link.toId}?flaggedTransaction=${link.fromId}&fromType=${link.fromType}`}
            key={idx}
          >
            {sectionPath.replaceAll('|', ' > ')}
          </Link>
        )}
      </div>
    );
  });

  const flaggedAmountFormatted = () =>
    transaction?.flaggedAmount && currencyFormatter2Decimals(transaction.flaggedAmount);

  const flaggedAmount = (links as ResolvedLink<EntityLink>[])?.map((resolvedLink, idx) => {
    const { sectionAmount } = resolvedLink;
    const trxAmount = flaggedAmountFormatted();
    const sectionAmountFomatted = sectionAmount && currencyFormatter2Decimals(sectionAmount);
    if (!sectionAmountFomatted || trxAmount === sectionAmountFomatted) {
      return (
        <div className="amount-group" key={idx}>
          <span>{trxAmount}</span>
        </div>
      );
    }

    return (
      <div className="amount-group" key={idx}>
        <span>{trxAmount}</span>
        <span className="div" />
        <span className="section-amount"> {sectionAmountFomatted}</span>
      </div>
    );
  });

  const getAttachments = (): JSX.Element | undefined => {
    const attachments =
      (links as ResolvedLink<EntityLink>[])?.length && (links as ResolvedLink<EntityLink>[])[0].attachments;

    return attachments ? (
      <button
        type="button"
        title="Download attachment"
        className="row-attach"
        onClick={e => getAttachmentsUrl(locationId, attachments, attachmentMetadata!)}
      >
        <IconDocumentOutline />
        {attachments && <span className="counter">{attachments.length}</span>}
      </button>
    ) : undefined;
  };

  const detailsCustomerRequestCell = [
    {
      label: 'Title',
      value: transaction?.identifier || '-',
    },
    {
      label: 'Type',
      value: 'Custom Request',
    },
    {
      label: 'Created By',
      value: transaction?.comments?.[0]?.userLocation?.email || '-',
    },
  ];

  let detailsFlaggedCell = [
    {
      label: 'Origin',
      value: origin,
    },
    {
      label: 'Type',
      value: 'Flagged Report Cell',
    },
    {
      label: 'Name',
      value: transaction?.identifier || '-',
    },
    {
      label: 'Amount',
      value: flaggedAmount?.length ? (
        <div className="amount-group">
          <span>{flaggedAmount}</span>
        </div>
      ) : (
        flaggedAmountFormatted()
      ),
    },

    {
      label: 'Period',
      value: transaction?.period || '-',
    },
  ];

  if (origin && origin?.filter((o: any) => o).length > 0) {
    detailsFlaggedCell = detailsFlaggedCell.filter(item => item.label !== 'Name' && item.label !== 'Period');
  } else {
    detailsFlaggedCell = detailsFlaggedCell.filter(item => item.label !== 'Origin');
  }

  let detailsRowsAskBookkeeper = [
    {
      label: 'TRX Date',
      value: trxDate,
    },
    {
      label: 'Origin',
      value: origin,
    },
    {
      label: 'Name',
      value: refTransaction?.name || '-',
    },
    {
      label: 'Description',
      value: refTransaction?.memo || '-',
    },
    {
      label: 'Type',
      value: refTransaction?.externalTransactionType || '-',
    },
    {
      label: 'Amount',
      value: (
        <div className="amount-group">
          <span>{refTransaction?.amount && currencyFormatter2Decimals(refTransaction.amount)}</span>
        </div>
      ),
    },
    {
      label: 'Account',
      value: refTransaction?.account || '-',
    },
  ];

  if (!origin || origin.filter(o => o).length === 0) {
    detailsRowsAskBookkeeper = detailsRowsAskBookkeeper.filter(item => item.label !== 'Origin');
  }

  const doAnimation = (status: AskRequestStatus) => {
    setAnimateTransaction(status);
  };

  const submit = async (comment: string) => {
    if (isBookkeeperAdmin && activeTab === 'In Progress') doAnimation('Open');
    if (!isBookkeeperAdmin && activeTab === 'Open') doAnimation('In Progress');
    onSubmit(comment);
  };

  const handleReopen = async (comment: string) => {
    doAnimation(isBookkeeperAdmin ? 'Open' : 'In Progress');
    postReopenFlaggedTransaction.mutate({
      trxId: transaction?.id,
      comment,
    });
  };

  const goBackMobileList = () => {
    setActiveTransaction(undefined);
  };

  let detailsRows = isFlaggedCell ? detailsFlaggedCell : detailsRowsAskBookkeeper;

  detailsRows = isCustomerRequest ? detailsCustomerRequestCell : detailsRows;

  if ((links as ResolvedLink<EntityLink>[])?.length && (links as ResolvedLink<EntityLink>[])[0].attachments) {
    detailsRows = [
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...detailsRows,
      {
        label: 'Attachments',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value: getAttachments(),
      },
    ];
  }

  const isClosedTrx = transaction?.status === 'Closed' || (transaction.status as any) === 'Categorized';

  const canReopen = transaction?.canReopen && isClosedTrx;

  const attachmentElements = getReportElementsFromLink();

  return (
    <div className="ask-customer-chat-container">
      <div className="ask-customer-chat-container-header">
        <div className="ask-customer-chat-container-title-icon">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {isMobile && <IconBack onClick={goBackMobileList} />}
          <div className="ask-customer-chat-container-title">
            <span>{status}</span>
            <span className="ask-customer-chat-container-title-sub">
              CREATED ON {formatDate(transaction.createdAt)}
            </span>
          </div>
        </div>
        <div className={'ask-customer-chat-container-title-icon'}>
          <div className="ask-customer-chat-container-title">
            {showLoading ? (
              <Loading loadingCircleClass="Loading-circle-small" />
            ) : (
              (links as ResolvedLink<EntityLink>[])?.map((resolvedLink, idx) => {
                const { link } = resolvedLink;

                if (resolvedLink.valid) {
                  if (link) {
                    return (
                      <Link
                        target="_blank"
                        title="View in report"
                        to={`/app/location/${locationId}/report/${link.toId}?flaggedTransaction=${link.fromId}&fromType=${link.fromType}`}
                        key={idx}
                      >
                        View in report
                      </Link>
                    );
                  }
                }
                if (link) {
                  return (
                    <Link
                      target="_blank"
                      title="Go to report"
                      to={`/app/location/${locationId}/report/${link.toId}?fromType=${link.fromType}`}
                      key={idx}
                    >
                      Go to report
                    </Link>
                  );
                }
              })
            )}
            <span className="updated-at">UPDATED {formatDate(transaction.updatedAt)}</span>
          </div>
          <span
            onClick={() => {
              refetchTransactions?.();
              refetch();
              queryClient.invalidateQueries({ queryKey: ['askRequestsCount', locationId] });
            }}
          >
            <IconRefreshDouble />
          </span>
        </div>
      </div>

      <div className="ask-customer-chat-container-body">
        <TransactionDetails toggle rows={detailsRows} transaction={transaction} isSideView />
      </div>

      <div className="ask-customer-chat-wrapper">
        <Chat<FlaggedTransactionComment>
          onSubmit={(comment: string) => (canReopen ? handleReopen(comment) : submit(comment))}
          messages={transaction.comments}
          canReOpen={canReopen}
          isClosedTrx={isClosedTrx}
          isSideView
        />
      </div>
      <AttachmentModal
        subtitle={[attachmentElements.title]}
        attachmentMetadata={attachmentMetadata}
        showAttachmentImage={showAttachmentImage}
        handleClose={handleCloseAttachmentImage}
        attachmentsUrl={attachmentsUrl}
        title={attachmentElements.title}
        breadcrumb={attachmentElements.breadcrumb}
      />
    </div>
  );
}

export default AskCustomerSideView;
