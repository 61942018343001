import { useMutation, useQueryClient } from '@tanstack/react-query';
import { makeRemotePostFlaggedTransactionComment } from 'main/factories/usecases/ask-customer/remote-post-flagged-transaction-comment';
import { showToast, TEN_SECONDS, TOAST_ERROR } from 'lib/utils';
import { AskRequest, AskRequestStatus } from '@hone-automation/common';

export const useCreateQueueCommentMutation = (
  status: AskRequestStatus,
  locationId: string,
  setActiveTransaction: (trx: AskRequest | undefined) => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => {
      const trxId = data.activeTransaction.flagged
        ? data.activeTransaction.flagged.id
        : data.activeTransaction.flaggedCell
          ? data.activeTransaction.flaggedCell.id
          : data.activeTransaction.id;

      const service = makeRemotePostFlaggedTransactionComment(trxId);

      const { comment, userId } = data;
      return service.post({ userId, comment, locationId });
    },
    onSuccess: async trx => {
      await queryClient.invalidateQueries({
        queryKey: ['askRequests', status, locationId],
      });
      await queryClient.invalidateQueries({ queryKey: ['askRequestsCount', locationId] });
      await queryClient.invalidateQueries({ queryKey: ['flaggedTransactions', locationId] });
      setActiveTransaction?.(trx as AskRequest);
    },
    onError: error => {
      showToast('An error has occurred creating message', TOAST_ERROR, TEN_SECONDS);
    },
  });
};
