import { useMutation } from '@tanstack/react-query';
import { makeRemotePostSendHubspotEmail } from 'main/factories/usecases';
import { FIVE_SECONDS, showToast, TEN_SECONDS, TOAST_ERROR, TOAST_SUCCESS } from 'lib/utils';
import { useParams } from 'react-router-dom';

export const useSendHubspotEmail = () => {
  const { locationId } = useParams();

  return useMutation({
    mutationFn: () => {
      const sendHubSpotEmailService = makeRemotePostSendHubspotEmail();
      if (!locationId) throw new Error('Location id not found');
      return sendHubSpotEmailService.sendEmail({ locationId });
    },
    onError: e => {
      showToast(e.message, TOAST_ERROR, TEN_SECONDS);
    },
    onSuccess: response => {
      const numberEmailSent = response?.length ?? 0;
      showToast(`Successfully sent to ${numberEmailSent} recipients`, TOAST_SUCCESS, FIVE_SECONDS);
    },
  });
};
