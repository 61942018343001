import { useState } from 'react';
import { PasswordModal } from '../../components/Profile/PasswordModal';
import { NameModal } from '../../components/Profile/NameModal';
import { IconBuilding, IconLock, IconUser } from 'components/Icons';
import { useUserLocationsQuery } from 'hooks/useUserLocationsQuery';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuthContext } from '../../../context/useAuthContext';

interface ProfileProps {
  locationUser: HoneLocationUser;
}

function Profile({ locationUser }: ProfileProps) {
  const { enableProfilePasswordChange } = useFlags();
  const { user } = useAuthContext();

  const { data: userLocations } = useUserLocationsQuery();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);

  const handlePasswordChange = (data: any) => {
    // Implement password change logic here
    setIsPasswordModalOpen(false);
  };

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-100 profile-page">
      <div className="profile-container">
        <div className="profile-header">
          <div className="user-avatar">
            <h1>{(locationUser?.name ? locationUser?.name : user.email)?.substring(0, 2)}</h1>
          </div>
          <h2>{locationUser?.name}</h2>
        </div>

        <div className="profile-section">
          <div className="section-header">
            <h2>Profile Settings</h2>
          </div>
          <div className="settings-grid">
            <button onClick={() => setIsNameModalOpen(true)} className="settings-button">
              <div className="button-content">
                <IconUser />
                <span>Update Name</span>
              </div>
              <span className="arrow">→</span>
            </button>
            {enableProfilePasswordChange && (
              <button onClick={() => setIsPasswordModalOpen(true)} className="settings-button">
                <div className="button-content">
                  <IconLock />
                  <span>Change Password</span>
                </div>
                <span className="arrow">→</span>
              </button>
            )}
          </div>
        </div>

        <div className="profile-section">
          <div className="section-header">
            <IconBuilding />
            <h2>Assigned Locations</h2>
          </div>
          <div className="locations-grid">
            {userLocations?.map((location, key) => (
              <div key={`${location.id}-${key}`} className="location-card">
                <div className="location-name">{location.locationName}</div>
                <div className="location-role">{location.role.split('_').join(' ')}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <PasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
        onSubmit={handlePasswordChange}
      />

      <NameModal
        userId={user.uid}
        locationId={locationUser.locationId}
        isOpen={isNameModalOpen}
        onClose={() => setIsNameModalOpen(false)}
        currentName={locationUser.name}
      />
    </div>
  );
}

export default Profile;
