import { useState } from 'react';
import { AIProvider, QueryResponse, AIQueryHook } from './types';

const API_BASE_URL = 'https://api.persp.info/ext/kai'; //'http://localhost:3005'; //

// Custom hook for single-shot queries
export const useAIQuery = (): AIQueryHook => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<QueryResponse | null>(null);

  const sendQuery = async (message: string, provider: AIProvider = 'openai'): Promise<QueryResponse> => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_BASE_URL}/query`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message,
          provider,
        }),
      });

      if (!response.ok) {
        const errorData: QueryResponse = await response.json();
        throw new Error(errorData.error || 'Failed to fetch response');
      }

      const data: QueryResponse = await response.json();
      setResponse(data);
      return data;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
      setError(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    sendQuery,
    loading,
    error,
    response,
    clearError: () => setError(null),
    clearResponse: () => setResponse(null),
  };
};
