import { prefixClass } from 'lib/utils';
import classNames from 'classnames';
import './Queue.scss';
import BrowserColumn from './BrowserColumn';
import QueueDetails from './QueueDetails/QueueDetails';

type QueueProps = {
  visible?: boolean;
};

const Queue = ({ visible = true }: QueueProps) => {
  const prefix = prefixClass('queue');

  return (
    <div className={classNames(prefix(), { 'queue-visible': visible })}>
      <div className={prefix('browser')}>
        <BrowserColumn />
      </div>
      <QueueDetails />
    </div>
  );
};

export default Queue;
