import { useMemo, useState, FormEvent } from 'react';
import { findIndex, isEmpty } from 'lodash';
import Loading from 'components/Loading';

import { useHoneGetUsers } from 'context/useAuthContext';
import { IconAddUser, IconDeleteOutline, IconEditUser, IconEmailOutline } from 'components/Icons';
import { HoneUserRoles } from '@hone-automation/common';
import { Can } from '@casl/react';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './BookkeperUsersForm.scss';

import {
  showToast,
  dismissToast,
  TOAST_DEFAULT,
  TOAST_SUCCESS,
  ONE_MINUTE,
  FIVE_SECONDS,
  TEN_SECONDS,
  TOAST_ERROR,
} from 'lib/utils';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useAuthContext } from 'context/useAuthContext';
import { RoleLabels } from '../../../../../constants';
import UserFormModal, { UserFormInputs } from 'presentation/components/UserFormModal/UserFormModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NameModal } from '../../../../components/Profile/NameModal';
import { useParams } from 'react-router-dom';

function BookkeeperUsersForm(): JSX.Element {
  const queryClient = useQueryClient();
  const { locationId } = useParams();
  const auth = useAuthContext();
  const { status: locationsStatus } = useLocationsStore();

  const { data: allLocationUsers } = useHoneGetUsers(locationId, auth.user ?? null);

  const { currentLocationAbility, addLocationUser, updateLocationUser, removeLocationUser } = useAuthContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNameModalOpen, setNameIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');
  const [editingUser, setEditingUser] = useState<UserFormInputs | undefined>();

  // Mutations
  const addUserMutation = useMutation({
    mutationFn: (userData: UserFormInputs) => {
      return addLocationUser(userData.email, userData.name, userData.role || HoneUserRoles.Bookkeeper);
    },
    onMutate: () => {
      return showToast('Adding user to this location, please wait a bit.', TOAST_DEFAULT, ONE_MINUTE);
    },
    onSuccess: (result: any, variables, toastId) => {
      if (result?.status === 200) {
        toastId && dismissToast(toastId);
        showToast(`${result.data?.result ?? 'Success!'}`, TOAST_SUCCESS, TEN_SECONDS);
      } else {
        toastId && dismissToast(toastId);
        showToast('Error adding user', TOAST_ERROR, TEN_SECONDS);
      }
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['locationUsers'] });
    },
    onError: (error, variables, toastId) => {
      toastId && dismissToast(toastId);
      showToast(`Error adding user: ${error}`, TOAST_ERROR, TEN_SECONDS);
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: ({
      email,
      role,
      emailOptOut,
      name,
    }: {
      email: string;
      role: string;
      emailOptOut: boolean;
      name?: string;
    }) => {
      return updateLocationUser(email, role, emailOptOut, name);
    },
    onMutate: () => {
      return showToast('Updating user', TOAST_DEFAULT, ONE_MINUTE);
    },
    onSuccess: (result: any, variables, toastId) => {
      if (result.data) {
        dismissToast(toastId);
        if (result.data.error === undefined) {
          const index = findIndex(allLocationUsers, { email: variables.email });
          if (index >= 0) {
            // @ts-ignore
            const newLocationUsers = Array.from(allLocationUsers);
            newLocationUsers[index].role = variables.role;
            newLocationUsers[index].emailOptOut = variables.emailOptOut;
          }
        } else {
          showToast('Error trying to update user', TOAST_ERROR, TEN_SECONDS);
        }
      }
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['locationUsers'] });
    },
    onError: (error, variables, toastId) => {
      toastId && dismissToast(toastId);
      showToast(`Error trying to update user: ${error}`, TOAST_ERROR, TEN_SECONDS);
    },
  });

  const removeUserMutation = useMutation({
    mutationFn: (email: string) => {
      return removeLocationUser(email);
    },
    onMutate: () => {
      return showToast('Removing user from this location, please wait a bit.', TOAST_DEFAULT, ONE_MINUTE);
    },
    onSuccess: (result: any, variables, toastId) => {
      if (result === 'ok') {
        dismissToast(toastId);
        showToast('Successfully removed user.', TOAST_SUCCESS, FIVE_SECONDS);
      }
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['locationUsers'] });
    },
    onError: (error, variables, toastId) => {
      toastId && dismissToast(toastId);
      showToast(`Error removing user: ${error}`, TOAST_ERROR, TEN_SECONDS);
    },
  });

  const roleChoices = useMemo(() => {
    return Object.values(HoneUserRoles);
  }, []);

  const openAddModal = () => {
    setModalMode('add');
    setEditingUser(undefined);
    setIsModalOpen(true);
  };

  const openEditModal = (user: HoneLocationUser) => {
    setModalMode('edit');
    setEditingUser({
      id: user.userId,
      name: user.name || '',
      email: user.email,
      role: user.role,
    });
    setNameIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);
  const closeNameModal = () => setNameIsModalOpen(false);

  const handleAddUser = (userData: UserFormInputs) => {
    addUserMutation.mutate(userData);
  };

  const handleEmailOptOutChange = (e: FormEvent<HTMLInputElement>, email: string) => {
    const index = findIndex(allLocationUsers, { email });
    if (index >= 0) {
      updateUserMutation.mutate({
        email,
        // @ts-ignore
        role: allLocationUsers?.[index].role,
        emailOptOut: !allLocationUsers?.[index].emailOptOut,
      });
    } else {
      showToast(`User not found: ${email}`, TOAST_ERROR, TEN_SECONDS);
    }
  };

  const handleRoleUpdate = (e: FormEvent<HTMLSelectElement>) => {
    const email = e.currentTarget.id;
    const newRole = e.currentTarget.value;
    const index = findIndex(allLocationUsers, { email });
    if (index >= 0) {
      updateUserMutation.mutate({
        email,
        role: newRole,
        // @ts-ignore
        emailOptOut: allLocationUsers?.[index].emailOptOut,
      });
    } else {
      showToast(`User not found: ${email}`, TOAST_ERROR, TEN_SECONDS);
    }
  };

  const handleRemoveLocationUser = async (e: FormEvent<HTMLButtonElement>) => {
    const remEmail = e.currentTarget.value;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3>Are you sure you want to remove '{remEmail}' from this location?</h3>
            <button className="button BKForm-btn-secondary" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button BKForm-btn"
              onClick={() => {
                onClose();
                removeUserMutation.mutate(remEmail);
              }}
            >
              Remove User
            </button>
          </div>
        );
      },
    });
  };

  const handlePasswordReset = async (e: FormEvent<HTMLButtonElement>) => {
    const email = e.currentTarget.value;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h4 className="mb-8">Do you want to send a 'Set Password' email to: {email}?</h4>
            <button className="button BKForm-btn-secondary mr-2" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button BKForm-btn"
              onClick={() => {
                onClose();
                const toastId = showToast("Sending 'Set Password' email", TOAST_DEFAULT, TEN_SECONDS);
                auth
                  .sendPasswordResetEmail({ email })
                  .then(function () {
                    dismissToast(toastId);
                    showToast('Successfully send email', TOAST_SUCCESS, TEN_SECONDS);
                  })
                  .catch(function (error: any) {
                    dismissToast(toastId);
                    showToast(`Error sending password email, error=${error}`, TOAST_ERROR, TEN_SECONDS);
                  });
              }}
            >
              Send Email
            </button>
          </div>
        );
      },
    });
  };

  return (
    <div className="bookkeeper-tabs-container">
      <div className="bk-users-form-container">
        {locationsStatus === 'loading' && (
          <div>
            <Loading loadingCircleClass="Loading-circle-small" />
          </div>
        )}
        {locationsStatus !== 'loading' && !isEmpty(currentLocationAbility) && (
          <Can not I="read" a="User" ability={currentLocationAbility}>
            <div className="mt-4 mr-3">You do not have access to view/update this location's users.</div>
          </Can>
        )}

        {locationsStatus !== 'loading' && !isEmpty(currentLocationAbility) && (
          <Can I="read" a="User" ability={currentLocationAbility}>
            <div className="bk-users-form-container__table_container" id="tab-settings">
              <div className="bk-users-form-container__header">
                <div className="bk-users-form-container__table_container__title">Users</div>
                <button className="button button-primary add-user-button" onClick={openAddModal}>
                  <IconAddUser /> Add User
                </button>
              </div>
              <div className="bk-users-form-container__table_wrapper">
                <table className="bk-users-form-container__table_container-content">
                  <thead>
                    <tr className="bk-users-form-container__table_container-content-row">
                      <th className="bk-users-form-container__table_container-content-row-cell-header align-left">
                        Name
                      </th>
                      <th className="bk-users-form-container__table_container-content-row-cell-header align-left">
                        Email
                      </th>
                      <th className="bk-users-form-container__table_container-content-row-cell-header align-left">
                        Role
                      </th>
                      <th className="bk-users-form-container__table_container-content-row-cell-header">
                        Email <br /> Opt Out
                      </th>
                      <th className="bk-users-form-container__table_container-content-row-cell-header">
                        Edit <br /> User
                      </th>
                      <th className="bk-users-form-container__table_container-content-row-cell-header">
                        Send Password <br /> Reset Email
                      </th>
                      <th className="bk-users-form-container__table_container-content-row-cell-header">
                        Remove <br /> User
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allLocationUsers &&
                      allLocationUsers.map((user: HoneLocationUser) => (
                        <tr key={user?.email} className="bk-users-form-container__table_container-content-row">
                          <td className="bk-users-form-container__table_container-content-cell">{user?.name}</td>
                          <td className="bk-users-form-container__table_container-content-cell">{user?.email}</td>
                          <td className="bk-users-form-container__table_container-content-cell">
                            <select id={user?.email} name="role" value={user?.role} onChange={e => handleRoleUpdate(e)}>
                              {roleChoices.map((roleChoice, index) => (
                                <option value={roleChoice} key={index}>
                                  {RoleLabels[roleChoice]}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="bk-users-form-container__table_container-content-cell text-center">
                            <input
                              type="checkbox"
                              checked={user?.emailOptOut}
                              onChange={e => handleEmailOptOutChange(e, user.email)}
                            />
                          </td>
                          <td className="bk-users-form-container__table_container-content-cell text-center">
                            <button
                              className="button button_outline p-1"
                              type="button"
                              onClick={() => openEditModal(user)}
                            >
                              <IconEditUser />
                            </button>
                          </td>
                          <td className="bk-users-form-container__table_container-content-cell text-center">
                            <button
                              className="button button_outline p-1"
                              type="button"
                              value={user?.email}
                              onClick={e => handlePasswordReset(e)}
                            >
                              <IconEmailOutline />
                            </button>
                          </td>
                          <td className="bk-users-form-container__table_container-content-cell text-center">
                            <button
                              className="button BKForm-btn-alert p-1"
                              type="button"
                              value={user?.email}
                              onClick={e => handleRemoveLocationUser(e)}
                            >
                              <IconDeleteOutline />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <UserFormModal
              isOpen={isModalOpen}
              closeModal={closeModal}
              onAddUser={modalMode === 'add' ? handleAddUser : undefined}
              editUser={editingUser}
              mode={modalMode}
            />
            {editingUser && (
              <NameModal
                isOpen={isNameModalOpen}
                onClose={closeNameModal}
                currentName={editingUser.name}
                userId={editingUser.id}
                onSuccess={() => {
                  queryClient.invalidateQueries({ queryKey: ['locationsUsers'] });
                }}
              />
            )}
          </Can>
        )}
      </div>
    </div>
  );
}

export default BookkeeperUsersForm;
