import React, { useRef, ReactNode } from 'react';
import { Tooltip, Whisper, WhisperInstance } from 'rsuite';

const TooltipWrapper = ({
  tooltip,
  children,
  delay = 800,
}: {
  tooltip?: string;
  delay?: number;
  children: ReactNode;
}) => {
  if (!tooltip) return <>{children}</>;

  // Create a ref to the Whisper component
  const whisperRef = useRef<WhisperInstance | null>(null);

  return (
    <Whisper
      ref={whisperRef}
      placement="top"
      controlId="control-id-hover"
      trigger="hover"
      delayOpen={delay}
      delayClose={100}
      speaker={<Tooltip>{tooltip}</Tooltip>}
      // Add onClick handler directly to Whisper
      onClick={() => {
        if (whisperRef.current) {
          whisperRef.current.close();
        }
      }}
    >
      {children}
    </Whisper>
  );
};

export default TooltipWrapper;
