import {
  commonUtil,
  ComparisonRequest,
  ComparisonType,
  GenerateRangesRequest,
  Location,
  ReportConfiguration,
  ReportRequest,
  ReportTimeframes,
  StartEndDates,
} from '@hone-automation/common';
import { generateRanges } from '@hone-automation/common/lib/reportUtil';
import { useGetReportConfiguration } from '../../hooks';
import { getInitialReportDateRanges } from 'lib/reportUtils';

type GenerateReportPayloadProps = {
  currentLocation?: Location;
  dateRange: string;
  comparisonPeriods: string | null;
  compareLocations: string | null;
  availableTemplates: TemplateRange[];
  comparisonType: string | null;
  reportsConfiguration?: ReportConfiguration;
  templateId: string;
  totalParsed: string | null;
  differenceParsed: string | null;
};

export const generateReportPayload = ({
  currentLocation,
  dateRange,
  comparisonPeriods,
  compareLocations,
  availableTemplates,
  comparisonType,
  reportsConfiguration,
  templateId,
  totalParsed,
  differenceParsed,
}: GenerateReportPayloadProps) => {
  if (!currentLocation) return;
  const type: 'P&L Comparison' | 'Balance Sheet' = 'P&L Comparison';
  const [startDate, endDate] = dateRange.split(',') || [];
  const limit = undefined;

  const comparison: ComparisonRequest = {
    comparisonType: (comparisonType as ComparisonType) || '',
    comparisonPeriods: Number(comparisonPeriods),
  };

  const now = commonUtil.toShortString(new Date());
  const rangeEnd = availableTemplates?.length ? availableTemplates?.[0].range.end : now;

  const request = Object.assign(
    new GenerateRangesRequest(ReportTimeframes.Week, currentLocation?.weekStart, reportsConfiguration?.data),
    {
      limit: 3,
      end: rangeEnd > now ? now : rangeEnd,
    }
  );

  const newDateRanges: StartEndDates[] = generateRanges(request);

  const locations = compareLocations
    ? compareLocations?.split(',').map((location: string) => location)
    : [String(currentLocation.id)];

  const dateParsed = getInitialReportDateRanges(newDateRanges);

  const reportPayload: ReportRequest = {
    type,
    locations,
    templateId: templateId,
    startDate: dateRange ? startDate : dateParsed?.start,
    endDate: dateRange ? endDate : dateParsed?.end,
    total: totalParsed === 'true',
    difference: differenceParsed === 'true',
    limit,
    timeframe: ReportTimeframes.Week || 'Week',
  };

  if (comparisonType) {
    reportPayload.comparison = comparison;
  }

  return reportPayload;
};
