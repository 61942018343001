import { AskRequest, ReportConfiguration, SummaryItem } from '@hone-automation/common';
import Queue from '../Queue';
import './Dashboard.scss';
import { prefixClass } from 'lib/utils';
import DashboardCardList from './DashboardListGroup/DashboardCardList';
import { useEffect, useMemo, useState } from 'react';
import KSChart from '../KSChart/KSChart';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { shallow } from 'zustand/shallow';
import { useTemplatesRangesQuery } from 'hooks/reports/useTemplatesRangesQuery';
import reportToDashboard from './DParser/reportToDashboard';
import { useDashboardReport } from './useDashboardReport';
import classNames from 'classnames';
import { useAuthContext } from 'context/useAuthContext';
import { ChartMetrics, metricsPeriodPlot, MetricsPlot } from './DParser/types';
import { logger } from '../KSExport/reportNTParser/utils';
import { useReportsStore } from 'hooks/useReportsStore';
import { useGetReportConfiguration } from '../../../hooks';
import { getPeriodsWeeksChartData } from './DParser/DPeriodStructure';
import { GetReportsConfig } from 'domain/usecases/reports/get-reports-config';

const Dashboard = () => {
  const prefix = prefixClass('dashboard');
  const trace = false;
  const { user } = useAuthContext();
  const [menu, setMenu] = useState<'Opportunities' | 'Queue'>('Queue');
  const { currentLocationId, currentLocation } = useLocationsStore(
    state => ({ currentLocationId: state.currentLocationId, currentLocation: state.currentLocation }),
    shallow
  );

  const { data: reportConfiguration } = useGetReportConfiguration(currentLocationId);
  if (trace) logger.msg('reportConfiguration', reportConfiguration);

  const { data: availableTemplates } = useTemplatesRangesQuery();
  const templateId = availableTemplates?.[0]?.id || '';

  // @ts-ignore
  const periodStructure = getPeriodsWeeksChartData(currentLocation?.weekStart, reportConfiguration?.data, 6);
  if (trace) logger.msg('periodStructure', periodStructure);

  const COMPARISON_PERIODS = periodStructure.completeWeeks;

  const { reportData } = useDashboardReport(currentLocation!, templateId, availableTemplates!, COMPARISON_PERIODS);
  const [chartData, setChartData] = useState<MetricsPlot>({ periodReporting: true, periods: [], metrics: [] });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (reportData) {
      setIsLoading(false);
      setMenu('Queue');
    }
  }, [reportData]);

  useEffect(() => {
    if (!reportData) return;
    const { chartData: newChartData } = reportToDashboard(reportData, periodStructure, trace);
    setChartData(newChartData);
  }, [reportData]);

  return (
    <div className={prefix()}>
      <div className={prefix('left-pane')}>
        <div className={prefix('left-pane__chart-container')}>
          <KSChart data={chartData} customHeight={350} />
        </div>
        <div className={prefix('left-pane__cards-container')}>
          <DashboardCardList onChange={(menu: 'Opportunities' | 'Queue') => setMenu(menu)} selected={menu} />
        </div>
      </div>
      <div className={prefix('right-pane')}>
        <div className={prefix('right-pane__title')}>{menu}</div>
        <div className={prefix('right-pane__view-area')}>
          <div className={classNames(prefix('right-pane__view-area-slider'), { 'slider-move': menu === 'Queue' })}>
            {!isLoading && (
              <>
                <div className={prefix('right-pane__view-area-slider-component')}></div>
                <div className={prefix('right-pane__view-area-slider-component')}>
                  <Queue visible={menu === 'Queue'} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
