import { useEffect, useRef, useCallback, useState } from 'react';
import { useNTContext } from '../NTContext';
import { useNTScroll } from '../NTScrollContext';
import { useNTUtilsContext } from '../NTUtilsContext';
import { useNTSelectionContext } from '../NTSelectionContext';
import { useNTTableContext } from '../NTTableContext';
import { overflowShadowing } from '../utils/sizingUtils';

export const useScrollProperties = () => {
  const { scrollState, setScrollState, scrollChange, setScrollChange, refreshBrowsers } = useNTScroll();
  const { filters } = useNTContext();
  const { actions } = useNTUtilsContext();
  const { isResizing } = useNTTableContext();
  const { setIsScrolling } = useNTSelectionContext();
  const isUpdatingRef = useRef(false);

  const updateScrollProperties = useCallback(
    (axis: 'x' | 'y', movementAmount?: number, browser?: boolean) => {
      if (isUpdatingRef.current) return;

      // scroll elements
      const headerMiddle = document.getElementById('header-middle');
      const tableMiddle = document.getElementById('table-middle');
      const tableYScroll = document.getElementById('table-y-scroll');
      const tableScroll = axis === 'x' ? tableMiddle : tableYScroll;
      const table = document.getElementById('new-table');

      // browser elements
      const columnBrowserArea = document.getElementById('columnBrowserArea');
      const columnBrowserWindow = document.getElementById('columnBrowserWindow');
      const columnBrowserElemPadding = getComputedStyle(document.documentElement)
        .getPropertyValue('--ks-column-browser-padding')
        .trim();

      // minimap elements
      const miniMapArea = document.getElementById('miniMapArea');
      const miniMapWindow = document.getElementById('miniMapWindow');
      const miniMapWindowArea = document.getElementById('miniMapWindowArea');

      if (!table || !headerMiddle || !tableMiddle || !tableYScroll || !tableScroll) return;

      const maskedAreaTotal =
        axis === 'x'
          ? columnBrowserArea
            ? columnBrowserArea.offsetWidth - parseInt(columnBrowserElemPadding)
            : 0
          : miniMapArea
            ? miniMapArea.offsetHeight
            : 0;

      // calculate scroll properties
      const scrollProperty = axis === 'x' ? 'scrollLeft' : 'scrollTop';
      const scrollDimension = axis === 'x' ? 'scrollWidth' : 'scrollHeight';
      const visibleAreaOfScroll =
        axis === 'x'
          ? tableMiddle.offsetWidth / tableMiddle[scrollDimension]
          : tableYScroll.offsetHeight / tableYScroll[scrollDimension];
      const maskedArea = maskedAreaTotal * visibleAreaOfScroll;

      const scrollObj = { position: 0, percentage: 0 };

      if (axis === 'x') {
        scrollObj.position = movementAmount ? movementAmount : tableMiddle[scrollProperty];
        scrollObj.percentage =
          (movementAmount ? movementAmount : tableMiddle[scrollProperty]) / tableMiddle[scrollDimension];
      } else {
        scrollObj.position = movementAmount ? movementAmount : tableYScroll[scrollProperty];
        scrollObj.percentage =
          (movementAmount ? movementAmount : tableYScroll[scrollProperty]) / tableYScroll[scrollDimension];
      }

      // if browser is true, we are moving the browser window
      if (movementAmount) {
        if (browser) {
          const legalMax = Math.max(0, Math.min(movementAmount, maskedAreaTotal - maskedArea));
          scrollObj.percentage = legalMax / maskedAreaTotal;
          scrollObj.position =
            axis === 'x'
              ? tableMiddle[scrollDimension] * scrollObj.percentage
              : tableYScroll[scrollDimension] * scrollObj.percentage;
        } else {
          const minPosition =
            axis === 'x'
              ? tableMiddle[scrollDimension] - tableMiddle.offsetWidth
              : tableYScroll[scrollDimension] - tableYScroll.offsetHeight;
          const legalMax = Math.max(0, Math.min(movementAmount, minPosition));

          scrollObj.percentage =
            axis === 'x' ? legalMax / tableMiddle[scrollDimension] : legalMax / tableYScroll[scrollDimension];
        }
      }

      const scrollPosition = scrollObj.position;
      const scrollPercentage = scrollObj.percentage;

      const browserAreaX = scrollPercentage * maskedAreaTotal + parseInt(columnBrowserElemPadding) / 2;
      const browserAreaY = scrollPercentage * maskedAreaTotal - 4;

      // paint
      // We only update the column browser if it exists and we are scrolling horizontally
      if (columnBrowserWindow && axis === 'x') {
        columnBrowserWindow.style.width = `${maskedArea}px`;
        columnBrowserWindow.style.left = `${browserAreaX}px`;
        headerMiddle[scrollProperty] = scrollPosition;
      }

      if (miniMapWindow) {
        if (axis === 'y') {
          miniMapWindow.style.height = `${maskedArea}px`;
          miniMapWindow.style.top = `${browserAreaY}px`;
        }
        if (miniMapWindowArea && axis === 'x') {
          const miniMapAreaWidth = miniMapWindow.offsetWidth - 16 - 6; // firstcolumnWidth (16px) + left Offset (-6px)
          miniMapWindowArea.style.width = `${miniMapAreaWidth * visibleAreaOfScroll}px`;
          // Let's add the right margin (-6px) to compensate the margins of the container fro display the inner window
          miniMapWindowArea.style.left = `${(miniMapAreaWidth - 6) * scrollPercentage + 16 + 4}px`;
        }
      }

      tableScroll[scrollProperty] = scrollPosition;

      isUpdatingRef.current = true;
      //console.log('scrollPosition', visibleAreaOfScroll, axis, scrollState.columnBrowserVisible);
      setScrollState(prevState => ({
        ...prevState,
        [axis === 'x' ? 'horizontalScroll' : 'verticalScroll']: scrollPosition,
        [axis === 'x' ? 'horizontalScrollPercentage' : 'verticalScrollPercentage']: scrollPercentage,
        scrollAreaWidth: tableMiddle.offsetWidth,
        scrollAreaHeight: tableYScroll[scrollDimension],
        visibleAreaOfScrollHorizontal: axis === 'x' ? visibleAreaOfScroll : prevState.visibleAreaOfScrollHorizontal,
        visibleAreaOfScrollVertical: axis === 'y' ? visibleAreaOfScroll : prevState.visibleAreaOfScrollVertical,
        // Column browser only visible when horizontal scroll needed
        columnBrowserVisible: axis === 'x' ? visibleAreaOfScroll < 1 : prevState.columnBrowserVisible,
        // Minimap visible when vertical scroll needed
        minimapVisible: axis === 'y' ? visibleAreaOfScroll < 1 : prevState.minimapVisible,
      }));

      requestAnimationFrame(() => {
        isUpdatingRef.current = false;
      });
    },
    [setScrollState]
  );

  useEffect(() => {
    updateScrollProperties(scrollChange.axis, scrollChange.amount, scrollChange.browser);
  }, [scrollChange, updateScrollProperties]);

  useEffect(() => {
    updateScrollProperties('y', 0, true);
    setTimeout(() => {
      updateScrollProperties('x', 0, true);
    }, 20);
  }, [refreshBrowsers, updateScrollProperties]);

  useEffect(() => {
    const handleResize = () => {
      updateScrollProperties('y');
      updateScrollProperties('x');
      // Manage Shadows
      overflowShadowing();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [updateScrollProperties]);

  useEffect(() => {
    updateScrollProperties('y');
    updateScrollProperties('x');
  }, [filters, actions, updateScrollProperties]);

  // Enable custom scroll to control properly the scroll of the table
  useEffect(() => {
    let wheelTimeout: NodeJS.Timeout;
    let wheelStartTimeout: NodeJS.Timeout;
    let isWheeling = false;
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();

      if (!isWheeling) {
        wheelStartTimeout = setTimeout(() => {
          setIsScrolling(true);
        }, 150);
        isWheeling = true;
      }

      clearTimeout(wheelTimeout);
      wheelTimeout = setTimeout(() => {
        setIsScrolling(false);
        isWheeling = false;
      }, 150);

      // Get scroll elements
      const tableMiddle = document.getElementById('table-middle');
      const tableYScroll = document.getElementById('table-y-scroll');
      const headerMiddle = document.getElementById('header-middle');
      if (!tableMiddle || !tableYScroll || !headerMiddle) return;

      // Prevent scroll when resizing
      if (isResizing) return;

      const deltaX = event.deltaX;
      const deltaY = event.deltaY;

      // Handle horizontal scroll (shift + wheel or trackpad horizontal swipe)
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        const newScrollLeft = tableMiddle.scrollLeft + deltaX;
        tableMiddle.scrollLeft = newScrollLeft;
        headerMiddle.scrollLeft = newScrollLeft;
        updateScrollProperties('x');
      }
      // Handle vertical scroll
      else if (deltaY !== 0) {
        const newScrollTop = tableYScroll.scrollTop + deltaY;
        tableYScroll.scrollTop = newScrollTop;
        updateScrollProperties('y');
      }
    };

    const table = document.getElementById('new-table');
    if (table && !isResizing) {
      table.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (table) {
        table.removeEventListener('wheel', handleWheel);
      }
      clearTimeout(wheelTimeout);
      clearTimeout(wheelStartTimeout);
    };
  }, [isResizing, setIsScrolling, updateScrollProperties]);

  return {
    updateScrollProperties,
  };
};
