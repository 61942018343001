import React, { forwardRef, SyntheticEvent } from 'react';
import { Whisper, Tooltip } from 'rsuite';
import classNames from 'classnames';
import { prefixClass } from 'lib/utils';

import './STBIcon.scss';

type STBIconProps = {
  tooltip?: string;
  disabled?: boolean;
  icon: React.ReactElement;
  handleClick: (e: SyntheticEvent<HTMLButtonElement, Event>) => void;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};

const STBIcon = forwardRef<HTMLButtonElement, STBIconProps>(
  ({ tooltip, disabled, icon, placement = 'top', handleClick }, ref) => {
    const prefix = prefixClass('STB-Icon');
    return (
      <Whisper placement={placement} trigger="hover" speaker={<Tooltip>{tooltip}</Tooltip>} delayOpen={600}>
        <button
          ref={ref}
          className={classNames(prefix(), { disabled: disabled || !handleClick })}
          onClick={e => !disabled && handleClick(e)}
          aria-disabled={disabled}
        >
          {icon}
        </button>
      </Whisper>
    );
  }
);

export default STBIcon;
