import { JournalEntry } from '@hone-automation/common';
import { useMutation } from '@tanstack/react-query';
import { useQueryState } from 'hooks/useQueryState';
import { FIVE_SECONDS, TEN_SECONDS, TOAST_ERROR, TOAST_SUCCESS, showToast } from 'lib/utils';

import { makeRemoteCreateUpdateJeAccruals } from 'main/factories/usecases';
import { useParams, useNavigate } from 'react-router-dom';

export const useCreateUpdateJEAccruals = () => {
  //   const { locationId: currentLocationId } = useParams();
  //   const navigateTo = useNavigate();

  //   const [dateRange] = useQueryState("dateRange");

  return useMutation({
    mutationFn: (journalEntries: JournalEntry[]) => {
      const service = makeRemoteCreateUpdateJeAccruals();
      return service.createJEAccruals(journalEntries);
    },
    onError: () => {
      showToast('Error while updating request', TOAST_ERROR, TEN_SECONDS);
    },
    onSuccess: () => {
      showToast('Journal Entry saved successfully', TOAST_SUCCESS, FIVE_SECONDS);
    },
  });
};
