import './NTLegend.scss';
import { prefixClass } from 'lib/utils';
import classNames from 'classnames';

export type NTLegendItem = {
  name: string;
  color: string;
  circular?: boolean;
  enabled?: boolean;
};

export type NTLegendProps = {
  legendItems: NTLegendItem[];
  vertical?: boolean;
  contained?: boolean;
};

const NTLegend = (props: NTLegendProps) => {
  const { legendItems, vertical, contained } = props;
  const prefix = prefixClass('nt-legend');
  if (!legendItems || !legendItems.length) {
    return null;
  }
  return (
    <div className={classNames(prefix(), { vertical: vertical, contained: contained })}>
      {legendItems.map((legendItem, index) => {
        const { name, color, enabled = true } = legendItem;
        if (!enabled) {
          return null;
        }
        return (
          <div key={index} className={classNames(prefix('item'), { vertical })}>
            <div
              className={classNames(prefix('item-color'), { rounded: legendItem.circular })}
              style={{ backgroundColor: color }}
            />
            <div className={prefix('item-name')}>{name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default NTLegend;
