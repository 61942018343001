import { useCallback, useMemo, useRef, useState } from 'react';
import { metricsPeriodPlot, metricsPeriodPlotWeek } from '../Dashboard/DParser/types';
import { formatAmountCondensed } from '../NewTable/NTCell/ValueFormatters/ValueFormatters';
import { ChartArea, ChartPoint, ConceptLabel, plotData, Point, SplitAreas, VisualConfig } from './types';
import { findAreaAtPoint, parsePoints, pointsToString, reorderPolygonPoints, splitPolygon } from './KSChartUtils';
import React from 'react';
import { throttle } from 'lodash';
import DashboardChartTooltip from './DashboardChartTooltip/DashboardChartTooltip';

const formatK = (value: number) => formatAmountCondensed(value, true);

export const visualConfig: VisualConfig = {
  fontSize: '11',
  fontFamily: 'var(--ks-ui-titles-font)',
  fontColors: {
    sales: 'var(--ks-black)',
    default: 'var(--ks-dark-medium-grey)',
    future: 'var(--ks-light-grey)',
  },
  colors: {
    salesLine: 'var(--ks-chart-dark-bluish-solid)',
    normalLine: '#7D7C7B66',
    connectorLine: '#7D7C7BCC',
    zeroLine: '#898989',
    gridline: '#e0e0e088',
    salesArea: '#7DD6E899',
    expensesArea0: '#EDE7C966',
    expensesArea1: 'var(--ks-hover)',
    expensesArea2: 'var(--ks-app-bg)',
    expensesArea3: '#7B84B732',
  },
  legends: {
    Profit: 'var(--ks-chart-bluish)',
    Loss: 'var(--ks-chart-redish)',
  },
  layout: {
    padding: 60,
    xLabelsDistance: 20,
    xLabelsOffset: 10,
    reservedVerticalSpaceForHeaderAndLegend: 50,
  },
};

type DashboardChartProps = {
  period: metricsPeriodPlot;
  customWitdth?: number;
  customHeight?: number;
  cumulative?: boolean;
  handleMouseMove?: (event: React.MouseEvent) => void;
  handleMouseLeave?: (event: React.MouseEvent) => void;
  yAxisSteps?: number;
};

type InfoAreaValues = {
  date: string;
  week: number;
  discrete: number;
  cumulative: number;
};

export type InfoArea = {
  type: 'area' | 'p&l' | 'line' | 'point';
  key: string;
  values: InfoAreaValues[];
  polygonArea: string;
  isProfit?: boolean;
  color: string;
};

export type hoverInfo = {
  info: InfoArea;
  x: number;
  y: number;
  week: number;
};

const DashboardChart = (props: DashboardChartProps) => {
  const {
    period,
    customWitdth,
    customHeight,
    cumulative = true,
    handleMouseMove,
    handleMouseLeave,
    yAxisSteps = 6,
  } = props;

  // **** Painting the chart
  if (!period) {
    return <div>No data available</div>;
  }

  const svgRef = useRef<SVGSVGElement>(null);
  const [hoverInfo, setHoverInfo] = useState<hoverInfo | null>(null);
  const [hoveredKey, setHoveredKey] = useState<string | null>(null);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
  const [pendingHoverKey, setPendingHoverKey] = useState<string | null>(null);
  const [hoveredWeek, setHoveredWeek] = useState<number | null>(null);
  const infoAreas: InfoArea[] = [];
  const tooltipDims = { width: 160, height: 120 };

  // Create a throttled handler function
  const handleSvgMouseMove = useCallback(
    throttle((e: React.MouseEvent<SVGSVGElement>) => {
      if (!svgRef.current) return;

      const svgRect = svgRef.current.getBoundingClientRect();
      const x = e.clientX - svgRect.left;
      const y = e.clientY - svgRect.top;

      // Find which area contains this point
      const hoveredArea: InfoArea | null = findAreaAtPoint({ x: x, y: y }, infoAreas);

      // Now let's modify the tooltip display coordinates so it always stays inside the chart
      const tooltipX =
        x + tooltipDims.width > svgRect.width
          ? x - tooltipDims.width
          : x > tooltipDims.width
            ? x - tooltipDims.width / 2
            : x;
      const tooltipY =
        y + tooltipDims.height > svgRect.height
          ? y - tooltipDims.height - 20
          : y > tooltipDims.height
            ? y - tooltipDims.height
            : y + 20;

      /*     console.log(
        x,
        tooltipX,
        tooltipDims.width,
        svgRect.left,
        svgRect.right,
        y,
        tooltipY,
        tooltipDims.height,
        svgRect.top,
        svgRect.bottom,
        hoveredArea
      ); */

      // Let's calculate the week number from the x coordinate using the width and the number of period.weeks
      const week = Math.min(
        Math.floor((x - 60) / ((svgRect.width - 120) / period.weeks.length)),
        period.weeks.length - 1
      );

      if (hoveredArea) {
        setHoverInfo({ info: hoveredArea, x: tooltipX, y: tooltipY, week: week });
        setHoveredKey(hoveredArea.key);
        setHoveredWeek(week);
      } else {
        setHoverInfo(null);
        setHoveredKey(null);
        setHoveredWeek(null);
      }

      // Call the prop handler if provided
      handleMouseMove?.(e);
    }, 30),
    [handleMouseMove, period.periodTitle, cumulative]
  );

  // Setting up physical dimensions
  const width = customWitdth || 340;
  const height = (customHeight || 400) - visualConfig.layout.reservedVerticalSpaceForHeaderAndLegend;
  const paddingVertical = 10;
  const conceptLabels: ConceptLabel[] = [];

  // Calculating max value for Y-axis
  const maxChartAreaHeight = height - visualConfig.layout.xLabelsDistance - 2 * paddingVertical;
  const yOrigin = height - visualConfig.layout.xLabelsDistance - paddingVertical;

  // Setting up Scale functions
  const xSteps = period.weeks.length - 1;
  const minValue = cumulative ? period.minCummulative : period.minValue;
  const maxValue = cumulative ? period.maxCummulative : period.maxValue;
  const valueRange = maxValue - minValue;

  const scaledY = (value: number) => {
    const proportion = Math.max(0, Math.min(1, (value - minValue) / valueRange));
    return yOrigin - (isNaN(proportion) ? 0 : proportion) * maxChartAreaHeight;
  };

  const scaledX = (index: number) =>
    visualConfig.layout.padding + (index / xSteps) * (width - 2 * visualConfig.layout.padding);

  // Drawing the Y Axis's left labels and lines, Inluding the ZeroLine
  const yAxis = () => {
    const yAxisLabels = Array.from({ length: yAxisSteps }).map((_, i) => {
      const fraction = i / (yAxisSteps - 1);
      const value = minValue + valueRange * fraction;
      const roundedValue = Math.round(value);
      const yLocal = scaledY(roundedValue);
      return (
        <g key={`y-axis-${i}`}>
          <text
            x={visualConfig.layout.padding - 10}
            y={yLocal}
            textAnchor="end"
            alignmentBaseline="middle"
            fontSize={visualConfig.fontSize}
            fontFamily={visualConfig.fontFamily}
            fill={visualConfig.fontColors.default}
          >
            {formatK(value)}
          </text>
          <line
            x1={visualConfig.layout.padding}
            y1={yLocal}
            x2={width - visualConfig.layout.padding}
            y2={yLocal}
            stroke={roundedValue === 0 ? '#e0e0e0' : '#e0e0e066'}
            strokeWidth={roundedValue === 0 ? 1.5 : 1}
          />
        </g>
      );
    });

    const hasZeroValue = Array.from({ length: yAxisSteps }).some((_, i) => {
      const fraction = i / (yAxisSteps - 1);
      const value = minValue + valueRange * fraction;
      return Math.round(value) === 0;
    });

    if (minValue < 0 && maxValue > 0 && !hasZeroValue) {
      const zeroY = scaledY(0);
      yAxisLabels.push(
        <g key="zero-line">
          <text
            x={visualConfig.layout.padding - 10}
            y={zeroY}
            textAnchor="end"
            alignmentBaseline="middle"
            fontSize={visualConfig.fontSize}
            fontFamily={visualConfig.fontFamily}
            fill={visualConfig.fontColors.default}
          >
            0
          </text>
          <line
            x1={visualConfig.layout.padding}
            y1={zeroY}
            x2={width - visualConfig.layout.padding}
            y2={zeroY}
            stroke={visualConfig.colors.zeroLine}
            strokeWidth={1.5}
          />
        </g>
      );
    }
    return yAxisLabels;
  };

  // Drawing the X Axis labels and lines
  const xAxis = (weeks: metricsPeriodPlotWeek[]) => {
    return weeks.map((item, index) => (
      <g key={item.weekId}>
        <text
          key={item.weekId}
          x={scaledX(index)}
          y={height - visualConfig.layout.xLabelsDistance + visualConfig.layout.xLabelsOffset}
          textAnchor={index === weeks.length - 1 ? 'end' : index === 0 ? 'start' : 'middle'}
          fontSize={visualConfig.fontSize}
          fontWeight={item.currentWeek ? 600 : 400}
          fontFamily={visualConfig.fontFamily}
          fill={item.noData ? visualConfig.fontColors.future : visualConfig.fontColors.sales}
        >
          W{item.weekId}
        </text>
        <line
          x1={scaledX(index)}
          y1={paddingVertical}
          x2={scaledX(index)}
          y2={yOrigin}
          stroke={hoveredWeek !== null && hoveredWeek === index ? 'var(--ks-dark-grey)' : visualConfig.colors.gridline}
          strokeDasharray={hoveredWeek !== null && hoveredWeek === index ? '3 3' : ''}
        />
      </g>
    ));
  };

  // Utility function to transform plot points (data line + area) into SVG polygon strings ino the chart coordinates
  const transformPlotPoints = (points: ChartArea, trace: boolean): { all: string; line: string } => {
    const line = points.line.map(point => (point.plot ? `${scaledX(point.x)},${scaledY(point.y)}` : '')).join(' ');
    const base = [...points.base]
      .reverse()
      .map(point => (point.plot ? `${scaledX(point.x)},${scaledY(point.y) - 1}` : ''))
      .join(' ');
    const allPoints = [line, base].join(' ');
    return { all: allPoints, line: line };
  };

  // Calculting the plot data for each concept. Resolving the data stacking: one chart above the other
  const plotConcept = (
    key: string,
    weeks: metricsPeriodPlotWeek[],
    keyMetrixIndex: number,
    prevArea?: ChartArea
  ): plotData => {
    let isSales = false;
    const points: ChartArea = { line: [], base: [] };
    const infoValues: InfoAreaValues[] = [];
    weeks.forEach((item, index) => {
      if (item.noData) {
        //if (isSales) console.log(item.weekId, key, 'No Data', prevY);
        const prevY = prevArea?.line[index].y ?? 0;
        points.line.push({ x: index, y: prevY, plot: false });
        points.base.push({ x: index, y: prevY, plot: false });
        return;
      }
      const localValue = item.data[key];
      infoValues.push({
        discrete: localValue.value,
        date: item.start + ' - ' + item.end,
        week: item.weekId,
        cumulative: localValue.cumulative,
      });
      isSales = localValue.isSales;
      const solvedValue = cumulative ? localValue.cumulative : localValue.value;
      const prevY = prevArea?.line[index].y ?? 0;
      points.line.push({ x: index, y: isSales ? solvedValue : (prevY < 0 ? 0 : prevY) + solvedValue, plot: true });
      points.base.push({ x: index, y: prevY >= 0 ? prevY : 0, plot: true });
    });

    const stringPoints = transformPlotPoints(points, isSales);
    infoAreas.push({
      type: isSales ? 'p&l' : 'area',
      key,
      polygonArea: stringPoints.all,
      values: infoValues,
      isProfit: isSales,
      color: visualConfig.colors[`expensesArea${keyMetrixIndex}`],
    });
    //console.log(stringPoints);
    return { key, points, isSales, stringPolygon: stringPoints };
  };

  // Drawing the plot data for each concept
  const drawConcept = ({
    key,
    isSales,
    plotPoints,
    lastValue,
    index,
  }: {
    key: string;
    isSales: boolean;
    plotPoints: { all: string; line: string };
    lastValue: number;
    index: number;
  }) => {
    const isHovered = hoveredKey === key;
    return (
      <g key={`plot_${key}`}>
        <polygon
          key={`polygon_${key}`}
          points={plotPoints.all}
          fill={visualConfig.colors[`expensesArea${index}`]}
          fillOpacity={isHovered || !hoveredKey ? 1 : 0.2}
        />
        <polyline
          key={`polyline__${key}`}
          points={plotPoints.line}
          fill="none"
          stroke={isSales ? visualConfig.colors.salesLine : visualConfig.colors.normalLine}
          strokeWidth={1}
        />
      </g>
    );
  };

  // Drawing the concept labels
  const drawConceptLabels = (data: ConceptLabel[]) => {
    if (!data || data.length === 0) return null;
    const labelXOrigin = width - visualConfig.layout.padding + 10;

    const calcConnector = (concept: ConceptLabel) => {
      const connector = pointsToString([
        { x: scaledX(concept.point.x), y: scaledY(concept.point.y) },
        { x: labelXOrigin - 4, y: scaledY(concept.point.y) },
      ]);
      return connector;
    };
    //const isHovered = hoveredKey === concept.key;
    return (
      <>
        {data.map((concept, index) => (
          <g key={`conceptLabel_${concept.key}`} opacity={hoveredKey === concept.key || !hoveredKey ? 1 : 0.4}>
            <polyline
              key={`connector__${concept.key}`}
              points={calcConnector(concept)}
              fill="none"
              stroke={visualConfig.colors.connectorLine}
              strokeWidth={hoveredKey === concept.key ? 2 : 1}
              strokeDasharray="1 1"
            />
            <text
              key={`label_${concept.key}`}
              x={labelXOrigin}
              y={scaledY(concept.point.y)}
              textAnchor="start"
              alignmentBaseline="middle"
              fontSize={visualConfig.fontSize}
              fontWeight={concept.isSales || hoveredKey === concept.key ? 600 : 400}
              fontFamily={visualConfig.fontFamily}
              fill={visualConfig.fontColors[concept.isSales || hoveredKey === concept.key ? 'sales' : 'default']}
            >
              {concept.key.length <= 5 ? concept.key : concept.key.substring(0, 4) + '...'}
            </text>
          </g>
        ))}
      </>
    );
  };

  // It darws the positive and negative areas for the profit/loss chart and draws the sales curve and label
  const drawPositiveNegative = (areas: SplitAreas) => {
    const { positive, negative, data, points } = areas;
    // let's join the areas
    const positiveAreas = positive.map((area: Point[]) => {
      return pointsToString(reorderPolygonPoints(area));
    });
    const negativeAreas = negative.map((area: Point[]) => {
      return pointsToString(reorderPolygonPoints(area));
    });
    if (positiveAreas.length === 0 && negativeAreas.length === 0) return null;
    // create infoAreas
    const parsePL2InfoAreas = (areas: string[], basePoints: Point[][], isProfit: boolean) => {
      if (!areas || areas.length === 0) return [];
      const infoLocalAreas: InfoArea[] = [];
      areas.forEach((area: string) => {
        const discrete = basePoints.reduce((acc, points) => {
          const yValues = points.map(p => p.y);
          const diff = Math.max(...yValues) - Math.min(...yValues);
          return acc + diff;
        }, 0);
        infoLocalAreas.push({
          type: 'p&l',
          key: isProfit ? 'profit' : 'loss',
          polygonArea: area,
          values: [
            {
              discrete: discrete,
              date: '',
              week: 0,
              cumulative: discrete,
            },
          ],
          isProfit: isProfit,
          color: isProfit ? visualConfig.legends.Profit : visualConfig.legends.Loss,
        });
      });
      return infoLocalAreas;
    };

    //console.log(parsePL2InfoAreas(positiveAreas, positive, true));
    infoAreas.push(...parsePL2InfoAreas(positiveAreas, positive, true));
    infoAreas.push(...parsePL2InfoAreas(negativeAreas, negative, false));

    const isHoveredProfit = hoveredKey === 'profit';
    const isHoveredLoss = hoveredKey === 'loss';
    return (
      <g key={`plot_positive-negative`}>
        {positiveAreas.map((area: string) => (
          <polygon
            key={`polygon_${area}`}
            points={area}
            fill={visualConfig.legends.Profit}
            fillOpacity={isHoveredProfit || !hoveredKey ? 0.8 : 0}
          />
        ))}
        {negativeAreas.map((area: string) => (
          <polygon
            key={`polygon_${area}`}
            points={area}
            fill={visualConfig.legends.Loss}
            fillOpacity={isHoveredLoss || !hoveredKey ? 0.8 : 0}
          />
        ))}
        <polyline
          key={`polyline_sales`}
          points={data.line}
          fill="none"
          stroke={
            isHoveredProfit || isHoveredLoss || !hoveredKey
              ? visualConfig.colors.salesLine
              : visualConfig.colors.normalLine
          }
          strokeWidth={4}
          strokeOpacity={isHoveredProfit || isHoveredLoss || !hoveredKey ? 1 : 0.4}
        />
      </g>
    );
  };
  // Creates the chart contents by iteratting over the metrics
  const chart = (metricKeys: string[]) => {
    const plotAreas: plotData[] = [];
    infoAreas.length = 0;
    let localPositiveNegative: SplitAreas | null = null;
    const localConceptLabels: ConceptLabel[] = [];

    // first we get the plot data for each concept
    metricKeys.forEach((key, index) => {
      const prevArea = plotAreas.length > 0 ? plotAreas[plotAreas.length - 1] : undefined;
      plotAreas.push(plotConcept(key, period.weeks, index, prevArea?.points));
    });

    //console.log(infoAreas);

    const elements = plotAreas.map((area: plotData, index: number) => {
      const { points, key, isSales, stringPolygon } = area;

      // Store concept labels in the local array instead of the outer variable
      const lastValue = points.line.filter(point => point.plot).slice(-1)[0].y;
      localConceptLabels.push({ key, point: points.line.filter(point => point.plot).slice(-1)[0], isSales });

      if (isSales) {
        localPositiveNegative = splitPolygon(stringPolygon, points);
        return null;
      } else {
        return drawConcept({ plotPoints: stringPolygon, key, isSales, lastValue, index });
      }
    });

    return {
      elements: elements.filter(Boolean),
      positiveNegative: localPositiveNegative,
      conceptLabels: localConceptLabels,
    };
  };

  // Then use the returned values in your memoized components
  const chartResult = useMemo(() => chart(period.metricKeys), [period.metricKeys, cumulative, hoveredKey]);
  const positiveNegativeElements = useMemo(
    () => chartResult.positiveNegative && drawPositiveNegative(chartResult.positiveNegative),
    [chartResult.positiveNegative, cumulative]
  );
  const yAxisElements = useMemo(() => yAxis(), [minValue, maxValue, valueRange, yAxisSteps, cumulative]);
  const xAxisElements = useMemo(() => xAxis(period.weeks), [period.weeks, width, cumulative, hoveredWeek]);
  const conceptLabelElements = useMemo(
    () => drawConceptLabels(chartResult.conceptLabels),
    [chartResult.conceptLabels, cumulative]
  );

  return (
    <svg
      ref={svgRef}
      width={width}
      height={height}
      onMouseMove={handleSvgMouseMove}
      onMouseLeave={e => {
        setHoverInfo(null);
        setHoveredKey(null);
        setHoveredWeek(null);
        handleMouseLeave?.(e);
      }}
    >
      <style>
        {`
    polygon, polyline {
      transition: fill-opacity 0.1s, stroke-opacity 0.1s;
    }
  `}
      </style>
      {chartResult.elements}
      {positiveNegativeElements}
      {yAxisElements}
      {xAxisElements}
      {conceptLabelElements}

      {/* tooltip*/}
      {hoverInfo && (
        <foreignObject
          x={hoverInfo.x} // Adjust positioning as needed
          y={hoverInfo.y}
          width={tooltipDims.width}
          height={tooltipDims.height}
        >
          <DashboardChartTooltip hoverInfo={hoverInfo} />
        </foreignObject>
      )}
    </svg>
  );
};
export default React.memo(DashboardChart);
