import React, { useState } from 'react';
import Avatar from '../common/AvatarContainer';
import './ChatMessage.scss';
import { User } from '@hone-automation/common';
import { useQueueContext } from '../QueueContext';
import { formatFromUtcToTimezone } from '../../../../lib/utils';
import DOMPurify from 'dompurify';
import { convertFromShortcodes } from '../utils/emojiParser';
import { IconMore } from 'components/Icons';
import { format } from 'date-fns';

interface ChatMessageProps {
  user?: User;
  editable: boolean;
  date?: Date;
  showDate: boolean;
  content: string;
  glCode?: boolean;
  createdAt: Date;
}

/**
 * Renders a chat message component with user details, content, and optional date display.
 *
 * @param {ChatMessageProps} props - The properties for rendering the chat message.
 * @param {User} [props.user] - Optional user information for the message.
 * @param {boolean} props.showDate - Flag to determine whether to display the message date.
 * @param {Date} [props.date] - Optional date of the message.
 * @param {string} props.content - The content of the chat message.
 * @returns {JSX.Element} A rendered chat message component.
 */
function ChatMessage({ user, showDate, date, content }: ChatMessageProps) {
  const {
    state: { meId },
  } = useQueueContext();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleEdit = () => {
    setShowDropdown(false);
    // Handle edit action
  };

  const handleDelete = () => {
    setShowDropdown(false);
    // Handle delete action
  };

  const userInfo = {
    userName: user?.name || '',
    userId: user?.userId || '',
    title: '',
    email: user?.name || '',
    colorId: '1',
  };

  const time = date && format(date, 'h:mm a');
  const chatDate = date && format(date, 'LLL do, yyyy');
  const purifyConfig = {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'u', 'a', 'ul', 'ol', 'li', 'p', 'br', 'strike', 'del'],
    ALLOWED_ATTR: ['href', 'target', 'rel', 'style', 'class'],
    ADD_ATTR: ['target', 'rel'],
    KEEP_CONTENT: true,
  };
  const sanitizedContent = convertFromShortcodes(DOMPurify.sanitize(content, purifyConfig));

  const parsedContent = { __html: sanitizedContent };

  const isKit = userInfo.userName.toLowerCase() === 'kit' || userInfo.userId === '4emD3RxpsUcnDgndL2vMbHryHJU2';

  return (
    <>
      {showDate && date && <div className="chat-block-date">{chatDate}</div>}
      <div className="message">
        <Avatar me={user?.userId === meId} userInfo={userInfo} />
        <div className="message__content">
          <div className="message__header">
            <div className="message__sender-info">
              <span className="message__sender">{isKit ? 'KAI' : userInfo.userName}</span>
              <span className="message__time">{time}</span>
            </div>
            {/* <button className="message__more-button" onClick={() => setShowDropdown(!showDropdown)}>
              <IconMore />
            </button> */}
            {/*{showDropdown && (*/}
            {/*  <div className="message__dropdown">*/}
            {/*    <button className="message__dropdown-item" onClick={handleEdit}>*/}
            {/*      Edit*/}
            {/*    </button>*/}
            {/*    <button className="message__dropdown-item message__dropdown-item--delete" onClick={handleDelete}>*/}
            {/*      Delete*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
          {/* <p className="message__text">{content}</p> */}
          <p className="message__text" dangerouslySetInnerHTML={parsedContent}></p>
          {/* TODO */}
          {/*{message.comment && <MessageAttachments attachments={} />}*/}
        </div>
      </div>
    </>
  );
}

export default ChatMessage;
