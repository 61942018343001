import { cloneDeep, find } from 'lodash';
import { format, parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { Tooltip, Whisper } from 'rsuite';
import { commonUtil, ReportTypes, StartEndDates } from '@hone-automation/common';
import { BALANCE_SHEET_PRESETS } from './utils';

function extractRow({ title, data, level, display }: NestedSection): FlatDatatableRow {
  return { title, data, level, display };
}

function collectRows(sections: NestedSection[]): FlatDatatableRow[] {
  const cloneSections = cloneDeep(sections);
  const rows = [];

  // collect rows from nested sections
  while (cloneSections.length > 0) {
    const section = cloneSections.shift();

    if (section) {
      const row = extractRow(section);

      if (!row.display) {
        // deprecated behavior to clear row data for those we don't want to render cells, prefer display flag instead
        // FIXME magic strings
        const keepTitles = ['total', 'gross', 'net', 'prime', 'kitchen'];
        if (row.level === 0 && !find(keepTitles, (title: string) => row.title.toLowerCase().startsWith(title))) {
          row.label = true;
        }
      }

      rows.push(row);
      if (section.sections.length > 0) cloneSections.unshift(...section.sections);
    }
  }

  return rows;
}

const groupedByMonth = (data: any, monthly = false, customKey = 'Custom') => {
  // Grouping data by year or year-month
  const grouped = data.reduce((acc: any, obj: any) => {
    if (Object.keys(BALANCE_SHEET_PRESETS).includes(obj.id)) {
      if (!acc['Custom']) {
        acc['Custom'] = [];
      }
      acc['Custom'].push(obj);
      return acc;
    }

    const date = parse(obj.startDate.split('T')[0], 'yyyy-MM-dd', new Date());
    const _format = monthly ? 'yyyy' : 'MMM yyyy';

    const yearMonth = format(date, _format);

    if (!acc[yearMonth]) {
      acc[yearMonth] = [];
    }

    acc[yearMonth].push(obj);
    return acc;
  }, {});

  // Sorting the keys (year or year-month) in descending order
  const sortedKeys = Object.keys(grouped).sort((a, b) => {
    // Check if any of the keys is the custom key
    if (a === customKey) return -1;
    if (b === customKey) return 1;

    // Parsing the keys as dates for comparison
    const dateA = monthly ? new Date(`${a}-01`) : new Date(a);
    const dateB = monthly ? new Date(`${b}-01`) : new Date(b);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return dateB - dateA;
  });

  // Creating a new object with sorted data
  const sortedGrouped = {};
  sortedKeys.forEach(key => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sortedGrouped[key] = grouped[key];
  });

  return {
    sortedKeys,
    data: sortedGrouped,
  };
};

const DATE_FORMAT = 'MM-dd-yyyy';

function formatDateReport(date: string | undefined) {
  if (!date) return;
  return formatInTimeZone(new Date(`${date}T00:00:00`), 'America/New_York', DATE_FORMAT);
}

const getTransformTimeFrame = (reportTimeframe: HoneReportTimeframe) => {
  const timeframes = { Weekly: 'Week', Monthly: 'Month', Quarterly: 'Quarter', 'YTD Monthly': 'Year' };
  return timeframes[reportTimeframe as keyof typeof timeframes];
};

const convertTimeframeToReportType = (timeframe: HoneReportTimeframe | undefined) => {
  if (!timeframe) return;
  if (timeframe === 'YTD' || timeframe === 'YTD Monthly') return 'income-statement';
  return timeframe.toLowerCase();
};

const getPrintedHeaderDate = (
  headerFormatted: string,
  compareLocations: string | null,
  returnJustLabel: boolean = false
) => {
  if (headerFormatted.includes('(') && compareLocations && !returnJustLabel) {
    return (
      <Whisper
        trigger="hover"
        placement="top"
        controlId={`${headerFormatted}-id`}
        speaker={<Tooltip>{headerFormatted}</Tooltip>}
      >
        <span className="cell_header_location">{headerFormatted.split('(')[0]}</span>
      </Whisper>
    );
  }

  return headerFormatted;
};

const formatRangeValues = (range: string | null | undefined, period: string | undefined) => {
  if (!period) return range;
  const parts = (range && range.split(' - ')) || [];
  if (parts.length == 0) return `Date not provided`;

  const startDate = commonUtil.fromShortString(parts[0]);
  if (parts.length === 2) {
    const endDate = commonUtil.fromShortString(parts[1]);
    /* Combining the dates into a single string
         Same year in both parts returns the date in format DATE - DATE, 2023
      */
    if (startDate.getFullYear() === endDate.getFullYear()) {
      // Same month in both parts returns the date in format JUNE 1 - 30, 2023
      if (startDate.getMonth() === endDate.getMonth()) {
        return `${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${endDate.toLocaleDateString(
          'en-US',
          { day: 'numeric' }
        )}, ${startDate.getFullYear()}`;
      } else {
        return `${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${endDate.toLocaleDateString(
          'en-US',
          { month: 'short', day: 'numeric' }
        )}, ${startDate.getFullYear()}`;
      }
    } else {
      // full formatted dates
      const formattedStartDate = startDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      const formattedEndDate = endDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
  }
  return startDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

function formatShortDate(dateStr: string | undefined) {
  if (!dateStr) return;
  return commonUtil.fromShortString(dateStr).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

function getCurrentValidUrl(
  pathname: string,
  locationId: string,
  searchParams: URLSearchParams,
  forecastingEnabled: boolean,
  canReadBookkeeperControls: boolean,
  journalEntriesEnabled: boolean,
  availableReportsTypes: string[]
): string {
  const currentPath = pathname.split('/').slice(4).join('/');
  if (!currentPath) {
    return `/app/location/${locationId}/dashboard`;
  }

  // const transformedUrl = {
  //   'report/weekly': 'P&L Comparison',
  //   'report/balance-sheet': 'Balance Sheet',
  //   'report/cash-flow': 'Cash Flow',
  //   'report/ap-aging': 'AP Aging',
  // };
  const types = [
    { path: 'report/weekly', type: ReportTypes.PLComparison },
    { path: 'report/monthly', type: ReportTypes.PLComparison },
    { path: 'report/balance-sheet', type: ReportTypes.BalanceSheet },
    { path: 'report/cash-flow', type: ReportTypes.CashFlow },
    { path: 'report/ap-aging', type: ReportTypes.APAging },
  ];

  if (currentPath === 'journal-entries' && journalEntriesEnabled) {
    return `/app/location/${locationId}/journal-entries`;
  }

  if (currentPath === 'bookkeeper' && canReadBookkeeperControls) {
    const activeTab = searchParams.get('activetab');
    const activeTabStr = activeTab ? `?activetab=${activeTab}` : '';
    return `/app/location/${locationId}/bookkeeper${activeTabStr}`;
  }

  if (currentPath === 'forecast' && forecastingEnabled) {
    return `/app/location/${locationId}/dashboard`;
  }

  if (currentPath === 'ask-requests') {
    return `/app/location/${locationId}/ask-requests`;
  }

  const type = types.find(t => currentPath.startsWith(t.path));
  //const reportType = transformedUrl[currentPath as keyof typeof transformedUrl];
  if (type && availableReportsTypes?.length && availableReportsTypes.includes(type.type)) {
    //return `/app/location/${locationId}/${currentPath}`;
    return `/app/location/${locationId}/${type.type === ReportTypes.BalanceSheet ? 'report/balance-sheet/6-months' : type.path}`;
  }

  return `/app/location/${locationId}/dashboard`;
}

function getInitialReportDateRanges(newDateRanges: StartEndDates[]) {
  if (newDateRanges) {
    const now = commonUtil.toShortString(new Date());
    const endInTheFuture = newDateRanges[0]?.end >= now;
    return endInTheFuture ? newDateRanges[1] : newDateRanges[0];
  }
}

export {
  collectRows,
  groupedByMonth,
  formatDateReport,
  DATE_FORMAT,
  getTransformTimeFrame,
  convertTimeframeToReportType,
  getPrintedHeaderDate,
  formatRangeValues,
  formatShortDate,
  getCurrentValidUrl,
  getInitialReportDateRanges,
};
