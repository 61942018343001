import './ValueFormatters.scss';
import { prefixClass } from 'lib/utils';
import classNames from 'classnames';

const prefix = prefixClass('value-formatters');

export const formatValue = (amount: number, decimals: number = 0, locale: string = 'en-US') => {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount);
};

export const formatAmountCondensed = (amount: number | null, zero = false) => {
  if (amount === null) return ' ';
  if (amount === 0) return zero ? '0' : '--';

  // Millions
  if (Math.abs(amount) >= 1000000) {
    let formatted = formatValue(Math.abs(amount) / 1000000, 1);
    if (formatted.indexOf('.0') > -1) formatted = formatted.replace('.0', '');
    return amount >= 0 ? `$${formatted}M` : `($${formatted}M)`;
  }
  //Thousands
  if (Math.abs(amount) >= 1000) {
    let formatted = formatValue(Math.abs(amount) / 1000, 1);
    if (formatted.indexOf('.0') > -1) formatted = formatted.replace('.0', '');
    return amount >= 0 ? `$${formatted}K` : `($${formatted}K)`;
  }
  // Normal values
  return amount >= 0 ? `$${formatValue(amount, 0)}` : `($${formatValue(Math.abs(amount), 0)})`;
};

export const formatAmount = (
  amount: number | null,
  decimals: number = 0,
  compact: boolean = false,
  unitless: boolean = false
) => {
  if (amount === null) return ' ';
  if (amount === 0) return compact ? '' : ' -- ';
  const units = unitless ? '' : compact ? '$' : '$ ';
  if (amount < 0) {
    return unitless ? formatValue(amount, decimals) : `(${units}${formatValue(Math.abs(amount), decimals)})`;
  }
  return `${units}${formatValue(amount, decimals)}`;
};

export const FormatActual = (
  value?: number | null,
  total?: boolean,
  small?: boolean,
  cb?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
) => {
  if (!value || value === 0)
    return (
      <div className={prefix('value')}>
        <div onClick={cb} className={classNames(prefix(), { 'has-callback': cb !== undefined })}>
          <div className={prefix('value-empty')}>--</div>
        </div>
      </div>
    );
  if (value < 0) {
    return (
      <div className={classNames(prefix('value'), { total: total, small: small })}>
        <div onClick={cb} className={classNames(prefix(), { 'has-callback': cb !== undefined })}>
          (<span>$</span>
          {formatValue(Math.abs(value))})
        </div>
      </div>
    );
  }
  return (
    <div className={classNames(prefix('value'), { total: total, small: small })}>
      <div onClick={cb} className={classNames(prefix(), { 'has-callback': cb !== undefined })}>
        <span>$</span>
        {formatValue(value)}
      </div>
    </div>
  );
};

export const FormatPercentage = (
  value?: number | null,
  total?: boolean,
  cb?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  forceZero?: boolean
) => {
  if (value === undefined || value === null || (!forceZero && value === 0))
    return (
      <div className={prefix('percentage')}>
        <div onClick={cb} className={classNames(prefix(), { 'has-callback': cb !== undefined })}>
          <div className={prefix('percentage-empty')}>--</div>
        </div>
      </div>
    );
  const perc = value * 100;
  if (value < 0) {
    return (
      <div className={classNames(prefix('percentage'), { total: total })}>
        <div onClick={cb} className={classNames(prefix(), { 'has-callback': cb !== undefined })}>
          -{formatValue(Math.abs(perc), 2)}
          <span>%</span>
        </div>
      </div>
    );
  }
  return (
    <div className={classNames(prefix('percentage'), { total: total })}>
      <div onClick={cb} className={classNames(prefix(), { 'has-callback': cb !== undefined })}>
        {value === 0 ? '0.00' : formatValue(perc, 2)}
        <span>%</span>
      </div>
    </div>
  );
};
