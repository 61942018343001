import { useEffect, useState } from 'react';
import './KSChart.scss';
import { metricsPeriodPlot, MetricsPlot } from '../Dashboard/DParser/types';
import { logger } from '../KSExport/reportNTParser/utils';
import PeriodSelector from './PeriodSelector';
import DashboardChart from './DashboardChart';
import ChartLegend from './ChartLegend';
import { prefixClass } from 'lib/utils';

type KSChartProps = {
  data: MetricsPlot;
  customWitdth?: number;
  customHeight?: number;
  period?: number;
  cumulative?: boolean;
};

const KSChart = ({ data, customWitdth, customHeight, period = 0 }: KSChartProps) => {
  const prefix = prefixClass('ks-dashboard-chart');
  const [currentPeriod, setCurrentPeriod] = useState<metricsPeriodPlot>(data.periods[period]);
  const [cumulative, setCummulative] = useState(true);

  // Tooltip setup
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const width = customWitdth || 600;

  const handleChangePeriod = (period: number) => {
    setCurrentPeriod(data.periods[period]);
  };

  useEffect(() => {
    //logger.msg('currentPeriod', currentPeriod);
  }, [currentPeriod]);

  if (!data || data.periods.length === 0) {
    return (
      <div className="ks-dashboard-chart">
        <div>No data available</div>
      </div>
    );
  }

  //logger.msg('KSChart', data);
  return (
    <div className={prefix()}>
      <PeriodSelector periods={data.periods} selectedPeriod={period} onChangePeriod={handleChangePeriod} />

      <DashboardChart
        period={currentPeriod}
        customWitdth={customWitdth}
        customHeight={customHeight}
        cumulative={cumulative}
      />

      <ChartLegend
        weekly={data.periods[period].periodtype === 'week'}
        handleClick={() => setCummulative(!cumulative)}
      />
    </div>
  );
};
export default KSChart;
