import { GetReportsConfig } from 'domain/usecases/reports/get-reports-config';
import { getWeekNumber } from './DchartData';
import { ChartMetricsData, metricsPeriodPlot, PeriodUnit } from './types';
import { formatRangeValues } from 'lib/reportUtils';

type PeriodConfig = {
  periodReporting: boolean;
  generateStartDate: string;
  periodConfiguration: number[]; // Example: [4, 4, 5]
  leapYearRestartDates: string[]; // Example: ["2023-12-25"]
};

type PeriodData = {
  period: number;
  year: number;
  weeks: number;
  startDate: string;
  endDate: string;
};

type RequestResult = {
  type: PeriodUnit;
  requestWeeks: number;
  data: PeriodData[];
};

// Helper function to format date as YYYY-MM-DD
function formatDate(date: Date): string {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}

/**
 * Calculate ISO week number according to ISO 8601 standard
 * Properly handles year boundaries and leap years
 */
function getISOWeekNumber(date: Date): number {
  return getISOWeekNumberExtended(date).weekNum;
}

// New function with extended information
function getISOWeekNumberExtended(date: Date): { weekNum: number; weekYear: number } {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);

  // Thursday in current week determines the year
  const thursday = new Date(d);
  thursday.setDate(d.getDate() + 4 - (d.getDay() || 7));

  // First Thursday of the year determines week 1
  const firstThursday = new Date(thursday.getFullYear(), 0, 1);

  // If January 1 is not a Thursday, find the first Thursday
  if (firstThursday.getDay() !== 4) {
    firstThursday.setMonth(0, 1 + ((4 - firstThursday.getDay() + 7) % 7));
  }

  // Calculate week number based on Thursdays
  const weekDiff = (thursday.getTime() - firstThursday.getTime()) / (7 * 24 * 60 * 60 * 1000);
  const weekNum = 1 + Math.floor(weekDiff);

  // Return both the week number and the year it belongs to
  return {
    weekNum: weekNum,
    weekYear: thursday.getFullYear(),
  };
}

/**
 * Gets month-based period boundaries with proper leap year handling
 * @param numPeriods Number of periods to return
 * @param today Reference date (defaults to current date)
 * @returns Period boundary data
 */
export const getMonthsBoundaries = (numPeriods: number = 3, today: Date = new Date()): RequestResult => {
  const currentDate = new Date(today);
  currentDate.setHours(0, 0, 0, 0);

  const result: PeriodData[] = [];
  let totalWeeks = 0;

  for (let monthOffset = 0; monthOffset > -numPeriods; monthOffset--) {
    const targetMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + monthOffset, 1);
    const year = targetMonth.getFullYear();
    const month = targetMonth.getMonth();

    // Get first and last day of the month (correctly handles leap years)
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0); // This correctly gets Feb 29 in leap years

    // Calculate how many weeks this month spans (handles leap years)
    const firstWeekStart = new Date(firstDay);
    firstWeekStart.setDate(firstWeekStart.getDate() - firstWeekStart.getDay()); // Go to Sunday

    // Ensure we have the correct last day accounting for leap years
    const lastWeekEnd = new Date(lastDay);
    const daysToAdd = 6 - lastWeekEnd.getDay();
    lastWeekEnd.setDate(lastWeekEnd.getDate() + daysToAdd); // Go to Saturday

    // Calculate complete weeks with proper date handling
    let completedWeeks = 0;
    if (monthOffset === 0) {
      // For current month, count only complete weeks and the current partial week
      const weekStart = new Date(firstWeekStart);

      // Track all week boundaries properly
      while (weekStart <= lastWeekEnd) {
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);

        // Ensure we're counting correctly relative to current date
        if (weekEnd < currentDate) {
          completedWeeks++;
        } else if (weekStart <= currentDate) {
          // Current week, count as partial
          completedWeeks++;
        }

        // Move to next week
        weekStart.setDate(weekStart.getDate() + 7);
      }
    } else {
      // For previous months, calculate total weeks
      // This approach is more precise for leap years than dividing by 7
      const weekStart = new Date(firstWeekStart);
      while (weekStart <= lastWeekEnd) {
        completedWeeks++;
        weekStart.setDate(weekStart.getDate() + 7);
      }
    }

    totalWeeks += completedWeeks;
    result.push({
      period: month + 1, // Month is 0-indexed, make it 1-indexed
      year: year,
      weeks: completedWeeks,
      startDate: formatDate(firstDay),
      endDate: formatDate(lastDay),
    });
  }

  return { type: 'month', requestWeeks: totalWeeks, data: result };
};
/**
 * Gets week-based period boundaries with proper leap year handling
 * @param weeksPerPeriod Number of weeks in each period
 * @param numPeriods Number of periods to return
 * @param today Reference date (defaults to current date)
 * @returns Period boundary data
 */
export const getWeeksBoundaries = (
  weeksPerPeriod: number = 4,
  numPeriods: number = 3,
  today: Date = new Date()
): RequestResult => {
  const currentDate = new Date(today);
  currentDate.setHours(0, 0, 0, 0);

  const result: PeriodData[] = [];
  let totalWeeks = 0;

  // Get the current week's Sunday
  const currentWeekStart = new Date(currentDate);
  currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());

  // Calculate backwards from current week with proper date handling
  for (let periodOffset = 0; periodOffset < numPeriods; periodOffset++) {
    // Calculate period end date (for first period, it's current week's end)
    const periodEndDate = new Date(currentWeekStart);
    periodEndDate.setDate(periodEndDate.getDate() + 6 - periodOffset * weeksPerPeriod * 7);

    // Calculate period start date with proper leap year handling
    const periodStartDate = new Date(periodEndDate);
    periodStartDate.setDate(periodStartDate.getDate() - (weeksPerPeriod - 1) * 7);

    // Precise handling of weeks, including leap year considerations
    totalWeeks += weeksPerPeriod;

    // Ensure we have the correct ISO week/year for both start and end dates
    const startWeekInfo = getISOWeekNumberExtended(periodStartDate);
    const endWeekInfo = getISOWeekNumberExtended(periodOffset === 0 ? currentDate : periodEndDate);

    result.push({
      period: numPeriods - periodOffset,
      year: periodStartDate.getFullYear(),
      weeks: weeksPerPeriod,
      startDate: formatDate(periodStartDate),
      endDate: formatDate(periodOffset === 0 ? currentDate : periodEndDate),
    });
  }

  return { type: 'week', requestWeeks: totalWeeks, data: result };
};
export const getPeriodsBoundaries = (
  config: PeriodConfig,
  numPeriods: number = 3,
  today: Date = new Date()
): RequestResult => {
  let currentDate = new Date(config.generateStartDate);
  const restartDates = config.leapYearRestartDates
    .map(date => new Date(date))
    .filter(date => date <= today)
    .sort((a, b) => b.getTime() - a.getTime()); // Get latest past restart date

  // If a valid restart date exists, use it as the new start date
  if (restartDates.length > 0) {
    currentDate = restartDates[0];
  }

  let periodIndex = 0;
  const lastThreePeriods: PeriodData[] = [];
  let periodCount = 1; // Tracks periods within the year

  // Loop through periods to find today's period
  while (currentDate <= today) {
    const weeks = config.periodConfiguration[periodIndex % 3]; // Cycle 4-4-5
    const periodStart = new Date(currentDate);
    const periodEnd = new Date(currentDate);
    periodEnd.setDate(periodEnd.getDate() + weeks * 7 - 1);

    // Set the correct year **based on the start date of the period**
    const periodYear = periodStart.getFullYear();

    if (currentDate <= today && today <= periodEnd) {
      // Calculate completed weeks in the current period
      const weekDifference = Math.floor((today.getTime() - currentDate.getTime()) / (7 * 24 * 60 * 60 * 1000));
      lastThreePeriods.push({
        period: periodCount,
        year: periodYear,
        weeks: weekDifference,
        startDate: periodStart.toISOString().split('T')[0],
        endDate: periodEnd.toISOString().split('T')[0],
      });
      break;
    }

    // Store previous periods
    lastThreePeriods.push({
      period: periodCount,
      year: periodYear,
      weeks: weeks,
      startDate: periodStart.toISOString().split('T')[0],
      endDate: periodEnd.toISOString().split('T')[0],
    });

    // Move to the next period
    currentDate.setDate(currentDate.getDate() + weeks * 7);
    periodIndex++;
    periodCount++;

    // Ensure each year **ends at Period 13** and resets at 1 for the next year
    if (periodCount > 13) {
      periodCount = 1;
    }
  }

  // Keep only the last three periods
  const data = lastThreePeriods.slice(-numPeriods).reverse();

  // Calculate total weeks requested
  const requestWeeks = data.reduce((sum, p) => sum + p.weeks, 0);

  return { type: 'fiscal', requestWeeks, data };
};

interface WeekData {
  weekNumber: number; // ISO or standard week number
  startDate: string; // Start date of the week (YYYY-MM-DD)
  endDate: string; // End date of the week (YYYY-MM-DD)
  isPartial: boolean; // Whether this week is shared with another month
  daysInMonth: number; // Number of days this week that belong to this month
  status: 'complete' | 'current' | 'future'; // Week status relative to today
  daysPassed?: number; // For current week: number of days passed so far
  metrics: ChartMetricsData; // Metrics for this week
}

interface PeriodWeekData {
  month: string; // Month name (e.g., "January")
  type: PeriodUnit; // Period type (e.g., "week", "month", "quarter", "year")
  year: number; // Year (e.g., 2025)
  weekCount: number; // Total number of weeks that contain any day of this month
  firstWeek: number; // First week number that contains any day of this month
  lastWeek: number; // Last week number that contains any day of this month
  isCurrent: boolean; // Whether this is the current month
  weeks: WeekData[]; // Details of each week
  metricsToPlot: metricsPeriodPlot; // Metrics to plot for this period
}

export type WeekStartDay = 'Su' | 'Mo' | 'Tu' | 'We' | 'Th' | 'Fr' | 'Sa';

export type PeriodsWeekDataProps = {
  type: PeriodUnit;
  periods: PeriodWeekData[];
  periodsToPlot: metricsPeriodPlot[];
  totalWeeks: number;
  completeWeeks: number;
  periodReporting: boolean;
  startOfTheWeek: WeekStartDay;
};

/**
 * Generates detailed week information based on period boundaries
 * @param periodData The period boundary data from getPeriodBoundaries
 * @param startOfTheWeek The day of the week to start on (Su, Mo, Tu, We, Th, Fr, Sa)
 * @returns Object with detailed week information
 */
export const generateWeekData = (
  periodData: RequestResult,
  startOfTheWeek: WeekStartDay = 'Su'
): PeriodsWeekDataProps => {
  const periods: PeriodWeekData[] = [];
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const allProcessedWeeks = new Set<number>();
  const completeWeeks = new Set<number>();
  const periodType = periodData.type;

  const dayMap: Record<WeekStartDay, number> = {
    Su: 0,
    Mo: 1,
    Tu: 2,
    We: 3,
    Th: 4,
    Fr: 5,
    Sa: 6,
  };
  const startDayNumber = dayMap[startOfTheWeek];

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  for (const period of periodData.data) {
    const periodStartDate = new Date(period.startDate);
    const periodEndDate = new Date(period.endDate);
    const year = periodStartDate.getFullYear();
    const month = periodStartDate.getMonth();
    const isCurrentPeriod = periodEndDate >= today && periodStartDate <= today;

    const titleForWeeks = formatRangeValues(`${period.startDate} - ${period.endDate}`, 'KS');
    const periodData: PeriodWeekData = {
      month:
        periodType === 'month'
          ? monthNames[month]
          : periodType === 'week' && titleForWeeks
            ? titleForWeeks
            : `P${period.period.toString().padStart(2, '0')}`,
      type: periodType,
      year: year,
      weekCount: 0,
      firstWeek: 0,
      lastWeek: 0,
      isCurrent: isCurrentPeriod,
      weeks: [],
      metricsToPlot: {
        periodTitle: '',
        periodtype: periodType,
        year: year,
        date: {
          start: period.startDate,
          end: period.endDate,
        },
        maxValue: 0,
        minValue: 0,
        maxCummulative: 0,
        minCummulative: 0,
        weeks: [],
        metricKeys: [],
      },
    };

    const firstWeekStart = new Date(periodStartDate);
    const currentDay = firstWeekStart.getDay();
    const daysToSubtract = (currentDay - startDayNumber + 7) % 7;
    firstWeekStart.setDate(firstWeekStart.getDate() - daysToSubtract);

    const lastWeekEnd = new Date(periodEndDate);
    const lastDay = lastWeekEnd.getDay();
    const daysToAdd = (startDayNumber - 1 - lastDay + 7) % 7 || 6;
    lastWeekEnd.setDate(lastWeekEnd.getDate() + daysToAdd);

    const currentDate = new Date(firstWeekStart);
    const processedWeeks = new Set<number>();

    while (currentDate <= lastWeekEnd) {
      const weekStart = new Date(currentDate);
      const weekEnd = new Date(currentDate);
      weekEnd.setDate(weekEnd.getDate() + 6);

      const weekNumber =
        startOfTheWeek === 'Mo'
          ? getISOWeekNumber(weekStart)
          : getWeekNumber(`${formatDate(weekStart)} - ${formatDate(weekEnd)}`);

      allProcessedWeeks.add(weekNumber);

      if (processedWeeks.has(weekNumber)) {
        currentDate.setDate(currentDate.getDate() + 7);
        continue;
      }

      processedWeeks.add(weekNumber);

      let daysInPeriod = 0;
      for (let i = 0; i < 7; i++) {
        const dayInWeek = new Date(weekStart);
        dayInWeek.setDate(weekStart.getDate() + i);
        if (dayInWeek >= periodStartDate && dayInWeek <= periodEndDate) {
          daysInPeriod++;
        }
      }

      let status: 'complete' | 'current' | 'future' = 'complete';
      let daysPassed: number | undefined = undefined;

      if (today >= weekStart && today <= weekEnd) {
        status = 'current';
        daysPassed = 0;
        for (let i = 0; i < 7; i++) {
          const dayInWeek = new Date(weekStart);
          dayInWeek.setDate(weekStart.getDate() + i);
          if (dayInWeek <= today) {
            daysPassed++;
          }
        }
      } else if (weekStart > today) {
        status = 'future';
      } else {
        completeWeeks.add(weekNumber);
      }

      if (daysInPeriod > 0) {
        periodData.weeks.push({
          weekNumber,
          startDate: formatDate(weekStart),
          endDate: formatDate(weekEnd),
          isPartial: daysInPeriod < 7,
          daysInMonth: daysInPeriod,
          status,
          ...(daysPassed !== undefined && { daysPassed }),
          metrics: {},
        });

        if (periodData.firstWeek === 0 || weekNumber < periodData.firstWeek) {
          periodData.firstWeek = weekNumber;
        }
        if (weekNumber > periodData.lastWeek) {
          periodData.lastWeek = weekNumber;
        }
      }

      currentDate.setDate(currentDate.getDate() + 7);
    }

    periodData.weekCount = periodData.weeks.length;

    periods.push(periodData);
  }

  return {
    type: periodData.type,
    periods,
    totalWeeks: allProcessedWeeks.size,
    completeWeeks: completeWeeks.size,
    periodsToPlot: [],
    periodReporting: true,
    startOfTheWeek,
  };
};

/**
 * Gets detailed week information for the current period and previous periods
 * This is a compatibility wrapper to maintain the original API
 */
export const getPeriodsWeeksChartData = (
  startOfTheWeek: WeekStartDay = 'Su',
  reportConfiguration: PeriodConfig | undefined,
  weeks: number = 0,
  periods: number = 3
): PeriodsWeekDataProps => {
  /*   if (!reportConfiguration) {
    reportConfiguration = { periodReporting: false };
  } */
  const periodReporting = reportConfiguration?.periodReporting ?? false;
  const periodBoundaries = periodReporting
    ? getPeriodsBoundaries(reportConfiguration as PeriodConfig, periods)
    : weeks > 0
      ? getWeeksBoundaries(weeks, 12 / weeks) //periods
      : getMonthsBoundaries(periods);
  const weekData = generateWeekData(periodBoundaries, startOfTheWeek);
  weekData.periodReporting = periodReporting;
  return weekData;
};
