import { AskRequest } from '@hone-automation/common/lib/AskRequests';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { filterAndSplitRequests, FilterProps, SplitedRequests } from './utils/filterAndSearchUtils';
import selectElement from './utils/selectElement';

const defaultFilters: FilterProps = {
  status: 'Open',
  search: '',
  sort: 'oldest',
  groupBy: 'date',
};

// Define the initial state as a function that reads from URL search params
const initialState = (props: { meId?: string; requests?: AskRequest[] }) => {
  let status = defaultFilters.status;
  let search = defaultFilters.search;
  let sort = defaultFilters.sort;
  let groupBy = defaultFilters.groupBy;

  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search);
    status = (searchParams.get('status') || defaultFilters.status) as FilterProps['status'];
    search = searchParams.get('search') || defaultFilters.search;
    sort = (searchParams.get('sort') || defaultFilters.sort) as FilterProps['sort'];
    groupBy = (searchParams.get('groupBy') || defaultFilters.groupBy) as FilterProps['groupBy'];
  }

  const initialFilters = {
    status,
    search,
    sort,
    groupBy,
  };

  const initialSplitRequests =
    props.requests && props.meId
      ? filterAndSplitRequests(props.requests, props.meId, initialFilters)
      : ({ me: [], team: [] } as SplitedRequests);

  return {
    requests: props.requests || [],
    splitRequests: initialSplitRequests,
    selectedItem: initialSplitRequests && selectElement(initialSplitRequests, initialFilters.sort),
    meId: props.meId || '',
    filters: initialFilters as FilterProps,
  };
};

type SetRequestsAction = { type: 'SET_REQUESTS'; payload: AskRequest[] };
type SetSelectedItemAction = { type: 'SET_SELECTED_ITEM'; payload: AskRequest | null };
type SetMeIdAction = { type: 'SET_ME_ID'; payload: string };
type SetUpdatedFiltersAction = { type: 'SET_FILTERS'; payload: FilterProps };
type Action = SetRequestsAction | SetSelectedItemAction | SetMeIdAction | SetUpdatedFiltersAction;

const queueReducer = (state: ReturnType<typeof initialState>, action: Action) => {
  switch (action.type) {
    case 'SET_REQUESTS': {
      const splitRequests = filterAndSplitRequests(action.payload, state.meId, state.filters);
      return {
        ...state,
        requests: action.payload,
        splitRequests: splitRequests,
        selectedItem: !state.selectedItem ? splitRequests?.me[0] : state.selectedItem,
      };
    }
    case 'SET_SELECTED_ITEM':
      return { ...state, selectedItem: action.payload };
    case 'SET_ME_ID':
      return { ...state, meId: action.payload };
    case 'SET_FILTERS': {
      const splitRequests = filterAndSplitRequests(state.requests, state.meId, action.payload);
      return {
        ...state,
        filters: action.payload,
        splitRequests,
        selectedItem: splitRequests && selectElement(splitRequests, action.payload.sort),
      };
    }
    default:
      return state;
  }
};

const QueueContext = createContext<{
  state: ReturnType<typeof initialState>;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState({}),
  dispatch: () => {},
});

export const useQueueContext = () => useContext(QueueContext);

export const QueueContextProvider = ({
  meId,
  requests,
  children,
}: {
  meId?: string;
  requests?: AskRequest[];
  children: React.ReactNode;
}) => {
  // @ts-ignore
  const [state, dispatch] = useReducer(queueReducer, initialState({ meId, requests }));

  // Sync state to URL parameters
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const newParams = new URLSearchParams(window.location.search);

    (Object.entries(state.filters) as [keyof FilterProps, string][]).forEach(([key, value]) => {
      // Use toString() only if value is not already a string
      newParams.set(key, value);
    });

    const newUrl = `${window.location.pathname}?${newParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  }, [state.filters]);

  return <QueueContext.Provider value={{ state, dispatch }}>{children}</QueueContext.Provider>;
};
