import { CSVLink } from 'react-csv';
import {
  IconDownload,
  IconExportCSV,
  IconExportExcel,
  IconExportSheet,
  IconExportPDF,
} from '../../../components/Icons';

import './KSExport.scss';
import { processCSV } from './ksCSV';
import { TransformedData } from './reportNTParser/types';
import parseReportNT from './reportNTParser';
import { exportParser } from './ksExporterUtils';
import { useFlaggedTransactionsQuery } from 'hooks/flaggedTransactions';
import { exportToExcel } from './excel/excel';
import { exportToPDF } from './pdf/pdf';
import { prefixClass } from 'lib/utils';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ExportData } from './types';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useReportsStore } from 'hooks/useReportsStore';
import { shallow } from 'zustand/shallow';
import { logger } from './reportNTParser/utils';

type KSExportProps = {
  report?: NestedHoneReport | TransformedData;
};

const KSExport = ({ report }: KSExportProps) => {
  if (!report) {
    return null;
  }

  const currentLocation = useLocationsStore(state => state.currentLocation);
  const { data: flaggedTransactions } = useFlaggedTransactionsQuery();
  const trace = false;
  const pdf = false;
  const ref = useRef<HTMLDivElement | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [data, setData] = useState<TransformedData | null>(null);
  const [exportObject, setExportObject] = useState<ExportData | null>(null);
  const [csvObject, setCsvObject] = useState<any>(null);

  const { reportsConfiguration } = useReportsStore(
    state => ({
      reportsConfiguration: state.reportsConfiguration,
    }),
    shallow
  );

  useEffect(() => {
    if ('sections' in report) {
      const urlParams = new URLSearchParams(window.location.search);
      setData(
        parseReportNT({
          report,
          useComputedColumns: true,
          periodReporting: reportsConfiguration?.data?.periodReporting,
          reportCFG: reportsConfiguration,
          flaggedTransactions,
          currentLocation,
          trace,
          reversedReport: urlParams.get('reverse') === 'true',
          consolidated: urlParams.get('consolidated') === 'true',
        })
      );
    } else {
      setData(report);
    }
  }, [report, flaggedTransactions]);

  // Closing the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const prefix = prefixClass('ks-export');

  // Excel Export
  const handleExcelExport = () => {
    if (exportObject) {
      exportToExcel(exportObject);
      setDropdownOpen(false);
    }
  };

  // Sheets Export
  const handleSheetExport = () => {
    if (exportObject) {
      exportToExcel(exportObject, true);
      setDropdownOpen(false);
    }
  };

  // PDF Export
  const handlePDFExport = () => {
    if (exportObject) {
      exportToPDF(exportObject);
      setDropdownOpen(false);
    }
  };

  // Prepare exporter
  const handleExport = () => {
    // Get URL params to configure export
    // But only once the user click on the export button to open the dropdown
    const urlParams = new URLSearchParams(window.location.search);
    const percentages = urlParams.get('percentages') !== 'false';
    const money = urlParams.get('amounts') !== 'false';
    const smoothing = urlParams.get('smoothing') === 'true';
    const actuals = urlParams.get('actuals') !== 'false';
    const variance = urlParams.get('variance') === 'true';

    const filters = {
      money,
      actuals,
      variance,
      percentage: percentages,
      budget: true,
      smoothing,
    };

    const newExportObject = exportParser({ transformedData: data, filters });
    if (newExportObject) {
      setExportObject(newExportObject);
      const newCsvObject = processCSV(newExportObject);
      setCsvObject(newCsvObject);
      if (trace) {
        logger.msg('exportObject', newExportObject);
        logger.msg('CSV', newCsvObject);
      }
      setDropdownOpen(true);
    }
  };

  return (
    <>
      <div className={prefix()}>
        <div className={classNames(prefix('icon'))} onClick={handleExport}>
          <IconDownload />
        </div>
        <div className={classNames(prefix('dropdown'), { hidden: !dropdownOpen })} ref={ref}>
          {csvObject && exportObject && (
            <CSVLink
              className={prefix('dropdown-item')}
              data={csvObject.data}
              filename={`${exportObject.fileName}.csv`}
              onClick={() => setDropdownOpen(false)}
            >
              <div className={classNames(prefix('dropdown-item-icon'))}>
                <IconExportCSV />
              </div>
              CSV
            </CSVLink>
          )}
          <div className={prefix('dropdown-item')} onClick={handleSheetExport}>
            <div className={classNames(prefix('dropdown-item-icon'), 'small')}>
              <IconExportSheet />
            </div>
            Google Sheets
          </div>
          <div className={prefix('dropdown-item')} onClick={handleExcelExport}>
            <div className={classNames(prefix('dropdown-item-icon'), 'small')}>
              <IconExportExcel />
            </div>
            Excel
          </div>
          {pdf && (
            <div className={prefix('dropdown-item')} onClick={handlePDFExport}>
              <div className={classNames(prefix('dropdown-item-icon'))}>
                <IconExportPDF />
              </div>
              PDF
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default KSExport;
