import { useQuery } from '@tanstack/react-query';
import { makeRemoteGetReportsConfig } from 'main/factories/usecases';

const reportConfigurationService = makeRemoteGetReportsConfig();

export const useGetReportConfiguration = (currentLocationId?: string) => {
  return useQuery({
    queryKey: ['report-configuration', currentLocationId],
    queryFn: async () => {
      return reportConfigurationService.getConfig({
        locationId: currentLocationId!,
      });
    },
  });
};
