import { SubmitHandler, useForm } from 'react-hook-form';
import { HoneUserRoles } from '@hone-automation/common';
import { useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { RoleLabels } from '../../../constants';

export interface UserFormInputs {
  name: string;
  email: string;
  role: string;
  id: string;
}

// Modal styles
const customModalStyles = {
  content: {
    marginRight: '-50%',
    transform: 'translate(-100%, 0%)',
    padding: '30px',
    maxWidth: '500px',
    width: '90%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

interface UserFormModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onAddUser?: (userData: UserFormInputs) => void;
  onUpdateUser?: (userData: UserFormInputs) => void;
  editUser?: {
    name: string;
    email: string;
    role: string;
  };
  mode: 'add' | 'edit';
}

// User Add Form Modal component
function UserFormModal({ isOpen, closeModal, onAddUser, onUpdateUser, editUser, mode }: UserFormModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<UserFormInputs>({
    defaultValues: {
      email: '',
      role: HoneUserRoles.Bookkeeper,
    },
  });

  // Set form values when editing a user
  useEffect(() => {
    if (mode === 'edit' && editUser) {
      setValue('email', editUser.email || '');
      setValue('role', editUser.role || HoneUserRoles.Bookkeeper);
    } else if (mode === 'add') {
      reset({
        email: '',
        role: HoneUserRoles.Bookkeeper,
      });
    }
  }, [mode, editUser, setValue, reset]);

  const roleChoices = useMemo(() => {
    return Object.values(HoneUserRoles);
  }, []);

  const onSubmit: SubmitHandler<UserFormInputs> = data => {
    if (mode === 'add' && onAddUser) {
      onAddUser(data);
    } else if (mode === 'edit' && onUpdateUser) {
      onUpdateUser(data);
    }
    reset();
    closeModal();
  };

  return (
    <Modal
      className="user-form-modal"
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customModalStyles}
      contentLabel="Add User Modal"
      ariaHideApp={false}
    >
      <div className="modal-content">
        <h2 className="modal-title">{mode === 'add' ? 'Add New User' : 'Edit User'}</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="user-form">
          {/* NOTE: Leaving it for future use - ND */}
          {/*<div className="form-group">*/}
          {/*  <label htmlFor="name">Name:</label>*/}
          {/*  <input*/}
          {/*    id="name"*/}
          {/*    type="text"*/}
          {/*    {...register('name', { required: 'Name is required' })}*/}
          {/*    className={errors.name ? 'error' : ''}*/}
          {/*  />*/}
          {/*  {errors.name && <span className="error-message">{errors.name.message}</span>}*/}
          {/*</div>*/}

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              id="email"
              type="email"
              data-testid="email"
              disabled={mode === 'edit'}
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
                onChange: e => {
                  // Trim whitespace from email input
                  const trimmedValue = e.target.value.trim();
                  e.target.value = trimmedValue;
                  return trimmedValue;
                },
              })}
              className={errors.email ? 'error' : ''}
            />
            {errors.email && <span className="error-message">{errors.email.message}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="role">Role:</label>
            <select
              id="role"
              {...register('role', { required: 'Role is required' })}
              className={errors.role ? 'error' : ''}
            >
              {roleChoices.map((roleChoice, index) => (
                <option value={roleChoice} key={index}>
                  {RoleLabels[roleChoice]}
                </option>
              ))}
            </select>
            {errors.role && <span className="error-message">{errors.role.message}</span>}
          </div>

          <div className="form-actions">
            <button type="button" onClick={closeModal} className="button button-secondary">
              Cancel
            </button>
            <button type="submit" className="button button-primary">
              {mode === 'add' ? 'Add User' : 'Update User'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default UserFormModal;
