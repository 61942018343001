import { AskRequest } from '@hone-automation/common/lib/AskRequests';
import DashboardCard, { DashboardCardProps } from '../DashboardCard/DashboardCard';
import './DashboardCardList.scss';
import { useEffect, useMemo, useState } from 'react';
import { DashboardCardCountType, DashboardCardType } from '../Types';
import { IconKSAP, IconNbAp, IconNbAsk, IconSmartStar } from 'components/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueueContext } from '../../Queue/QueueContext';

type DashboardCardListProps = {
  onChange?: (selectedCard: 'Opportunities' | 'Queue') => void;
  selected: 'Opportunities' | 'Queue';
};

const DashboardCardList = ({ onChange, selected }: DashboardCardListProps) => {
  const navigateTo = useNavigate();
  const { state } = useQueueContext();
  const { requests } = state;
  const { locationId } = useParams();
  const { dashmode } = useParams();
  const [selectedCard, setSelectedCard] = useState<'Opportunities' | 'Queue'>(
    dashmode === 'Queue' ? 'Queue' : selected
  );

  const requestsOpen = useMemo(() => {
    if (!requests) return 0;
    return requests.filter(request => request.status === 'Open').length;
  }, [requests]);

  const requestsInprogress = useMemo(() => {
    if (!requests) return 0;
    return requests.filter(request => request.status === 'In Progress').length;
  }, [requests]);

  useEffect(() => {
    if (onChange && (selectedCard === 'Opportunities' || selectedCard === 'Queue')) {
      onChange(selectedCard);
    }
  }, [selectedCard]);

  const dashboardCards: DashboardCardProps[] = [
    {
      type: 'disabled' as DashboardCardType,
      icon: (<IconSmartStar />) as React.ReactNode,
      title: 'Opportunities' as string,
      badgesContainer: 'Coming Soon' as string,
      /* onClick: () => {
        setSelectedCard('Opportunities');
      }, */
    },
    {
      type: 'normal' as DashboardCardType,
      icon: (<IconNbAsk />) as React.ReactNode,
      title: 'Queue' as string,
      badgesContainer: [
        {
          type: 'highlight' as DashboardCardCountType,
          value: (requestsOpen || 0) as number,
          tooltip: 'Open Requests',
        },
        {
          type: 'normal' as DashboardCardCountType,
          value: (requestsInprogress || 0) as number,
          tooltip: 'Requests in Progress',
        },
      ],
      onClick: () => {
        setSelectedCard('Queue');
      },
    },
    {
      type: 'highlight' as DashboardCardType,
      icon: (<IconKSAP />) as React.ReactNode,
      title: 'Payables' as string,
      badgesContainer: [
        {
          type: 'highlight' as DashboardCardCountType,
          value: 1 as number,
          tooltip: 'Invoice(s) due in 7 days',
        },
        /* {
          type: 'normal' as DashboardCardCountType,
          value: 2 as number,
        }, */
      ],
      onClick: () => {
        navigateTo(`/app/location/${locationId}/report/ap-aging`);
      },
    },
  ];

  return (
    <div className="dashboard-cards-lists-container">
      {dashboardCards.map((card, index) => {
        return (
          <div key={index} className="dashboard-cards-lists-container__card">
            <DashboardCard {...card} selected={selectedCard === card.title} />
          </div>
        );
      })}
    </div>
  );
};
export default DashboardCardList;
