import { FlaggedTransactionReopenPayload, UIFlaggedTransaction } from 'types/askcustomer';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { makeRemotePostReopenFlaggedTransaction } from 'main/factories/usecases/ask-customer';
import { FIVE_SECONDS, TEN_SECONDS, TOAST_ERROR, TOAST_SUCCESS, showToast } from 'lib/utils';
import { AskRequestStatus } from '@hone-automation/common';

import { onFlaggedTransactionUpdate } from './handlers';
import { useFlaggedTransactions } from './useFlaggedTransactions';

export const useReopenFlaggedTransactionMutation = (
  status: AskRequestStatus,
  locationId: string,
  onSuccessCallback: (trx: UIFlaggedTransaction | undefined) => void
) => {
  const queryClient = useQueryClient();
  const { setAnimationTransaction } = useFlaggedTransactions(state => ({
    setAnimationTransaction: state.setAnimatedTransaction,
  }));

  return useMutation({
    mutationFn: (data: FlaggedTransactionReopenPayload) => {
      const trxId = data.trxId;
      const comment = data.comment;
      const service = makeRemotePostReopenFlaggedTransaction(trxId);
      return service.post({ locationId, comment });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['askRequests', status, locationId] });
      queryClient.invalidateQueries({ queryKey: ['askRequestsCount', locationId] });
      setAnimationTransaction(undefined);
      onFlaggedTransactionUpdate(data, variables, locationId, queryClient, onSuccessCallback);
      showToast('Request Updated ', TOAST_SUCCESS, FIVE_SECONDS);
    },
    onError: () => {
      showToast('Error while updating request', TOAST_ERROR, TEN_SECONDS);
    },
  });
};
