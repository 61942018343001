import { find, isNil, times } from 'lodash';
import { HoneTemplateDisplays } from '../../../../constants';
import BookkeeperAccountsAutocomplete from './BookkeeperAccountsAutocomplete';
import {
  IconAddCircle,
  IconIndent,
  IconOutdent,
  IconRemoveCircle,
  IconUp,
  IconDown,
  IconExpand,
  IconCollapse,
} from '../../../../components/Icons';
import * as TemplateUtils from '../../../../lib/templateUtils';
import type { SyntheticEvent } from 'react';

interface EventHandlerProps {
  onRemoveSection: (e: SyntheticEvent) => void;
  onDisplayChange: (e: SyntheticEvent<HTMLSelectElement>, isTotal: boolean) => void;
  onTotalToggle: (e: SyntheticEvent) => void;
  onTitleChange: (e: SyntheticEvent<HTMLInputElement>, isTotal: boolean) => void;
  onAccountChange: (selected: HoneAccount | undefined) => void;
  onChartChange: (e: SyntheticEvent<HTMLSelectElement>, isTotal: boolean) => void;
  onPercentChange: (selected: HoneAccount | undefined, isTotal: boolean) => void;
  onAddSameLevel: (e: SyntheticEvent) => void;
  onLevelChange: (operation: 'increment' | 'decrement') => void;
  onSmoothingChange: (e: SyntheticEvent<HTMLSelectElement>) => void;
  handleMoveAfterNextSection: (e: SyntheticEvent, section: FlatTemplateSection) => void;
  handleMoveBeforePreviousSection: (e: SyntheticEvent, section: FlatTemplateSection) => void;
  handleCollapseSection?: (e: SyntheticEvent) => void;
}

interface Props extends EventHandlerProps {
  chartOfAccounts?: HoneAccount[];
  calculatableAccounts?: HoneAccount[];
  section: FlatTemplateSection;
  chartCount: number;
  showControls?: boolean;
  disallowDecrementLevel?: boolean;
  disallowIncrementLevel?: boolean;
  sectionCollapsed?: boolean;
}

function BookkeeperTemplateSectionForm({
  section,
  chartCount,
  chartOfAccounts = [],
  calculatableAccounts = [],
  showControls = true,
  sectionCollapsed = false,
  disallowDecrementLevel,
  disallowIncrementLevel,
  ...eventHandlers
}: Props): JSX.Element {
  return (
    <div>
      <div className={`BKForm-section level-${section.level} flex-gap`}>
        <div className="BKForm-debug">
          <code>{JSON.stringify(section)}</code>
        </div>
        {showControls && (
          <button
            className="button button_icon"
            type="button"
            aria-label="Remove section"
            onClick={e => eventHandlers.onRemoveSection(e)}
          >
            <IconRemoveCircle />
          </button>
        )}
        {eventHandlers.handleCollapseSection && (
          <button
            className="button button_collapse"
            type="button"
            aria-label="Collapse section"
            onClick={e => eventHandlers.handleCollapseSection && eventHandlers.handleCollapseSection(e)}
          >
            {sectionCollapsed ? <IconCollapse /> : <IconExpand />}
          </button>
        )}
        <select
          onChange={e => eventHandlers.onDisplayChange(e, false)}
          value={section.display || 'default'}
          className="BKForm-section-select"
          aria-label="section title"
          role="combobox"
        >
          {Object.keys(HoneTemplateDisplays).map(key => (
            <option value={HoneTemplateDisplays[key]} key={key}>
              {key}
            </option>
          ))}
        </select>
        <span className="d-flex">
          <span className="BKForm-btnGroup">
            <input
              type="checkbox"
              name={`t${section.id}`}
              id={`t${section.id}`}
              checked={!!section.total}
              aria-label="Section total toggle"
              onChange={e => eventHandlers.onTotalToggle(e)}
            />
            <label className="button button_outline px-2 rounded-right-0" htmlFor={`t${section.id}`}>
              T
            </label>
          </span>
          <input
            className="rounded-left-0"
            type="text"
            aria-label="Section title"
            placeholder="Row Title"
            value={section.title}
            onChange={e => eventHandlers.onTitleChange(e, false)}
          />
        </span>
        {section.value && section.value.eq && <input type="text" value={section.value.eq.acct_num} disabled hidden />}
        {chartOfAccounts?.length > 0 && (
          <BookkeeperAccountsAutocomplete
            initialInputItems={calculatableAccounts}
            placeholder="GL Code / Title"
            selectedItem={
              find(calculatableAccounts, { Id: section.value?.eq?.acct_id }) ||
              find(calculatableAccounts, { id: section.value?.section }) ||
              find(calculatableAccounts, { AcctNum: section.value?.eq?.acct_num || '' })
            }
            onChange={selected => eventHandlers.onAccountChange(selected)}
          />
        )}
        {calculatableAccounts?.length > 0 && (
          <BookkeeperAccountsAutocomplete
            initialInputItems={calculatableAccounts}
            placeholder="% Denominator"
            selectedItem={
              find(calculatableAccounts, { Id: section.percent?.acct_id }) ||
              find(calculatableAccounts, { id: section.percent?.section || '' }) ||
              find(calculatableAccounts, { AcctNum: section.percent?.acct_num || '' }) ||
              null
            }
            onChange={selected => eventHandlers.onPercentChange(selected, false)}
          />
        )}
        <select
          onChange={e => eventHandlers.onSmoothingChange(e)}
          value={TemplateUtils.getSmoothing(section)}
          aria-label="Smoothing"
          className={TemplateUtils.getSmoothing(section) === '' ? 'select-no-value' : ''}
        >
          <option value="" key="" disabled selected>
            Smoothing
          </option>
          <option value="Bi-Weekly Even" key="Bi-Weekly Even">
            Bi-Weekly Even
          </option>
          <option value="Bi-Weekly Odd" key="Bi-Weekly Odd">
            Bi-Weekly Odd
          </option>
          <option value="Monthly" key="Monthly">
            Monthly
          </option>
          <option value="Yearly" key="Yearly">
            Yearly
          </option>
        </select>
        {showControls && (
          <button
            className="button button_icon"
            type="button"
            title="Add section"
            aria-label="Add section"
            onClick={e => eventHandlers.onAddSameLevel(e)}
          >
            <IconAddCircle />
          </button>
        )}
        {showControls && !isNil(section.level) && (
          <>
            <button
              className="button button_icon"
              type="button"
              title="Outdent"
              aria-label="Outdent section"
              onClick={e => eventHandlers.onLevelChange('decrement')}
              // allow decrement when we wont drop below 1 and before is smaller or eq, or after is smaller
              disabled={disallowDecrementLevel}
            >
              <IconOutdent />
            </button>
            <button
              className="button button_icon"
              type="button"
              title="Indent"
              aria-label="Indent section"
              onClick={e => eventHandlers.onLevelChange('increment')}
              // look at previous section to see if we have another section that is the same level, so we can nest in it
              disabled={disallowIncrementLevel}
            >
              <IconIndent />
            </button>
          </>
        )}
        {showControls && (
          <>
            <button
              className="button button_icon"
              aria-label="Move before previous section"
              type="button"
              onClick={e => eventHandlers.handleMoveBeforePreviousSection(e, section)}
            >
              <IconUp />
            </button>
            <button
              className="button button_icon"
              aria-label="Move after previous section"
              type="button"
              onClick={e => eventHandlers.handleMoveAfterNextSection(e, section)}
            >
              <IconDown />
            </button>
          </>
        )}
      </div>
      {section.total && !sectionCollapsed && (
        <div className={`BKForm-section level-${section.level + 2} flex-gap`}>
          <span className="BKForm-border">&nbsp;</span>
          <span>Total: </span>
          <select onChange={e => eventHandlers.onDisplayChange(e, true)} value={section.total?.display || 'total_1'}>
            {Object.keys(HoneTemplateDisplays).map(key => (
              <option value={HoneTemplateDisplays[key]} key={key}>
                {key}
              </option>
            ))}
          </select>
          <input
            className="rounded-left-0"
            type="text"
            id="totalTitle"
            name="totalTitle"
            aria-label="Total title"
            placeholder="Total Title"
            value={section.total?.title}
            onChange={e => eventHandlers.onTitleChange(e, true)}
            disabled={!section.total}
            hidden={!section.total}
          />
          <select
            onChange={e => eventHandlers.onChartChange(e, true)}
            value={TemplateUtils.hasChart(section, true) ? TemplateUtils.chartOrder(section, true) : ''}
            aria-label="Chart order"
          >
            <option value=""></option>
            {times(TemplateUtils.hasChart(section, true) ? chartCount : chartCount + 1, order => (
              <option value={order} key={order}>
                {order + 1}
              </option>
            ))}
          </select>
          {calculatableAccounts?.length > 0 && (
            <BookkeeperAccountsAutocomplete
              initialInputItems={calculatableAccounts}
              placeholder="% Denominator"
              selectedItem={
                find(calculatableAccounts, { Id: section.total?.percent?.acct_id }) ||
                find(calculatableAccounts, { id: section.total?.percent?.section || '' }) ||
                find(calculatableAccounts, { AcctNum: section.total?.percent?.acct_num || '' }) ||
                null
              }
              onChange={selected => eventHandlers.onPercentChange(selected, true)}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default BookkeeperTemplateSectionForm;
