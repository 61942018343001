import { useEffect, useState } from 'react';
import { prefixClass } from 'lib/utils';
import './ChartNavigation.scss';
import { IconButton } from 'presentation/components/ReportTable/NTSearchAndFilter/SFActions/IconButton';
import {
  IconChevron,
  FilterActual,
  FilterUnconstrain,
  FilterConstrain,
  IconX,
  IconClose,
  IconBackward,
  IconForward,
} from 'components/Icons';

import { ChartPath } from '../types';
import { useNTSelectionContext } from '../../NTSelectionContext';
import { useNTUtilsContext } from '../../NTUtilsContext';

const ChartNavigation = ({ path }: { path: ChartPath[] | null }) => {
  const prefix = prefixClass('chart-navigation');
  const { handleSelect, selectionHistory, historyIndex, setHistoryIndex } = useNTSelectionContext();
  const { actions, setActions } = useNTUtilsContext();

  const handleBack = () => {
    if (historyIndex > -1) {
      const newIndex = historyIndex - 1;
      setHistoryIndex(newIndex);
      const previousId = selectionHistory[newIndex];
      handleSelect(previousId, false);
    }
  };

  const handleForward = () => {
    if (historyIndex < selectionHistory.length - 1) {
      const newIndex = historyIndex + 1;
      setHistoryIndex(newIndex);
      const nextId = selectionHistory[newIndex];
      handleSelect(nextId, false);
    }
  };

  const handleClose = () => {
    setActions({ ...actions, detailsPanel: false });
  };

  return (
    <div className={prefix()}>
      <div className={prefix('back')}>
        <IconButton
          tooltipLabel={'Click to go back'}
          icon={<IconBackward />}
          status={historyIndex > -1 ? true : false}
          iconSize={28}
          onClick={handleBack}
        />
        <IconButton
          tooltipLabel={'Click to go forward'}
          icon={<IconForward />}
          status={historyIndex < selectionHistory.length - 2 ? true : false}
          iconSize={28}
          onClick={handleForward}
        />
      </div>
      <div className={prefix('path')}>
        {path &&
          path.map((p, index) => (
            <div className={prefix('path-item')} key={index} onClick={() => handleSelect(p.id, true)}>
              {p.name}
            </div>
          ))}
      </div>
      <div className={prefix('options')}>
        {/*         <IconButton
          tooltipLabel={'Expand Chart'}
          status={false}
          icon={<FilterUnconstrain />}
          iconTrue={<FilterConstrain />}
        /> */}
        <IconButton
          tooltipLabel={'Close Chart'}
          icon={<IconClose />}
          status={false}
          iconSize={28}
          boundary={true}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default ChartNavigation;
