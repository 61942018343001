import { makeApiUrl } from 'main/factories/http';
import { makeAuthorizeHttpClientDecorator } from 'main/factories/decorators';
import { PostHubspotEmail } from 'domain/usecases/reports/post-send-hubspot-email';
import { RemotePostSendHubspotEmail } from 'data/usecases/reports/remote-post-send-hubspot-email';

export const makeRemotePostSendHubspotEmail = (): PostHubspotEmail => {
  const hubSportUrl = `/hubspot/email/financials`;

  return new RemotePostSendHubspotEmail(makeApiUrl(hubSportUrl), makeAuthorizeHttpClientDecorator());
};
