import { JournalEntry } from '@hone-automation/common';
import { useMutation } from '@tanstack/react-query';
import { useQueryState } from 'hooks/useQueryState';
import { FIVE_SECONDS, TEN_SECONDS, TOAST_ERROR, TOAST_SUCCESS, showToast } from 'lib/utils';

import { makeRemoteCreateJeAccruals } from 'main/factories/usecases';
import { useParams, useNavigate } from 'react-router-dom';

export const useCreateJEAccruals = () => {
  const { locationId: currentLocationId } = useParams();
  const navigateTo = useNavigate();

  const [dateRange] = useQueryState('dateRange');

  return useMutation({
    mutationFn: (journalEntries: JournalEntry[]) => {
      const service = makeRemoteCreateJeAccruals();
      return service.createJEAccruals(journalEntries);
    },
    onError: () => {
      showToast('Error while updating request', TOAST_ERROR, TEN_SECONDS);
    },
    onSuccess: () => {
      showToast('Accrual created successfully', TOAST_SUCCESS, FIVE_SECONDS);
      navigateTo(`/app/location/${currentLocationId}/journal-entries?dateRange=${dateRange}&status=accrual-created`);
    },
  });
};
