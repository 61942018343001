import { useEffect, useState } from 'react';
import { generateReportPayload } from 'presentation/utils/generateReportPayload';
import { debounce } from 'lodash';
import { useActiveReportStore } from 'hooks/useActiveReportStore';
import { shallow } from 'zustand/shallow';
import { Location } from '@hone-automation/common';

export const useDashboardReport = (
  currentLocation: Location,
  templateId: string,
  availableTemplates: TemplateRange[],
  comparisonPeriods: number
) => {
  const [reportData, setReportData] = useState<any>(null);
  const { getGroupReport, setReportPayload } = useActiveReportStore(
    state => ({
      getGroupReport: state.getGroupReport,
      setReportPayload: state.setReportPayload,
    }),
    shallow
  );

  useEffect(() => {
    if (!currentLocation.id || !templateId) return;

    const fetchDashboardReport = async () => {
      const reportPayload = generateReportPayload({
        currentLocation,
        comparisonPeriods: String(comparisonPeriods),
        compareLocations: null,
        comparisonType: 'Prior Period',
        templateId,
        totalParsed: null,
        dateRange: '',
        availableTemplates,
        differenceParsed: null,
      });

      if (reportPayload) {
        setReportPayload(reportPayload);
        const response = await getGroupReport(reportPayload);
        if (response !== 'error') {
          setReportData(response);
        }
      }
    };

    const debouncedFetch = debounce(fetchDashboardReport, 300);
    debouncedFetch();
  }, [currentLocation, templateId]);

  return { reportData };
};
