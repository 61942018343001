import { useMutation } from '@tanstack/react-query';
import { FIVE_SECONDS, TEN_SECONDS, TOAST_ERROR, TOAST_SUCCESS, showToast } from 'lib/utils';

import { makeLinkSlackChannels } from 'main/factories/usecases/slack';
import { useParams, useNavigate } from 'react-router-dom';

export const useLinkSlackChannel = () => {
  const { locationId: currentLocationId } = useParams();

  return useMutation({
    mutationFn: (channelIds: string[]) => {
      const service = makeLinkSlackChannels();
      return service.linkSlackChannel({ locationId: String(currentLocationId), channelIds });
    },
    onError: () => {
      showToast('Error while updating request', TOAST_ERROR, TEN_SECONDS);
    },
    onSuccess: () => {
      showToast('Slack channels link successfully', TOAST_SUCCESS, FIVE_SECONDS);
    },
  });
};
