import { hoverInfo, InfoArea } from './DashboardChart';
import { Point, ChartArea, SplitAreas } from './types';

// SPlit the string of points into an array of points
// *CLaude's code*
export function parsePoints(str: string): Point[] {
  return str
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ')
    .map(pt => {
      const [x, y] = pt.trim().split(',').map(Number);
      return { x, y };
    });
}

// Find the intersections between two line segments
// *03-mini's code*
export function interpolateIntersection(lp1: Point, lp2: Point, rp1: Point, rp2: Point): Point {
  // Find t where line from lp1->lp2 and rp1->rp2 "cross" in y
  const t = (rp1.y - lp1.y) / (lp2.y - lp1.y - (rp2.y - rp1.y));
  return {
    x: lp1.x + t * (lp2.x - lp1.x),
    y: lp1.y + t * (lp2.y - lp1.y),
  };
}

// Uses the line and the resulting area to identify the area above (negative) and below(positive) the line
// *03-mini's code*
export function splitPolygon(data: { all: string; line: string }, points: ChartArea): SplitAreas {
  const linePoints = parsePoints(data.line);
  const allPoints = parsePoints(data.all);
  const n = linePoints.length;
  const returnPoints = allPoints.slice(n);
  // Reverse so that each return point aligns with its line counterpart
  const revReturn = [...returnPoints].reverse();

  // We’ll accumulate contiguous sub-polygons.
  type SubPoly = Point[];
  const abovePolys: SubPoly[] = [];
  const belowPolys: SubPoly[] = [];
  let currPoly: SubPoly = [];
  let currType: 'above' | 'below' | null = null;

  function pushPoly() {
    if (currPoly.length) {
      // Ensure polygon is closed
      if (currPoly[0].x !== currPoly[currPoly.length - 1].x || currPoly[0].y !== currPoly[currPoly.length - 1].y) {
        currPoly.push({ ...currPoly[0] });
      }
      if (currType === 'above') abovePolys.push(currPoly);
      else if (currType === 'below') belowPolys.push(currPoly);
      currPoly = [];
    }
  }

  // Iterate over paired points
  for (let i = 0; i < n; i++) {
    const lp = linePoints[i];
    const rp = revReturn[i];
    const type = rp.y < lp.y ? 'above' : 'below';
    if (currType === null) {
      currType = type;
      currPoly.push(lp, rp);
    } else if (type === currType) {
      currPoly.push(lp, rp);
    } else {
      // Classification change: compute intersection between segments [i-1, i]
      const prevLP = linePoints[i - 1];
      const prevRP = revReturn[i - 1];
      const intersect = interpolateIntersection(prevLP, lp, prevRP, rp);
      currPoly.push(intersect);
      pushPoly();
      // Start new sub-poly with the intersection and current pair
      currType = type;
      currPoly.push(intersect, lp, rp);
    }
  }
  pushPoly();

  return { negative: abovePolys, positive: belowPolys, data, points };
}

// Reorders polygon points to create a continuous shape
// Uses a sweep line approach from left to right, then right to left
// *Claude's code*
export function reorderPolygonPoints(points: Point[]): Point[] {
  if (points.length <= 3) {
    return [...points]; // No need to reorder if it's just a triangle
  }

  // Create a deep copy to avoid modifying the original array
  const pointsCopy = points.map(p => ({ x: p.x, y: p.y }));

  // First, group points by their x-coordinate
  const pointsByX = new Map<number, Point[]>();

  for (const point of pointsCopy) {
    // Round x to handle floating point imprecision
    const xKey = Math.round(point.x * 1000) / 1000;
    if (!pointsByX.has(xKey)) {
      pointsByX.set(xKey, []);
    }
    pointsByX.get(xKey)?.push(point);
  }

  // Get all unique x-coordinates and sort them
  const xValues = Array.from(pointsByX.keys()).sort((a, b) => a - b);

  // Find min and max x values
  const minX = xValues[0];
  const maxX = xValues[xValues.length - 1];

  // First phase: Left to right (upper edge)
  const result: Point[] = [];
  for (const x of xValues) {
    const pointsAtX = pointsByX.get(x) || [];

    if (pointsAtX.length === 0) continue;

    // When moving left to right, take the uppermost point (minimum y)
    pointsAtX.sort((a, b) => a.y - b.y);
    result.push(pointsAtX[0]);

    // Mark this point as used
    pointsAtX.shift();
    pointsByX.set(x, pointsAtX);
  }

  // Second phase: Right to left (lower edge)
  for (const x of xValues.reverse()) {
    const pointsAtX = pointsByX.get(x) || [];

    if (pointsAtX.length === 0) continue;

    // When moving right to left, take the lowermost points (maximum y)
    pointsAtX.sort((a, b) => b.y - a.y);

    // Add all remaining points at this x-coordinate, from lowest to highest
    for (const point of pointsAtX) {
      result.push(point);
    }
  }

  return result;
}

export function pointsToString(points: Point[]): string {
  return points.map(p => `${p.x},${p.y}`).join(' ');
}

//  ********* Calculation of area detection for Hovering *********

// Determines if a point is inside a polygon using the ray casting algorithm
// *Claude's code*
export function isPointInPolygon(point: Point, polygon: Point[]): boolean {
  // Ensure we have at least 3 points to form a polygon
  if (polygon.length < 3) {
    return false;
  }

  // Ray casting algorithm
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const vertI = polygon[i];
    const vertJ = polygon[j];

    // Check if the point is on an edge
    if (isPointOnLine(point, vertI, vertJ)) {
      return true;
    }

    // Check if the ray from the point crosses this edge
    if (
      vertI.y > point.y !== vertJ.y > point.y &&
      point.x < ((vertJ.x - vertI.x) * (point.y - vertI.y)) / (vertJ.y - vertI.y) + vertI.x
    ) {
      inside = !inside;
    }
  }

  return inside;
}

// Determines if a point lies on a line segment
// *Claude's code*
function isPointOnLine(point: Point, lineStart: Point, lineEnd: Point): boolean {
  // Check if the point is on the line segment
  const dxLine = lineEnd.x - lineStart.x;
  const dyLine = lineEnd.y - lineStart.y;

  // If the line is a point
  if (dxLine === 0 && dyLine === 0) {
    return point.x === lineStart.x && point.y === lineStart.y;
  }

  // Calculate the line parameter t
  const t = ((point.x - lineStart.x) * dxLine + (point.y - lineStart.y) * dyLine) / (dxLine * dxLine + dyLine * dyLine);

  // Check if the point is on the line segment
  if (t < 0 || t > 1) {
    return false;
  }

  // Calculate the projection of the point on the line
  const projX = lineStart.x + t * dxLine;
  const projY = lineStart.y + t * dyLine;

  // Check if the point equals its projection (with some epsilon for floating point errors)
  const epsilon = 1e-10;
  return Math.abs(point.x - projX) < epsilon && Math.abs(point.y - projY) < epsilon;
}

export function findAreaAtPoint(point: Point, infoAreas: InfoArea[]): InfoArea | null {
  let found = false;
  for (let i = infoAreas.length - 1; i >= 0; i--) {
    const area = infoAreas[i];
    if (isPointInPolygon(point, parsePoints(area.polygonArea))) {
      found = true;
      return area;
    }
  }
  isPointInPolygon;
  parsePoints;
  return null;
}
