import React, { useState } from 'react';
import { useEvent } from '../../../../hooks/useEvent';

type EventPayload = {
  duration: number;
  field: string;
};

const withQueueBrowser = <P extends object>(
  WrappedComponent: React.ComponentType<P & { pulseField: string; pulseInProgress: boolean }>
) => {
  return function WithQueueBrowser(props: P) {
    const [pulseInProgress, setPulseInProgress] = useState(false);
    const [pulseField, setPulseField] = useState('');
    const triggerAnimation = (duration: number) => {
      setPulseInProgress(true);
      const timer = setTimeout(() => setPulseInProgress(false), duration);
      return () => clearTimeout(timer);
    };
    useEvent('onQueueRequestResolve', (payload: EventPayload) => {
      setPulseField(payload.field);
      triggerAnimation(payload.duration);
    });

    return <WrappedComponent {...props} pulseField={pulseField} pulseInProgress={pulseInProgress} />;
  };
};

export default withQueueBrowser;
