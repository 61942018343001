import classNames from 'classnames';
import React from 'react';
import './DashboardCard.scss';
import { DashboardCardCount, DashboardCardType } from '../Types';
import { Tooltip, Whisper } from 'rsuite';

export type DashboardCardProps = {
  type: DashboardCardType;
  icon: React.ReactNode;
  title: string;
  badgesContainer: string | DashboardCardCount[];
  selected?: boolean;
  onClick?: () => void;
};

const DashboardCard = ({ icon, type, title, badgesContainer, onClick, selected }: DashboardCardProps) => {
  return (
    <div
      className={classNames('dashboard-card', {
        selected: selected,
        disabled: type === 'disabled',
      })}
      onClick={onClick}
    >
      <div
        className={classNames('dashboard-card__icon', {
          disabled: type === 'disabled',
        })}
      >
        {icon}
      </div>
      <div
        className={classNames('dashboard-card__title', {
          disabled: type === 'disabled',
        })}
      >
        {title}
      </div>
      <div className="dashboard-card__badges--container">
        {typeof badgesContainer === 'string' ? (
          <div className="dashboard-card__badges--container--title disabled"> {badgesContainer}</div>
        ) : (
          badgesContainer.map((badge, index) =>
            badge.value ? (
              <Whisper
                key={index}
                placement="top"
                trigger="hover"
                speaker={<Tooltip>{badge.value + ' ' + badge.tooltip}</Tooltip>}
                delay={300}
              >
                <div
                  className={classNames(`dashboard-card__badges--container--item`, {
                    selected: selected,
                  })}
                >
                  {badge.value}
                </div>
              </Whisper>
            ) : null
          )
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
