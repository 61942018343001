import './RequestGroup.scss';
import { prefixClass } from 'lib/utils';
import RequestItem from '../RequestItem';
import { AskRequest } from '@hone-automation/common';
import RequestGroupHeader from './RequestGroupHeader';
import { useState } from 'react';
import classNames from 'classnames';
import { useAutoAnimate } from '@formkit/auto-animate/react';

type RequestGroupProps = {
  collapsed?: boolean;
  requests: AskRequest[];
  title: string;
  multiLocation: boolean;
  onRequestClick?: (id: string) => void;
};

const RequestGroup = ({ collapsed, requests, title, multiLocation = false, onRequestClick }: RequestGroupProps) => {
  if (!requests || !requests.length) throw new Error('No data');

  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const numRequests = requests.length;
  const prefix = prefixClass('request-group');
  const [parent] = useAutoAnimate({
    duration: 200,
  });

  const onCollapse = () => {
    setIsCollapsed(i => !i);
  };
  const handleClick = (id: string) => {
    onRequestClick && onRequestClick(id);
  };

  return (
    <div className={prefix()}>
      <RequestGroupHeader title={title} numRequests={numRequests} collapsed={isCollapsed} onCollapse={onCollapse} />
      <div ref={parent} className={classNames(prefix('list'), { 'list-collapsed': isCollapsed })}>
        {requests.map((request, index) => {
          return (
            <RequestItem
              key={request.id}
              request={request}
              onClick={handleClick}
              multiLocation={multiLocation}
              read={true}
              me={false}
            />
          );
        })}
      </div>
    </div>
  );
};

export default RequestGroup;
