import { HierarchicalAccountData } from '../DInsights';
import { calculateMetrics, calculateStatistics, CHANGE_THRESHOLDS, findSignificantChanges } from './analysis';
import { calculateUnifiedMetrics } from './unifiedMetrics';

// Combine both metric calculation approaches
interface EnhancedPromptData {
  // Your existing data
  basicMetrics: periodMetrics[];
  significantChanges: PeriodChange[];
  statistics: ReturnType<typeof calculateStatistics>;

  // New unified metrics
  unifiedMetrics: UnifiedMetrics[];
  forecastData: {
    nextWeek: {
      revenue: ForecastMetrics;
      costs: ForecastMetrics;
      margins: ForecastMetrics;
    };
    dataQuality: DataQualityMetrics;
  };
}

// First, let's define what we want to show in the prompt
interface PromptFormattedData {
  summary: {
    period: string;
    revenue: {
      value: number;
      trend: string;
      confidence: number;
    };
    margins: {
      gross: {
        value: number;
        trend: string;
      };
      operating: {
        value: number;
        trend: string;
      };
    };
    qualityScore: number;
  }[];
  forecasts: {
    nextWeek: {
      revenue: ForecastMetrics;
      costs: ForecastMetrics;
      margins: ForecastMetrics;
    };
    dataQuality: DataQualityMetrics;
  };
  trends: {
    period: string;
    trends: Record<string, TrendMetrics>;
    quality: DataQualityMetrics;
  }[];
}

// Helper function to format the data for LLM
function formatMetricsForPrompt(
  metrics: UnifiedMetrics[],
  enhancedData: EnhancedPromptData,
  includeDetails: boolean = false
): PromptFormattedData {
  const summary = metrics.map(m => ({
    period: m.period.id,
    revenue: {
      value: m.current.totalRevenue,
      trend: m.trends.totalRevenue?.shortTerm.direction ?? '',
      confidence: m.trends.totalRevenue?.shortTerm.confidence ?? 0,
    },
    margins: {
      gross: {
        value: m.current.grossMargin,
        trend: m.trends.grossMargin?.shortTerm.direction ?? '',
      },
      operating: {
        value: m.current.operatingMargin,
        trend: m.trends.operatingMargin?.shortTerm.direction ?? '',
      },
    },
    qualityScore: m.quality.completeness,
  }));

  return {
    summary,
    forecasts: {
      nextWeek: enhancedData.forecastData.nextWeek,
      dataQuality: enhancedData.forecastData.dataQuality,
    },
    trends: metrics.map(m => ({
      period: m.period.id,
      trends: m.trends,
      quality: m.quality,
    })),
  };
}
// Configuration for both systems
const UNIFIED_CONFIG: MetricsConfig = {
  changeThresholds: {
    // Incorporate your existing thresholds
    totalRevenue: { percentage: CHANGE_THRESHOLDS.totalRevenue },
    grossMargin: { percentage: CHANGE_THRESHOLDS.grossMargin },
    operatingMargin: { percentage: CHANGE_THRESHOLDS.operatingMargin },
    laborCostRatio: { percentage: CHANGE_THRESHOLDS.laborCostRatio },
    cogRatio: { percentage: CHANGE_THRESHOLDS.cogRatio },
    // Add new thresholds for additional metrics
    revenueChange: { percentage: 5 },
    netMargin: { percentage: 2 },
    ebitda: { percentage: 5 },
    operatingExpenseRatio: { percentage: 3 },
    averageTransactionValue: { percentage: 5 },
    laborProductivity: { percentage: 5 },
    revenuePerCategory: { percentage: 5 }, // Added missing property
  },
  qualityParams: {
    completenessThreshold: 0.9,
    consistencyThreshold: 0.8,
    outlierThreshold: 2,
    volatilityThreshold: 0.5,
  },
  forecastParams: {
    confidenceInterval: 0.95,
    minimumDataPoints: 3,
    seasonalityThreshold: 0.7,
    trendSignificance: 0.05,
  },
};

// Enhanced prompt generation function
export const createEnhancedStructuredPrompt = (plData: HierarchicalAccountData[], includeFullContext = false) => {
  const basicMetrics = calculateMetrics(plData);
  const significantChanges = findSignificantChanges(basicMetrics);
  const statistics = calculateStatistics(basicMetrics);
  const unifiedMetrics = calculateUnifiedMetrics(plData, UNIFIED_CONFIG);

  //console.log('Enhanced Prompt Data:', basicMetrics, significantChanges, statistics, unifiedMetrics);

  const enhancedData: EnhancedPromptData = {
    basicMetrics,
    significantChanges,
    statistics,
    unifiedMetrics,
    forecastData: {
      nextWeek: {
        revenue: unifiedMetrics[unifiedMetrics.length - 1].forecasts.totalRevenue!,
        costs: unifiedMetrics[unifiedMetrics.length - 1].forecasts.cogRatio!,
        margins: unifiedMetrics[unifiedMetrics.length - 1].forecasts.grossMargin!,
      },
      dataQuality: unifiedMetrics[unifiedMetrics.length - 1].quality,
    },
  };

  const formattedData = formatMetricsForPrompt(unifiedMetrics, enhancedData, includeFullContext);

  /*   
  
    Raw weekly metrics:
    ${JSON.stringify(basicMetrics, null, 2)}  

    Enhanced Metrics and Forecasts:
    ${JSON.stringify(formattedData, null, 2)}

    Significant changes detected:
    ${JSON.stringify(significantChanges, null, 2)}

*/
  return `

Analyze this P&L data and provide insights in JSON format.
Do not use your internal tools for analysis.
First period is the oldest, last period is the most recent.
Ensure all text fields within "statusReasoning", "narrativeSummary", "keyInsights[].change", "anomalies[].description", and any other text-based fields use css styles (e.g., <b> bold for emphasis, <i> for highlights).

Additionally:
- Escape characters properly to maintain JSON validity.
- Avoid stripping formatting when structuring the final JSON.

Business Structure Context:
${JSON.stringify(plData, null, 2)}

Pre-calculated statistics for accuracy:
${JSON.stringify(statistics, null, 2)}

Significant changes detected:
${JSON.stringify(significantChanges, null, 2)}

Please provide your analysis in this exact JSON schema:
{
  "summary": {
    "businessStatus": "string",
    "statusReasoning": "string",
    "narrativeSummary": ["string"],
    "dataQualityNotes": {
      "hasRecentAnomalies": boolean,
      "incompleteWeeks": ["string"],
      "excludedMetrics": ["string"]
    },
    "forecastSummary": {
      "nextWeek": {
        "overview": "string",
        "expectedTrends": {
          "revenue": "up|down|stable",
          "costs": "up|down|stable",
          "margins": "improving|declining|stable"
        },
        "keyConsiderations": ["string"],
        "confidence": "high|medium|low"
      }
    }
  },
  "summaryMetrics": {
    "weeklyPerformance": {
      "revenue": {
        "trend": "increasing|decreasing|stable",
        "keyInsight": "string"
      },
      "margins": {
        "grossMargin": {
          "trend": "improving|declining|stable",
          "keyInsight": "string"
        },
        "operatingMargin": {
          "trend": "improving|declining|stable",
          "keyInsight": "string"
        }
      }
    }
  },
  "insights": [
    {
      "UUID": "string",
      "type": "emergingTrend|opportunity|generalInsight",
      "category": "revenue|cost|operations|profitability|risk",
      "insight": "string",
      "supportingData": "string"
    }
  ],
  "anomalies": [
    {
      "UUID": "string",
      "weekId": "string",
      "category": "string",
      "priority": "Highest|High|Medium|Low",
      "description": "string",
      "suggestedInvestigation": "string"
    }
  ],
  "recommendations": [
    {
      "UUID": "string",
      "category": "revenue|cost|operations|profitability|risk",
      "recommendation": "string",
      "timeSensitivity": "immediate|short_term|long_term"
    }
  ],
  "followupPrompts": [
    {
      "short": "string",
      "description": "string",
    }
  ]
}



Base your trend assessments on the provided statistics and focus on key insights that aren't directly obvious from the numbers.
Ensure all insights are supported by the pre-calculated metrics.
Keep insights concise and actionable.

Analysis Guidelines:
Overall Health: Evaluate based on revenue stability, margin trends, and operational consistency. Try to be conservative defining businessStatus.
Data Quality: Flag potential data issues in "dataQualityNotes" without repeating details already in "anomalies."
Rounding: Round all monetary values to 2 decimal places and percentages to 1 decimal place.
Consistency: Use only the allowed trend values ("increasing", "decreasing", "stable") for revenue trends.

Guidelines for Narrative Summary:
Avoid duplicating insights already mentioned in "insights."
Focus on high-level trends and potential risks or opportunities.
Keep the Narrative Summary items concise.

Guidelines for Recommendations:
Unique Insights: Ensure insights in "insights" do not duplicate points mentioned in "narrativeSummary."
Keep recommendations unique, concise, and directly tied to the insight.
Try to avoid recommending actions that are already mentioned in "narrativeSummary" or "insights." Also, avoid recommending too generic actions like "review data" or "check for errors."

Guidelines for Insights (insights Section)
1. Types of Insights
Each "insight" entry must be categorized as one of the following:
  "emergingTrend" → A notable shift or pattern in the data that could indicate a risk or advantage.
  ✅ Example: "Gross margin fluctuated from 45.9% to 117.2% in two weeks, suggesting potential misclassification."
  "opportunity" → A leveraged advantage based on recent trends in the data.
  ✅ Example: "A consistent 8% reduction in COGS over Q1 suggests an opportunity to renegotiate supplier contracts for long-term savings."
  "generalInsight" → A noteworthy fact, but not necessarily a trend or opportunity.
  ✅ Example: "Expense reporting shows inconsistent values, with W4_2025, W6_2025, and W7_2025 having operating expenses close to 0."
2. Avoid Redundancy
  Do not duplicate points from "narrativeSummary" or "recommendations".
  Each insight must be supported by data (listed in "supportingData").
  If an insight requires action, it should be included as a "recommendation", not an insight.

Guidelines for FollowupPrompts:
1. Short Description: Provide a brief description of the topic. This will be the label of the suggestions propmt component.
2. Description: Provide a detailed prompt that would expand the user's understanding of the topic.

** Be absolutely sure that the return object is valid JSON and that all fields are present and have the correct types. **
** Do not include any additional information or formatting in the final JSON. **
`;
};

// Usage example:
/*

. If necessary, use **HTML tags** such as "<b>bold</b>" or "<i>italic</i>" to ensure formatting is retained

Forecasting Guidelines:
1. Confidence Scores:
   - High (>0.8): Stable metrics, good data quality
   - Medium (0.5-0.8): Some volatility or data issues
   - Low (<0.5): High volatility or significant data problems

2. Forecast Ranges:
   - Should represent 80% confidence interval
   - Must be justified by historical volatility
   - Should widen with longer timeframes

3. Assumptions:
   - Must be specific and data-driven
   - Should reference historical patterns
   - Must acknowledge data limitations

4. Time Sensitivity:
   - immediate: Required action within current week
   - short_term: 1-4 weeks
   - long_term: Beyond 4 weeks*/
