import './RIInfo.scss';
import { prefixClass } from 'lib/utils';
import StatusItem, { StatusItemProps } from '../../common/StatusItem/StatusItem';
import { formatAmount } from 'presentation/components/KSExport/ksExporterUtils';
import classNames from 'classnames';

export type RIInfoProps = {
  vendorName?: string;
  vendorId?: string;
  description?: string;
  status?: StatusItemProps;
  amount?: number;
  showStatus?: boolean;
};

const RIInfo = (props: RIInfoProps) => {
  const { vendorName, vendorId, description, status, amount, showStatus = false } = props;
  if (!vendorName && !description && !status && !amount) return null;

  const displayStatus = status && showStatus ? <StatusItem {...status} /> : null;
  const amountToDisplay =
    amount && !isNaN(amount) && amount !== 0
      ? formatAmount(amount, amount - Math.floor(amount) !== 0 ? 2 : 0, true)
      : null;
  const prefix = prefixClass('ri-info');
  return (
    <div className={prefix()}>
      {(vendorName || description) && (
        <div data-testid="labels" className={prefix('labels')}>
          {vendorName && <div className={prefix('labels-vendor')}>{vendorName}</div>}
          {description && <div className={prefix('labels-description')}>{description}</div>}
        </div>
      )}
      {(status || amount) && (
        <div
          data-testid="details"
          className={classNames(prefix('details'), { 'should-fill-space': !vendorName && !description })}
        >
          {amountToDisplay && <div className={prefix('details-amount')}>{amountToDisplay}</div>}
          {displayStatus}
        </div>
      )}
    </div>
  );
};

export default RIInfo;
