import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { IconRefresh, IconTriangleWarning } from 'components/Icons';
import useVersionQuery from 'hooks/useVersionQuery';
import { compareVersions } from 'lib/utils/compareVersions';

function UpdateVersionModal() {
  const appVersion = __APP_VERSION__;
  const [enableUpdate, setEnableUpdate] = React.useState(false);
  const { data: manifestVersion } = useVersionQuery();
  const updateIdentifier = `ks:updateNotified:${appVersion}-${manifestVersion}`;

  useEffect(() => {
    if (appVersion && manifestVersion) {
      // Remove old update notification values except the current one
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('ks:updateNotified:') && key !== updateIdentifier) {
          localStorage.removeItem(key);
        }
      });
      // remove old key
      localStorage.removeItem('ks:updated');
      localStorage.setItem('ks:appVersion', appVersion);
      localStorage.setItem('ks:manifestVersion', manifestVersion);

      // Check if the user has already been notified about this update
      const hasNotified = localStorage.getItem(updateIdentifier) === 'true';

      if (!hasNotified) {
        const result = compareVersions(appVersion, manifestVersion);

        if (result === -1) {
          setEnableUpdate(true);
        }
      }
    }
  }, [manifestVersion]);

  const handleReload = () => {
    // Mark this update notification as shown
    localStorage.setItem(updateIdentifier, 'true');
    window.location.reload();
  };

  return (
    <Modal overlayClassName="update-overlay" className="modal-update-version" isOpen={enableUpdate}>
      <div className="modal-header">
        <div className="modal-title">
          <IconTriangleWarning />
          <h6> Browser Refresh Needed </h6>
        </div>
      </div>
      <div className="modal-body">
        <p>
          In order to improve the KitchenSync experience, we need you to refresh this website. Click the button below to
          proceed.
        </p>
        <button onClick={handleReload}>
          Refresh
          <IconRefresh />
        </button>
      </div>
    </Modal>
  );
}

export default UpdateVersionModal;
