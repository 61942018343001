import { GetPostReport } from 'domain/usecases';
import { BaseHttpService } from 'main/factories/http';

export class RemotePostReport extends BaseHttpService<GetPostReport.Model> implements GetPostReport {
  async postReport(
    params: GetPostReport.Params,
    options?: { signal?: AbortSignal }
  ): Promise<GetPostReport.Model | HttpGenericErrorResponse> {
    const { type, startDate, endDate } = params;

    const payload = {
      ...params,
      startDate,
      endDate,
    };
    if (type === 'Balance Sheet') Object.assign(payload, { templateId: undefined });

    return await this.request('post', payload, options?.signal);
  }
}
