import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IconX } from 'components/Icons';
import { useUpdateUser } from '../../../hooks/useUpdateUser';
import Loading from '../../../components/Loading';

interface NameModalProps {
  isOpen: boolean;
  onSuccess?: () => void;
  onClose: () => void;
  currentName: string;
  userId: string;
  uid?: string;
  locationId?: string;
}

interface NameFormData {
  userId: string;
  name: string;
}

export const NameModal: React.FC<NameModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  currentName,
  userId,
  locationId,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NameFormData>({
    defaultValues: {
      userId,
      name: currentName,
    },
  });

  const mutation = useUpdateUser({
    onSuccess: () => {
      // Reset the form to initial values
      reset({ userId, name: currentName });
      // Close the modal
      onSuccess?.();
      onClose();
    },
  });

  // Reset form when modal is opened with new values
  useEffect(() => {
    if (isOpen) {
      reset({ userId, name: currentName });
    }
  }, [isOpen, userId, currentName, reset]);

  const onSubmit = (data: NameFormData) => {
    mutation.mutate({ ...data, locationId, uid: userId });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={e => e.target === e.currentTarget && onClose()}>
      <div className="modal-content user-profile" data-testid="modal-content">
        <div className="modal-header">
          <h2>Update Name</h2>
          <button onClick={onClose} className="close-button">
            <IconX />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              {...register('name', {
                required: 'Name is required',
                minLength: {
                  value: 2,
                  message: 'Name must be at least 2 characters',
                },
              })}
            />
            {errors.name && <p className="error">{errors.name.message}</p>}
          </div>

          <div className="modal-actions">
            <button type="button" onClick={onClose} className="cancel">
              Cancel
            </button>
            <button type="submit" className="submit" disabled={mutation.isPending}>
              {mutation.isPending ? <Loading loadingCircleClass="Loading-circle-small" /> : 'Update Name'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
