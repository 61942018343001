import './BrowserColumn.scss';
import { prefixClass } from 'lib/utils';
import classNames from 'classnames';
import RequestsBrowser from '../RequestsBrowser';
import React from 'react';
import LabelSwitch from '../common/LabelSwitch';
import { useQueueContext } from '../QueueContext';
import { IconAdd } from 'components/Icons';
import { QueueCreateAskReq } from '../../AskRequest';
import withQueueBrowser from './withQueueBrowser';

const EnhancedLabelSwitch = withQueueBrowser(LabelSwitch);

const BrowserColumn = () => {
  const prefix = prefixClass('browser-column');
  const [createRequestModalOpen, setCreateRequestModalOpen] = React.useState<boolean>(false);
  const {
    state: { filters },
    dispatch,
  } = useQueueContext();

  const toggleCreateRequestModal = () => {
    setCreateRequestModalOpen(val => !val);
  };

  const handleStatusChange = (status: 'Open' | 'In Progress' | 'Closed') => {
    dispatch({ type: 'SET_FILTERS', payload: { ...filters, status, sort: status !== 'Closed' ? 'oldest' : 'newest' } });
  };

  // Define display labels that will be shown in the UI
  const displayLabels = ['To-do', 'In Progress', 'Closed'];
  const displayColors = ['--ks-ASK-list-item-selected', '--ks-ASK-customer', '--ks-chart-primary'];

  // Map display labels back to actual status values
  const getActualStatus = (displayLabel: string): 'Open' | 'In Progress' | 'Closed' => {
    if (displayLabel === 'To-do') return 'Open';
    return displayLabel as 'In Progress' | 'Closed';
  };

  // Get display label from actual status
  const getDisplayLabel = (status: 'Open' | 'In Progress' | 'Closed'): string => {
    const statusIndex = status === 'Open' ? 0 : status === 'In Progress' ? 1 : 2;
    document.documentElement.style.setProperty('--ks-queue-selected-color', `var(${displayColors[statusIndex]})`);
    if (status === 'Open') return 'To-do';
    return status;
  };

  return (
    <div className={prefix()}>
      <div className={classNames(prefix('filters'))}>
        <div className={prefix('filters-switch')}>
          <EnhancedLabelSwitch
            labels={displayLabels}
            selected={getDisplayLabel(filters.status)}
            onSelect={value => handleStatusChange(getActualStatus(value as string))}
          />
        </div>
        <div>
          <button onClick={toggleCreateRequestModal} className={prefix('filters-switch-create-request-toggle')}>
            <IconAdd />
          </button>
        </div>
      </div>
      <div className={classNames(prefix('request-browser'))}>
        <RequestsBrowser />
      </div>
      <QueueCreateAskReq isOpen={createRequestModalOpen} handleClose={toggleCreateRequestModal} />
    </div>
  );
};
export default BrowserColumn;
