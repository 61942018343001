import emojiData from 'emoji-picker-react/src/data/emojis.json';
// Proper type definitions based on the actual data structure
type EmojiItem = {
  n: string[]; // Name/description parts
  u: string; // Unicode code point
  a?: string; // Availability/version
  shortcodes?: string[]; // This might be added by the library
  [key: string]: any;
};

type EmojiData = {
  custom: EmojiItem[];
  smileys_people: EmojiItem[];
  animals_nature: EmojiItem[];
  food_drink: EmojiItem[];
  travel_places: EmojiItem[];
  activities: EmojiItem[];
  objects: EmojiItem[];
  symbols: EmojiItem[];
  flags: EmojiItem[];
  [key: string]: EmojiItem[];
};

// Create mapping between emojis and shortcodes
const emojiToShortcode: Record<string, string> = {};
const shortcodeToEmoji: Record<string, string> = {};

// Function to generate a shortcode from emoji name
const generateShortcode = (names: string[]): string => {
  // Use first name part as shortcode
  return names[0].toLowerCase().replace(/\s+/g, '_');
};

// Populate mappings from emoji-picker-react data
Object.entries(emojiData as EmojiData).forEach(([category, emojis]) => {
  emojis.forEach(emoji => {
    // Convert unicode code point to actual emoji character
    const emojiChar = String.fromCodePoint(parseInt(emoji.u, 16));

    // Generate shortcode from emoji name if not provided
    const shortcode = emoji.shortcodes?.[0] || generateShortcode(emoji.n);

    emojiToShortcode[emojiChar] = `:${shortcode}:`;
    shortcodeToEmoji[`:${shortcode}:`] = emojiChar;
  });
});

/**
 * Converts emojis in text to shortcodes for database storage
 * @param text - The input text containing emojis
 * @returns Text with emojis converted to shortcodes
 */
export const convertToShortcodes = (text: string): string => {
  return Array.from(text)
    .map(char => {
      // Skip conversion for numeric characters
      if ('0123456789'.includes(char)) {
        return char;
      }
      return emojiToShortcode[char] || char;
    })
    .join('');
};

/**
 * Converts shortcodes back to emoji characters for display
 * @param text - The input text containing shortcodes
 * @returns Text with shortcodes converted to emojis
 */
export const convertFromShortcodes = (text: string): string => {
  // Use a more specific regex that requires at least one letter in the shortcode
  // This will prevent matching pure number sequences
  return text.replace(/:[a-z]\w*(?:_\w+)*:/gi, match => {
    // Only replace if it's a known shortcode
    if (shortcodeToEmoji[match]) {
      return `<span class="ks-emoji">${shortcodeToEmoji[match]}</span>`;
    }
    // Otherwise return the original text
    return match;
  });
};

/**
 * Utility to check if a string contains any emoji
 * @param text - The input text to check
 * @returns Boolean indicating if text contains emoji
 */
export const containsEmoji = (text: string): boolean => {
  return Array.from(text).some(char => emojiToShortcode[char] !== undefined);
};

/**
 * Utility to get all emojis from a string
 * @param text - The input text to analyze
 * @returns Array of emojis found in the text
 */
export const getEmojisFromText = (text: string): string[] => {
  return Array.from(text).filter(char => emojiToShortcode[char] !== undefined);
};
