import React, { useRef } from 'react';
import Modal from 'react-modal';
import { currencyFormatter2Decimals } from 'lib/utils';
import { IconClear } from 'components/Icons';
import Loading from 'components/Loading';
import ImageViewer from 'presentation/components/Images/ImageViewer';
import DownloadButton from './DownloadButton';
import { AttachmentMetadata } from 'hooks/useAttachmentsModal';

const attachmentModalStyle = {
  content: {
    top: '5%',
    left: '25%',
    width: '60%',
    height: '800px',
  },
};

type AttachmentModalProps = {
  title: string;
  subtitle: (undefined | string | null)[];
  showAttachmentImage: boolean;
  handleClose: () => void;
  attachmentMetadata?: AttachmentMetadata;
  attachmentsUrl: string[];
  breadcrumb?: React.ReactNode;
};

function AttachmentModal(props: AttachmentModalProps) {
  const { showAttachmentImage, handleClose, attachmentMetadata, attachmentsUrl, title, breadcrumb, subtitle } = props;

  const modalRef = useRef(null);

  return (
    <Modal
      ref={modalRef}
      id="attachment-modal"
      style={attachmentModalStyle}
      isOpen={showAttachmentImage}
      onRequestClose={handleClose}
    >
      <div className="close">
        <span className="attachment-amount">
          {attachmentMetadata && currencyFormatter2Decimals(attachmentMetadata?.amount)}
        </span>
        <DownloadButton fileNameRelated={subtitle} attachmentsUrl={attachmentsUrl} className="attachment-download" />
        <button style={{ background: 'transparent' }} onClick={handleClose}>
          <IconClear />
        </button>
      </div>
      <div className="modal-header">
        <span className="title">{title}</span>
        {breadcrumb}
      </div>
      <div>
        {!attachmentsUrl && <Loading />}
        {attachmentsUrl && <ImageViewer fileNameRelated={subtitle} images={attachmentsUrl} />}
      </div>
    </Modal>
  );
}

export default AttachmentModal;
