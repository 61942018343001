import classNames from 'classnames';
import { Whisper, Tooltip } from 'rsuite';
import { useNTContext } from '../../NTContext';
import { useNTUtilsContext } from '../../NTUtilsContext';
import { Amounts } from '../../../KSExport/reportNTParser/types';
import { prefixClass } from 'lib/utils';
import { FormatPercentage, formatAmount } from '../ValueFormatters/ValueFormatters';

import { formatWhisperTooltip, trxModalPipe } from './formatReportValuesUtils';
import './FormatReportValues.scss';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useReportValueFormatters } from './FormatReportValues.hook';

const FormatReportValues = ({
  cellData,
  cellID,
  rowID,
  totalColumn = false,
}: {
  cellData: Amounts;
  cellID: string | null;
  rowID?: string | null;
  totalColumn?: boolean;
}) => {
  const prefix = prefixClass('report-values');
  const { filters, budgetAvailable, columns, reportId: globalReportID } = useNTContext();
  const { actions, setActions, capabilities } = useNTUtilsContext();
  //const { transformedData } = useNTSelectionContext();
  const {
    index,
    date,
    reportId,
    transactions,
    amount,
    amountAVG,
    percent,
    percentAVG,
    budget,
    budgetPercent,
    difference,
    variance,
    varianceAnalisys,
    vizHelpers,
    anomaly,
    trxAnomaly,
    dataRows,
    trxFlagged,
  } = cellData;
  const { smoothing, anomalies } = filters;
  const currentLocation = useLocationsStore(state => state.currentLocation);

  // We decide to apply smoothing if the smoothing filter is active and if amountAVG is not undefined and it does not have the same value as amount
  const mustSmooth = smoothing && !vizHelpers.noValuesToShow && amountAVG !== undefined && amountAVG !== amount;
  const composedAmount = mustSmooth ? amountAVG : amount;
  const composedPercent = mustSmooth ? percentAVG : percent;

  // Right now anomaly is only shown if the smoothing filter is not active and if anomalies is not undefined and it does not have the same value as amount
  const anomalyValue = anomaly && anomaly.anomalyScores[index];
  const showAnomaly =
    capabilities.anomalies &&
    !mustSmooth &&
    !totalColumn &&
    anomalies &&
    columns > 2 &&
    anomalyValue !== 0 &&
    anomalyValue !== undefined;

  // Anomalies calculation
  const trxAnomalyValue = trxAnomaly && trxAnomaly.anomalyScores[index];
  const showtrxAnomaly =
    capabilities.anomalies &&
    anomalies &&
    !totalColumn &&
    trxAnomaly &&
    columns > 3 &&
    trxAnomalyValue !== 0 &&
    trxAnomalyValue !== undefined;

  // Flagging
  const flaggedCell =
    capabilities.flaggedTransactions && cellData.trxFlagged && cellData.trxFlagged.length > 0
      ? cellData.trxFlagged[0].status
      : null;

  const flagInfo = {
    flaggedTransactions: cellData.trxFlagged.filter(trx => trx.type === 'trx'),
    isFlaggedCell: flaggedCell && cellData.trxFlagged.some(trx => trx.type === 'Flagged Cell'),
    fTrxtotal: cellData.trxFlagged.reduce((total, trx) => (trx.type === 'trx' ? total + trx.amount : total), 0),
    totalTrxFlagged: cellData.trxFlagged.filter(trx => trx.type === 'trx').length,
  };

  const { formatActualsBlock, formatComposedAmount, formatVariance } = useReportValueFormatters({
    cellData,
    smoothing: filters.smoothing,
    showAnomaly,
    showtrxAnomaly,
    composedAmount,
    flaggedCell,
    flagInfo,
    filters,
  });

  const handleClick = async (e: React.MouseEvent, cellData: Amounts) => {
    e.preventDefault();
    if (!capabilities.flaggedTransactions) return;
    const trxModalData = await trxModalPipe(cellData, currentLocation, reportId || globalReportID);
    setActions({ ...actions, trxModal: true, trxModalData });
  };

  return (
    <div
      className={classNames(prefix('KS-report-cell'), {
        'extra-height': vizHelpers.isBigHeader,
        smoothing: mustSmooth,
        total: vizHelpers.isTotal,
      })}
      data-cell-id={cellID}
    >
      {vizHelpers.noValuesToShow ? (
        <div className={classNames(prefix('KS-report-cell'))} data-cell-id={cellID} />
      ) : (
        <>
          {flaggedCell && (
            <div
              className={classNames(prefix('flagged'), {
                open: flaggedCell === 'open',
                inProgress: flaggedCell === 'inProgress',
                closed: flaggedCell === 'closed',
              })}
              /* onClick={e => handleClick(e, cellData)} */
            ></div>
          )}
          {filters.actuals &&
            formatActualsBlock(
              <div
                className={classNames(prefix('actual-container'), {
                  percentage: filters.percentage && filters.money,
                  anomaly: showAnomaly,
                  ['anomaly-positive']: showAnomaly && anomalyValue > 0,
                })}
                data-cell-id={cellID}
              >
                {capabilities.money &&
                  filters.money &&
                  formatComposedAmount(null, false, e => handleClick(e, cellData))}
                {capabilities.percentage &&
                  filters.percentage &&
                  FormatPercentage(
                    composedPercent,
                    vizHelpers.isTotal,
                    undefined,
                    cellData.amount !== 0 || flagInfo.flaggedTransactions.length > 0
                  )}
              </div>
            )}

          {capabilities.budget && budgetAvailable && filters.budget && date !== 'Difference' && (
            <div
              className={classNames(prefix('actual-container'), {
                percentage: filters.percentage && filters.money,
                budget: true,
                'no-separator': !filters.actuals,
              })}
              data-cell-id={cellID}
            >
              {capabilities.money && filters.money && formatComposedAmount(budget)}
              {capabilities.percentage && filters.percentage && FormatPercentage(budgetPercent)}
            </div>
          )}
          {capabilities.budget &&
            budgetAvailable &&
            filters.budget &&
            filters.actuals &&
            filters.variance &&
            formatVariance(
              <div
                className={classNames(prefix('variance-container'), {
                  percentage: filters.percentage && filters.money,
                  anomaly: varianceAnalisys && varianceAnalisys.tendency === 'negative',
                  ['anomaly-positive']: varianceAnalisys && varianceAnalisys.tendency === 'positive',
                })}
                data-cell-id={cellID}
              >
                {capabilities.money && filters.money && formatComposedAmount(difference, true)}
                {capabilities.percentage && filters.percentage && FormatPercentage(variance)}
              </div>
            )}
        </>
      )}
    </div>
  );
};
export default FormatReportValues;
