import React from 'react';
import { useForm } from 'react-hook-form';
import { IconX } from 'components/Icons';

interface PasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: PasswordFormData) => void;
}

interface PasswordFormData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const PasswordModal: React.FC<PasswordModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<PasswordFormData>();

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={e => e.target === e.currentTarget && onClose()}>
      <div className="modal-content">
        <div className="modal-header">
          <h2>Change Password</h2>
          <button onClick={onClose} className="close-button">
            <IconX />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="currentPassword">Current Password</label>
            <input
              type="password"
              id="currentPassword"
              {...register('currentPassword', { required: 'Current password is required' })}
            />
            {errors.currentPassword && <p className="error">{errors.currentPassword.message}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              {...register('newPassword', {
                required: 'New password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters',
                },
              })}
            />
            {errors.newPassword && <p className="error">{errors.newPassword.message}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm New Password</label>
            <input
              type="password"
              id="confirmPassword"
              {...register('confirmPassword', {
                required: 'Please confirm your password',
                validate: value => value === watch('newPassword') || 'Passwords do not match',
              })}
            />
            {errors.confirmPassword && <p className="error">{errors.confirmPassword.message}</p>}
          </div>

          <div className="modal-actions">
            <button type="button" onClick={onClose} className="cancel">
              Cancel
            </button>
            <button type="submit" className="submit">
              Update Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
