import { getWeekNumber, sumCategories } from './DchartData';
import { ParsedReport } from './types';

export interface HierarchicalAccountData {
  periodId: string;
  periodStart: string;
  periodEnd: string;
  periodType: string;
  data: {
    sales: {
      total: number;
      accounts: { [key: string]: number };
    };
    cogs: {
      total: number;
      accounts: { [key: string]: number };
    };
    labor: {
      total: number;
      accounts: { [key: string]: number };
    };
    operatingExpenses: {
      total: number;
      accounts: { [key: string]: number };
    };
  };
}

export function getHierarchicalAccountData(parsedReport: ParsedReport): HierarchicalAccountData[] {
  const result: HierarchicalAccountData[] = [];
  const categorySums = sumCategories(parsedReport);

  // For each time period
  Object.entries(categorySums).forEach(([columnId, columnData]) => {
    const periodData: HierarchicalAccountData = {
      periodId: getPeriodId(columnData.dateRange),
      periodStart: columnData.dateRange.split(' - ')[0],
      periodEnd: columnData.dateRange.split(' - ')[1],
      periodType: 'weekly', // Can be modified based on actual period type
      data: {
        sales: { total: 0, accounts: {} },
        cogs: { total: 0, accounts: {} },
        labor: { total: 0, accounts: {} },
        operatingExpenses: { total: 0, accounts: {} },
      },
    };

    // Process each category
    Object.entries(columnData).forEach(([category, data]) => {
      if (typeof data === 'object' && 'values' in data) {
        // Get only level 1 and 2 accounts
        data.values.forEach((item: any) => {
          const row = parsedReport.rows[item.id];
          if (row && (category === 'labor' ? row.level < 5 : row.level < 4)) {
            const normalizedTitle = normalizeAccountName(item.title);
            const amount = item.value.amount;

            switch (category) {
              case 'sales':
                periodData.data.sales.accounts[normalizedTitle] = amount;
                periodData.data.sales.total += amount;
                break;
              case 'cogs':
                periodData.data.cogs.accounts[normalizedTitle] = amount;
                periodData.data.cogs.total += amount;
                break;
              case 'labor':
                periodData.data.labor.accounts[normalizedTitle] = amount;
                periodData.data.labor.total += amount;
                break;
              case 'gna':
              case 'rent':
              case 'advertising':
                periodData.data.operatingExpenses.accounts[normalizedTitle] = amount;
                periodData.data.operatingExpenses.total += amount;
                break;
            }
          }
        });
      }
    });

    result.unshift(periodData);
  });

  return result;
}

// Helper functions
function getPeriodId(dateRange: string): string {
  const [start] = dateRange.split(' - ');
  const date = new Date(start);
  const weekNum = getWeekNumber(dateRange);
  return `W${weekNum}_${date.getFullYear()}`;
}

function normalizeAccountName(title: string): string {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '_')
    .replace(/_+/g, '_')
    .replace(/^_|_$/g, '');
}
