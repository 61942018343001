import { AskRequest } from '@hone-automation/common';
import { IconCheck, IconChevron } from 'components/Icons';
import React, { useState } from 'react';

import './TransactionDetails.scss';
import { prefixClass } from '../../../../lib/utils';
import { StatusItem } from '../common/StatusItem';
import { RequestStatus } from '../RequestItem/type';
import classNames from 'classnames';
import { format } from 'date-fns';
import { formatAmount } from 'presentation/components/NewTable/NTCell/ValueFormatters/ValueFormatters';

interface TransactionDetailsProps {
  request: AskRequest;
  children?: React.ReactNode;
}

const STATUS_MAP: { [key: string]: RequestStatus } = {
  Open: 'created',
  'In Progress': 'inProgress',
  Closed: 'resolved',
};

const TRXDetailsItem: React.FC<{
  label: string;
  value?: string | number;
  money?: boolean;
  originalMoney?: string | number;
}> = ({ label, value, money, originalMoney }) => {
  if (!value || value === '-') return null;
  const prefix = prefixClass('transaction-info-details-prop');
  return (
    <div className={prefix()}>
      <div className={prefix('label')}>{label}</div>
      <div className={classNames(prefix('amount'), { money })}>
        {money ? (
          <>
            {formatAmount(typeof value === 'string' ? parseFloat(value) : value, 2, true)}
            {originalMoney && (
              <div className={prefix('original-money')}>
                {' '}
                | {formatAmount(typeof originalMoney === 'string' ? parseFloat(originalMoney) : originalMoney, 2, true)}
              </div>
            )}
          </>
        ) : (
          value
        )}
      </div>
    </div>
  );
};

// Component for collapsed view
const CollapsedView: React.FC<{
  request: AskRequest;
  prefix: (modifier?: string) => string;
  isCustomRequest: boolean;
}> = ({ request, prefix, isCustomRequest }) => {
  const trxName = request.transaction?.name || '-';
  const amount = request.transaction?.amount || '-';
  const description = isCustomRequest
    ? request.identifier || `Flagged Cell `
    : trxName || request.transaction?.memo || '-';
  return (
    <div className={prefix('collapsed-section')}>
      <div className={prefix('collapsed-description')}>{description}</div>
    </div>
  );
};

// Component for Custom Requests and Flagged Cells
const SimpleRequestView: React.FC<{
  request: AskRequest;
  isCustomRequest: boolean;
  isFlaggedCell: boolean;
  formattedDates: { createdDate: string; trxDate: string };
  createdByUser: string;
}> = ({ request, isCustomRequest, isFlaggedCell, formattedDates, createdByUser }) => {
  const { createdDate } = formattedDates;
  const amount = request.transaction?.amount || '-';

  if (isCustomRequest) {
    return (
      <>
        <TRXDetailsItem label="Type" value={request.type || 'Custom Request'} />
        <TRXDetailsItem label="Title" value={request.identifier} />
        <TRXDetailsItem label="Created on" value={createdDate} />
        <TRXDetailsItem label="Created by" value={createdByUser} />
      </>
    );
  }

  if (isFlaggedCell) {
    return (
      <>
        <TRXDetailsItem label="Type" value={'Flagged Report Cell'} />
        <TRXDetailsItem label="Created on" value={createdDate} />
        <TRXDetailsItem label="Created by" value={createdByUser} />
        <TRXDetailsItem label="Amount" value={request.flaggedAmount || amount} money />
      </>
    );
  }

  return null;
};

// Component for Flagged Transactions and Ask Requests
const TransactionRequestView: React.FC<{
  request: AskRequest;
  isFlaggedTransaction: boolean;
  isAskRequest: boolean;
  formattedDates: { createdDate: string; trxDate: string };
  createdByUser: string;
}> = ({ request, isFlaggedTransaction, isAskRequest, formattedDates, createdByUser }) => {
  if (!isFlaggedTransaction && !isAskRequest) return null;

  const { createdDate, trxDate } = formattedDates;
  const requestType = isFlaggedTransaction ? 'Flagged transaction' : request.type || 'Ask Request';
  const accountInfo = request.transaction?.account || '--';
  const trxName = request.transaction?.name || '-';
  const description = request.transaction?.memo || '-';
  const amount = request.transaction?.amount || '-';

  return (
    <>
      <TRXDetailsItem label="Type" value={requestType} />
      <TRXDetailsItem label="Original Account" value={accountInfo} />
      <TRXDetailsItem label="Final Account" value={request?.resolution} />
      <TRXDetailsItem label="Created on" value={createdDate} />
      <TRXDetailsItem label="Created by" value={createdByUser} />
      <TRXDetailsItem label="Transaction Date" value={trxDate} />
      <TRXDetailsItem label="Name" value={trxName} />
      <TRXDetailsItem label="Description" value={description} />
      <TRXDetailsItem label="Amount" value={request.flaggedAmount || amount} money />
    </>
  );
};

const FlowInfoItem = ({ status, date }: { status: string; date: string }) => {
  const prefix = prefixClass('transaction-info-details-flow-item');
  return (
    <div className={prefix()}>
      <div className={prefix('flow-item-status')}>{status}</div>
      <div className={prefix('flow-item-date')}>{date}</div>
    </div>
  );
};

const TransactionsFlow = ({ request }: { request: AskRequest }) => {
  const prefix = prefixClass('transaction-info-details-flow');
  const { status, comments, transaction } = request;
  const createdDate = request.createdAt ? format(new Date(request.createdAt), 'MM/dd/yyyy') : '-';
  const createdByUser =
    request.comments?.[0]?.user?.name ||
    request.comments?.[0]?.userLocation?.email?.split('@')[0] ||
    request.comments?.[0]?.userLocation?.role ||
    'Unknown';
  //find the latest status
  const latestStatus = comments
    ?.slice()
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];

  const updatedDate = latestStatus?.createdAt ? format(new Date(latestStatus.createdAt), 'MM/dd/yyyy') : '-';

  const updatedByUser =
    latestStatus?.user?.name ||
    latestStatus?.userLocation?.email?.split('@')[0] ||
    latestStatus?.userLocation?.role ||
    'Unknown';
  return (
    <div className={prefix()}>
      <div className={prefix('header')}>
        <div className={prefix('header-label')}>Flow</div>
      </div>
      <div className={prefix('items')}>
        {status === 'Closed' && (
          <>
            <div className={prefix('item')}>
              <StatusItem userName={updatedByUser} status={STATUS_MAP['Closed']} date={updatedDate} showDate />
            </div>
          </>
        )}
        {status === 'In Progress' && (
          <div className={prefix('item')}>
            <StatusItem userName={updatedByUser} status={STATUS_MAP['In Progress']} date={updatedDate} showDate />
          </div>
        )}
        <StatusItem userName={createdByUser} status={STATUS_MAP['Open']} date={createdDate} showDate />
      </div>
    </div>
  );
};

const TransactionDetails: React.FC<TransactionDetailsProps> = ({ request, children }) => {
  const prefix = prefixClass('transaction-info-details');
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Format dates
  const createdDate = request.createdAt ? format(new Date(request.createdAt), 'MM/dd/yyyy') : '-';
  const trxDate = request.transaction?.createdAt ? format(new Date(request.transaction.createdAt), 'MM/dd/yyyy') : '-';

  // Get transaction details
  const trxName = request.transaction?.name || '-';
  const description = request.transaction?.memo || '-';
  const amount = request.transaction?.amount || '-';

  // Get user name from first comment if available
  const createdByUser =
    request.comments?.[0]?.userLocation?.email?.split('@')[0] || request.comments?.[0]?.userLocation?.role || 'Unknown';

  // Determine request type
  const requestType = request.type || 'Custom Request';
  const isAskRequest = requestType.includes('Ask');
  const isFlaggedTransaction = requestType.includes('Flagged transaction');
  const isFlaggedCell = requestType.includes('Flagged Cell');
  const isCustomRequest = !isAskRequest && !isFlaggedTransaction && !isFlaggedCell;

  // Extract account information
  const accountInfo = request.transaction?.account || '--';

  return (
    <div className={prefix()}>
      <div className={prefix('container')}>
        <button
          className={classNames(prefix('collapse-btn'), { active: !isCollapsed })}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <IconChevron />
        </button>

        {!isCollapsed ? (
          <>
            <div className={prefix('props')}>
              <div className={prefix('props-header')}>
                <div className={prefix('props-header-label')}>Context</div>
              </div>
              {/* Render the appropriate expanded view based on request type */}
              {(isCustomRequest || isFlaggedCell) && (
                <SimpleRequestView
                  request={request}
                  isCustomRequest={isCustomRequest}
                  isFlaggedCell={isFlaggedCell}
                  formattedDates={{ createdDate, trxDate }}
                  createdByUser={createdByUser}
                />
              )}

              {(isFlaggedTransaction || isAskRequest) && (
                <TransactionRequestView
                  request={request}
                  isFlaggedTransaction={isFlaggedTransaction}
                  isAskRequest={isAskRequest}
                  formattedDates={{ createdDate, trxDate }}
                  createdByUser={createdByUser}
                />
              )}
            </div>
            <TransactionsFlow request={request} />
          </>
        ) : (
          <>
            <CollapsedView request={request} isCustomRequest prefix={prefix} />
            <StatusItem userName={''} status={STATUS_MAP[request.status]} date={createdDate} showDate />
          </>
        )}
      </div>
      <div className={prefix('actions')}>{children}</div>
    </div>
  );
};

export default TransactionDetails;
