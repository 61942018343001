import Axios, { AxiosError } from 'axios';
import { endsWith, find, isEmpty, map } from 'lodash';
import toast from 'react-hot-toast';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { HoneReportTimeframes, HoneReportTypes } from '../../constants';
import jsPDF from 'jspdf';
import {
  format,
  parseJSON,
  previousFriday,
  previousMonday,
  previousSaturday,
  previousSunday,
  previousThursday,
  previousTuesday,
  previousWednesday,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  nextSunday,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  startOfMonth,
  lastDayOfMonth,
  getMonth,
  subMonths,
  startOfYear,
  subYears,
  endOfYear,
  endOfMonth,
  parse,
} from 'date-fns';

import {
  commonUtil,
  GenerateRangesRequest,
  HoneAbility,
  HoneAbilityAction,
  HoneAbilitySubject,
  ReportConfiguration,
  ReportConfigurationData,
  ReportTimeframe,
  ReportTimeframes,
  StartEndDates,
} from '@hone-automation/common';
import { PureAbility } from '@casl/ability';
import { QueryParamsPayload } from 'domain/models';
import { Option } from 'types';
import { Location as KsLocation } from '@hone-automation/common';
import { fiscalRanges, generateRanges } from '@hone-automation/common/lib/reportUtil';
import { toUTCDate } from '../../presentation/utils';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';

export const fetcher = (url: string): Promise<unknown> => fetch(url).then(res => res.json());

export const getLocalStorage = (key: string): string | null => {
  return window.localStorage.getItem(key);
};
export const setLocalStorage = (key: string, value: string): void => {
  window.localStorage.setItem(key, value);
};

export const FIVE_SECONDS = 5 * 1000;
export const TEN_SECONDS = 10 * 1000;
export const HALF_MINUTE = 30 * 1000;
export const ONE_MINUTE = 60 * 1000;
export const FIVE_MINUTES = 5 * 60 * 1000;

export const TOAST_DEFAULT = 'default';
export const TOAST_SUCCESS = 'success';
export const TOAST_ERROR = 'error';
export const TOAST_SAVE_TEXT = 'Saving template, will take a moment';

export type BalanceSheetPreset = '6-months' | '12-months' | 'ytd' | 'last-year';

const BalanceSheetPresets = {
  Six_Months: '6-months',
  Twelve_Months: '12-months',
  Year_to_Date: 'ytd',
  Last_Year: 'last-year',
} as Readonly<Record<string, BalanceSheetPreset>>;

export const BALANCE_SHEET_PRESETS: Record<BalanceSheetPreset, StartEndDates> = {
  '6-months': new StartEndDates(
    format(startOfMonth(subMonths(new Date(), 6)), 'yyyy-MM-dd'),
    format(lastDayOfMonth(new Date()), 'yyyy-MM-dd'),
    'Last 6 months'
  ),
  '12-months': new StartEndDates(
    format(startOfMonth(subMonths(new Date(), 12)), 'yyyy-MM-dd'),
    format(lastDayOfMonth(new Date()), 'yyyy-MM-dd'),
    'Last 12 months'
  ),
  ytd: new StartEndDates(
    format(startOfYear(new Date()), 'yyyy-MM-dd'),
    format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    'Year-to-date'
  ),
  'last-year': new StartEndDates(
    format(startOfYear(subYears(new Date(), 1)), 'yyyy-MM-dd'),
    format(endOfYear(subYears(new Date(), 1)), 'yyyy-MM-dd'),
    'Last year'
  ),
};

export const findBSPresetDates = (
  preset: StartEndDates,
  key: BalanceSheetPreset,
  weekStart?: WeekDay,
  config?: ReportConfigurationData
) => {
  if (!config?.periodReporting) {
    return preset;
  }

  if (!weekStart) {
    console.warn(`[findBSPresetDates] ${key} unexpected weekStart ${weekStart} for config ${JSON.stringify(config)}`);
    return preset;
  }

  switch (key) {
    case BalanceSheetPresets.Six_Months:
      return findPeriodPreset(preset, key, weekStart, config, ReportTimeframes.Month, 6, false);
    case BalanceSheetPresets.Twelve_Months:
      return findPeriodPreset(preset, key, weekStart, config, ReportTimeframes.Month, 12, false);
    case BalanceSheetPresets.Last_Year:
      return findPeriodPreset(preset, key, weekStart, config, ReportTimeframes.Year, 2, true);
    case BalanceSheetPresets.Year_to_Date: {
      const today = commonUtil.toShortString(new Date());
      const start = fiscalRanges(ReportTimeframes.Year, weekStart, today, 1, config)[0]?.start;
      const end = fiscalRanges(ReportTimeframes.Month, weekStart, today, 1, config)[0]?.end;
      if (!start || !end) {
        console.warn(`[findBSPresetDates] ${key} unexpected start ${start} end ${end} values`);
        return preset;
      }
      return new StartEndDates(start, end, preset.name);
    }
    default: {
      console.warn(`[getBalanceSheetPreset] Unknown key: ${key}`);
      return preset;
    }
  }
};

const findPeriodPreset = (
  preset: StartEndDates,
  key: BalanceSheetPreset,
  weekStart: WeekDay,
  config: ReportConfigurationData,
  timeframe: ReportTimeframe,
  limit: number,
  lastValue: boolean
) => {
  const request = Object.assign(new GenerateRangesRequest(timeframe, weekStart, config), { limit });
  const values = generateRanges(request);
  if (!values?.length) {
    console.warn(`[findPeriodPreset] ${key} unexpected empty generateRanges return for request ${request}`);
    return preset;
  }
  return new StartEndDates(values[values.length - 1].start, values[lastValue ? values.length - 1 : 0].end, preset.name);
};

export const showToast = (text: string, type: string, duration?: number): string => {
  const style: any = {
    position: 'top-center',
    className: 'react-toast',
    style: {
      minWidth: '400px',
      maxWidth: 'max(50vw, 450px)',
    },
  };

  if (duration) {
    style['duration'] = duration;
  }
  if (type && type === 'success') {
    return toast.success(text, style);
  }
  if (type && type === 'error') {
    return toast.error(text, style);
  }
  if (type && type === 'warning') {
    return toast(text, { ...style, icon: '⚠️' });
  }
  return toast(text, style);
};

export const dismissToast = (toastId: string): void => {
  toast.dismiss(toastId);
};

export const currencyFormatter2Decimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  maximumFractionDigits: 2,
}).format;

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  maximumFractionDigits: 0,
}).format;

export const percentageFormatter = (val: number | undefined): string => {
  if (!val) return '0';
  return `${(val * 100).toFixed(2)}%`;
};

function hiLo(value: number, goal: number, hi = false) {
  if ((hi && value >= goal) || (!hi && value <= goal)) {
    return '#00ba88';
  } else {
    return '#d9e3f0';
  }
}

export const currencyFormatterShort = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format;

const formatCash = (n: number) => {
  if (n < 0) {
    const abs = Math.abs(n);
    if (abs < 1e3) return currencyFormatterShort(n);
    if (abs >= 1e3) return currencyFormatterShort(+(n / 1e3).toFixed(1)).replace(/.$/, 'k)');
  }
  if (n < 1e3) return currencyFormatterShort(n);
  if (n >= 1e3) return currencyFormatterShort(+(n / 1e3).toFixed(1)) + 'k';
};

interface ChartGoal {
  hiLo: boolean;
  val: number[];
}

export function getReportTitle(report: any): string {
  if (!report || report.type !== HoneReportTypes.PLComparison) return '';
  const period = report.timeframe === HoneReportTimeframes.Monthly ? 'Month' : 'Week';
  return `${period} Ending`;
}

export const userBelongsToHone = (user?: User): boolean =>
  Boolean(user && user.email && endsWith(user.email, process.env.VITE_HONE_ADMIN_EMAIL_SUFFIX));

export const isProdHost = (): boolean => window.location.hostname === process.env.VITE_HONE_PROD_HOST;

export const getHostEnvironment = (): 'local' | 'development' | 'production' => {
  if (window.location.hostname.includes('localhost')) return 'local';
  if (isProdHost()) return 'production';
  return 'development';
};

export const cdnOrigin = (): string =>
  isProdHost() ? process.env.VITE_HONE_PROD_CDN_ORIGIN : process.env.VITE_HONE_DEV_CDN_ORIGIN;

export const isLocalDevReporting = (): boolean => process.env.VITE_USE_LOCAL_REPORTING !== undefined;

export const reportingDevOrigin = (): string =>
  isLocalDevReporting() ? 'http://localhost:8081' : 'https://reporting-dev.kitchensync.us';

export const reportingOrigin = (): string => (isProdHost() ? 'https://reporting.kitchensync.us' : reportingDevOrigin());

export const launchDarklyClientSideKey = (): string =>
  isProdHost() ? process.env.VITE_LD_PROD_CLIENT_SIDE_ID : process.env.VITE_LD_DEV_CLIENT_SIDE_ID;

export const percentFormatter = (value: number): string => {
  return `${(value * 100).toFixed(2)}%`;
};

export const checkDataRows = (row: any, parent: any, dataRowsLoaded: boolean) => {
  if (dataRowsLoaded) {
    return !!row?.dataRows && row.dataRows.length > 0;
  }
  return !!row?.amount && !!parent?.glCode;
};

export const smoothingField = (
  row: any,
  smoothingEnabled: boolean,
  normalField: string,
  smoothedField: string
): [number | undefined, boolean] => {
  if (!row) return [undefined, false];

  let value = row[normalField];
  let valueIsDifferent = false;

  if (smoothingEnabled) {
    if (Object.prototype.hasOwnProperty.call(row, smoothedField)) {
      value = row[smoothedField];

      if (Object.prototype.hasOwnProperty.call(row, normalField)) {
        if (row[normalField] !== row[smoothedField]) {
          valueIsDifferent = true;
        }
      }
    }
  }

  return [value, valueIsDifferent];
};

export type pdfProps = {
  data: any;
  headers: any;
  filename: string;
};

export const pdf = ({ data, headers, filename }: pdfProps): void => {
  const doc = new jsPDF();
  doc.save(filename);
};

export function getUTCDate() {
  const now = new Date();
  return new Date(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
}

export function getCdnParams() {
  return getLocalStorage('hone:signedParamsCDN');
}

export function doDelete(url: string) {
  const options = {
    method: 'DELETE',
    headers: {},
    url: url,
    timeout: 60000,
  };

  // Add session token if available
  const session = getLocalStorage('hone:session');
  if (session) {
    options['headers'] = { session };
  }

  return Axios.create(options)
    .delete(url)
    .then(function (res: any) {
      return 'ok';
    })
    .catch(async function (error: AxiosError) {
      if (error.response?.status === 401) {
        return;
      }
      throw new Error(JSON.stringify(error));
    });
}

export function doGet(url: string, errorHandler?: (error: any) => any, noHeaders = false) {
  const options: any = {
    method: 'GET',
    headers: {},
    url: url,
    timeout: 60000,
  };

  // Add session token if available
  const session = getLocalStorage('hone:session');
  if (session && !noHeaders) options['headers'] = { session };

  return Axios.create(options)
    .get(url)
    .then(function (result: any) {
      return result;
    })
    .catch(
      errorHandler ??
        async function (error: AxiosError) {
          if (error.response?.status === 401 || error.response?.status === 403) {
            showToast('Your session has expired. Please sign in again', TOAST_DEFAULT, TEN_SECONDS);
            return;
          }
        }
    );
}

export function doPost(url: string, body: any, opts?: { signal: AbortSignal }) {
  const options: any = {
    method: 'POST',
    headers: {},
    url: url,
    timeout: 60000,
  };

  // Add session token if available
  const session = getLocalStorage('hone:session');
  if (session) {
    options['headers'] = { session };
  }

  return Axios.create(options)
    .post(url, body, opts)
    .then(function (res: any) {
      return res;
    })
    .catch(async function (error: AxiosError) {
      if (error.response?.status === 400 && error.response?.data) {
        throw new Error(error.response.data.toString());
      }
      if (error.response?.status === 401) {
        return;
      }
      throw new Error(JSON.stringify(error));
    });
}

export const DATE_EXP = /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-2])\/(2[0-3]|[0-1][0-9])$/;

export const getStatusIcon = (type: AuditReportType) => {
  switch (type) {
    case 'Error':
      return 'fa-exclamation';
    case 'Fail':
      return 'fa-times';
    case 'Warning':
      return 'fa-exclamation-triangle';
    case 'Pass':
      return 'fa-check';
  }
};

function formatValue(fr: AuditReport, value: number | string) {
  if (fr.perc) {
    return Number(value).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
  } else {
    return Number(value).toLocaleString(undefined, { style: 'currency', currency: 'USD' });
  }
}

function msgStr(message?: string): string {
  return message ? `: ${message}` : '';
}

export function auditTextResult(fr: AuditReport): string {
  switch (fr.type) {
    case 'Warning':
      return `${fr.fieldName}${msgStr(fr.message)}`;
    case 'Pass':
      return `${fr.fieldName} ${formatValue(fr, fr.reportValue)}${msgStr(fr.message)}`;
    case 'Fail':
      return `${fr.fieldName} ${formatValue(fr, fr.reportValue)}${msgStr(fr.message)}`;
    case 'Error':
    default:
      return `${fr.fieldName}${msgStr(fr.message)}`;
  }
}

export function filterRows<T extends { type: string }>(rows: T[], filter: string): T[] {
  if (isEmpty(filter) || filter === 'All') return rows;

  return rows.filter((row: T) => {
    return row.type === filter;
  });
}

export function mergeByFieldNameAndName(arr: AuditReport[]) {
  return {
    with: function (arr2: AuditReport[]) {
      return map(arr, item => {
        return find(arr2, obj => obj.fieldName === item.fieldName && obj.reportName === item.reportName) || item;
      });
    },
  };
}

export function prefixClass(className: string): (extra?: string) => string {
  return (extra?: string): string => {
    if (!extra) {
      return className;
    }
    return className + '-' + extra;
  };
}

function ensureTimeString(dateString: string) {
  // Check if the dateString ends with "T00:00:00"
  if (!dateString.endsWith('T00:00:00')) {
    // If it doesn't, append "T00:00:00" to the dateString
    dateString += 'T00:00:00';
  }
  return dateString;
}

export function formatDate(date: string | Date, dateFormat = 'MM/dd/yyyy'): string | null {
  if (date === '') return date;
  if (!date) return null;

  try {
    if (typeof date === 'string') {
      return format(parseJSON(ensureTimeString(date)), dateFormat);
    } else {
      return format(date, dateFormat);
    }
  } catch (e) {
    return null;
  }
}

export function formatToLocalTimezone(dateString: string | undefined, dateFormat = 'yyyy-MM-dd HH:mm:ss') {
  if (dateString === '') return dateString;
  if (!dateString) return null;

  let formattedDate = dateString;
  // Check if the dateString contains 'T', split date and time accordingly
  if (dateString?.indexOf('T') > 0) {
    formattedDate = dateString.split('T')[0] + ' ' + dateString.split('T')[1].split('.')[0];
  }

  // Parse the input date string as UTC
  const utcDate = parse(formattedDate, 'yyyy-MM-dd HH:mm:ss', new Date());

  // Convert the UTC date to the local timezone
  const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);

  // Format the date in the desired format for the local timezone
  return format(localDate, dateFormat);
}

export function formatFromUtcToTimezone(date: Date | string, timezone: string, dateFormat: string): string {
  const utcDate = typeof date === 'string' ? new Date(Date.parse(date + 'Z')) : new Date(date.getTime());
  const zonedDate = toZonedTime(utcDate, timezone);

  return formatInTimeZone(zonedDate, timezone, dateFormat);
}

export function transformDateString(date: string): string {
  const month = date.split('-')[0];
  const day = date.split('-')[1];
  const year = date.split('-')[2];

  return `${year}-${month}-${day}`;
}

export function formatDateStringToUSFormat(dateStr: string | undefined): string {
  if (!dateStr) return '';
  //check if date is in the format of yyyy-mm-dd is not return the date
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateStr)) {
    throw new Error('Invalid date format. Expected format is YYYY-MM-DD.');
  }

  const [year, month, day] = dateStr.split('-');
  return `${month}-${day}-${year}`;
}

export function generateTooltip(value: number, marker: string, seriesName: string, seriesType: string): string {
  return !isNaN(value)
    ? `
${marker}<b>${seriesName}</b>: ${
        !isNaN(value) ? (seriesType === 'bar' ? currencyFormatter(value) : percentageFormatter(value)) : ''
      }<br />`
    : '';
}

export function getCustomPeriodLabel(customRanges: Option[], selectedValue: string, backupRanges?: () => Option[]) {
  const customRangeValue =
    customRanges.find(range => range.value === selectedValue) ??
    backupRanges?.()?.find(range => range.value === selectedValue);
  return `${customRangeValue?.customLabel}`;
}

export const getLastDayByInitial = (day: string): Date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const days: Record<string, Date> = {
    Mo: previousMonday(today),
    Tu: previousTuesday(today),
    We: previousWednesday(today),
    Th: previousThursday(today),
    Fr: previousFriday(today),
    Sa: previousSaturday(today),
    Su: previousSunday(today),
  };
  return days[day];
};

export function getDigits(number: number | string): number {
  return number.toString().length;
}

export function reportTitleToUrl(title: string, queryParam = false): string {
  if (queryParam) {
    return title.toLowerCase().replaceAll(' ', '-').replaceAll('&', '').replaceAll('-pl', '');
  }

  if (title === 'ytd--income-statement') {
    return 'YTD Monthly Income Statement';
  }

  const pattern = /\b(monthly|weekly|report|'s|&|P&L)\b/g;

  return title.toLowerCase().replaceAll(pattern, '').replaceAll(' ', '-').replaceAll('-pl', 'pl');
}

export function getConnectionLocation(): string {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().locale;
  return userTimeZone;
}

export function getDateRange(
  date: Date,
  weekStart: WeekDay,
  timeFrame: HoneReportTimeframe,
  dateSelected?: Date | string
): DateRange {
  if (typeof dateSelected === 'string') {
    dateSelected = new Date(dateSelected);
  }
  if (timeFrame === 'Monthly') {
    if (!dateSelected) {
      const firstDayMonth = startOfMonth(date);
      const lastDayOfTheMonth = lastDayOfMonth(date);
      return [firstDayMonth, lastDayOfTheMonth];
    }
    // const dateIsBeforeThanSelected = date.getTime() < dateSelected.getTime();
    const firstDayMonth = dateSelected && startOfMonth(dateSelected);
    const lastDayMonth = lastDayOfMonth(date);
    return [firstDayMonth, lastDayMonth];
  }
  const dateRange: Record<string, DateRange> = {
    Mo: [isMonday(date) ? date : previousMonday(date), isSunday(date) ? date : nextSunday(date)],
    Tu: [isTuesday(date) ? date : previousTuesday(date), isMonday(date) ? date : nextMonday(date)],
    We: [isWednesday(date) ? date : previousWednesday(date), isTuesday(date) ? date : nextTuesday(date)],
    Th: [isThursday(date) ? date : previousThursday(date), isWednesday(date) ? date : nextWednesday(date)],
    Fr: [isFriday(date) ? date : previousFriday(date), isThursday(date) ? date : nextThursday(date)],
    Sa: [isSaturday(date) ? date : previousSaturday(date), isFriday(date) ? date : nextFriday(date)],
    Su: [isSunday(date) ? date : previousSunday(date), isSaturday(date) ? date : nextSaturday(date)],
  };

  return dateRange[weekStart];
}

export function isDateInMonth(date: string, monthCode: string, yearPlotted: number | undefined): boolean {
  const months: { [code: string]: number } = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  let targetMonth = months[monthCode];
  if (targetMonth === undefined) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      targetMonth = months[format(monthCode, 'MMM')];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error while trying to process date');
    }
  }

  const targetDate = new Date(date);
  const year = targetDate.getFullYear();
  const month = targetDate.getMonth();

  return year !== undefined && month === targetMonth && year === yearPlotted;
}

export function abilityCan(abilities: HoneAbility[], action: HoneAbilityAction, subject: HoneAbilitySubject) {
  if (!abilities) {
    return false;
  }
  const ability = new PureAbility(abilities);
  return ability.can(action, subject);
}

export const sortReportTitles = (reports: HoneReportSummary[]): HoneReportSummary[] => {
  // Define the special titles and their order of precedence
  const specialTitles = ['Weekly P&L Comparison', 'Monthly P&L Comparison', 'YTD Income Statement'];

  // Separate special titles from other reports
  const specialReports: HoneReportSummary[] = [];
  const weeklyReports: HoneReportSummary[] = [];
  const monthlyReports: HoneReportSummary[] = [];
  const noTimeframeReports: HoneReportSummary[] = [];

  for (const report of reports) {
    if (specialTitles.includes(report.title)) {
      specialReports.push(report);
    } else if (report.timeframe === 'Weekly') {
      weeklyReports.push(report);
    } else if (report.timeframe === 'Monthly') {
      monthlyReports.push(report);
    } else {
      noTimeframeReports.push(report);
    }
  }

  // Sort the special reports based on their defined order
  specialReports.sort((a, b) => specialTitles.indexOf(a.title) - specialTitles.indexOf(b.title));

  // Sort the weekly and monthly reports alphabetically by title
  weeklyReports.sort((a, b) => a.title.localeCompare(b.title));
  monthlyReports.sort((a, b) => a.title.localeCompare(b.title));

  // Concatenate the sorted arrays
  return [...specialReports, ...weeklyReports, ...monthlyReports, ...noTimeframeReports];
};

export const roundValuesToOneDecimal = (value: number) => {
  return Math.round(value / 100) / 10;
};

export const currencyStringToNumber = (currencyStr: string | null): number => {
  if (!currencyStr) return NaN;
  // Remove currency symbol ($) and commas, then parse it to a floating point number
  return parseFloat(currencyStr.replace(/[$,]/g, ''));
};

/**
 * Moves an object within an array from one index to another.
 * @param array - The array to modify.
 * @param fromIndex - The index of the object to move.
 * @param toIndex - The index where the object should be moved to.
 * @returns The modified array with the object moved to the new index.
 */
export function moveObjectAtIndex<T>(array: T[], fromIndex: number, toIndex: number): T[] {
  if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length) {
    return array; // Return without changing the array if indices are out of bounds
  }

  // Remove the object from the array at fromIndex
  const [itemToMove] = array.splice(fromIndex, 1);

  // Insert the removed object at the new index (toIndex)
  array.splice(toIndex, 0, itemToMove);

  return array; // Return the modified array
}

/**
 * Removes specific parameters from a URL.
 *
 * @param url - The URL to remove parameters from.
 * @param paramsToRemove - An array of parameter names to remove.
 * @returns The modified URL with the specified parameters removed.
 */
export function removeSpecificParams(url: string, paramsToRemove: string[]) {
  const [baseUrl, queryString] = url.split('?');
  if (!queryString) return url; // No parameters to remove

  const queryParams = queryString.split('&');
  const filteredParams = queryParams.filter(param => {
    const [key] = param.split('=');
    return !paramsToRemove.includes(key);
  });

  return filteredParams.length ? `${baseUrl}?${filteredParams.join('&')}` : baseUrl;
}

export function generateNotAllowedUrlAggregateSideBySide(
  localCompareLocations: string,
  queryParams: QueryParamsPayload,
  consolidated: string
): QueryParamsPayload | null {
  const isAggrSideBySide =
    localCompareLocations && (!consolidated || consolidated === 'false')
      ? localCompareLocations?.split(',').length > 1
      : false;
  if (
    isAggrSideBySide &&
    (queryParams.breakdownPeriods === 'true' ||
      queryParams.difference === 'true' ||
      queryParams.total === 'true' ||
      (queryParams.comparisonPeriods && queryParams.comparisonPeriods > 0) ||
      queryParams.reverse === 'true')
  ) {
    delete queryParams.comparisonPeriods;
    const insideNewQueries = {
      ...queryParams,
      total: 'false',
      difference: 'false',
      breakdownPeriods: 'false',
      reverse: 'false',
      chartCollapsed: 'true',
      comparisonPeriods: 0,
      compareLocations: localCompareLocations.toString(),
    };
    if (consolidated) {
      insideNewQueries.consolidated = consolidated;
    }

    delete insideNewQueries.compareEnabled;
    delete insideNewQueries.comparisonType;

    return insideNewQueries;
  }
  return null;
}
