import React, { createContext, useContext, useState } from 'react';
import { useNTUtilsContext } from './NTUtilsContext';

type NTTableContextType = {
  redimParent: HTMLDivElement | null;
  setRedimParent: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  isResizing: boolean;
  setIsResizing: React.Dispatch<React.SetStateAction<boolean>>;
  tableWidth: string;
  setTableWidth: React.Dispatch<React.SetStateAction<string>>;
  reportType: 'report' | 'budget';
  setReportType: React.Dispatch<React.SetStateAction<'report' | 'budget'>>;
};

const NTTableContext = createContext<NTTableContextType | undefined>(undefined);
export const NTTableProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { actions, capabilities } = useNTUtilsContext();
  const [redimParent, setRedimParent] = useState<HTMLDivElement | null>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [tableWidth, setTableWidth] = useState(
    actions.detailsPanel && capabilities.charts ? 'var(--ks-left-pane-constrained-width)' : '100%'
  );
  const [reportType, setReportType] = useState<'report' | 'budget'>('report');

  return (
    <NTTableContext.Provider
      value={{
        redimParent,
        setRedimParent,
        isResizing,
        setIsResizing,
        tableWidth,
        setTableWidth,
        reportType,
        setReportType,
      }}
    >
      {children}
    </NTTableContext.Provider>
  );
};

export const useNTTableContext = () => {
  const context = useContext(NTTableContext);
  if (!context) {
    throw new Error('useNTTableContext must be used within a NTTableProvider');
  }
  return context;
};
