import { logger } from 'presentation/components/KSExport/reportNTParser/utils';
import { identifyAccountType } from './DAccountMatching';
import { getChartData, getWeekNumber, sumCategories } from './DchartData';
import { getHierarchicalAccountData, HierarchicalAccountData } from './DInsights';
import { calculateMetrics, findSignificantChanges } from './DPLAnalysis/analysis';
import { createEnhancedStructuredPrompt } from './DPLAnalysis/promptGeneration';
import {
  ParsedReport,
  DasboardReport,
  PLNode,
  ChartMetrics,
  metricsPeriodPlotWeek,
  metricsPeriodPlot,
  MetricsPlot,
} from './types';
import { PeriodsWeekDataProps } from './DPeriodStructure';

export const CATEGORY_NAMES = {
  chart: ['chart metrics'],
  sales: ['sales'],
  occupancy: ['rent', 'occupancy'],
};

function parsePLReport(report: NestedHoneReport): ParsedReport {
  const parsedReport: ParsedReport = {
    columns: {},
    rows: {},
  };

  // Create column IDs once
  const columnIds = report.dates.map(() => crypto.randomUUID());

  // Initialize columns with the pre-generated IDs
  report.dates.forEach((date, index) => {
    const columnId = columnIds[index];
    parsedReport.columns[columnId] = {
      values: [],
      start: date.start,
      end: date.end,
      location: date.location,
      id: columnId,
    };
  });

  let orderCounter = 0;

  function collectValues(node: PLNode, parentId: string | null = null): string {
    const rowId = crypto.randomUUID();
    const { category, confidence } = identifyAccountType(node.title, node.glCode ?? null);

    parsedReport.rows[rowId] = {
      title: node.title,
      level: node.level,
      glCode: node.glCode ?? null,
      order: orderCounter++,
      parentId,
      category,
      categorization: {
        confidence,
        needsReview: confidence === 'low',
      },
    };

    report.dates.forEach((date, dateIndex) => {
      parsedReport.columns[columnIds[dateIndex]].values.push({
        data: node.data[dateIndex] ?? null,
        rowId,
      });
    });

    if (node.sections && node.sections.length > 0) {
      node.sections.forEach(section => section.display !== 'hidden' && collectValues(section, rowId));
    }

    return rowId;
  }

  report.sections.forEach(section => collectValues(section as PLNode, null));

  return parsedReport;
}

function parseChartMetrics(report: NestedHoneReport): ChartMetrics[] | null {
  const chartData: ChartMetrics[] = [];

  const chartGroup = report.sections.find(section => CATEGORY_NAMES.chart.includes(section.title.toLowerCase()));
  if (!chartGroup) {
    logger.msg('No CHART METRICS found in the report Schema, infering chart data from the report ...');
    return null;
  }
  logger.msg('CHART METRICS detected');
  report.dates.forEach((date, index) => {
    const YAxisItem: ChartMetrics = {
      weekId: getWeekNumber(report.dates[index].start),
      date: { start: date.start, end: date.end },
      data: {},
    };
    chartGroup?.sections?.forEach(section => {
      YAxisItem.data[section.title] = {
        value: section.data[index].amount,
        label: section.title,
        sales: CATEGORY_NAMES.sales.includes(section.title.toLowerCase()),
        occupancy: CATEGORY_NAMES.occupancy.includes(section.title.toLowerCase()),
      };
    });
    chartData.push(YAxisItem);
  });
  return chartData;
}

export const getConceptSortIndex = (concept: string, totalConcepts: number): number => {
  const lowercaseConcept = concept.toLowerCase();
  if (lowercaseConcept === 'rent' || lowercaseConcept === 'occupancy') {
    return 0;
  }
  if (lowercaseConcept === 'sales' || lowercaseConcept === 'revenue') {
    return totalConcepts - 1;
  }
  if (lowercaseConcept === 'cogs' || lowercaseConcept === 'cost of goods sold') {
    return totalConcepts - 2;
  }
  if (lowercaseConcept === 'labor' || lowercaseConcept === 'labour') {
    return totalConcepts - 3;
  }
  return Math.floor(totalConcepts / 2) - 1;
};

const mapChartMetricsToPeriodStructure = (
  chartData: ChartMetrics[],
  periodStructure: PeriodsWeekDataProps
): MetricsPlot => {
  const metricsPlot: MetricsPlot = {
    periodReporting: periodStructure.periodReporting,
    periods: [],
    metrics: [],
  };
  periodStructure.periods.forEach((period, index) => {
    const columnMetrics: metricsPeriodPlot = {
      periodTitle: period.month,
      periodtype: period.type,
      year: period.year,
      date: { start: period.weeks[0].startDate, end: period.weeks[period.weeks.length - 1].endDate },
      maxValue: 0,
      minValue: 0,
      maxCummulative: 0,
      minCummulative: 0,
      weeks: [],
      metricKeys: [],
    };
    period.weeks.forEach((week, weekIndex) => {
      const plotData: metricsPeriodPlotWeek = {
        weekId: week.weekNumber,
        start: week.startDate,
        end: week.endDate,
        noData: true,
        complete: !week.isPartial,
        bom: weekIndex === 0,
        eom: weekIndex === period.weeks.length - 1,
        daysIn: week.daysInMonth,
        currentWeek: week.status === 'current',
        today: week.status === 'current' ? week.daysPassed || 0 : 0,
        data: {},
      };

      const chartMetrics = chartData.find(chart => chart.date.start === week.startDate);
      //console.log(plotData, chartMetrics, chartData, 'week', week.startDate);

      if (chartMetrics) {
        period.weeks[weekIndex].metrics = chartMetrics.data;
        const conceptsToPlot = Object.keys(chartMetrics.data).length;
        plotData.noData = Object.values(chartMetrics.data).every(metric => metric.value === 0);

        // First set up all metrics and calculate minimum values
        Object.keys(chartMetrics.data).forEach(metric => {
          const metricObject = chartMetrics.data[metric];
          // Only update minValue directly
          columnMetrics.minValue = Math.min(columnMetrics.minValue, metricObject.value);
          plotData.data[metric] = {
            sortIndex: getConceptSortIndex(metric, conceptsToPlot),
            value: metricObject.value,
            label: metricObject.label,
            cumulative: metricObject.value,
            isSales: metricObject.sales,
          };
        });

        // Now calculate combined expenses value vs sales value
        let salesValue = 0;
        let expensesValue = 0;
        Object.keys(chartMetrics.data).forEach(metric => {
          const metricObject = chartMetrics.data[metric];
          if (metricObject.sales) {
            salesValue = metricObject.value;
          } else {
            expensesValue += metricObject.value;
          }
        });

        // Max is the greater of sales or total expenses
        const maxForWeek = Math.max(salesValue, expensesValue);
        columnMetrics.maxValue = Math.max(columnMetrics.maxValue, maxForWeek);
        /* logger.msg('salesValue', salesValue);
        logger.msg('expensesValue', expensesValue); */
      }
      columnMetrics.weeks.push(plotData);
    });
    // Check if we have any weeks with data
    const weeksWithData = columnMetrics.weeks.filter(week => !week.noData);

    if (weeksWithData.length > 0) {
      metricsPlot.metrics = Object.keys(weeksWithData[0].data).sort(
        (a, b) => weeksWithData[0].data[a].sortIndex - weeksWithData[0].data[b].sortIndex
      ) as string[];

      // First calculate individual cumulative values as before
      metricsPlot.metrics.forEach(element => {
        let cumulative = 0;
        columnMetrics.metricKeys = metricsPlot.metrics;
        columnMetrics.weeks.forEach(week => {
          if (!week.noData && week.data[element]) {
            cumulative += week.data[element].value;
            // Store cumulative value for each metric
            week.data[element].cumulative = cumulative;

            // For min, we still check against each individual metric's cumulative
            columnMetrics.minCummulative = Math.min(columnMetrics.minCummulative, cumulative);
          }
        });
      });

      // Now calculate max by comparing sales vs combined expenses
      columnMetrics.weeks.forEach(week => {
        if (!week.noData) {
          let salesCumulative = 0;
          let expensesCumulative = 0;

          // Calculate sales and non-sales separately
          metricsPlot.metrics.forEach(metric => {
            if (week.data[metric]) {
              if (week.data[metric].isSales) {
                salesCumulative = week.data[metric].cumulative;
              } else {
                expensesCumulative += week.data[metric].cumulative; // Sum all expenses
              }
            }
          });

          // Max is the greater of sales or total expenses
          /* logger.msg('salesCumulative', salesCumulative);
          logger.msg('expensesCumulative', expensesCumulative); */
          const maxForWeek = Math.max(salesCumulative, expensesCumulative);
          columnMetrics.maxCummulative = Math.max(columnMetrics.maxCummulative, maxForWeek);
        }
      });
    }

    periodStructure.periodsToPlot.push(columnMetrics);
  });
  metricsPlot.periods = periodStructure.periodsToPlot;
  return metricsPlot;
};

const reportToDashboard = (
  report: NestedHoneReport,
  periodStructure: PeriodsWeekDataProps,
  trace: boolean = false
): DasboardReport => {
  const aiReport = null;
  if (trace) {
    logger.msg('periodStructure', periodStructure);
    logger.msg('API report', report);
  }
  const parsedReport = parsePLReport(report);
  if (trace) logger.msg('report', parsedReport);

  // First we try to get Chart Data from the special section on the API Report. If not found, we infer it from the report
  const chartMetrics = parseChartMetrics(report);
  const chartData: any = chartMetrics || getChartData(parsedReport);
  if (trace) logger.msg('chart metrics from report', chartMetrics);
  if (trace) logger.msg('chart metrics', chartData);

  const mappedChartData = mapChartMetricsToPeriodStructure(chartData, periodStructure);
  if (trace) logger.msg('Mapped chart metrics', mappedChartData);

  const hierarchicalData = getHierarchicalAccountData(parsedReport);
  if (trace) logger.msg('hierarchicalData', hierarchicalData);
  /*   const metrics = calculateMetrics(hierarchicalData);
  logger.msg('metrics', metrics);
  const significantChanges = findSignificantChanges(metrics);
  logger.msg('significantChanges', significantChanges); */
  const structuredPropmt = createEnhancedStructuredPrompt(hierarchicalData);
  //logger.msg('structuredPropmt', structuredPropmt);
  return { chartData: mappedChartData, aiReport: hierarchicalData, structuredPropmt };
};

export default reportToDashboard;
