import { useQuery } from '@tanstack/react-query';
import { makeGetBudgets } from 'main/factories/usecases/budgets/remote-get-budgets';
import { GetBudgetRequest } from '@hone-automation/common';
import { useActiveReportStore } from 'hooks/useActiveReportStore';
import { showToast, TEN_SECONDS } from 'lib/utils';

export const useGetBudgets = (budgetRequest: GetBudgetRequest) => {
  return useQuery({
    queryKey: ['budgets', budgetRequest.locationId, budgetRequest.startDate],
    queryFn: async () => {
      try {
        const service = makeGetBudgets();
        const budgetReport = await service.getBudgets(budgetRequest);
        const nestedBudgetReport = { ...budgetReport } as NestedHoneReport;

        const newDates = nestedBudgetReport.dates.map(item => ({
          ...item,
          type: 'Data',
        }));

        const newTransformedData = { ...nestedBudgetReport, dates: newDates };

        useActiveReportStore.setState({ currentReport: newTransformedData });

        return newTransformedData;
      } catch (error) {
        showToast('Error while fetching budgets' + (error as ErrorResponse).message, 'error', TEN_SECONDS);
        console.error('Error fetching budgets:', error);
      }
    },
    staleTime: 1000 * 60 * 5,
    enabled: !!budgetRequest.startDate && !!budgetRequest.locationId,
  });
};
