import { ChartMetrics, ColumnData, ParsedReport } from './types';
import { CATEGORY_NAMES } from './reportToDashboard';
import { logger } from 'presentation/components/KSExport/reportNTParser/utils';

interface CategorySums {
  startDate: string;
  endDate: string;
  dateRange: string;
  sales: {
    total: number;
    values: Array<{
      value: ColumnData;
      title: string;
      glCode: number | null;
    }>;
  };
  labor: {
    total: number;
    values: Array<{
      value: ColumnData;
      title: string;
      glCode: number | null;
    }>;
  };
  cogs: {
    total: number;
    values: Array<{
      value: ColumnData;
      title: string;
      glCode: number | null;
    }>;
  };
  rent: {
    total: number;
    values: Array<{
      value: ColumnData;
      title: string;
      glCode: number | null;
    }>;
  };
  advertising: { total: number; values: Array<{ value: ColumnData; title: string; glCode: number | null }> };
  gna: {
    total: number;
    values: Array<{
      value: ColumnData;
      title: string;
      glCode: number | null;
    }>;
  };
}

export function sumCategories(parsedReport: ParsedReport): { [columnId: string]: CategorySums } {
  const sums: { [columnId: string]: CategorySums } = {};

  // First, get the column IDs sorted by date (most recent first)
  const sortedColumnIds = Object.keys(parsedReport.columns).sort((a, b) => {
    const dateA = new Date(parsedReport.columns[a].end); // Using end date for sorting
    const dateB = new Date(parsedReport.columns[b].end);
    return dateB.getTime() - dateA.getTime(); // Descending order (most recent first)
  });

  // Initialize sums for each column in the sorted order
  sortedColumnIds.forEach(columnId => {
    const column = parsedReport.columns[columnId];
    sums[columnId] = {
      startDate: column.start,
      endDate: column.end,
      dateRange: `${column.start} - ${column.end}`,
      sales: { total: 0, values: [] },
      labor: { total: 0, values: [] },
      cogs: { total: 0, values: [] },
      rent: { total: 0, values: [] },
      advertising: { total: 0, values: [] },
      gna: { total: 0, values: [] },
    };
  });

  // Process values maintaining the sorted order
  sortedColumnIds.forEach(columnId => {
    const column = parsedReport.columns[columnId];
    column.values.forEach(value => {
      if (value.data !== null) {
        const rowMetadata = parsedReport.rows[value.rowId];

        // Skip synthetic accounts
        if (rowMetadata.category === 'synthetic') {
          return;
        }

        const valueInfo = {
          value: value.data,
          title: rowMetadata.title,
          glCode: rowMetadata.glCode,
          id: value.rowId,
        };
        //console.log(rowMetadata.category, 'valueInfo', valueInfo);
        // Add to the appropriate category
        switch (rowMetadata.category) {
          case 'advertising':
            sums[columnId].advertising.total += value.data.amount;
            sums[columnId].advertising.values.push(valueInfo);
            break;
          case 'sales':
            sums[columnId].sales.total += value.data.amount;
            sums[columnId].sales.values.push(valueInfo);
            break;
          case 'labor':
            sums[columnId].labor.total += value.data.amount;
            sums[columnId].labor.values.push(valueInfo);
            break;
          case 'cogs':
            sums[columnId].cogs.total += value.data.amount;
            sums[columnId].cogs.values.push(valueInfo);
            break;
          case 'rent':
            sums[columnId].rent.total += value.data.amount;
            sums[columnId].rent.values.push(valueInfo);
            break;
          case 'gna':
            sums[columnId].gna.total += value.data.amount;
            sums[columnId].gna.values.push(valueInfo);
            break;
        }
      }
    });
  });

  // Create a new object with sorted entries
  const sortedSums: { [columnId: string]: CategorySums } = {};
  sortedColumnIds.forEach(columnId => {
    sortedSums[columnId] = sums[columnId];
  });
  return sortedSums;
}

/**
 * Gets the week number for a given date range string.
 * Handles leap years correctly according to ISO 8601 standards.
 *
 * @param dateRangeString String containing date range (format: "YYYY-MM-DD - YYYY-MM-DD")
 * @returns The week number and optionally which year it belongs to
 */
export const getWeekNumber = (dateRangeString: string) => {
  // Extract the start date from the range (first part before the hyphen)
  const startDate = dateRangeString.split(' - ')[0];

  // Create a Date object from the start date
  const date = new Date(startDate);

  // Set to midnight for consistent calculations
  date.setHours(0, 0, 0, 0);

  // Using Thursday to determine the year (ISO 8601 standard)
  // Thursday in current week determines the year
  const thursday = new Date(date);
  thursday.setDate(date.getDate() + 4 - (date.getDay() || 7));

  // January 4th is always in the first week of the year in ISO 8601
  const january4th = new Date(thursday.getFullYear(), 0, 4);

  // First Thursday of the year
  const firstThursday = new Date(january4th);
  firstThursday.setDate(january4th.getDate() - (january4th.getDay() || 7) + 4);

  // Calculate week number: number of days since first Thursday, divided by 7, plus 1
  const weekNumber = 1 + Math.floor((thursday.getTime() - firstThursday.getTime()) / (7 * 24 * 60 * 60 * 1000));

  return weekNumber;
};

export const getChartData = (parsedReport: ParsedReport): ChartMetrics[] => {
  const categorySums = sumCategories(parsedReport);
  const chartFields = { sales: 'Sales', labor: 'Labor', cogs: 'COGS', gna: 'G&A', rent: 'Rent' };
  const chartData: ChartMetrics[] = [];
  Object.entries(categorySums)
    .reverse()
    .forEach(([columnId, columnData]) => {
      // Will be in date order
      const tmpObj: ChartMetrics = {
        weekId: getWeekNumber(columnData.dateRange),
        date: { start: columnData.startDate, end: columnData.endDate },
        data: {},
      };
      Object.entries(chartFields)
        .reverse()
        .forEach(([key, value]) => {
          if (key in columnData) {
            const categoryData = columnData[key as keyof CategorySums];
            if (typeof categoryData === 'object' && 'total' in categoryData) {
              tmpObj.data[value] = {
                value: categoryData.total,
                label: value,
                sales: CATEGORY_NAMES.sales.includes(value.toLowerCase()),
                occupancy: CATEGORY_NAMES.occupancy.includes(value.toLowerCase()),
              };
            }
          }
        });
      chartData.push(tmpObj);
    });
  return chartData;
};
