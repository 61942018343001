import { prefixClass } from 'lib/utils';
import { formatAmountCondensed } from '../../NewTable/NTCell/ValueFormatters/ValueFormatters';
import { hoverInfo } from '../DashboardChart';
import './DashboardChartTooltip.scss';
import { formatRangeValues } from 'lib/reportUtils';

const DashboardChartTooltip = ({ hoverInfo }: { hoverInfo: hoverInfo }) => {
  const prefix = prefixClass('ks-dashboard-chart-tooltip');
  document.documentElement.style.setProperty('--ks-dashboard-chart-concept-color', `${hoverInfo.info.color}`);
  const titleForWeeks = hoverInfo.info.values[hoverInfo.week]
    ? formatRangeValues(hoverInfo.info.values[hoverInfo.week].date, 'KS')
    : '';
  //+ ` ${hoverInfo.x},${hoverInfo.y}  ${hoverInfo.week}`
  return (
    <div className={prefix()}>
      <div className={prefix('concept')}>{hoverInfo.info.key}</div>
      <div className={prefix('date-entry')}>
        {/* <div className={prefix('date-entry-week')}>{`W${hoverInfo.week + 1}`}</div> */}
        {hoverInfo.info.values[hoverInfo.week] && <div className={prefix('date-entry-date')}>{titleForWeeks}</div>}
      </div>
      <div className={prefix('value-entry')}>
        <div className={prefix('value-entry-label')}>Discrete</div>
        <div className={prefix('value-entry-value')}>
          {formatAmountCondensed(
            hoverInfo.info.values[hoverInfo.info.type !== 'p&l' ? hoverInfo.week : 0].discrete,
            true
          )}
        </div>
      </div>
      <div className={prefix('value-entry')}>
        <div className={prefix('value-entry-label')}>Cumulative</div>
        <div className={prefix('value-entry-value')}>
          {formatAmountCondensed(
            hoverInfo.info.values[hoverInfo.info.type !== 'p&l' ? hoverInfo.week : 0].cumulative,
            true
          )}
        </div>
      </div>
    </div>
  );
};
export default DashboardChartTooltip;
