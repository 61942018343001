import { forEach } from 'lodash';
import { TransformedData, Column, Amounts, Accounts } from './types';
import { formatValue } from '../ksExporterUtils';

type KSTransactionsProps = {
  transactions: KSTransaction[];
  vendors: KSVendor[];
  accounts: KSAccount[];
  auditedTransactions: KSAuditedTransaction[];
};

type DataTransaction = {
  amount: number;
  balance?: number;
  description: string;
  eventId: string;
  name: string;
  num: string;
  txnDate: string;
  dueDate?: string;
  pastDue?: number;
  type: string;
};

type KSTransaction = {
  id: number;
  amount: number;
  description: string; // memo
  category: string; // type of transaction
  accountId: string; // KSAccount id
  name: string; // vendor id
  vendorId: number;
  period: number; // period id of the transaction (column id)
  txnDate: string; // date of the transaction
  dueDate: string; // due date of the transaction
  pastDue: number; // days past due
  eventId: string;
  num: string;
  notes: string;

  // Additional fields
  audit: KSAudit;
};

type KSAudit = {
  status: 'Normal' | 'Outlier' | 'Anomaly' | 'Flagged';
  amount: number; // deviation from the mean
  reason: string;
};
type Boundaries = {
  threeStdDev: {
    lower: number;
    upper: number;
  };
  iqr: {
    q1: number;
    q3: number;
    lower: number;
    upper: number;
  };
};

type KSVendorAccount = {
  accountId: number;
  stats: KSstats | null;
};

type KSVendor = {
  id: number;
  externalId: string;
  name: string;
  glAccounts: KSVendorAccount[]; // A vendor can serve as a vendor for multiple accounts so we need to store the boundaries for each account
  categories: string[];
};

type KSstats = {
  count: number;
  min: number;
  max: number;
  stdDev: number;
  median: number;
  mean: number;
  variance: number;
  sum: number;
  boundaries: Boundaries;
};

type KSAccount = {
  id: number;
  glCode: number;
  accountId: string;
  name: string;
  stats: KSstats | null;
  auditedTransactions: KSAuditedTransaction | null;
};

type KSAuditedTransaction = {
  outliers: number[];
  anomalies: number[];
  flaggedTransactions: number[];
};

function calculateStats(values: number[]): KSstats {
  const count = values.length;
  if (count === 0) {
    throw new Error('Cannot calculate stats for empty array');
  }

  const sorted = [...values].sort((a, b) => a - b);
  const sum = values.reduce((acc, val) => acc + val, 0);
  const mean = sum / count;
  const variance = values.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / count;
  const stdDev = Math.sqrt(variance);

  const median = count % 2 === 0 ? (sorted[count / 2 - 1] + sorted[count / 2]) / 2 : sorted[Math.floor(count / 2)];

  // Calculate quartiles for IQR
  const q1Index = Math.floor(count * 0.25);
  const q3Index = Math.floor(count * 0.75);
  const q1 = sorted[q1Index];
  const q3 = sorted[q3Index];
  const iqr = q3 - q1;

  return {
    count,
    min: sorted[0],
    max: sorted[count - 1],
    stdDev,
    median,
    mean,
    variance,
    sum,
    boundaries: {
      threeStdDev: {
        lower: mean - 3 * stdDev,
        upper: mean + 3 * stdDev,
      },
      iqr: {
        q1,
        q3,
        lower: q1 - 1.5 * iqr,
        upper: q3 + 1.5 * iqr,
      },
    },
  };
}

const convertDataTransactionToKSTransaction = (
  dataTransaction: DataTransaction,
  period: number = 0,
  accountId: string,
  id: number = 0
): KSTransaction => {
  const pastDue = dataTransaction.dueDate
    ? Math.max(
        0,
        Math.floor((new Date().getTime() - new Date(dataTransaction.dueDate).getTime()) / (1000 * 60 * 60 * 24))
      )
    : 0;
  return {
    id: id, // You might need to generate a unique ID. Probably a UUID or incrementing number
    amount: dataTransaction.amount,
    description: dataTransaction.description,
    category: dataTransaction.type,
    accountId: accountId, // To be filled in later
    name: dataTransaction.name,
    vendorId: 0, // To be filled in later
    period: period, // To be filled in later
    txnDate: dataTransaction.txnDate,
    dueDate: dataTransaction.dueDate || '',
    pastDue: pastDue,
    eventId: dataTransaction.eventId,
    num: dataTransaction.num,
    notes: '',
    audit: {
      status: 'Normal',
      amount: 0,
      reason: '',
    },
  };
};

const getTransactions = (
  transformedData: TransformedData
): { transactions: KSTransaction[]; accounts: KSAccount[]; vendors: KSVendor[] } => {
  const transactions: KSTransaction[] = [];
  const accounts: KSAccount[] = [];
  const vendors: KSVendor[] = [];
  const columns = transformedData.columns;
  //let outliersCount = 0;

  // iterate rows (accounts)
  forEach(columns[0].data, (row: Accounts, rowIndex: number) => {
    const account: KSAccount = {
      id: rowIndex,
      glCode: row.glCode,
      accountId: row.id,
      name: row.amountTitle,
      stats: null,
      auditedTransactions: null,
    };

    // iterate columns
    const values: number[] = [];
    const tmpTransactions: KSTransaction[] = [];
    forEach(columns, (column: Column, index: number) => {
      if (index > 0 && column.data && column.data[rowIndex].dataRows.length > 0) {
        // iterate data rows
        forEach(column.data[rowIndex].dataRows, (dataTransaction: DataTransaction) => {
          const transaction: KSTransaction = convertDataTransactionToKSTransaction(
            dataTransaction,
            index - 1,
            row.id,
            transactions.length
          );

          const existingVendor = vendors.find(vendor => vendor.name === transaction.name);
          if (existingVendor) {
            if (!existingVendor.glAccounts.some(acc => acc.accountId === account.id)) {
              existingVendor.glAccounts.push({
                accountId: account.id,
                stats: null,
              });
            }
            transaction.vendorId = existingVendor.id;
          } else {
            vendors.push({
              id: vendors.length,
              name: transaction.name,
              categories: [],
              externalId: '',
              glAccounts: [
                {
                  accountId: account.id,
                  stats: null,
                },
              ],
            });
            transaction.vendorId = vendors.length - 1;
          }
          values.push(transaction.amount);
          transactions.push(transaction);
          tmpTransactions.push(transaction);
        });
      }
    });

    if (values.length > 0) {
      const stats = calculateStats(values);
      account.stats = stats;
    }

    accounts.push(account);
  });
  return { transactions, accounts, vendors };
};

export const compileTransactions = (transformedData: TransformedData): KSTransactionsProps => {
  const { transactions, accounts, vendors } = getTransactions(transformedData);
  const trxs: KSTransactionsProps = {
    transactions: transactions,
    vendors: vendors,
    accounts: accounts,
    auditedTransactions: [],
  };
  return trxs;
};
