import React from 'react';
import { useFlaggedTransactions } from 'hooks/flaggedTransactions';
import { useAskRequestReCategorizationMutation } from 'hooks/flaggedTransactions/useAskRequestReCategorizationMutation';
import { AskRequestStatus } from '@hone-automation/common';
import { shallow } from 'zustand/shallow';
import type { ReCategorizeButtonProps } from './RecategorizeButton';

// Define the expected props for the HOC
export type WithAskRequestsProps = {
  txId: string;
  locationId: string;
  activeTab: AskRequestStatus;
};

const withAskRequests = <P extends WithAskRequestsProps>(
  WrappedComponent: ({ onSubmit, isPending }: ReCategorizeButtonProps) => React.JSX.Element
) => {
  return function WithAskRequests(props: P) {
    const { locationId, activeTab } = props;

    const { setActiveTransaction } = useFlaggedTransactions(
      state => ({
        setActiveTransaction: state.setActiveTransaction,
        setTransactionBeingFlagged: state.setTransactionBeingFlagged,
        setAnimateTransaction: state.setAnimatedTransaction,
        transactionBeingFlagged: state.transactionBeingFlagged,
      }),
      shallow
    );

    const reCategorizeTransaction = useAskRequestReCategorizationMutation(locationId!, activeTab, trx => {
      setActiveTransaction(trx);
    });

    const recategorize = async ({ comment, account }: { comment: string; account: string }) => {
      reCategorizeTransaction.mutate({ trxId: props.txId, newAccountId: account, comment });
    };

    // Extract only the necessary props for the wrapped component
    const wrappedComponentProps = {
      isPending: reCategorizeTransaction.isPending,
      onSubmit: recategorize,
    } as ReCategorizeButtonProps;

    return <WrappedComponent {...wrappedComponentProps} />;
  };
};

export default withAskRequests;
