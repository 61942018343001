import { create } from 'zustand';
import { doDelete, doGet, doPost, reportingOrigin } from '../lib/utils';
import { subscribeWithSelector } from 'zustand/middleware';
import { RemoteGetAuditReport } from '../data/usecases/remote-get-audit-report';
import { RemoteGetReportConfig } from '../data/usecases/remote-get-report-config';
import { RemoteUpdateReportConfig } from 'data/usecases/remote-update-report-config';
import { makeAuthorizeHttpClientDecorator } from '../main/factories/decorators';
import { GetAuditReport } from '../domain/usecases/get-audit-report';
import { makeApiUrl } from '../main/factories/http';
import { ReportConfiguration, UpdateReportConfigPayload } from '@hone-automation/common';
import { GetReportConfig } from 'domain/usecases/get-report-config';
import { Location as KsLocation } from '@hone-automation/common';

type ReportsErrors = 'No reports found for location';

interface HoneReportsState {
  allReports: HoneReportSummary[];
  auditReport: AuditReport[] | undefined | null;
  selectedReport: HoneReportSummary | undefined;
  status: 'idle' | 'loading' | 'error';
  error?: ReportsErrors;
  openDateRangeModal: boolean;
  setOpenDateRangeModal: (open: boolean) => void;
  timeframe: HoneReportTimeframe | undefined;
  setTimeframe: (timeframe: HoneReportTimeframe) => void;
  reportsConfiguration: ReportConfiguration | undefined;
  setReportsConfiguration: (nextState: ReportConfiguration | undefined) => void;
}

const auditReport = new RemoteGetAuditReport(makeApiUrl('/auditReportData'), makeAuthorizeHttpClientDecorator());
const reportConfig = new RemoteGetReportConfig(makeApiUrl('/reports/config'), makeAuthorizeHttpClientDecorator());
const reportConfigUpdate = new RemoteUpdateReportConfig(
  makeApiUrl('/reports/config'),
  makeAuthorizeHttpClientDecorator()
);

export const useReportsStore = create(
  subscribeWithSelector<HoneReportsState>(set => ({
    allReports: [],
    auditReport: undefined,
    status: 'idle',
    selectedReport: undefined,
    dateRangeParams: undefined,
    openDateRangeModal: false,
    timeframe: undefined,
    setTimeframe: (timeframe: HoneReportTimeframe) => set({ timeframe }),
    setOpenDateRangeModal: (open: boolean) => set({ openDateRangeModal: open }),
    reportsConfiguration: undefined,
    setReportsConfiguration: (nextState: ReportConfiguration | undefined) => set({ reportsConfiguration: nextState }),
  }))
);

export const refreshAccounts = (locationId: string) => {
  const url = `${reportingOrigin()}/refreshAccounts`;
  return doPost(url, { locationId });
};

export const emailReportMetrics = (reportId: string, recipients: Array<any>, message: string) => {
  const url = `${reportingOrigin()}/sendReportEmail`;
  return doPost(url, { reportId, recipients, message });
};

export const generateReports = (
  location: KsLocation,
  reportType: HoneReportType | undefined,
  reportTimeframe: HoneReportTimeframe | undefined,
  dateStr: string | undefined,
  endDateStr: string | undefined,
  errorHandler?: (error: any) => any
) => {
  let generateReportsUrl = `${reportingOrigin()}/generateReports?locationId=${location.id}`;
  if (reportType) {
    generateReportsUrl += `&reportType=${encodeURIComponent(reportType)}`;
  }
  if (reportTimeframe) {
    generateReportsUrl += `&reportTimeframe=${encodeURIComponent(reportTimeframe)}`;
  }
  if (dateStr) {
    generateReportsUrl += `&dateStr=${dateStr}`;
  }
  if (endDateStr) {
    generateReportsUrl += `&endDateStr=${endDateStr}`;
  }
  return doGet(generateReportsUrl, errorHandler);
};

export const refreshReport = (reportIds: string[], opts?: { signal: AbortSignal }) => {
  const url = `${reportingOrigin()}/reports/refresh`;
  return doPost(url, { reports: reportIds }, opts);
};

export const deleteReport = (reportId: string) => {
  const url = `${reportingOrigin()}/deleteReport?reportId=${reportId}`;
  return doDelete(url);
};

export const getAuditReport = async (id: string): Promise<GetAuditReport.Model> => {
  const response = await auditReport.getAuditReportById({ id });
  useReportsStore.setState({ auditReport: response as AuditReport[] });
  return response;
};

export const getReportConfig = async (locationId: string): Promise<GetReportConfig.Model> => {
  const response = await reportConfig.getReportConfig({ locationId });
  return response;
};

export const updateReportConfig = async (body: UpdateReportConfigPayload): Promise<ReportConfiguration> => {
  const response = await reportConfigUpdate.update(body);
  return response;
};
