import AskCustomerSideView from 'presentation/components/AskCustomer/SideView';
import { useAskRequestsQuery } from 'hooks/useAskRequestsQuery';
import { AskRequest, AskRequestStatus, AskRequestType } from '@hone-automation/common';
import TransactionRow from 'presentation/components/AskCustomer/TransactionRow';
import FlaggedCellRow from 'presentation/components/AskCustomer/FlaggedCellRow';

import classnames from 'classnames';
import { IconAsk } from 'components/Icons';
import { useQueryState } from '../../../hooks/useQueryState';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

type AskRequestTab = {
  activeTransaction: any;
  handleNewCommentSubmit: any;
  type?: AskRequestType;
  status: AskRequestStatus;
  onSelectActiveTransaction: (transaction: any) => void;
};

const statusMap = {
  Open: 'For Customer',
  'In Progress': 'For Accountant',
  Closed: 'Resolved',
};

function Tab({ status, activeTransaction, handleNewCommentSubmit, onSelectActiveTransaction }: AskRequestTab) {
  const [activeTab] = useQueryState<AskRequestStatus>('activeTab');
  const { locationId } = useParams();
  const queryClient = useQueryClient();
  const { data } = useAskRequestsQuery(activeTab);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const handleClick = (transaction: AskRequest) => {
    onSelectActiveTransaction(transaction);
  };

  if (!data) return null;

  if (data.length === 0)
    return (
      <div className="ask-request-no-requests">
        <IconAsk />
        <span>
          At the moment, there are no requests <strong>{statusMap[status]}</strong>
        </span>
      </div>
    );

  return (
    <div className={'ask-customer-tabs-container'}>
      <div
        className={classnames(`ask-customer-tabs-container-list ${status.toLowerCase()}`, {
          'is-active': isMobile && activeTransaction,
        })}
      >
        {data.map((askRequest: AskRequest) => {
          const isCustomerRequest = askRequest.type === 'Customer Request';
          const shouldShowFlagCell = askRequest.type === 'Flagged Cell';

          if (shouldShowFlagCell || isCustomerRequest) {
            return <FlaggedCellRow request={askRequest} onClick={() => handleClick(askRequest)} key={askRequest.id} />;
          }

          return (
            <TransactionRow key={askRequest.id} onClick={() => handleClick(askRequest)} transaction={askRequest} />
          );
        })}
      </div>

      <div className={classnames('ask-customer-tabs-container-chat', { 'is-active': isMobile && activeTransaction })}>
        {activeTransaction && (
          <AskCustomerSideView
            onSubmit={handleNewCommentSubmit}
            transaction={activeTransaction}
            refetchTransactions={() => queryClient.invalidateQueries({ queryKey: ['askRequests', status, locationId] })}
          />
        )}
      </div>
    </div>
  );
}

export default Tab;
