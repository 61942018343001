import { useMemo } from 'react';
import useAttachmentsModal, { AttachmentMetadata } from 'hooks/useAttachmentsModal';
import DataRowModal from 'components/Table/DataRowModal';
import AttachmentModal from 'components/Attachments/AttachmentModal';
import { IconForwardBtn } from 'components/Icons';
import { renderDate } from 'lib';
import { useActiveReportStore } from 'hooks/useActiveReportStore';
import { HoneReportTypes } from '../../../constants';
import { useModalState } from './hooks/useModalState';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useReportsStore } from 'hooks/useReportsStore';

const NTAttachmentManager = () => {
  const currentLocationId = useLocationsStore(state => state.currentLocationId);
  const currentReport = useActiveReportStore(state => state.currentReport);
  const selectedReport = useReportsStore(state => state.selectedReport);
  const { showAttachmentImage, attachmentsUrl, attachmentMetadata, handleCloseAttachmentImage, getAttachmentsUrl } =
    useAttachmentsModal();

  const { modalState, handleClose, showModal } = useModalState();

  const breadcrumb = useMemo(() => {
    if (!modalState) return null;
    if (attachmentMetadata) {
      return (
        <ol className="breadcrumb">
          <li>{attachmentMetadata?.description}</li>
        </ol>
      );
    }
    return (
      <ol className="breadcrumb">
        {modalState.parent && (
          <>
            <li>{modalState.parent}</li>
            <li className="breadcrumb-separator">
              <IconForwardBtn />
            </li>
          </>
        )}
        <li>{modalState.title}</li>
        {/* YTD */}
        {modalState?.date && modalState?.date.end === '' ? (
          <>
            <li className="breadcrumb-separator">
              <IconForwardBtn />
            </li>
            <li>
              {modalState?.date.start}
              {selectedReport &&
                selectedReport.type !== HoneReportTypes.ApAging &&
                renderDate(selectedReport?.startDateText)}
            </li>
          </>
        ) : (
          <>
            <li className="breadcrumb-separator">
              <IconForwardBtn />
            </li>
            <li>{modalState?.date && renderDate(modalState?.date.end)}</li>
          </>
        )}
      </ol>
    );
  }, [modalState, attachmentMetadata]);

  const modalTitle = useMemo(() => {
    return attachmentMetadata ? modalState?.title : currentReport?.title;
  }, [modalState, attachmentMetadata]);

  return (
    <>
      {showModal && (
        <>
          <DataRowModal
            modalState={modalState}
            handleClose={handleClose}
            showAttachment={(attachments, attachmentMetadata) =>
              getAttachmentsUrl(currentLocationId, attachments, attachmentMetadata!)
            }
          />
          <AttachmentModal
            showAttachmentImage={showAttachmentImage}
            handleClose={handleCloseAttachmentImage}
            attachmentMetadata={attachmentMetadata}
            attachmentsUrl={attachmentsUrl}
            title={modalTitle!}
            subtitle={[currentReport?.title, modalState.parent, modalState.title, modalState.date.start]}
            breadcrumb={breadcrumb}
          />
        </>
      )}
    </>
  );
};

export default NTAttachmentManager;
