import {
  GenerateRangesRequest,
  GetBudgetRequest,
  ReportConfiguration,
  ReportTimeframes,
} from '@hone-automation/common';
import Loading from 'components/Loading';
import { useGetBudgets } from 'hooks/budgets';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useQueryState } from 'hooks/useQueryState';

import './BookkeeperBudgets.scss';

import KSButton from 'presentation/components/KSButton';
import { IconPlus } from 'components/Icons';
import BookkeerBudgetEmptyState from './BookkeerBudgetEmptyState';
import NewTable from 'presentation/components/NewTable';
import Footer from 'presentation/components/Footer/Footer';
import BookkepperBudgetsModal from './BookkeeperBudgetsModal';
import { shallow } from 'zustand/shallow';
import { generateRanges } from '@hone-automation/common/lib/reportUtil';
import { useGetReportConfiguration } from 'hooks';
import { useEffect, useMemo } from 'react';

export default function BookkeeperBudgets() {
  const { currentLocationId, currentLocation } = useLocationsStore(
    state => ({ currentLocationId: state.currentLocationId, currentLocation: state.currentLocation }),
    shallow
  );

  const [startDate, setStartDate] = useQueryState<string | undefined>('startDate');
  const [modalBudgetOpen, setModalBudgetOpen] = useQueryState('modalBudgetOpen', false);
  const budgetParams: GetBudgetRequest = {
    locationId: String(currentLocationId),
    timeframe: 'Month',
    startDate: startDate,
  };
  const { isLoading, data: budgetReport, refetch, isRefetching } = useGetBudgets(budgetParams);
  const { isLoading: loadingReporConfiguration, data: reportConfiguration } =
    useGetReportConfiguration(currentLocationId);

  const newDateRanges = useMemo(() => {
    if (!reportConfiguration) return [];
    const request = Object.assign(
      new GenerateRangesRequest(
        ReportTimeframes.Year,
        currentLocation?.weekStart,
        (reportConfiguration as ReportConfiguration)?.data
      ),
      {
        limit: 5,
        includeNames: true,
      }
    );
    const calculatedDateRanges = generateRanges(request);
    return calculatedDateRanges;
  }, [reportConfiguration]);

  useEffect(() => {
    if (!newDateRanges.length) return;
    if (!startDate) {
      setStartDate(newDateRanges[0]?.start);
      return;
    }
  }, [startDate, newDateRanges]);

  const handleChangeBudgetPeriod = (startDate: string) => {
    setStartDate(startDate);
  };

  const handleCreateBudget = () => {
    setModalBudgetOpen('true');
  };

  const periods = newDateRanges.map(range => ({ name: range.name, date: range.start })).reverse();

  if (isLoading || isRefetching || loadingReporConfiguration) {
    return <Loading />;
  }

  return (
    <>
      <div className="budgets-layout">
        <BookkepperBudgetsModal
          isOpen={modalBudgetOpen === 'true'}
          onRequestClose={() => setModalBudgetOpen('false')}
          refetch={() => refetch()}
        />

        <div className="budgets-layout__header">
          <div className="budgets-layout__header__periods">
            {periods.map((period, i) => (
              <>
                <KSButton
                  onClick={() => handleChangeBudgetPeriod(`${period.date}`)}
                  label={period.name}
                  color={period.date === startDate ? 'active' : 'primary'}
                />
                {i !== periods.length - 1 && <span className="budgets-layout__header__periods_divider" />}
              </>
            ))}
          </div>
          <div className="budgets-layout__header__actions">
            <KSButton onClick={() => handleCreateBudget()} label="New Budget" icon={<IconPlus />} />
          </div>
        </div>
        <div className="budgets-layout__container">
          <div className="budgets-layout__container__content">
            {budgetReport && budgetReport.sections.length > 0 ? (
              <NewTable report={budgetReport as NestedHoneReport} reportType="budget" />
            ) : (
              <BookkeerBudgetEmptyState />
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
