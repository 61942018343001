import React from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { IconDownload } from 'components/Icons';

interface DownloadButtonProps {
  fileNameRelated?: (undefined | string | null)[];
  attachmentsUrl: string[];
  className?: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ fileNameRelated, attachmentsUrl, className }) => {
  if (!attachmentsUrl || attachmentsUrl.length === 0) return null;

  const fileNamePrefix = React.useMemo(() => fileNameRelated?.join('-').toLowerCase(), [fileNameRelated]);

  const handleDownload = async () => {
    try {
      if (attachmentsUrl.length === 1) {
        // Download single file
        const response = await fetch(attachmentsUrl[0]);
        const blob = await response.blob();

        // Use a simple filename with extension based on mime type
        const filename = `${fileNamePrefix}-1${getFileExtension(blob.type)}`;

        saveAs(blob, filename);
      } else {
        // Create zip file for multiple files
        const zip = new JSZip();

        // Process each file sequentially to avoid race conditions
        for (let i = 0; i < attachmentsUrl.length; i++) {
          const url = attachmentsUrl[i];
          try {
            const response = await fetch(url);
            const blob = await response.blob();

            // Simple sequential filename with index starting from 1
            const filename = `${fileNamePrefix}-${i + 1}${getFileExtension(blob.type)}`;

            // Add file to the zip
            zip.file(filename, blob);
          } catch (error) {
            console.error(`Failed to add file ${url} to zip:`, error);
          }
        }

        // Generate the zip file
        const zipBlob = await zip.generateAsync({
          type: 'blob',
          compression: 'DEFLATE',
          compressionOptions: {
            level: 6,
          },
        });

        saveAs(zipBlob, `${fileNamePrefix} attachments.zip`);
      }
    } catch (error) {
      console.error('Download failed:', error);
      alert('Failed to download attachment(s). Please try again.');
    }
  };

  // Helper function to get file extension based on MIME type
  const getFileExtension = (mimeType: string): string => {
    const types: Record<string, string> = {
      'image/jpeg': '.jpg',
      'image/jpg': '.jpg',
      'image/png': '.png',
      'image/gif': '.gif',
      'image/svg+xml': '.svg',
      'image/webp': '.webp',
      'image/bmp': '.bmp',
      'image/tiff': '.tiff',
      'application/pdf': '.pdf',
      'text/plain': '.txt',
      'text/html': '.html',
      'text/css': '.css',
      'text/javascript': '.js',
      'application/json': '.json',
      'application/xml': '.xml',
      'application/zip': '.zip',
      'application/vnd.ms-excel': '.xls',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
      'application/msword': '.doc',
      'application/vnd.ms-powerpoint': '.ppt',
    };

    return types[mimeType] || '.bin'; // Default to .bin if type unknown
  };

  return (
    <button
      className={`download-button ${className || ''}`}
      onClick={handleDownload}
      title={attachmentsUrl.length === 1 ? 'Download file' : 'Download all files as ZIP'}
    >
      <IconDownload />
      Download
    </button>
  );
};

export default DownloadButton;
