import './NTColumnBrowser.scss';
import { useNTContext } from '../NTContext';
import { useNTUtilsContext } from '../NTUtilsContext';
import { prefixClass } from 'lib/utils';
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useNTScroll } from '../NTScrollContext';
import { useDragHandler } from '../hooks/useDragHandler';

const NTColumnBrowser = () => {
  const { columns, filters } = useNTContext();
  const { tooltip, actions } = useNTUtilsContext();
  const { scrollState, setScrollChange } = useNTScroll();
  const prefix = prefixClass('nt-column-browser');
  const [changed, setChanged] = useState<boolean>(true);
  const [done, setDone] = useState<boolean>(false);
  const { columnBrowserVisible } = scrollState;

  // Use refs for DOM elements
  const columnBrowserAreaRef = useRef<HTMLDivElement>(null);
  const columnBrowserWindowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDone(true);
    const timer = setTimeout(() => {
      setChanged(false);
    }, 200);
    return () => clearTimeout(timer);
  }, [filters, actions]);

  useEffect(() => {
    setChanged(true);
  }, [tooltip]);

  const [delayedWidthTrigger, setDelayedWidthTrigger] = useState(0);
  const [readyToShow, setReadyToShow] = useState(false);

  useEffect(() => {
    if (scrollState.columnBrowserVisible) {
      const timeout = setTimeout(() => {
        // Update dummy state to force recalculation after delay
        setDelayedWidthTrigger(Date.now());
        setReadyToShow(true);
      }, 10);
      return () => clearTimeout(timeout);
    }
  }, [scrollState.columnBrowserVisible]);

  // Memoize calculations
  const { maskedAreaTotal, maskedArea } = useMemo(() => {
    const columnBrowserElemPadding = getComputedStyle(document.documentElement)
      .getPropertyValue('--ks-column-browser-padding')
      .trim();

    const areaTotal = columnBrowserAreaRef.current
      ? columnBrowserAreaRef.current.offsetWidth - parseInt(columnBrowserElemPadding)
      : 0;
    return {
      maskedAreaTotal: areaTotal,
      maskedArea: scrollState.visibleAreaOfScrollHorizontal * areaTotal,
    };
  }, [scrollState.visibleAreaOfScrollHorizontal, scrollState.columnBrowserVisible, delayedWidthTrigger]);

  const scrollingColumns = columns - (actions.total ? 2 : 1);

  // Memoize mouse handler
  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const startX = e.clientX;
      const windowElement = columnBrowserWindowRef.current;
      if (!windowElement) return;

      const startLeft = windowElement.offsetLeft;
      let lastUpdate = 0;

      const handleMouseMove = (moveEvent: MouseEvent) => {
        // Throttle updates to prevent excessive state changes
        const now = Date.now();
        if (now - lastUpdate < 16) return; // ~60fps

        const deltaX = moveEvent.clientX - startX;
        const newLeft = startLeft + deltaX;
        setScrollChange({ axis: 'x', amount: newLeft, browser: true });
        lastUpdate = now;
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [setScrollChange]
  );

  const handleDragStart = useDragHandler({
    axis: 'x',
    throttleMs: 16,
    isBrowser: true,
  });

  return (
    <div className={prefix()}>
      <div className={prefix('gradient')}>
        {tooltip.tooltip && (
          <div
            className={classNames(prefix('gradient-tooltip'), {
              'zoom-fade': changed,
              pulse: changed,
              'zoom-fade-out': !changed,
            })}
          >
            {done ? tooltip.tooltip : 'Done'}
          </div>
        )}
        <div
          ref={columnBrowserAreaRef}
          id="columnBrowserArea"
          className={classNames(prefix('gradient-container'), { hidden: !columnBrowserVisible })}
          style={{ visibility: readyToShow ? 'visible' : 'hidden' }}
        >
          {Array.from({ length: scrollingColumns }, (_, index) => (
            <div key={index} className={prefix('gradient-container-column')}></div>
          ))}
          <div
            ref={columnBrowserWindowRef}
            id="columnBrowserWindow"
            className={prefix('gradient-container-window')}
            style={{
              width: `${maskedArea}px`,
            }}
            onMouseDown={e => {
              const windowElement = columnBrowserWindowRef.current;
              if (!windowElement) return;
              handleDragStart(e, windowElement.offsetLeft);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NTColumnBrowser;
