import React, { useRef, useEffect, useState, KeyboardEvent, forwardRef, useImperativeHandle, useCallback } from 'react';
import './RichTextEditor.scss';
import classNames from 'classnames';
import {
  IconRTEBold,
  IconRTEItalic,
  IconRTEUnderline,
  IconRTECrossed,
  IconRTEUL,
  IconRTEOL,
  IconRTELink,
} from 'components/Icons';
import DOMPurify from 'dompurify';

export interface RichTextEditorRef {
  insertText: (text: string) => void;
  focus: () => void;
  submit: () => void;
  KAI: (text: string) => void;
}

interface RichTextEditorProps {
  value: string;
  onChange: (value: string, plainText: string) => void;
  onSubmit: () => void;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  maxHeight?: string;
  toolbar?: boolean;
}

// This flag prevents repeated calls to updateContent from onChange events
let isUpdating = false;

const RichTextEditor = forwardRef<RichTextEditorRef, RichTextEditorProps>(
  (
    {
      value,
      onChange,
      onSubmit,
      placeholder = 'Write your message...',
      className = '',
      autoFocus = false,
      maxHeight = '250px',
      toolbar = true,
    },
    ref
  ) => {
    const editorRef = useRef<HTMLDivElement>(null);
    const [showPlaceholder, setShowPlaceholder] = useState(!value);
    // Track if we're currently handling a paste or other operation
    const operationInProgressRef = useRef(false);
    // At the top of your file
    type RangeType = Range; // Browser's native Range type
    const lastKnownSelectionRef = useRef<RangeType | null>(null);

    const purifyConfig = {
      ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'u', 'a', 'ul', 'ol', 'li', 'p', 'br', 'strike', 'del'],
      ALLOWED_ATTR: ['href', 'target', 'rel', 'style', 'class'],
      ADD_ATTR: ['target', 'rel'],
      KEEP_CONTENT: true,
    };

    const [activeFormats, setActiveFormats] = useState({
      bold: false,
      italic: false,
      underline: false,
      strikeThrough: false,
      ul: false,
      ol: false,
    });

    const checkFormatting = useCallback(() => {
      const selection = window.getSelection();
      if (!selection || !selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      let container = range.commonAncestorContainer;

      // If text node, get its parent
      if (container.nodeType === Node.TEXT_NODE) {
        container = container.parentNode as Node;
      }

      // Check for formatting by walking up the DOM
      let currentNode = container as HTMLElement;
      const formats = {
        bold: false,
        italic: false,
        underline: false,
        strikeThrough: false,
        ul: false,
        ol: false,
      };

      while (currentNode && currentNode !== editorRef.current) {
        const nodeName = currentNode.nodeName.toLowerCase();

        // Check various ways text might be bold
        if (
          nodeName === 'b' ||
          nodeName === 'strong' ||
          (currentNode.style && currentNode.style.fontWeight === 'bold') ||
          (currentNode.style && parseInt(currentNode.style.fontWeight, 10) >= 700)
        ) {
          formats.bold = true;
        }

        // Check italic
        if (nodeName === 'i' || nodeName === 'em' || (currentNode.style && currentNode.style.fontStyle === 'italic')) {
          formats.italic = true;
        }

        // Check underline
        if (nodeName === 'u' || (currentNode.style && currentNode.style.textDecoration === 'underline')) {
          formats.underline = true;
        }

        // Check strikethrough
        if (
          nodeName === 's' ||
          nodeName === 'strike' ||
          nodeName === 'del' ||
          (currentNode.style &&
            (currentNode.style.textDecoration === 'line-through' ||
              currentNode.style.textDecoration.includes('line-through')))
        ) {
          formats.strikeThrough = true;
        }

        // Check for unordered list
        if (nodeName === 'ul') {
          formats.ul = true;
        }
        // Check for ordered list
        if (nodeName === 'ol') {
          formats.ol = true;
        }

        currentNode = currentNode.parentNode as HTMLElement;
      }

      setActiveFormats(formats);
    }, []); // Keep height in sync with content

    const handleAutoGrow = () => {
      if (editorRef.current) {
        editorRef.current.style.height = 'auto';
        const scrollHeight = editorRef.current.scrollHeight;
        const maxHeightPx = parseInt(maxHeight.replace('px', ''), 10);
        const newHeight = Math.min(scrollHeight, maxHeightPx);
        editorRef.current.style.height = `${newHeight}px`;
        editorRef.current.style.overflowY = scrollHeight > maxHeightPx ? 'auto' : 'hidden';
      }
    };

    // Direct manipulation of selection
    const insertAtCursor = (html: string) => {
      if (!editorRef.current) return;

      // Get current selection
      const selection = window.getSelection();
      if (!selection || !selection.rangeCount) return;

      // Only proceed if selection is within our editor
      const range = selection.getRangeAt(0);
      if (!editorRef.current.contains(range.commonAncestorContainer)) {
        editorRef.current.focus();
        const newRange = document.createRange();
        newRange.setStart(editorRef.current, 0);
        newRange.setEnd(editorRef.current, 0);
        selection.removeAllRanges();
        selection.addRange(newRange);
      }

      // Mark that we're in the middle of an operation
      operationInProgressRef.current = true;

      // Use execCommand for inserting HTML which handles most edge cases better
      document.execCommand('insertHTML', false, html);

      // Release operation lock
      setTimeout(() => {
        const rawHtmlContent = editorRef?.current?.innerHTML || '';
        const sanitizedHtml = DOMPurify.sanitize(rawHtmlContent, purifyConfig);
        const plainText = editorRef?.current?.textContent || '';
        onChange(sanitizedHtml, plainText);
        operationInProgressRef.current = false;
        handleAutoGrow();
      }, 10);
    };

    // Method to wrap operations that modify the editor content
    const withOperation = (callback: () => void) => {
      operationInProgressRef.current = true;
      callback();
      // Short delay to prevent race conditions with input event handlers
      setTimeout(() => {
        operationInProgressRef.current = false;
      }, 10);
    };

    // Expose methods via ref

    useImperativeHandle(ref, () => ({
      insertText: (text: string) => {
        // Focus the editor first
        editorRef.current?.focus();

        // Try to restore last known selection
        if (lastKnownSelectionRef.current) {
          const selection = window.getSelection();
          if (selection) {
            try {
              selection.removeAllRanges();
              selection.addRange(lastKnownSelectionRef.current);
            } catch (e) {
              console.error('Failed to restore selection:', e);
            }
          }
        }

        // Now insert the text at the current/restored position
        const safeText = DOMPurify.sanitize(text, purifyConfig);
        insertAtCursor(safeText);
      },
      focus: () => {
        editorRef.current?.focus();
      },
      submit: () => {
        setTimeout(() => {
          if (editorRef.current) {
            editorRef.current.innerHTML = '';
            setShowPlaceholder(value === '' || value === '<br>');
            editorRef.current.focus();
          }
        }, 10);
      },
      KAI: (text: string) => {
        editorRef.current?.focus();
        //console.log('KAI:', text);
        if (editorRef.current) {
          editorRef.current.innerHTML = '';
          const safeText = DOMPurify.sanitize(text, purifyConfig);
          insertAtCursor(safeText);
        }
      },
    }));

    // Apply link styling to all links
    const ensureLinkStyling = () => {
      if (!editorRef.current) return;
      const links = editorRef.current.querySelectorAll('a[href]:not(.rte-link)');
      links.forEach(link => {
        link.classList.add('rte-link');
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      });
    };

    // Core content update function
    const updateContent = () => {
      if (!editorRef.current || isUpdating) return;

      // Lock to prevent recursive updates
      isUpdating = true;

      // Don't update content during an internal operation
      if (operationInProgressRef.current) {
        isUpdating = false;
        return;
      }

      try {
        const rawHtmlContent = editorRef.current.innerHTML;
        const sanitizedHtml = DOMPurify.sanitize(rawHtmlContent, purifyConfig);

        // Use a more lenient comparison that ignores attribute order:
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = sanitizedHtml;
        const sanitizedText = tempDiv.textContent;

        const contentDiv = document.createElement('div');
        contentDiv.innerHTML = rawHtmlContent;
        const rawText = contentDiv.textContent;

        // Only update if content actually changed through sanitization
        if (sanitizedText !== rawText) {
          const selection = window.getSelection();
          let selectionSaved = false;
          let startContainer: Node | null = null;
          let startOffset = 0;
          let endContainer: Node | null = null;
          let endOffset = 0;

          // Try to save current selection
          if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            if (editorRef.current.contains(range.startContainer)) {
              startContainer = range.startContainer;
              startOffset = range.startOffset;
              endContainer = range.endContainer;
              endOffset = range.endOffset;
              selectionSaved = true;
            }
          }

          // Update the HTML content
          editorRef.current.innerHTML = sanitizedHtml;

          // Attempt to restore selection if we saved it
          // Attempt to restore selection if we saved it
          if (selectionSaved && selection && startContainer && endContainer) {
            try {
              // Save focus state first
              const hadFocus = document.activeElement === editorRef.current;

              // Create a new range
              const newRange = document.createRange();

              // Try to set the range to the same positions
              newRange.setStart(startContainer, startOffset);
              newRange.setEnd(endContainer, endOffset);

              // Apply the range to the selection
              selection.removeAllRanges();
              selection.addRange(newRange);

              // Re-focus explicitly if we had focus before
              if (hadFocus) {
                editorRef.current.focus();

                // This is important: schedule another focus after the current
                // event processing completes
                setTimeout(() => {
                  if (editorRef.current) {
                    editorRef.current.focus();

                    // Also restore selection again after re-focusing
                    try {
                      selection.removeAllRanges();
                      selection.addRange(newRange);
                    } catch (e) {
                      console.error('Failed to restore selection after timeout:', e);
                    }
                  }
                }, 0);
              }
            } catch (e) {
              console.error('Failed to restore selection:', e);
              // Focus anyway as a fallback
              editorRef.current.focus();
            }
          }
        }

        // Apply link styling
        ensureLinkStyling();

        // Update placeholder status
        const isEmpty = editorRef.current.innerHTML === '' || editorRef.current.innerHTML === '<br>';
        setShowPlaceholder(isEmpty);

        // Update parent component with changed content
        const plainText = editorRef.current.textContent || '';
        onChange(sanitizedHtml, plainText);

        // Handle auto-grow
        handleAutoGrow();
      } finally {
        // Always release the update lock
        isUpdating = false;
      }
    };

    // Initialize editor
    useEffect(() => {
      if (editorRef.current) {
        // Initialize with sanitized initial value
        if (value) {
          const sanitizedValue = DOMPurify.sanitize(value, purifyConfig);
          editorRef.current.innerHTML = sanitizedValue;
          if (sanitizedValue !== value) {
            onChange(sanitizedValue, editorRef.current.textContent || '');
          }
        }

        // Initial auto-grow
        handleAutoGrow();

        // Set up mutation observer for link styling
        const observer = new MutationObserver(() => {
          if (!operationInProgressRef.current) {
            ensureLinkStyling();
          }
        });

        observer.observe(editorRef.current, {
          childList: true,
          subtree: true,
          characterData: true,
        });

        return () => observer.disconnect();
      }
    }, []);

    // Auto focus if desired
    useEffect(() => {
      if (autoFocus && editorRef.current) {
        editorRef.current.focus();
      }
    }, [autoFocus]);

    // Add event listener for selection changes
    useEffect(() => {
      // When selection changes, check formatting
      const handleSelectionChange = () => {
        // Only check if our editor has focus
        if (document.activeElement === editorRef.current) {
          checkFormatting();
        }
      };

      document.addEventListener('selectionchange', handleSelectionChange);

      // Also check on focus
      const handleFocus = () => {
        checkFormatting();
      };

      if (editorRef.current) {
        editorRef.current.addEventListener('focus', handleFocus);
      }

      return () => {
        document.removeEventListener('selectionchange', handleSelectionChange);
        if (editorRef.current) {
          editorRef.current.removeEventListener('focus', handleFocus);
        }
      };
    }, [checkFormatting]);

    // Formatting commands
    const execCommand = (command: string, value: string = '') => {
      withOperation(() => {
        editorRef.current?.focus();
        document.execCommand(command, false, value);

        const rawHtmlContent = editorRef?.current?.innerHTML || '';
        const sanitizedHtml = DOMPurify.sanitize(rawHtmlContent, purifyConfig);
        const plainText = editorRef?.current?.textContent || '';
        onChange(sanitizedHtml, plainText);
        // Update content after command completes
        updateContent();
      });
    };

    const handleBold = () => execCommand('bold');
    const handleItalic = () => execCommand('italic');
    const handleUnderline = () => execCommand('underline');
    const handleStrikethrough = () => execCommand('strikeThrough');
    const handleOrderedList = () => execCommand('insertOrderedList');
    const handleUnorderedList = () => execCommand('insertUnorderedList');

    const handleLink = () => {
      const selection = window.getSelection();
      const hasSelection = selection && selection.toString().trim() !== '';
      const url = prompt('Enter URL:', 'https://');
      if (!url) return;

      let formattedUrl = url;
      if (!/^https?:\/\//i.test(url)) {
        formattedUrl = 'https://' + url;
      }

      withOperation(() => {
        if (hasSelection) {
          // Create link from selection
          execCommand('createLink', formattedUrl);

          // Apply styling to the created link
          if (selection && selection.rangeCount) {
            const range = selection.getRangeAt(0);
            const linkNode = range.commonAncestorContainer.parentElement;
            if (linkNode && linkNode.tagName === 'A') {
              linkNode.className = 'rte-link';
              linkNode.setAttribute('target', '_blank');
              linkNode.setAttribute('rel', 'noopener noreferrer');
            }
          }
        } else {
          // Insert a new link with the URL's hostname as text
          const linkText = new URL(formattedUrl).hostname;
          const linkHtml = `<a href="${formattedUrl}" class="rte-link" target="_blank" rel="noopener noreferrer">${linkText}</a>`;
          insertAtCursor(linkHtml);
        }
      });
    };

    // Handle paste with careful cursor preservation
    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
      e.preventDefault();

      let pastedText = '';
      if (e.clipboardData) {
        pastedText = e.clipboardData.getData('text/plain');
      }

      withOperation(() => {
        // Handle emoji special case
        const isEmoji = /^\p{Emoji}+$/u.test(pastedText);

        if (isEmoji) {
          insertAtCursor(pastedText);
          return;
        }

        // Handle URL special case
        if (/^https?:\/\/\S+$/.test(pastedText.trim())) {
          const url = pastedText.trim();
          try {
            const linkText = new URL(url).hostname;
            const linkHtml = `<a href="${url}" class="rte-link" target="_blank" rel="noopener noreferrer">${linkText}</a>`;
            insertAtCursor(linkHtml);
          } catch (e) {
            // If URL parsing fails, just insert as plain text
            insertAtCursor(pastedText);
          }
        } else {
          // Regular text - insert as plain text to avoid HTML issues
          insertAtCursor(pastedText);
        }
      });
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSubmit();

        setTimeout(() => {
          if (editorRef.current) {
            editorRef.current.innerHTML = '';
            setShowPlaceholder(value === '' || value === '<br>');
            editorRef.current.focus();
          }
        }, 10);
      }
    };

    const handleFocus = () => {
      setShowPlaceholder(false);
    };

    // Add this to your blur handler to save the position when editor loses focus
    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
      // Store cursor position on blur
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        lastKnownSelectionRef.current = selection.getRangeAt(0).cloneRange() || null;
      }

      // Your existing blur code
      if (editorRef.current) {
        const isEmpty = editorRef.current.innerHTML === '' || editorRef.current.innerHTML === '<br>';
        setShowPlaceholder(isEmpty);
      }
    };

    return (
      <div className={`rich-text-editor ${className}`}>
        {toolbar && (
          <div className="rich-text-toolbar">
            <div className="rich-text-toolbar-group">
              <button
                type="button"
                className={`toolbar-button ${activeFormats.bold ? 'active' : ''}`}
                onClick={handleBold}
                title="Bold"
              >
                <IconRTEBold />
              </button>
              <button
                type="button"
                className={`toolbar-button ${activeFormats.italic ? 'active' : ''}`}
                onClick={handleItalic}
                title="Italic"
              >
                <IconRTEItalic />
              </button>
              <button
                type="button"
                className={`toolbar-button ${activeFormats.underline ? 'active' : ''}`}
                onClick={handleUnderline}
                title="Underline"
              >
                <IconRTEUnderline />
              </button>
              <button
                type="button"
                className={`toolbar-button ${activeFormats.strikeThrough ? 'active' : ''}`}
                onClick={handleStrikethrough}
                title="Strikethrough"
              >
                <IconRTECrossed />
              </button>
            </div>
            <div className="rich-text-toolbar-group">
              <button
                type="button"
                className={`toolbar-button ${activeFormats.ul ? 'active' : ''}`}
                onClick={handleUnorderedList}
                title="Unordered List"
              >
                <IconRTEUL />
              </button>
              <button
                type="button"
                className={`toolbar-button ${activeFormats.ol ? 'active' : ''}`}
                onClick={handleOrderedList}
                title="Ordered List"
              >
                <IconRTEOL />
              </button>
            </div>
            <button type="button" className="toolbar-button" onClick={handleLink} title="Insert Link">
              <IconRTELink />
            </button>
          </div>
        )}

        <div
          className={classNames('rich-text-content', { 'show-placeholder': showPlaceholder })}
          ref={editorRef}
          contentEditable={true}
          onInput={updateContent}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          data-placeholder={placeholder}
          style={{
            minHeight: '20px',
            maxHeight: maxHeight,
            overflow: 'hidden',
          }}
        />
      </div>
    );
  }
);

export default RichTextEditor;
