import React, { useRef, useState } from 'react';
import { IconCheck, IconClose } from 'components/Icons';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';
import classNames from 'classnames';
import { ChartOfAccountsSelect } from 'presentation/pages/bookkeeper/components/BookkeeperAccrualsSettingsForm';

export type ReCategorizeButtonProps = {
  isPending: boolean;
  onSubmit: (form: { comment: string; account: string }) => void;
};

export const RecategorizeButton = ({ onSubmit, isPending }: ReCategorizeButtonProps) => {
  const innerRef = useRef<HTMLTextAreaElement>(null);
  const [active, setActive] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [account, setAccount] = useState<string>('');

  const toggleDialog = () => {
    setActive(active => !active);
    setDirty(true);
    setTimeout(() => {
      setComment('');
    }, 500);
  };

  const handleCOASelect = (value: { value: string; label: string }) => {
    setAccount(value.value);
  };

  const handleSubmit = () => {
    onSubmit({ comment, account });
  };

  return (
    <div className="resolve-wrapper recategorize">
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={<Tooltip>Re-categorize transaction</Tooltip>}
      >
        <div className="resolved-button-wrapper">
          <button className={classNames('chat-input__mask-resolved', { active })} type="button" onClick={toggleDialog}>
            {isPending ? (
              <>
                Loading...
                <i className="fa fa-circle-o-notch fa-spin" />{' '}
              </>
            ) : (
              <>Re-Categorize</>
            )}
          </button>
        </div>
      </Whisper>
      {active && (
        <div className={classNames('resolved-dialog', { active, inactive: dirty && !active })}>
          <div className="form-control">
            <label>Select a new gl category</label>
            <ChartOfAccountsSelect selected={account} onChange={handleCOASelect} />
          </div>
          <div className="comment">
            <textarea
              ref={innerRef}
              value={comment}
              onChange={e => setComment(e.target.value)}
              autoFocus={active}
              placeholder="Specify the reason for changing the category."
            />
          </div>
          <div className="actions">
            <button onClick={toggleDialog}>
              <IconClose />
              Cancel
            </button>
            <button disabled={!account} onClick={handleSubmit}>
              <IconCheck />
              Re-Categorize
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecategorizeButton;
