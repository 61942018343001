import React, { useCallback, useRef, useState, useEffect } from 'react';
import { IconDocumentNew, IconNext, IconPrev, IconRotate, IconZoomIn, IconZoomOut } from 'components/Icons';
import Loading from 'components/Loading';
import DownloadButton from '../../../components/Attachments/DownloadButton';

type ImageViewerProps = {
  images: string[];
  fileNameRelated: (undefined | string | null)[];
};

type DisplayMode = 'image' | 'iframe' | 'download';

type ImageState = {
  displayMode: DisplayMode;
  loadFailed: boolean;
  iframeFailed: boolean;
};

const steps = [0.5, 0.75, 1, 1.5, 2, 3, 4];

function findNearestIndex(arr: number[], num: number) {
  let nearestIndex = 0;
  let minDifference = Math.abs(arr[0] - num);

  for (let i = 1; i < arr.length; i++) {
    const currentDifference = Math.abs(arr[i] - num);
    if (currentDifference < minDifference) {
      nearestIndex = i;
      minDifference = currentDifference;
    }
  }
  return nearestIndex;
}

// Helper function to get file extension
const getFileExtension = (filename: string): string => {
  const match = /\.([0-9a-z]+)(?:[?#]|$)/i.exec(filename);
  return match ? match[1] : '';
};

const ImageViewer = ({ images, fileNameRelated }: ImageViewerProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [scaleIndex, setScaleIndex] = useState(2);
  const [rotation, setRotation] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [scale, setScale] = useState(steps[scaleIndex]);

  // Determine initial display mode based on file extension
  const getInitialDisplayMode = (url: string): DisplayMode => {
    // List of extensions that should be rendered in an iframe
    const iframeExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt'];
    const extension = getFileExtension(url).toLowerCase();
    return iframeExtensions.includes(extension) ? 'iframe' : 'image';
  };

  // Track display mode for each image (image or iframe or download)
  const [imageStates, setImageStates] = useState<ImageState[]>(() =>
    images.map(url => ({
      displayMode: getInitialDisplayMode(url),
      loadFailed: false,
      iframeFailed: false,
    }))
  );

  // Update imageStates when images prop changes
  useEffect(() => {
    setImageStates(prevStates => {
      if (prevStates.length === images.length) {
        // If length matches but we're initializing, make sure display modes are correct
        return images.map((url, index) => {
          if (prevStates[index].displayMode === 'download' && !prevStates[index].iframeFailed) {
            // Reset documents that may have been incorrectly marked as failed
            return {
              ...prevStates[index],
              displayMode: getInitialDisplayMode(url),
              loadFailed: false,
              iframeFailed: false,
            };
          }
          return prevStates[index];
        });
      }

      // Initialize new states for any new images
      return images.map((url, index) =>
        index < prevStates.length
          ? prevStates[index]
          : {
              displayMode: getInitialDisplayMode(url),
              loadFailed: false,
              iframeFailed: false,
            }
      );
    });
  }, [images]);

  // Reset view parameters when current index changes
  useEffect(() => {
    setLoading(true);
    // Don't reset display mode here as we want to preserve it per document
  }, [currentIndex]);

  useEffect(() => {
    // We'll also make sure to reset any loading states when changing documents
    if (imageStates[currentIndex]?.displayMode === 'iframe') {
      setLoading(true);
    }
  }, [currentIndex, imageStates[currentIndex]?.displayMode]);

  const markIframeFailed = () => {
    setImageStates(prevStates =>
      prevStates.map((state, idx) =>
        idx === currentIndex
          ? {
              ...state,
              iframeFailed: true,
              displayMode: 'download',
            }
          : state
      )
    );
    // Make sure loading is set to false when marking as failed
    setLoading(false);
  };

  const handleZoomIn = useCallback(() => {
    if (scaleIndex >= steps.length - 1) return;
    setScaleIndex(scaleIndex + 1);
    setScale(steps[scaleIndex + 1]);
  }, [scaleIndex]);

  const handleZoomOut = useCallback(() => {
    if (scaleIndex <= 0) return;
    setScaleIndex(scaleIndex - 1);
    setScale(steps[scaleIndex - 1]);
  }, [scaleIndex]);

  const resetZoom = () => {
    if (canvasRef?.current && imageRef?.current) {
      const x = canvasRef.current.clientWidth / imageRef.current.width;
      const y = canvasRef.current.clientHeight / imageRef.current.height;
      const max = Math.max(x, y);
      const index = findNearestIndex(steps, max);
      setScale(Math.max(x, y));
      setScaleIndex(index);
      const width = (canvasRef.current.clientWidth - imageRef.current.width) / 2;
      const height = (canvasRef.current.clientHeight - imageRef.current.height) / 2;
      imageRef.current?.style.setProperty('left', `${width}px`);
      imageRef.current?.style.setProperty('top', `${height}px`);
    }
  };

  const handleRotateCounterClockwise = () => {
    setRotation(rotation - 90);
  };

  const handleThumbnailClick = (index: number) => {
    setCurrentIndex(index);
    // Don't reset zoom here as it will be handled by the useEffect
  };

  const handleMoveDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
    setPosition({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
  };

  const handleMove = (e: React.MouseEvent) => {
    const { clientX, clientY } = e.nativeEvent;

    if (isDragging && imageRef.current) {
      // convert margin left to integer
      const marginTop = parseInt(imageRef.current.style.top.replace('px', ''));
      const marginLeft = parseInt(imageRef.current.style.left.replace('px', ''));

      const offsetX = clientX - position.x;
      const offsetY = clientY - position.y;

      const newMarginLeft = marginLeft + offsetX;
      const newMarginTop = marginTop + offsetY;
      imageRef.current?.style.setProperty('left', `${newMarginLeft}px`);
      imageRef.current?.style.setProperty('top', `${newMarginTop}px`);

      setPosition({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
    }
  };

  const handleMoveUp = (e: React.MouseEvent) => {
    setPosition({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
    setIsDragging(false);
  };

  const renderThumbnails = () => {
    return images.map((image, index) => {
      const state = imageStates[index];
      const isNonImageDocument = state?.displayMode === 'iframe' || state?.displayMode === 'download';

      if (isNonImageDocument) {
        // For iframe or download types, use a simpler document icon that matches the existing UI
        return (
          <div
            key={index}
            className={`thumbnail-container ${currentIndex === index ? 'active' : ''}`}
            onClick={() => handleThumbnailClick(index)}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
              }}
            >
              {/* Simple document icon */}
              <IconDocumentNew />
            </div>
          </div>
        );
      } else {
        // Regular image thumbnail
        return (
          <div className="thumbnail-container">
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${currentIndex === index ? 'active' : ''}`}
              onClick={() => handleThumbnailClick(index)}
            />
          </div>
        );
      }
    });
  };

  const showActionButtons = images.length > 1;

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = images[currentIndex];
    link.download = images[currentIndex].split('/').pop() || 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImageLoaded = (event: React.SyntheticEvent<HTMLImageElement>) => {
    if (canvasRef?.current) {
      const target = event.target as HTMLImageElement;
      const width = (canvasRef.current.clientWidth - target.width) / 2;
      const height = (canvasRef.current.clientHeight - target.height) / 2 - 48;
      imageRef.current?.style.setProperty('left', `${width}px`);
      imageRef.current?.style.setProperty('top', `${height}px`);
    }
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
    // Mark the current image as failed and try iframe mode
    setImageStates(prevStates =>
      prevStates.map((state, idx) =>
        idx === currentIndex ? { ...state, loadFailed: true, displayMode: 'iframe' } : state
      )
    );
  };

  // Determine current display mode
  const getCurrentDisplayMode = (): DisplayMode => {
    const currentState = imageStates[currentIndex];
    if (!currentState) return getInitialDisplayMode(images[currentIndex]);

    // Simply respect the current display mode stored in state
    // This avoids logic that might inadvertently switch modes
    return currentState.displayMode;
  };

  const currentDisplayMode = getCurrentDisplayMode();

  // Render appropriate content based on display mode
  const renderContent = () => {
    switch (currentDisplayMode) {
      case 'download':
        return (
          <div
            className="download-fallback"
            style={{
              height: '75vh',
              maxHeight: '730px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <p>The document could not be displayed at the moment.</p>
              <DownloadButton
                fileNameRelated={fileNameRelated ? [fileNameRelated[currentIndex]] : undefined}
                attachmentsUrl={[images[currentIndex]]}
              />
              <button
                className="try-again-btn"
                onClick={() => {
                  // Give the user a way to retry loading the document
                  setImageStates(prevStates =>
                    prevStates.map((state, idx) =>
                      idx === currentIndex
                        ? {
                            ...state,
                            displayMode: getInitialDisplayMode(images[currentIndex]),
                            loadFailed: false,
                            iframeFailed: false,
                          }
                        : state
                    )
                  );
                  setLoading(true);
                }}
              >
                Try Again
              </button>
            </div>
            {images.length > 1 && <div className="thumbnails">{renderThumbnails()}</div>}
          </div>
        );

      case 'iframe':
        return (
          <div>
            {loading && (
              <div
                style={{
                  height: '730px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loading />
              </div>
            )}
            <iframe
              ref={iframeRef}
              style={{
                pointerEvents: 'all',
                display: loading ? 'none' : 'block', // Hide iframe while loading
              }}
              src={images[currentIndex]}
              width="100%"
              height="730px"
              title={`Content ${currentIndex + 1}`}
              onLoad={() => {
                // When iframe loads successfully, update state
                setLoading(false);
                setImageStates(prevStates =>
                  prevStates.map((state, idx) => (idx === currentIndex ? { ...state, iframeFailed: false } : state))
                );
              }}
              onError={() => {
                // Only mark as failed if we get an actual error event
                markIframeFailed();
              }}
            />
            {images.length > 1 && <div className="thumbnails">{renderThumbnails()}</div>}
          </div>
        );

      case 'image':
      default:
        return (
          <div className="image-viewer-container" style={{ height: '75vh', maxHeight: '730px' }}>
            <div className="control-bar">
              <div className="zoom-controls">
                <button onClick={handleZoomIn}>
                  <IconZoomIn />
                </button>
                <button className="btn-fit" onClick={resetZoom}>
                  FIT
                </button>
                <button onClick={handleZoomOut}>
                  <IconZoomOut />
                </button>
              </div>
              <div className="rotate-controls">
                <button onClick={handleRotateCounterClockwise}>
                  <IconRotate />
                </button>
              </div>
            </div>
            <div className="button-wrapper left">
              {showActionButtons && (
                <button onClick={handlePrev} disabled={currentIndex === 0}>
                  <IconPrev />
                </button>
              )}
            </div>
            <div className="button-wrapper right">
              {showActionButtons && (
                <button onClick={handleNext} disabled={currentIndex === images.length - 1}>
                  <IconNext />
                </button>
              )}
            </div>
            <div className="canvas" ref={canvasRef}>
              <div
                className="image-container"
                onMouseDown={handleMoveDown}
                onMouseMove={handleMove}
                onMouseUp={handleMoveUp}
              >
                {loading && <Loading />}
                <img
                  ref={imageRef}
                  onLoadStart={() => setLoading(true)}
                  onLoad={handleImageLoaded}
                  onError={handleImageError}
                  src={images[currentIndex]}
                  alt="Zoomable Rotatable"
                  style={{
                    left: 0,
                    top: 0,
                    transform: `scale(${scale}) rotate(${rotation}deg)`,
                  }}
                />
              </div>
            </div>
            {images.length > 1 && <div className="thumbnails">{renderThumbnails()}</div>}
          </div>
        );
    }
  };

  return renderContent();
};

export default ImageViewer;
