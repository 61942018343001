import classNames from 'classnames';
import { useMemo } from 'react';
import { useActiveReportStore } from 'hooks/useActiveReportStore';
import * as ReportUtils from 'lib/reportUtils';
import { useReportsStore } from 'hooks/useReportsStore';
import { formatToLocalTimezone } from 'lib/utils';

interface FooterProps {
  elementCount?: number;
}

export default function Footer({ elementCount = 0 }: FooterProps): JSX.Element {
  const selectedReport = useReportsStore(state => state.selectedReport);
  const { currentReport } = useActiveReportStore(state => ({
    currentReport: state.currentReport,
    status: state.status,
  }));

  const reportSections = useMemo(() => {
    return currentReport ? currentReport.sections : [];
  }, [currentReport]);

  const rows: any[] = currentReport ? ReportUtils.collectRows(Array.from(reportSections) as any) : [];
  const rowCount: number = rows.length;

  const updatedAt = formatToLocalTimezone(currentReport?.updatedAt || selectedReport?.updatedAt, 'MM/dd/yyyy hh:mm aa');

  return (
    <div className="Layout-footer-container">
      <div className={classNames('Layout-footer')}>
        {rowCount > 0 && <div className="footer-counter">{rowCount + ' items'}</div>}
        <div className="footer-version">{__APP_VERSION__}</div>
        {updatedAt && (
          <div className="footer-timestamp">
            UPDATED: <span>{updatedAt}</span>
          </div>
        )}
      </div>
    </div>
  );
}
