import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-modal';
import { IconCalendar, IconCustomerRequest, IconRange } from 'components/Icons';
import { BALANCE_SHEET_PRESETS, formatDate, getDateRange } from 'lib/utils';
import classNames from 'classnames';
import { differenceInCalendarDays, differenceInMonths, endOfMonth, format, parse } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useReportsStore } from 'hooks/useReportsStore';
import { GroupReportPayload, HoneReportTimeframes } from 'domain/models';
import { DateRange } from 'rsuite/DateRangePicker';
import { stringify, parse as qsparse } from 'querystring';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useQueryState } from 'hooks/useQueryState';
import { HoneReportTypes } from '../constants';
import { groupedByMonth } from 'lib/reportUtils';

type ReportDatesModalProps = {
  options?: any;
  selectedOption?: any;
  onReportChange: (reportId: string) => void;
  onLoadMore: () => void;
  selectedReportTitle: string;
  timeframe?: HoneReportTimeframe;
  uniqueTitles: HoneReportSummary[];
};

const DATE_FORMAT = 'MM-dd-yyyy';

function ReportDatesModal({
  options,
  selectedOption,
  timeframe,
  uniqueTitles,
  selectedReportTitle,
  onReportChange,
  onLoadMore,
}: ReportDatesModalProps) {
  const [datesModal, setDatesModal] = useState<boolean>(false);
  const [dateRange] = useQueryState<string>('dateRange');
  const [total] = useQueryState<string>('total');
  const [validate] = useQueryState<string>('validate');
  const [difference] = useQueryState<string>('difference');

  const navigateTo = useNavigate();

  const location = useLocation();
  const { currentLocation } = useLocationsStore(state => ({ currentLocation: state.currentLocation }));
  const { locationId, reportType, reportId } = useParams();

  const selectedReport = useReportsStore(state => state.selectedReport);

  const [templateId] = useQueryState<string>('templateId');

  let optionsFiltered = options.filter((option: any) => option.id !== 'custom_range');

  const [tempDateRangeParams, settempDateRangeParams] = useState<Omit<GroupReportPayload, 'type'> | undefined>({
    startDate: dateRange ? dateRange.split(',')[0] : undefined,
    endDate: dateRange ? dateRange.split(',')[1] : undefined,
    total: total === 'true',
    difference: difference === 'true',
  });

  const startDate = tempDateRangeParams?.startDate
    ? parse(tempDateRangeParams.startDate, DATE_FORMAT, new Date())
    : null;
  const endDate = tempDateRangeParams?.endDate ? parse(tempDateRangeParams.endDate, DATE_FORMAT, new Date()) : null;

  optionsFiltered = groupedByMonth(optionsFiltered, timeframe === 'Monthly');

  const isMonthly = selectedReport?.timeframe === 'Monthly';
  const isWeekly = selectedReport?.timeframe === 'Weekly';

  const differenceDisabled = useMemo(() => {
    if (startDate && endDate) {
      const differenceInMonth = differenceInMonths(endDate, startDate);
      const differenceInDays = differenceInCalendarDays(endDate, startDate);
      if (differenceInMonth !== undefined && differenceInDays !== undefined) {
        return isMonthly ? !(differenceInMonth === 1) : !(differenceInDays === 13);
      }
    }
    return true;
  }, [startDate, endDate]);

  const totalDisabled = useMemo(() => {
    if (startDate && endDate) {
      const differenceInMonth = differenceInMonths(endDate, startDate);
      const differenceInDays = differenceInCalendarDays(endDate, startDate);
      if (differenceInMonth !== undefined && differenceInDays !== undefined) {
        return isMonthly ? !(differenceInMonth > 0) : !(differenceInDays > 7);
      }
    }
    return true;
  }, [startDate, endDate]);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === 'total') {
      settempDateRangeParams(prev => ({ ...prev, [name]: checked, difference: false }));
    } else if (name === 'difference') {
      settempDateRangeParams(prev => ({ ...prev, [name]: checked, total: false }));
    }
  };

  const handleReportChange = (id: string) => {
    onReportChange(id);
    toggleModal();
  };

  const getMonthlyDifference = useCallback(
    (selectedDate: DateRange) => {
      if (!tempDateRangeParams?.difference) return false;

      return differenceInMonths(selectedDate[1], selectedDate[0]) === 1 ? tempDateRangeParams.difference : false;
    },
    [tempDateRangeParams]
  );

  const getWeeklyDifference = useCallback(
    (selectedDate: DateRange) => {
      if (!tempDateRangeParams?.difference) return false;

      return differenceInCalendarDays(selectedDate[1], selectedDate[0]) === 1 ? tempDateRangeParams.difference : false;
    },
    [tempDateRangeParams]
  );

  const handleEndDateChange = (selectedDate: Date | null) => {
    if (!selectedDate) {
      settempDateRangeParams(prev => ({
        ...prev,
        endDate: undefined,
      }));
      return;
    }

    if (selectedDate && currentLocation && selectedReport) {
      if (isMonthly) {
        selectedDate = endOfMonth(selectedDate);
      } else {
        const range = getDateRange(selectedDate, currentLocation.weekStart!, selectedReport?.timeframe);
        selectedDate = range[1];
      }

      if (startDate) {
        const difference = isMonthly
          ? getMonthlyDifference([startDate, selectedDate])
          : getWeeklyDifference([startDate, selectedDate]);
        settempDateRangeParams(prev => ({
          ...prev,
          endDate: format(selectedDate as Date, DATE_FORMAT),
          difference,
        }));
      } else {
        settempDateRangeParams(prev => ({
          ...prev,
          endDate: format(selectedDate as Date, DATE_FORMAT),
        }));
      }
    }
  };

  const handleStartDateChange = (selectedDate: Date | null) => {
    if (selectedDate && currentLocation && selectedReport) {
      const range = getDateRange(selectedDate, currentLocation.weekStart!, selectedReport?.timeframe);
      const end: Date = endDate ?? range[1];

      const difference = isMonthly
        ? getMonthlyDifference([selectedDate, end])
        : getWeeklyDifference([selectedDate, end]);

      settempDateRangeParams(prev => ({
        ...prev,
        startDate: format(range[0], DATE_FORMAT),
        endDate: format(end, DATE_FORMAT),
        difference,
      }));
      return;
    }
  };

  const toggleModal = () => {
    setDatesModal(datesModal => !datesModal);
  };

  const handleConfirmDateRange = () => {
    const { total, difference, startDate, endDate } = tempDateRangeParams!;
    if (startDate && endDate) {
      const currentTemplateId = templateId || selectedReport?.templateId;
      const _templateId = currentTemplateId
        ? currentTemplateId
        : uniqueTitles[0]?.templateId && uniqueTitles[0]?.templateId;

      const queryParams = qsparse(location.search.replace('?', ''));

      const newQueries = {
        ...queryParams,
        dateRange: `${tempDateRangeParams?.startDate},${tempDateRangeParams?.endDate}`,
        templateId: _templateId,
        total: String(total),
        difference,
        validate: validate ? 'true' : undefined,
      };

      if (currentLocation?.id && tempDateRangeParams) {
        navigateTo({
          pathname: `/app/location/${locationId}/report/${reportType}`,
          search: `?${stringify(newQueries)}`,
        });
        useReportsStore.setState({ selectedReport: undefined });
      }
    }
    toggleModal();
  };

  const listContainerRef = useRef<HTMLDivElement | null>(null);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(!datesModal); // Set the mounted state to true everytime the component mounts
  }, [datesModal]);

  useEffect(() => {
    if (isMounted && listContainerRef.current) {
      const listContainer = listContainerRef.current;
      const activeElement = listContainer?.querySelector<HTMLDivElement>('.list-active');

      if (listContainer && activeElement) {
        const activeElementTop = activeElement.offsetTop;
        const activeElementHeight = activeElement.offsetHeight;
        const containerScrollTop = listContainer.scrollTop;
        const containerHeight = listContainer.clientHeight;

        const isElementOutOfView =
          activeElementTop < containerScrollTop ||
          activeElementTop + activeElementHeight > containerScrollTop + containerHeight;

        if (isElementOutOfView) {
          listContainer.scrollTop = activeElementTop - containerHeight / 2 + activeElementHeight / 2;
        }
      }
    }
  }, [isMounted]);

  const showAllButton =
    selectedReport?.type === HoneReportTypes.PLComparison && selectedReport?.timeframe === HoneReportTimeframes.Weekly;

  const canSetCustomDates = selectedReport?.type === HoneReportTypes.PLComparison;

  const title = useMemo(() => {
    if (dateRange) {
      const dates = dateRange.split(',');

      const start = formatDate(parse(dates[0], 'MM-dd-yyyy', new Date()));
      const end = formatDate(parse(dates[1], 'MM-dd-yyyy', new Date()));

      return (
        <>
          {start}
          <IconRange />
          {end}
        </>
      );
    }

    if (reportType === 'balance-sheet') {
      const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      if (reportId && !reportId.match(regex)) {
        return optionsFiltered.data.Custom && optionsFiltered.data.Custom.find((ele: any) => ele.id === reportId).name;
      }
    }

    return (
      <>
        {formatDate(new Date(selectedReport?.startDateText + 'T00:00:00'))}
        <IconRange />
        {formatDate(new Date(selectedReport?.endDateText + 'T00:00:00'))}
      </>
    );
  }, [dateRange, optionsFiltered]);

  return (
    <>
      <button className="report-dates-control" onClick={toggleModal}>
        <div className="report-dates-control-range no-separator">
          <IconCalendar />
          {title}
        </div>
      </button>
      <Modal
        id="report-dates-modal"
        className={classNames({ custom: canSetCustomDates })}
        isOpen={datesModal}
        onRequestClose={toggleModal}
      >
        <div className="modal-header">
          <h3>{selectedReportTitle}</h3>
        </div>
        <div className="modal-body">
          <div className="list">
            <div className="list-header">
              <IconCustomerRequest />
              <p>Available Reports</p>
            </div>
            <div className="list-list" ref={listContainerRef}>
              {optionsFiltered.sortedKeys.map((key: string) => {
                return (
                  <div className="list-group" key={key}>
                    <p className="list-group-title">{key}</p>
                    {optionsFiltered.data[key].map(({ id, startDateText, endDateText, name }: any) => {
                      if (Object.keys(BALANCE_SHEET_PRESETS).includes(id)) {
                        return (
                          <div
                            key={id}
                            onClick={() => {
                              handleReportChange(id);
                            }}
                            className={classNames('list-item', { 'list-active': id === selectedOption })}
                          >
                            {name}
                          </div>
                        );
                      }

                      return (
                        <div
                          key={id}
                          onClick={() => handleReportChange(id)}
                          className={classNames('list-item', { 'list-active': id === selectedOption })}
                        >
                          {formatDate(new Date(startDateText + 'T00:00:00'))}
                          <IconRange />
                          {formatDate(new Date(endDateText + 'T00:00:00'))}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className="list-footer">
              <p>Newest</p>
              {showAllButton && <button onClick={onLoadMore}>All</button>}
            </div>
          </div>
          {canSetCustomDates && (
            <div className="set">
              <div className="set-header">
                <IconCalendar />
                <p>Set custom dates</p>
              </div>
              <div className="set-body">
                <div className="report-dates-wrapper">
                  <div className="picker-wrapper">
                    <span>From</span>
                    <DatePicker
                      showIcon
                      isClearable
                      placeholderText="MM/DD/YYYY"
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={new Date()}
                      showMonthYearPicker={isMonthly}
                    />
                  </div>
                  <div className="icon">
                    <IconRange />
                  </div>
                  <div className="picker-wrapper">
                    <span>To</span>
                    <DatePicker
                      showIcon
                      showMonthDropdown
                      isClearable
                      placeholderText="MM/DD/YYYY"
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      maxDate={new Date()}
                      showMonthYearPicker={isMonthly}
                    />
                  </div>
                </div>
                <div className="report-dates-modal-actions">
                  <div>
                    <input
                      className="mx-2"
                      type="checkbox"
                      name="total"
                      disabled={totalDisabled}
                      onChange={handleCheck}
                      checked={tempDateRangeParams?.total}
                    />
                    <label>Show Total</label>
                  </div>
                  <div>
                    <input
                      className="mx-2"
                      type="checkbox"
                      name="difference"
                      disabled={differenceDisabled}
                      onChange={handleCheck}
                      checked={tempDateRangeParams?.difference}
                    />
                    <label>Show Differences</label>
                  </div>
                </div>
              </div>
              <div className="set-footer">
                <button disabled={!startDate || !endDate} onClick={handleConfirmDateRange}>
                  Select
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ReportDatesModal;
