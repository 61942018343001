import React from 'react';
import ProfilePage from 'presentation/pages/profile/Profile';
import NewLayout from '../../../../presentation/components/NewLayout/NewLayout';
import { IconUser } from 'components/Icons';
import { useParams } from 'react-router-dom';
import { useHoneGetUserInfo } from '../../../../hooks/useHoneGetUserInfo';
import { useAuthContext } from '../../../../context/useAuthContext';
import Loading from '../../../../components/Loading';

type ProfileEntryParams = {
  locationId: string;
};

function ProfileViewFactory() {
  const { locationId } = useParams<ProfileEntryParams>();
  const { user } = useAuthContext();
  const { data: userData } = useHoneGetUserInfo(user, locationId);

  return (
    <NewLayout icon={IconUser} pageTitle="My Profile" className="profile">
      <ProfilePage locationUser={userData as HoneLocationUser} />
    </NewLayout>
  );
}

export default ProfileViewFactory;
