import './RequestsBrowser.scss';
import { prefixClass } from 'lib/utils';
import AssignmentGroup from '../AssignmentGroup';
import { filterAndSplitRequests, FilterProps } from '../utils/filterAndSearchUtils';
import { useMemo } from 'react';
import RBSearch from './RBSearch';
import RBFilter from './RBFilter';
import { useQueueContext } from '../QueueContext';

const RequestsBrowser = () => {
  const prefix = prefixClass('requests-browser');
  const {
    state: { filters, requests, splitRequests },
    dispatch,
  } = useQueueContext();

  const handleSearch = (value: string) => {
    dispatch({
      type: 'SET_FILTERS',
      payload: { ...filters, search: value },
    });
  };
  const handleClear = () => {
    dispatch({
      type: 'SET_FILTERS',
      payload: { ...filters, search: '' },
    });
  };

  const updateFilters = (newFilter: string) => {
    dispatch({ type: 'SET_FILTERS', payload: { ...filters, sort: newFilter } as FilterProps });
  };

  const handleRequestClick = (requestId: string) => {
    const request = requests.find(r => r.id === requestId);
    if (request) {
      dispatch({ type: 'SET_SELECTED_ITEM', payload: request });
    }
  };

  return (
    <div className={prefix()}>
      <RBSearch searchValue={filters.search || ''} onSearch={handleSearch} onClear={handleClear} />
      <RBFilter filter={filters} onFilterChange={updateFilters} />
      <div className={prefix('results')}>
        <AssignmentGroup
          me
          requests={splitRequests?.me || []}
          reversed={filters.sort === 'newest'}
          onClick={handleRequestClick}
        />
        <AssignmentGroup
          me={false}
          requests={splitRequests?.team || []}
          reversed={filters.sort === 'newest'}
          onClick={handleRequestClick}
        />
      </div>
    </div>
  );
};

export default RequestsBrowser;
