import { makeApiUrl } from 'main/factories/http';
import { makeAuthorizeHttpClientDecorator } from 'main/factories/decorators';
import { GetReviewFinancials } from 'domain/usecases';
import { RemoteGetReviewFinancials } from 'data/usecases/reports/remote-review-financials';

export const makeRemoteReviewFinancials = (): GetReviewFinancials => {
  const reviewFinancialsUrl = `/financials/ready`;

  return new RemoteGetReviewFinancials(makeApiUrl(reviewFinancialsUrl), makeAuthorizeHttpClientDecorator());
};
