import { AppLayout } from 'presentation/components';

import DashboardViewNew from 'presentation/pages/dashboard/DashboardViewNew';
import { useAskRequestsQuery } from 'hooks/useAskRequestsQuery';
import { useAuthContext } from '../../../../context/useAuthContext';
import Loading from '../../../../components/Loading';
import { QueueContextProvider } from '../../../../presentation/components/Queue/QueueContext';
import React from 'react';

export const NewDashboardViewFactory = (): JSX.Element => {
  const { data: requests, status } = useAskRequestsQuery(undefined, true);
  const { user } = useAuthContext();
  const meId = user?.uid;
  return (
    <AppLayout noFooter={true}>
      {status === 'pending' ? (
        <Loading />
      ) : status === 'success' ? (
        <QueueContextProvider meId={meId} requests={requests}>
          <DashboardViewNew />
        </QueueContextProvider>
      ) : (
        <div>An error has occurred while getting requests</div>
      )}
    </AppLayout>
  );
};
