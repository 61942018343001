import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { makeRemoteUpdateUser } from '../main/factories/usecases/remote-update-user';
import { UpdateUserPayload } from '@hone-automation/common';
import { showToast, TEN_SECONDS, TOAST_ERROR } from 'lib/utils';

/**
 * Custom hook for updating a user
 * @param {UseMutationOptions} options - Optional configuration options
 * @returns {Object} Mutation object with various properties and methods
 */
export const useUpdateUser = (
  options?: UseMutationOptions<
    any,
    Error,
    UpdateUserPayload & {
      locationId?: string;
      uid?: string;
    },
    unknown
  >
) => {
  const queryClient = useQueryClient();
  const updateUserService = makeRemoteUpdateUser();
  // Function to update the user via API
  const updateUserFn = async (userData: UpdateUserPayload) => {
    return updateUserService.update(userData);
  };

  const { onSuccess, ...rest } = options || {};

  return useMutation({
    mutationFn: updateUserFn,

    onError: (error, variables, _context) => {
      showToast(error.message, TOAST_ERROR, TEN_SECONDS);
    },

    // When the mutation succeeds, invalidate related queries
    onSuccess: async (updatedUser, variables, _) => {
      await queryClient.invalidateQueries({ queryKey: ['userInfo', variables.locationId, variables.uid] });
      await queryClient.refetchQueries({ queryKey: ['userInfo', variables.locationId, variables.uid] });

      // Invalidate the specific user query
      await queryClient.invalidateQueries({ queryKey: ['user', variables.userId] });

      // Optionally invalidate the users list query
      await queryClient.invalidateQueries({ queryKey: ['users'] });

      onSuccess?.(updatedUser, variables, _);
    },

    // Forward other options
    ...rest,
  });
};
