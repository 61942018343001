import React, { memo, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import './NTCell.scss';
import { prefixClass } from 'lib/utils';
import FormatAccounts from './FormatAccounts';
import FormatReportValues from './FormatReportValues';
import { Accounts, Amounts } from '../../KSExport/reportNTParser/types';
import { useNTContext } from '../NTContext';
import { useNTSelectionContext } from '../NTSelectionContext';
import { el } from 'date-fns/locale';

const UNEXPECTED_CELL_DATA_WARNING = 'Unexpected cellData type:';
interface NTCellProps {
  cellData: any;
  rowID: string | null;
  cellID: string;
  allColumn: any;
  liveColumn?: boolean;
  totalColumn?: boolean;
}

const NTCell = (
  { cellData, rowID, cellID, allColumn, totalColumn, liveColumn }: NTCellProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const prefix = prefixClass('nt-cell');

  const MemoizedFormatAccounts = useMemo(() => memo(FormatAccounts), []);
  const MemoizedFormatReportValues = useMemo(() => memo(FormatReportValues), []);
  const { columns } = useNTContext();
  const { cellStates, setCellStates, isScrolling, setSelectionHistory, selectionHistory, updateSelectionHistory } =
    useNTSelectionContext();

  const { vizHelpers } = cellData;
  const { noValuestoShow } = vizHelpers;
  const [rowId, setRowId] = useState(noValuestoShow ? null : rowID);

  const content = useMemo(() => {
    return () => {
      if (!cellData) return '';

      switch (typeof cellData) {
        case 'string':
          return cellData;
        case 'number':
          return formatNumber(cellData);
        case 'object':
          return renderObject(cellData);
        default:
          console.warn(`${UNEXPECTED_CELL_DATA_WARNING} ${typeof cellData}`);
          return String(cellData);
      }
    };
  }, [cellData]);

  const formatNumber = (num: number) => num.toFixed(2);

  const renderObject = (obj: Amounts | Accounts) => {
    if ('amountTitle' in obj)
      return <MemoizedFormatAccounts cellData={obj} cellID={cellID} allColumn={allColumn} rowID={rowId} />;
    return <MemoizedFormatReportValues cellData={obj} cellID={cellID} rowID={rowId} totalColumn={totalColumn} />;
  };

  const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    /* 
    const targetElement = e.target as HTMLElement;
    const cellElement = targetElement.closest(`[data-row-id="${rowId}"]`); */

    if (cellStates.selected !== rowId) {
      const elementsSelected = document.querySelectorAll(`[data-row-id="${cellStates.selected}"]`);
      elementsSelected.forEach(el => el.classList.remove('selected'));
      const elementsNew = document.querySelectorAll(`[data-row-id="${rowId}"]`);
      elementsNew.forEach(el => el.classList.add('selected'));
      setCellStates({ ...cellStates, selected: rowID !== cellStates.selected ? rowID : null });
      if (rowID) updateSelectionHistory(rowID);
    }
  };

  const handleMouseEnter = () => {
    const elements = document.querySelectorAll(`[data-row-id="${rowId}"]`);
    if (!isScrolling && cellStates.hover !== rowId && cellStates.selected !== rowId)
      //setCellStates({ ...cellStates, hover: rowId });
      elements.forEach(el => el.classList.add('hovered'));
  };

  const handleMouseLeave = () => {
    const elements = document.querySelectorAll(`[data-row-id="${rowId}"]`);
    elements.forEach(el => el.classList.remove('hovered'));
    //if (cellStates.hover === rowId) setCellStates({ ...cellStates, hover: null });
  };

  return (
    <div
      key={cellID}
      className={classNames(prefix(), { live: liveColumn })}
      onClick={handleSelect}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-cell-id={cellID}
      data-row-id={rowId}
    >
      {content()}
    </div>
  );
};
export default React.memo(React.forwardRef(NTCell));
