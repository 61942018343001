import React from 'react';
import { AskRequest, AskRequestStatus } from '@hone-automation/common';
import type { PropsType } from './MarkAsSolvedButton';
import { usePatchFlaggedTransactionMutation } from 'hooks/flaggedTransactions/usePatchFlaggedTransactionMutation';
import { useQueueContext } from '../../Queue/QueueContext';
import { useEvent } from '../../../../hooks/useEvent';

// Define the expected props for the HOC
export type WithQueueRequestProps = {
  txId: string;
  locationId: string;
  activeTab: AskRequestStatus;
  isSideView?: boolean;
};

const withQueueRequest = <P extends WithQueueRequestProps>(
  WrappedComponent: ({ onSubmit, isPending }: PropsType) => React.JSX.Element
) => {
  return function WithQueueRequest(props: P) {
    const { locationId, activeTab, txId } = props;

    const {
      state: { requests, filters },
      dispatch,
    } = useQueueContext();
    const { dispatch: emitEvent } = useEvent('onQueueRequestResolve');

    const { isPending, mutate } = usePatchFlaggedTransactionMutation(locationId!, activeTab, (trx: any | undefined) => {
      const trxExists = requests.some(req => req.id === trx?.id);
      if (trxExists) {
        dispatch({
          type: 'SET_REQUESTS',
          payload: requests.map(req => (req.id === trx?.id ? (trx as unknown as AskRequest) : req)),
        });
      } else {
        dispatch({
          type: 'SET_REQUESTS',
          payload: [...requests, trx as unknown as AskRequest],
        });
      }
      emitEvent({ field: 'Closed', duration: 5000 });
      const order = filters.sort;

      const sortedRequests = requests
        .filter(req => req.comments && req.comments.length > 0)
        .sort((a, b) => {
          const latestCommentA = a.comments?.length
            ? a.comments.reduce((latest, comment) => (latest.createdAt > comment.createdAt ? latest : comment))
            : null;
          const latestCommentB = b.comments?.length
            ? b.comments.reduce((latest, comment) => (latest.createdAt > comment.createdAt ? latest : comment))
            : null;
          const dateA = latestCommentA ? new Date(latestCommentA.createdAt) : new Date(a.createdAt);
          const dateB = latestCommentB ? new Date(latestCommentB.createdAt) : new Date(b.createdAt);
          if (order === 'oldest') {
            return dateA.getTime() - dateB.getTime();
          } else {
            return dateB.getTime() - dateA.getTime();
          }
        });
      //console.log('sortedRequests', sortedRequests);
      const nextQueueItem = sortedRequests.find(req => req.status === filters.status && req.id !== txId);
      dispatch({ type: 'SET_SELECTED_ITEM', payload: nextQueueItem as AskRequest });
    });

    const markAsSolved = async ({ comment }: { comment: string }) => {
      mutate({ txId, status: 'Closed', comment });
    };

    // Extract only the necessary props for the wrapped component
    const wrappedComponentProps = {
      isPending: isPending,
      onSubmit: markAsSolved,
    } as PropsType;

    return <WrappedComponent {...wrappedComponentProps} />;
  };
};

export default withQueueRequest;
