import './KAILoader.scss';

type KAILoaderProps = {
  message?: string;
  small?: boolean;
};

const KAILoader = (props: KAILoaderProps) => {
  const { message = 'Loading...', small = false } = props;
  return (
    <div className="text-loader">
      <span className={`text-loader__message ${small ? 'small' : ''}`}>{message}</span>
      <div className="text-loader__dots">
        <div className="text-loader__dot"></div>
        <div className="text-loader__dot"></div>
        <div className="text-loader__dot"></div>
      </div>
    </div>
  );
};

export default KAILoader;
