import React, { ChangeEvent, useEffect, useRef, useState, MouseEvent, KeyboardEvent, useMemo } from 'react';
import './CMComposer.scss';
import {
  IconAttachment,
  IconDown,
  IconEmoji,
  FilterKAI,
  IconCancel,
  IconRegenerate,
  IconAccept,
} from 'components/Icons';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { useClickAway } from 'react-use';
import classNames from 'classnames';
import { useReopenFlaggedTransactionMutation } from '../../../../hooks/flaggedTransactions';
import { useParams } from 'react-router-dom';
import { useCreateQueueCommentMutation } from 'hooks/queue/useCreateQueueCommentMutation';
import { AskRequest } from '@hone-automation/common';
import { useAuthContext } from '../../../../context/useAuthContext';
import { useQueueContext } from '../QueueContext';
import RichTextEditor, { RichTextEditorRef } from './RichTextEditor/RichTextEditor';
import { convertToShortcodes } from '../utils/emojiParser';
import { useAIQuery } from 'presentation/components/KAI/KAI.hook';
import { rewriteMessage } from 'presentation/components/KAI/SystemPrompts/Rewrite';
import { Tooltip, Whisper } from 'rsuite';
import { set } from 'lodash';
import KSCheckBox from 'presentation/components/KSCheckBox';
import KAILoader from 'presentation/components/KAI/KAILoader';
import AutoEnhancer from './AutoEnhancer';

interface MessageComposerProps {
  request: AskRequest;
  KAI?: boolean;
}

const MessageComposer = ({ request, KAI = false }: MessageComposerProps) => {
  const { locationId } = useParams();

  const {
    dispatch,
    state: { selectedItem, requests },
  } = useQueueContext();

  const { user } = useAuthContext();
  const createCommentMutation = useCreateQueueCommentMutation(request.status, locationId!, trx => {
    const trxExists = requests.some(req => req.id === trx?.id);

    if (trxExists) {
      // Replace the existing trx
      dispatch({
        type: 'SET_REQUESTS',
        payload: requests.map(req => (req.id === trx?.id ? (trx as unknown as AskRequest) : req)),
      });
    } else {
      // Add trx as a new request
      dispatch({
        type: 'SET_REQUESTS',
        payload: [...requests, trx as unknown as AskRequest],
      });
    }
    dispatch({ type: 'SET_SELECTED_ITEM', payload: trx as unknown as AskRequest });
  });

  const postReopenMutation = useReopenFlaggedTransactionMutation(request.status, locationId!, response => {
    //
  });

  const [toolbarOpen, setToolbar] = useState<boolean>(true);
  const [emojiPickerOpen, setEmojiPicker] = useState<boolean>(false);
  const [richText, setRichText] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [KAIclicked, setKAIclicked] = useState<boolean>(false);
  const [autoEnhance, setAutoEnhance] = useState<boolean>(false);

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  const richTextEditorRef = useRef<RichTextEditorRef>(null);
  const { sendQuery } = useAIQuery();

  const handleEmojiRTEClick = (emojiData: EmojiClickData) => {
    if (richTextEditorRef.current) {
      richTextEditorRef.current.insertText(emojiData.emoji);
    }
    setEmojiPicker(false);

    // Use a small timeout to ensure focus returns to the editor
    setTimeout(() => {
      if (richTextEditorRef.current) {
        richTextEditorRef.current.focus();
      }
    }, 10);
  };

  const toggleEmojiPick = () => {
    setEmojiPicker(prevState => {
      return !prevState;
    });
  };

  useClickAway(emojiPickerRef, () => {
    setEmojiPicker(false);
  });

  const handleRewrite = (message: string) => {
    // Send a query to the AI
    if (richTextEditorRef.current) {
      richTextEditorRef.current.KAI(message);
    }
  };

  const handleSubmit = () => {
    // Check if both plain text and rich text are empty
    if (text === '' || richText === '' || richText === '<br>') return;

    const emojiSafeRichText = convertToShortcodes(richText);
    const emojiSafeText = convertToShortcodes(text);

    if (selectedItem?.status === 'Closed' && selectedItem.canReopen) {
      postReopenMutation.mutate({
        trxId: selectedItem?.id,
        comment: emojiSafeRichText, // Use rich text if you want to preserve formatting
      });
    }
    createCommentMutation.mutate({
      comment: emojiSafeRichText, // Use rich text to preserve formatting
      userId: user?.uid,
      locationId,
      activeTransaction: request,
    });

    // Reset both states
    if (richTextEditorRef.current) richTextEditorRef.current.submit();
  };

  const handleRichTextChange = (html: string, plain: string) => {
    //console.log('Rich Text:', html, text);
    setRichText(html);
    setText(plain);
  };

  const hideActions = false;
  const hideAttachments = true;

  // Memoize the RichTextEditor component
  const MemoizedRichTextEditor = useMemo(
    () => (
      <RichTextEditor
        ref={richTextEditorRef}
        value={richText}
        onChange={handleRichTextChange}
        onSubmit={handleSubmit}
        autoFocus
        className={`message-input ${toolbarOpen ? 'tooltip-open' : ''}`}
        maxHeight="150px"
        toolbar={toolbarOpen}
      />
    ),
    [richText, toolbarOpen, handleSubmit]
  );

  return (
    <div className="message-composer">
      {KAI && <AutoEnhancer message={text} rewriteMessageCallBack={handleRewrite} toolbarOpen={toolbarOpen} />}
      <div className="message-row">{MemoizedRichTextEditor}</div>
      <div className="message-actions">
        <div className="left-actions">
          {!hideActions && (
            <>
              <button
                className={classNames('action-button', { active: toolbarOpen })}
                onClick={() => setToolbar(prevValue => !prevValue)}
              >
                A<span>a</span>
              </button>
              {!hideAttachments && (
                <button className="action-button">
                  <IconAttachment />
                </button>
              )}
              <button onClick={toggleEmojiPick} className={classNames('action-button', { active: emojiPickerOpen })}>
                <IconEmoji />
              </button>
              <div ref={emojiPickerRef} className="emoji-wrapper">
                {/* @ts-ignore: Types from package are wrong */}
                <EmojiPicker open={emojiPickerOpen} onEmojiClick={handleEmojiRTEClick} /> {/* handleEmojiClick */}
              </div>
            </>
          )}
        </div>

        <div className="right-actions">
          {KAI && (
            <Whisper
              placement="top"
              trigger="hover"
              delayOpen={1200}
              speaker={<Tooltip>Automatically enhance the message when it's sent</Tooltip>}
            >
              <div className="message-auto-improve">
                <KSCheckBox
                  rounded={true}
                  smallCaps={true}
                  label={'Auto-Enhance message'}
                  checked={autoEnhance}
                  onChange={function (checked: boolean): void {
                    setAutoEnhance(checked);
                  }}
                />
              </div>
            </Whisper>
          )}
          <div className={`message-submit${text.length > 0 ? '' : ' disabled'}`} onClick={handleSubmit}>
            SEND
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageComposer;
