import { PostHubspotEmail } from 'domain/usecases';
import { BaseHttpService } from 'main/factories/http';

export class RemotePostSendHubspotEmail extends BaseHttpService<PostHubspotEmail.Model> implements PostHubspotEmail {
  async sendEmail(params: PostHubspotEmail.Params): Promise<PostHubspotEmail.Model | HttpGenericErrorResponse> {
    const { locationId } = params;
    const payload = {
      locationId,
    };
    return await this.request('post', payload);
  }
}
