import classNames from 'classnames';
import { FilterKAI, IconCancel, IconRegenerate, IconAccept } from 'components/Icons';
import { useAIQuery } from 'presentation/components/KAI/KAI.hook';
import KAILoader from 'presentation/components/KAI/KAILoader';
import { rewriteMessage } from 'presentation/components/KAI/SystemPrompts/Rewrite';
import { useState } from 'react';
import { Whisper, Tooltip } from 'rsuite';
import './AutoEnhancer.scss';

type AutoEnhancerProps = {
  message: string;
  toolbarOpen?: boolean;
  rewriteMessageCallBack: (text: string) => void;
  onCancel?: () => void;
};

const AutoEnhancer = (props: AutoEnhancerProps) => {
  const { message, toolbarOpen, rewriteMessageCallBack, onCancel } = props;
  const [text, setText] = useState(message);
  const [KAIclicked, setKAIclicked] = useState(false);

  const [kayResponse, setKayResponse] = useState<string>('');

  const { sendQuery } = useAIQuery();

  const handleRewrite = () => {
    rewriteMessageCallBack(kayResponse);
    setKAIclicked(false);
  };

  const handleRequestRewrite = async () => {
    const prompt = rewriteMessage(message);
    if (!prompt) return;
    const rewrite = await sendQuery(prompt);

    // The AI response is a JSON object that conatins a content property. Sometimes the content property (which is an stringify object) comes preceded with ```json and ends with ```. We need to remove these characters before parsing the content.
    // We also need to remove the first and last line of the string, which are empty.
    const content = rewrite.content
      ? rewrite.content
          .replace(/^```json\n/, '')
          .replace(/\n```$/, '')
          .replace(/^\n/, '')
          .replace(/\n$/, '')
      : null;
    if (content) {
      try {
        setKayResponse(JSON.parse(content).improvedSentence);
      } catch (e) {
        console.error(e, message, prompt, rewrite);
      }
    } else setKayResponse('No content available. Please retry');
  };

  return (
    <div className={classNames('message-kai')}>
      <div className="message-kai-result-container">
        <div
          className={classNames('message-kai-result-container-result', {
            'no-visible': !KAIclicked,
            disabled: message === '',
          })}
        >
          <div className={classNames('message-kai-result-container-result-header')}>MESSAGE SUGGESTION</div>
          <div className={classNames('message-kai-result-container-result-content')}>
            {kayResponse === '' ? <KAILoader message="Rephrasing" small={true} /> : kayResponse}
          </div>
        </div>
      </div>
      <div className="message-kai-actions">
        {!KAIclicked ? (
          <div className={classNames('message-kai-actions before', { 'no-header': !toolbarOpen })}>
            <Whisper
              placement="top"
              trigger="hover"
              delayOpen={800}
              delayClose={300}
              speaker={<Tooltip>Enhance the message</Tooltip>}
            >
              <div
                className={classNames('message-kai-actions-icon', {
                  disabled: message === '',
                  clicked: KAIclicked,
                  'enhance-button': true,
                })}
                onClick={() => {
                  setKAIclicked(true);
                  setKayResponse('');
                  setTimeout(handleRequestRewrite, 20);
                }}
              >
                <FilterKAI />
              </div>
            </Whisper>
          </div>
        ) : (
          <div className="message-kai-actions after">
            <Whisper
              placement="right"
              trigger="hover"
              delayOpen={800}
              delayClose={300}
              speaker={<Tooltip>Cancel</Tooltip>}
            >
              <div className="message-kai-actions-icon" onClick={() => setKAIclicked(false)}>
                <IconCancel />
              </div>
            </Whisper>
            <Whisper placement="right" trigger="hover" delayOpen={800} speaker={<Tooltip>Regenerate</Tooltip>}>
              <div
                className="message-kai-actions-icon"
                onClick={() => {
                  setKayResponse('');
                  setTimeout(handleRequestRewrite, 20);
                }}
              >
                <IconRegenerate />
              </div>
            </Whisper>
            <Whisper
              placement="right"
              trigger="hover"
              delayOpen={800}
              delayClose={300}
              speaker={<Tooltip>Use the Enhanced Message</Tooltip>}
            >
              <div className="message-kai-actions-icon" onClick={handleRewrite}>
                <IconAccept />
              </div>
            </Whisper>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoEnhancer;
