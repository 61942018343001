import { IconChevron } from 'components/Icons';
import { useState, useEffect } from 'react';
import { metricsPeriodPlot } from '../Dashboard/DParser/types';
import { logger } from '../KSExport/reportNTParser/utils';
import classNames from 'classnames';

const PeriodSelector = ({
  periods,
  selectedPeriod,
  onChangePeriod,
}: {
  periods: metricsPeriodPlot[];
  selectedPeriod: number;
  onChangePeriod: (period: number) => void;
}) => {
  if (periods.length === 0) {
    return null;
  }
  const [currentPeriod, setCurrentPeriod] = useState(selectedPeriod);

  const handleForwardClick = () => {
    if (currentPeriod > 0) setCurrentPeriod(currentPeriod - 1);
  };

  const handleBackClick = () => {
    if (currentPeriod < periods.length - 1) setCurrentPeriod(currentPeriod + 1);
  };

  useEffect(() => {
    onChangePeriod(currentPeriod);
  }, [currentPeriod]);

  return (
    <div className="ks-dashboard-chart__header">
      <div
        className={classNames('ks-dashboard-chart__header-icon reversed', {
          disabled: currentPeriod === periods.length - 1,
        })}
        onClick={handleBackClick}
      >
        <IconChevron />
      </div>
      <div className="ks-dashboard-chart__header-title">
        {periods[currentPeriod]?.periodTitle}{' '}
        {periods[currentPeriod]?.periodtype !== 'week' ? periods[currentPeriod]?.year : ''}
      </div>

      <div
        className={classNames('ks-dashboard-chart__header-icon', {
          disabled: currentPeriod === 0,
        })}
        onClick={handleForwardClick}
      >
        <IconChevron />
      </div>
    </div>
  );
};

export default PeriodSelector;
