import React, { useMemo, useRef, useEffect } from 'react';
import ChatMessage from '../ChatMessage/ChatMessage';
import { groupMessagesByDate } from '../../../utils';
import { AskRequestComment, User } from '@hone-automation/common';
import { useAuthContext } from '../../../../context/useAuthContext';
import { parseISO, parseJSON } from 'date-fns';

interface ChatProps {
  comments?: AskRequestComment[];
}

type CommentOrientation = 'left' | 'right';

type Comment = {
  user?: User;
  orientation: CommentOrientation;
  showDate: boolean;
  glCode?: boolean;
  date?: Date;
  content: string;
  editable: boolean;
  createdAt: Date;
};

function Chat({ comments }: ChatProps) {
  const { isBookkeeperAdmin } = useAuthContext();
  const groupedMessages = useMemo(() => groupMessagesByDate(comments ?? []), [comments]);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Scroll to the bottom when new comments are added
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [comments]);

  return (
    <div className="chat-block" ref={chatContainerRef}>
      {Object.entries(groupedMessages).map(([date, messages]) =>
        messages.map((message, idx) => {
          const showDate = idx === 0;

          let orientation: CommentOrientation = 'left';
          const { isHoneInternalUser } = message.userLocation ?? {};

          if ((isBookkeeperAdmin && isHoneInternalUser) || (!isBookkeeperAdmin && !isHoneInternalUser)) {
            orientation = 'right';
          }

          const comment: Comment = {
            user: message.user,
            orientation: orientation,
            editable: false,
            date: parseJSON(message.updatedAt),
            showDate,
            content: message.comment,
            createdAt: message.createdAt,
          };

          return <ChatMessage key={`chat-${idx}`} {...comment} />;
        })
      )}
    </div>
  );
}

export default Chat;
