import React, { useEffect } from 'react';
import { prefixClass } from 'lib/utils';
import './TransactionHeader.scss';
import { IconBrokenLink, IconChevron, IconLink } from 'components/Icons';
import { Link, useParams } from 'react-router-dom';
import useFetchLinks from 'hooks/useFetchLinks';
import { AskRequest, EntityLink, ResolvedLink } from '@hone-automation/common';
import { startOfWeek, endOfWeek, format, getWeek } from 'date-fns';
import { Tooltip, Whisper } from 'rsuite';
import classNames from 'classnames';

interface TransactionHeaderProps {
  request: AskRequest;
}

const FormURL = ({ routePath, trxURL }: { routePath: (string | undefined)[]; trxURL: string }) => {
  const prefix = prefixClass('transaction-header-breadcrumb');
  const [containerWidth, setContainerWidth] = React.useState(0);
  const containerRef = React.useRef<HTMLDivElement>(null);

  // Let's dinamically check if the container is too narrow to display the text and adpat if needed
  useEffect(() => {
    const containerEl = containerRef.current;
    if (!containerEl) return;

    // Initial size measurement
    setContainerWidth(containerEl.offsetWidth);

    const checkElementWidths = () => {
      const middleItems = document.querySelectorAll('.middle-item');
      middleItems.forEach(item => {
        // Reset the class for all items to be able to meause the width of the container
        item.classList.remove('too-narrow');
        if (item.getBoundingClientRect().width < 40) {
          item.classList.add('too-narrow');
        }
      });
    };

    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        // Use contentRect for more accurate measurements
        const width = entry.contentRect.width;
        //setContainerWidth(width);
        checkElementWidths();
      }
    });

    observer.observe(containerEl);
    return () => observer.disconnect();
  }, []);

  if (routePath.length === 0) return null;
  const qualifiedPath = routePath.join(' > ');

  return (
    <div className={prefix()} ref={containerRef}>
      {routePath.map((path, index) => {
        const isFirst = index === 0;
        const isLast = index === routePath.length - 1;

        return (
          <div
            key={index}
            className={classNames(prefix('item'), {
              'first-item': isFirst,
              'last-item': isLast,
              'middle-item': !isFirst && !isLast,
            })}
          >
            <Whisper placement="top" trigger="hover" delayOpen={400} speaker={<Tooltip>{qualifiedPath}</Tooltip>}>
              <div className={prefix('item-content')}>{path}</div>
            </Whisper>
            {index < routePath.length - 1 && (
              <div className={prefix('separator')}>
                <IconChevron />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

function TransactionHeader({ request }: TransactionHeaderProps) {
  const { locationId } = useParams();
  const { data: links } = useFetchLinks(request, locationId);

  const linksEnabled = request.type !== 'Customer Request';

  if (!linksEnabled || !links) {
    return null;
  }

  const firstLink = (links as ResolvedLink<EntityLink>[])[0];
  const prefix = prefixClass('transaction-header');

  if (!firstLink || !firstLink.valid || !firstLink.sectionPath || !firstLink.link) {
    return null;
  }

  const startDate = new Date(firstLink.dataDateRange.start);

  const weekNumber = getWeek(startDate, { weekStartsOn: 1 });
  const weekStartDate = startOfWeek(startDate, { weekStartsOn: 1 });
  const weekEndDate = endOfWeek(startDate, { weekStartsOn: 1 });

  const trxURLPrefix = window.location.href.split('/app')[0];
  const trxURL = `${trxURLPrefix}/app/location/${locationId}/report/${firstLink.link.toId}?flaggedTransaction=${firstLink.link.fromId}&fromType=${firstLink.link.fromType}`;

  const routePath = [firstLink.reportTitle, ...firstLink.sectionPath.split('|')];

  return (
    <div className={prefix()}>
      <div className={prefix('week')}>
        <span>W{weekNumber}</span>
        {`${format(weekStartDate, 'MMM. d')} - ${format(weekEndDate, 'MMM. d, yyyy')}`}
      </div>
      <Whisper
        placement="top"
        trigger="hover"
        delayOpen={400}
        speaker={<Tooltip>Copy link to the Queue Request</Tooltip>}
      >
        <div className={prefix('icon-link')} onClick={() => navigator.clipboard.writeText(trxURL)}>
          <IconLink />
        </div>
      </Whisper>

      <FormURL routePath={routePath} trxURL={trxURL} />
    </div>
  );
}
export default TransactionHeader;
