import { AppLayout } from 'presentation/components';
import React from 'react';
import QueuePage from 'presentation/pages/queue';
import Loading from 'components/Loading';
import { QueueContextProvider } from 'presentation/components/Queue/QueueContext';
import { useAuthContext } from '../../../../context/useAuthContext';
import { useQueueRequestsQuery } from 'hooks/useQueueRequestsQuery';

function QueueViewFactory() {
  const { user } = useAuthContext();
  const meId = user?.uid;
  const { data: requests, status } = useQueueRequestsQuery(undefined, true);

  return (
    <AppLayout noFooter={true}>
      {status === 'pending' ? (
        <Loading />
      ) : status === 'success' ? (
        <QueueContextProvider meId={meId} requests={requests}>
          <QueuePage />
        </QueueContextProvider>
      ) : (
        <div>An error has occurred while getting requests</div>
      )}
    </AppLayout>
  );
}

export default QueueViewFactory;
