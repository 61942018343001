import React from 'react';
import { useAskRequestReCategorizationMutation } from 'hooks/flaggedTransactions/useAskRequestReCategorizationMutation';
import { AskRequestStatus } from '@hone-automation/common';
import type { ReCategorizeButtonProps } from './RecategorizeButton';
import { useQueueContext } from '../../Queue/QueueContext';
import { useEvent } from '../../../../hooks/useEvent';

// Define the expected props for the HOC
export type WithAskRequestsProps = {
  txId: string;
  locationId: string;
  activeTab: AskRequestStatus;
};

const withAskRequests = <P extends WithAskRequestsProps>(
  WrappedComponent: ({ onSubmit, isPending }: ReCategorizeButtonProps) => React.JSX.Element
) => {
  return function WithAskRequests(props: P) {
    const { locationId, activeTab } = props;

    const { dispatch } = useQueueContext();
    const { dispatch: emitEvent } = useEvent('onQueueRequestResolve');

    const reCategorizeTransaction = useAskRequestReCategorizationMutation(locationId!, activeTab, (_, trx) => {
      dispatch({ type: 'SET_SELECTED_ITEM', payload: trx });
      emitEvent({ field: trx.status, duration: 5000 });
    });

    const recategorize = async ({ comment, account }: { comment: string; account: string }) => {
      reCategorizeTransaction.mutate({ trxId: props.txId, newAccountId: account, comment });
    };

    // Extract only the necessary props for the wrapped component
    const wrappedComponentProps = {
      isPending: reCategorizeTransaction.isPending,
      onSubmit: recategorize,
    } as ReCategorizeButtonProps;

    return <WrappedComponent {...wrappedComponentProps} />;
  };
};

export default withAskRequests;
