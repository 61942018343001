import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TransformedData } from '../KSExport/reportNTParser/types';
import { useNTUtilsContext } from './NTUtilsContext';
import { useNTScroll } from './NTScrollContext';
import { useNTContext } from './NTContext';
import { overflowShadowing } from './utils/sizingUtils';

export type cellStates = {
  hover: string | null;
  selected: string | null;
};

const defaultTransformedData: TransformedData = {
  liveReport: -1,
  totalColumn: false,
  difference: false,
  columns: [],
  locationId: null,
  locationName: null,
  timeFrame: 'Weekly',
  renderableColumns: 0,
  name: '',
  type: 'P&L Comparison',
  currency: 'USD',
  capabilities: {
    money: false,
    budget: false,
    percentage: false,
    transactions: false,
    charts: false,
    sparklines: 'none',
    smoothing: false,
    anomalies: false,
    totalRow: false,
    variance: false,
    synthetic: false,
    flaggedTransactions: false,
    liveReport: false,
  },
  utilityData: {
    totalColumn: {
      type: [{ type: 'Data', index: 0 }],
      data: undefined,
      originType: 'Data',
    },
    differenceColumn: { type: [{ type: 'Data', index: 0 }], data: undefined, originType: 'Data' },
  },
  periodTitles: null,
};

const NTSelectionContext = createContext<{
  transformedData: TransformedData;
  setTransformedData: (transformedData: TransformedData | null) => void;
  cellStates: cellStates;
  setCellStates: (cellStates: cellStates) => void;
  isScrolling: boolean;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelect: (newIndex: string, updateHistory: boolean) => void;
  updateSelectionHistory: (newItem: string) => void;
  selectRow: (scrollTo: boolean) => void;
  // New selectionHistory state and updater
  selectionHistory: string[];
  setSelectionHistory: React.Dispatch<React.SetStateAction<string[]>>;
  historyIndex: number;
  setHistoryIndex: React.Dispatch<React.SetStateAction<number>>;
}>({
  transformedData: defaultTransformedData,
  setTransformedData: () => {},
  cellStates: {
    hover: null,
    selected: null,
  },
  setCellStates: () => {},
  isScrolling: false,
  setIsScrolling: () => {},
  handleSelect: () => {},
  updateSelectionHistory: () => {},
  selectRow: () => {},
  selectionHistory: [],
  setSelectionHistory: () => {},
  historyIndex: 0,
  setHistoryIndex: () => {},
});

export const useNTSelectionContext = () => useContext(NTSelectionContext);

export const NTSelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { actions, setActions, capabilities } = useNTUtilsContext();
  const { columns } = useNTContext();
  const { setScrollChange, setRefreshBrowsers, refreshBrowsers } = useNTScroll();
  const [transformedData, setTransformedDataState] = useState<TransformedData>(defaultTransformedData);
  const [cellStates, setCellStatesState] = useState<cellStates>({
    hover: null,
    selected: null,
  });
  const setCellStates = useCallback((newCellStates: cellStates) => {
    setCellStatesState(newCellStates);
  }, []);
  const [isScrolling, setIsScrolling] = useState(false);

  // New state variable for selection history
  const [selectionHistory, setSelectionHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(0);

  // Add new state for overflow
  const [hasOverflow, setHasOverflow] = useState(false);

  // Add overflow check when component mounts and when data changes
  useEffect(() => {
    overflowShadowing();
  }, [transformedData]);

  // Add a helper function to update the selection history
  const updateSelectionHistory = (newItem: string) => {
    if (!newItem) return;
    setSelectionHistory(prevHistory => {
      // Remove the item if already in the history to avoid duplicates
      let newHistory = prevHistory;
      // Append the new item
      newHistory.push(newItem);
      // Enforce a maximum of 20 items by removing oldest items if necessary
      if (newHistory.length > 20) {
        newHistory = newHistory.slice(newHistory.length - 20);
      }
      return newHistory;
    });
    // Update the history index to the last item
    setHistoryIndex(selectionHistory.length - 1);
  };

  const scrollToSelectedRow = (newIndex: string) => {
    const tableYScroll = document.getElementById('table-y-scroll');
    const selectedRow = document.querySelector(`[data-row-id="${newIndex}"]`);
    if (tableYScroll && selectedRow) {
      const container = selectedRow.closest('.first-column') || selectedRow.parentElement;
      if (container) {
        const containerRect = container.getBoundingClientRect();
        const rowRect = selectedRow.getBoundingClientRect();
        const scrollOffset = rowRect.top - containerRect.top - tableYScroll.offsetHeight / 2;
        setScrollChange({ axis: 'y', amount: scrollOffset });
      }
    }
  };

  const selectRow = (scrollTo: boolean = false) => {
    const elementsSelected = document.querySelectorAll(`[data-row-id="${cellStates.selected}"]`);
    elementsSelected.forEach(el => el.classList.remove('selected'));
    if (cellStates.selected !== null) {
      const elementsNew = document.querySelectorAll(`[data-row-id="${cellStates.selected}"]`);
      elementsNew.forEach(el => el.classList.add('selected'));
      if (scrollTo) scrollToSelectedRow(cellStates.selected);
    }
  };

  const handleSelect = useCallback(
    (newIndex: string, updateHistory: boolean = true) => {
      if (cellStates.selected !== newIndex) {
        // Remove current selection style
        const elementsSelected = document.querySelectorAll(`[data-row-id="${cellStates.selected}"]`);
        elementsSelected.forEach(el => el.classList.remove('selected'));
        const elementsNew = document.querySelectorAll(`[data-row-id="${newIndex}"]`);
        elementsNew.forEach(el => el.classList.add('selected'));
        setCellStates({
          ...cellStates,
          selected: newIndex,
        });
        // Use the helper function to update the selection history
        if (updateHistory) updateSelectionHistory(newIndex);
        scrollToSelectedRow(newIndex);
      }
    },
    [cellStates, setCellStates, updateSelectionHistory, setSelectionHistory]
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!transformedData.columns[0]?.data || !cellStates.selected || actions.trxModal) {
        return;
      }
      const selectedIndex = transformedData.columns[0].data.findIndex(item => item.id === cellStates.selected);
      let newIndex;
      switch (e.key) {
        case 'ArrowUp': {
          e.preventDefault();
          newIndex = selectedIndex > 0 ? selectedIndex - 1 : 0;
          handleSelect(transformedData.columns[0].data[newIndex].id);
          break;
        }
        case 'ArrowDown':
          e.preventDefault();
          newIndex = selectedIndex < transformedData.columns[0].data.length - 1 ? selectedIndex + 1 : selectedIndex;
          handleSelect(transformedData.columns[0].data[newIndex].id);
          break;
        case 'Enter':
          if (capabilities.charts && columns > 2) {
            e.preventDefault();
            setActions({ ...actions, detailsPanel: !actions.detailsPanel });
            setTimeout(() => {
              setRefreshBrowsers(r => !r);
            }, 12);
          }
          break;
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [transformedData, cellStates, actions, setActions, capabilities.charts, handleSelect]);

  const setTransformedData = useCallback((newData: TransformedData | null) => {
    if (newData !== null) {
      setTransformedDataState(newData);
    }
  }, []);

  const value = useMemo(
    () => ({
      transformedData,
      setTransformedData,
      cellStates,
      setCellStates,
      isScrolling,
      setIsScrolling,
      handleSelect,
      updateSelectionHistory,
      selectRow,
      // Expose selectionHistory and its updater
      selectionHistory,
      setSelectionHistory,
      historyIndex,
      setHistoryIndex,
    }),
    [
      transformedData,
      setTransformedData,
      cellStates,
      setCellStates,
      isScrolling,
      setIsScrolling,
      handleSelect,
      updateSelectionHistory,
      selectRow,
      selectionHistory,
      setSelectionHistory,
      historyIndex,
      setHistoryIndex,
    ]
  );

  return <NTSelectionContext.Provider value={value}>{children}</NTSelectionContext.Provider>;
};
