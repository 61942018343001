import { useQuery } from '@tanstack/react-query';
import { AskRequest, AskRequestStatus, FlaggedTransaction } from '@hone-automation/common';
import { transformApiTrxToUITrx, useFlaggedTransactions } from 'hooks/flaggedTransactions';
import { shallow } from 'zustand/shallow';
import { useParams } from 'react-router-dom';
import { makeRemoteQueueRequests } from '../main/factories/usecases/queue/remote-get-queue-requests';

const getAskRequests = makeRemoteQueueRequests();

export const useQueueRequestsQuery = (status?: AskRequestStatus, isQueue = false) => {
  const { locationId } = useParams();
  const { activeFlaggedTransaction } = useFlaggedTransactions(
    state => ({
      activeFlaggedTransaction: state.activeFlaggedTransaction,
    }),
    shallow
  );
  return useQuery({
    gcTime: 0,
    queryKey: ['queueRequests', status, locationId],
    queryFn: async () => {
      const requests = (await getAskRequests.get({ locationId, statuses: status })) as AskRequest[];
      if (!isQueue) {
        const sortedRequests = requests.sort((a, b) => {
          return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
        });

        const trx = sortedRequests.find((trx: any) => trx.id === activeFlaggedTransaction?.id);
        let nextActiveFlaggedTransaction = requests[0] as unknown as FlaggedTransaction;
        if (trx) {
          nextActiveFlaggedTransaction = trx as unknown as FlaggedTransaction;
        }
        useFlaggedTransactions.setState({
          activeFlaggedTransaction: transformApiTrxToUITrx(nextActiveFlaggedTransaction),
        });
      }

      return requests;
    },
  });
};
