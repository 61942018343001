import { cloneDeep, findIndex, isNil, lowerCase, omit, sortBy, unset } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { HoneTemplateDisplays } from '../constants';

// FIXME we're guaranteed to have levels in these functions until we support spacers

function isFlatTemplateSection(section: NestedTemplateSection): section is FlatTemplateSection {
  const { title, display, level } = section;
  return !isNil(title) && !isNil(display) && !isNil(level);
}

function isCustomCalculation(value: FlatTemplateSection['value']): value is CustomCalculation {
  if (isNil(value)) return false;
  const cast = value as CustomCalculation;
  return !isNil(cast.sum); // TODO support minus, div independently
}

function hasChart(section: FlatTemplateSection, isTotal: boolean): boolean {
  if (isTotal) {
    return Boolean(section.total?.chart);
  }
  return Boolean(section.chart);
}

function hasAnyChart(section: FlatTemplateSection): boolean {
  return Boolean(section.chart || section.total?.chart);
}

function chartOrder(section: FlatTemplateSection, isTotal: boolean): number {
  if (isTotal) {
    if (section.total?.chart) return section.total.chart.order;
  } else {
    if (section.chart) return section.chart.order;
  }
  return -1;
}

function removeSection(
  section: FlatTemplateSection,
  index: number,
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  const [removed] = newTemplate.splice(index, 1);

  if (hasAnyChart(removed)) {
    newTemplate.forEach(s => {
      if (hasChart(s, false) && chartOrder(s, false) > chartOrder(removed, false)) {
        if (s.chart) s.chart.order = s.chart.order - 1;
      }

      if (hasChart(s, true) && chartOrder(s, true) > chartOrder(removed, true)) {
        if (s.total && s.total.chart) s.total.chart.order = s.total.chart.order - 1;
      }
    });
  }

  // we removed a root, fix it
  if (newTemplate[index]) {
    const removedRoot = newTemplate[index].level > removed.level;

    // strategy: just unnest the next section (unnest one)
    // if (removedRoot) {
    //   --newTemplate[index].level!
    // }

    // strategy: collapse the entire nested hierierchy (unnest all)
    if (removedRoot) {
      const removedLevel = removed.level;
      while (newTemplate[index] && newTemplate[index].level > removedLevel) {
        --newTemplate[index].level;
        ++index;
      }
    }
  }

  return newTemplate;
}

function removeRootSection(
  section: FlatTemplateSection,
  index: number,
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  const [removed] = newTemplate.splice(index, 1);

  const removedLevel = removed.level; // removed.level should always be 0
  while (newTemplate[index] && newTemplate[index].level > removedLevel) {
    // collapse root
    newTemplate.splice(index, 1);
  }

  // fix chart order sequence to account for any removed charts in root section
  sortBy(newTemplate.filter(hasAnyChart), s => s.chart?.order || s.total?.chart?.order).forEach((s, jndex) => {
    if (s.chart) s.chart.order = jndex;
    if (s.total?.chart) s.total.chart.order = jndex;
  });

  if (newTemplate.length === 0) {
    // always ensure we have an empty template
    newTemplate.push({ title: '', display: HoneTemplateDisplays.Header1, level: 0, id: uuidv4() });
  }

  return newTemplate;
}

function toggleTotalSection(
  section: FlatTemplateSection,
  index: number,
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  const updateSection = newTemplate[index];

  if (updateSection.total) {
    // move chart to section
    if (updateSection.total.chart) {
      updateSection.chart = cloneDeep(updateSection.total.chart);
    }

    // move percent to section
    if (updateSection.total.percent) {
      updateSection.percent = updateSection.total.percent;
    }

    // change total to value
    unset(updateSection, 'total');
    updateSection.display = HoneTemplateDisplays.Default;
    //updateSection.title = "";
    //updateSection.value = { eq: { acct_num: "" } };
  } else {
    // change value to total
    //unset(updateSection, "value");
    updateSection.total = {
      title: `Total ${section.title}`,
      level: section.level,
      display: HoneTemplateDisplays.Total2,
      id: uuidv4(),
    };
    updateSection.display = HoneTemplateDisplays.Header2;

    // move chart to total
    if (updateSection.chart) {
      updateSection.total.chart = cloneDeep(updateSection.chart);
      unset(updateSection, 'chart');
    }

    // move percent to total
    if (updateSection.percent) {
      updateSection.total.percent = updateSection.percent;
      // unset(updateSection, 'percent');
    }
  }

  return newTemplate;
}

function changeSectionTitle(
  section: FlatTemplateSection,
  index: number,
  newTitle: string,
  currentTemplate: FlatTemplateSection[],
  isTotal: boolean
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  const updateSection = newTemplate[index];

  if (isTotal) {
    if (updateSection.total) {
      updateSection.total.title = newTitle;
    }
  } else {
    updateSection.title = newTitle;
  }

  return newTemplate;
}

function changeSectionAccount(
  section: FlatTemplateSection,
  index: number,
  selectedAccount: HoneAccount | undefined,
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);

  // auto-update the title to the account name?
  newTemplate[index] = omit(section, ['value']);

  if (selectedAccount) {
    if (selectedAccount.Id !== undefined) {
      newTemplate[index].value = { eq: { acct_id: selectedAccount.Id } };
    } else if (selectedAccount.AcctNum !== undefined) {
      newTemplate[index].value = { eq: { acct_num: selectedAccount.AcctNum } };
    } else if (selectedAccount.id !== undefined) {
      newTemplate[index].value = { section: selectedAccount.id };
    }
    if (newTemplate[index].title === undefined || newTemplate[index].title === '') {
      newTemplate[index].title = selectedAccount.Name;
    }
  } else {
    newTemplate[index].value = { eq: { acct_num: '' } };
    if (newTemplate[index].title === undefined || newTemplate[index].title === '') {
      newTemplate[index].title = '';
    }
  }

  return newTemplate;
}

function changeSectionChart(
  section: FlatTemplateSection,
  index: number,
  newOrder: number | undefined,
  currentTemplate: FlatTemplateSection[],
  isTotal: boolean
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  const updateSection = newTemplate[index];

  if (!isNil(newOrder)) {
    if (hasAnyChart(section)) {
      const oldOrder = chartOrder(section, isTotal);

      // change chart order
      if (updateSection.total && updateSection.total.chart) {
        updateSection.total.chart.order = newOrder;
      } else if (updateSection.chart) {
        updateSection.chart.order = newOrder;
      }

      newTemplate.forEach((s, jndex) => {
        // not using filter here because we need to check index === jndex to know our section
        if (!hasAnyChart(s) || index === jndex) return;

        if (oldOrder < newOrder) {
          // increased section order
          if (chartOrder(s, isTotal) <= newOrder && chartOrder(s, isTotal) > oldOrder) {
            if (isTotal) {
              if (s.total && s.total.chart) s.total.chart.order = s.total.chart.order - 1;
            } else {
              if (s.chart) s.chart.order = s.chart.order - 1;
            }
          }
        } else {
          // decreased section order
          if (chartOrder(s, isTotal) >= newOrder && chartOrder(s, isTotal) < oldOrder) {
            if (isTotal) {
              if (s.total && s.total.chart) s.total.chart.order = s.total.chart.order + 1;
            } else {
              if (s.chart) s.chart.order = s.chart.order + 1;
            }
          }
        }
      });
    } else {
      // add chart
      if (updateSection.total) {
        updateSection.total.chart = { order: newOrder };
      } else {
        updateSection.chart = { order: newOrder };
      }

      newTemplate.forEach((s, jndex) => {
        // not using filter here because we need to check index === jndex to know our section
        if (!hasAnyChart(s) || index === jndex) return;

        if (chartOrder(s, false) >= newOrder) {
          if (s.chart) s.chart.order = s.chart.order + 1;
        }

        if (chartOrder(s, true) >= newOrder) {
          if (s.total && s.total.chart) s.total.chart.order = s.total.chart.order + 1;
        }
      });
    }
  } else {
    const oldOrder = chartOrder(section, isTotal);

    // remove chart
    if (isTotal && section.total) {
      // XXX dangerous here because we will remove the chart goals
      unset(updateSection, ['total', 'chart']);
    } else if (!isTotal && section.chart) {
      unset(updateSection, 'chart');
    }

    newTemplate.forEach(s => {
      if (!hasChart(s, isTotal)) return;

      if (chartOrder(s, isTotal) >= oldOrder) {
        if (isTotal) {
          if (s.total && s.total.chart) s.total.chart.order = s.total.chart.order - 1;
        } else {
          if (s.chart) s.chart.order = s.chart.order - 1;
        }
      }
    });
  }

  return newTemplate;
}

function changeSectionPercent(
  section: FlatTemplateSection,
  index: number,
  selectedAccount: HoneAccount | undefined,
  currentTemplate: FlatTemplateSection[],
  isTotal: boolean
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  const updateSection = newTemplate[index];

  if (selectedAccount) {
    let percent = {};
    if (selectedAccount.Id) {
      percent = {
        acct_id: selectedAccount.Id,
      };
    } else if (selectedAccount.id) {
      percent = {
        section: selectedAccount.id,
      };
    } else {
      percent = {
        acct_num: selectedAccount.AcctNum,
      };
    }

    if (isTotal) {
      if (updateSection.total) {
        updateSection.total.percent = percent;
      }
    } else {
      updateSection.percent = percent;
    }
  } else {
    if (isTotal) {
      if (updateSection.total) {
        unset(updateSection, ['total', 'percent']);
      }
    } else {
      unset(updateSection, 'percent');
    }
  }

  return newTemplate;
}

function getSmoothing(section: FlatTemplateSection): string {
  if (section.avgTimeframe === undefined) {
    return '';
  }
  return section.avgTimeframe;
}

function changeSmoothing(
  section: FlatTemplateSection,
  index: number,
  value: string,
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  const updateSection = newTemplate[index];

  if (value === null || value === '') {
    unset(updateSection, ['avgTimeframe', 'avgType']);
    updateSection.avgTimeframe = undefined;
    updateSection.avgType = undefined;
  } else {
    updateSection.avgTimeframe = value;
    updateSection.avgType = 'backward';
  }

  return newTemplate;
}

function addRootSection(currentTemplate: FlatTemplateSection[], index: number): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  const newSection = { display: HoneTemplateDisplays.Header1, level: 0, title: '', id: uuidv4() };
  const nextRootIndex = findIndex(newTemplate, { level: 0 }, index + 1);

  if (nextRootIndex === -1) {
    // didnt find a root after this, we clicked last root, append to end
    newTemplate.push(newSection);
    return newTemplate;
  }

  // insert before next root
  newTemplate.splice(nextRootIndex, 0, newSection);
  return newTemplate;
}

// FIXME test this
function addSection(
  section: FlatTemplateSection,
  index: number,
  currentTemplate: FlatTemplateSection[],
  item?: FlatTemplateSection
): FlatTemplateSection[] {
  const newItem: FlatTemplateSection = {
    display: HoneTemplateDisplays.Default,
    title: '',
    level: section.level,
    id: uuidv4(),
    percent: item?.percent,
  };

  const newTemplate = cloneDeep(currentTemplate);
  newTemplate.splice(index + 1, 0, newItem);

  return newTemplate;
}

function addNestedSection(
  section: FlatTemplateSection,
  index: number,
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  newTemplate.splice(index + 1, 0, {
    display: HoneTemplateDisplays.Header1,
    title: '',
    level: section.level + 1,
    id: uuidv4(),
  });

  return newTemplate;
}

function changeSectionDisplay(
  section: FlatTemplateSection,
  index: number,
  display: HoneTemplateDisplay,
  currentTemplate: FlatTemplateSection[],
  isTotal: boolean
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  const updateSection = newTemplate[index];

  if (!isTotal) {
    updateSection.display = display;
  } else {
    if (updateSection.total) {
      updateSection.total.display = display;
    }
  }
  return newTemplate;
}

function changeSectionLevel(
  section: FlatTemplateSection,
  index: number,
  operation: 'increment' | 'decrement',
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);
  const updateSection = newTemplate[index];

  // TODO test me
  if (operation === 'increment') {
    updateSection.level = updateSection.level + 1;
  } else {
    // decrement
    updateSection.level = updateSection.level - 1;
  }

  return newTemplate;
}

function moveBeforePreviousRootSection(
  section: FlatTemplateSection,
  index: number,
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);

  // remove this section
  const nextRootIndex = findIndex(newTemplate, { level: 0 }, index + 1);

  let removedSections: FlatTemplateSection[];

  if (nextRootIndex === -1) {
    // moved last section up
    removedSections = newTemplate.splice(index);
  } else {
    removedSections = newTemplate.splice(index, nextRootIndex - index);
  }

  // find where to insert them back before previous root
  let newRootIndex = 0;
  for (let i = index - 1; i >= 0; i--) {
    if (newTemplate[i].level === 0) {
      newRootIndex = i;
      break;
    }
  }

  newTemplate.splice(newRootIndex, 0, ...removedSections);

  return newTemplate;
}

function moveAfterNextRootSection(
  section: FlatTemplateSection,
  index: number,
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);

  // remove this section
  const nextRootIndex = findIndex(newTemplate, { level: 0 }, index + 1);
  const removedSections = newTemplate.splice(index, nextRootIndex - index);
  // find where to insert them back after next root
  const newRootIndex = nextRootIndex - removedSections.length;
  const newNextRootIndex = findIndex(newTemplate, { level: 0 }, newRootIndex + 1);

  if (newNextRootIndex < 0) {
    // moved to last section, append to the end
    newTemplate.push(...removedSections);
  } else {
    newTemplate.splice(newNextRootIndex, 0, ...removedSections);
  }

  return newTemplate;
}

function moveBeforePreviousSection(
  section: FlatTemplateSection,
  index: number,
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);

  // remove this section
  const removedSections: FlatTemplateSection[] = newTemplate.splice(index, 1);
  const newIndex = index - 1;

  newTemplate.splice(newIndex, 0, ...removedSections);
  return newTemplate;
}

function moveAfterNextSection(
  section: FlatTemplateSection,
  index: number,
  currentTemplate: FlatTemplateSection[]
): FlatTemplateSection[] {
  const newTemplate = cloneDeep(currentTemplate);

  // remove this section
  const removedSections = newTemplate.splice(index, 1);

  // find where to insert them back after next section
  const newIndex = index + 1;
  newTemplate.splice(newIndex, 0, ...removedSections);

  return newTemplate;
}

function matchesAccount(inputValue: string, account: HoneAccount): boolean {
  const match = (inputValue || '').match(/\((\d+)?\) (.+)/);

  if (match) {
    // split input value so we can match pre-selected field
    const acctNum = match[1];
    const name = match[2];

    return Boolean(
      lowerCase(account.Name).includes(lowerCase(name)) || (account.AcctNum && account.AcctNum.includes(acctNum || ''))
    );
  } else {
    return Boolean(
      lowerCase(account.Name).includes(lowerCase(inputValue)) ||
        (account.AcctNum && account.AcctNum.includes(inputValue || ''))
    );
  }
}

function calculateTemplateGroups(currentTemplate: FlatTemplateSection[]) {
  return currentTemplate.reduce((memo, section) => {
    // collect rows by section
    if (section.level === 0) {
      // new group
      memo.push([]);
    }

    if (memo.length > 0) {
      memo[memo.length - 1].push(section);
    }

    return memo;
  }, [] as FlatTemplateSection[][]);
}

function makeInitialCustomCalculations(currentTemplate: FlatTemplateSection[]) {
  return currentTemplate
    .filter(section => section.value && isCustomCalculation(section.value))
    .map(
      section =>
        ({
          id: section.id,
          title: section.title,
          sum: section.value?.sum || [],
        }) as CustomCalculation
    );
}

export {
  makeInitialCustomCalculations,
  calculateTemplateGroups,
  isFlatTemplateSection,
  isCustomCalculation,
  hasChart,
  hasAnyChart,
  chartOrder,
  getSmoothing,
  changeSmoothing,
  addRootSection,
  addSection,
  addNestedSection,
  removeSection,
  removeRootSection,
  toggleTotalSection,
  changeSectionTitle,
  changeSectionAccount,
  changeSectionChart,
  changeSectionPercent,
  changeSectionDisplay,
  changeSectionLevel,
  moveBeforePreviousRootSection,
  moveAfterNextRootSection,
  moveBeforePreviousSection,
  moveAfterNextSection,
  matchesAccount,
};
