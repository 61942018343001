import { createContext, useContext, useEffect, useState } from 'react';
import type { PropsWithChildren, ReactNode } from 'react';
import { doDelete, doGet, doPost, reportingOrigin, setLocalStorage } from 'lib/utils';
import { initializeAnalytics, trackError } from 'lib/analytics';
import { FullStory } from '@fullstory/browser';
import { HoneAbility, HoneUserRoles, defineAbilityFor } from '@hone-automation/common';
import { useQuery } from '@tanstack/react-query';
import { makeRemoteGetLocationsUsers } from 'main/factories/usecases';
import { useLocationsStore } from '../hooks/useLocationsStore';
import { useHoneGetUserInfo } from '../hooks/useHoneGetUserInfo';

// Types
export interface User {
  uid: string;
  email: string;
  displayName?: string;
}

interface LoginDto {
  username: string;
  password: string;
}

interface PasswordResetDto {
  email: string;
}

interface AuthContextType {
  loading: boolean;
  user: User | null;
  session: string | null;
  userId: string | null;
  status: string;
  hasAdminRole: boolean;
  isBookkeeperAdmin: boolean;
  currentLocation: HoneLocationUser | undefined;
  allLocationUsers: HoneLocationUser[];
  isOwner: boolean;
  userInfoHasLoaded: boolean;
  userLocationsForUser: Record<string, HoneLocationUser>;
  currentLocationAbility: any;
  currentLocationAbilities: HoneAbility[];

  // Auth functions
  signOut: () => Promise<void>;
  login: ({ username, password }: LoginDto) => Promise<string>;
  sendPasswordResetEmail: ({ email }: PasswordResetDto) => Promise<string>;

  // User management functions
  setAllLocationUsers: (allLocationUsers: HoneLocationUser[]) => void;
  addLocationUser: (
    email: string,
    password: string,
    role: string,
    locationId?: string,
    emailOptOut?: boolean
  ) => Promise<any>;
  updateLocationUser: (email: string, role: string, emailOptOut: boolean, locationId?: string) => Promise<any>;
  removeLocationUser: (email: string, locationId?: string) => Promise<any>;
}

const isTest = process.env.NODE_ENV === 'test';
const locationUsers = makeRemoteGetLocationsUsers();

// Create context with default values
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Custom hook to get location users data
export function useHoneGetUsers(locationId: string | undefined, user: User | null) {
  return useQuery({
    enabled: !!locationId && !!user && !!user.uid && !!localStorage.getItem('hone:session'),
    queryKey: ['locationsUsers', locationId, user?.uid],
    queryFn: async () => {
      if (!user) return [];
      return (await locationUsers.getAll({ userId: user.uid, locationId })) as HoneLocationUser[];
    },
  });
}

// Custom hook to get user locations
function useUserLocationsQuery(user: User | null) {
  return useQuery({
    queryKey: ['userLocations', user?.uid],
    enabled: !!user && !!user.uid,
    queryFn: async () => {
      if (!user) return [];
      const response = await doGet(`${reportingOrigin()}/userLocations?userId=${user.uid}`);
      return response.data;
    },
  });
}

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  // Auth state
  const [loading, setLoading] = useState<boolean>(false);
  const [session, setSession] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);

  // Location users state
  const currentLocationId = useLocationsStore(state => state.currentLocationId);
  const { data: remoteAllLocationUsers, status } = useHoneGetUsers(currentLocationId, user);
  const [allLocationUsers, setAllLocationUsers] = useState<HoneLocationUser[]>([]);
  const [userLocationsForUser, setUserLocationsForUser] = useState<Record<string, HoneLocationUser>>({});
  const [currentLocation, setCurrentLocation] = useState<HoneLocationUser | undefined>();
  const [currentLocationAbility, setCurrentLocationAbility] = useState<any>([]);
  const [currentLocationAbilities, setCurrentLocationAbilities] = useState<HoneAbility[]>([]);

  // Data fetching
  const { data: userInfo, isSuccess: userInfoHasLoaded } = useHoneGetUserInfo(user, currentLocationId);
  const { data: userLocations } = useUserLocationsQuery(user);

  // Derived state
  const userIsAdmin = (userInfo as HoneLocationUser)?.role === HoneUserRoles.Bookkeeper_Account_Admin;
  let isOwner = false;
  if (user && currentLocationId) {
    isOwner = userIsAdmin ? true : userInfo ? (userInfo as HoneLocationUser).role === HoneUserRoles.Owner : false;
  }

  // Authentication functions
  const login = async ({ username, password }: LoginDto) => {
    return await doPost(`${reportingOrigin()}/login`, { username, password })
      .then(result => {
        if (result && result.data.status === 'success') {
          const userData = result.data.firebaseUser;
          setUser(userData);
          setSession(result.data.session);
          setLocalStorage('hone:signedParamsCDN', result.data.signedParamsCDN);
          setLocalStorage('hone:session', result.data.session);

          initializeAnalytics(userData);

          if (!isTest) {
            FullStory('setProperties', {
              type: 'user',
              properties: {
                displayName: userData.displayName ? userData.displayName : '',
                email: userData.email ? userData.email : '',
              },
            });
          }
        }
        return 'success';
      })
      .catch(() => {
        return 'error';
      });
  };

  const sendPasswordResetEmail = async ({ email }: PasswordResetDto) => {
    return await doPost(`${reportingOrigin()}/users/passwordResetEmail`, { email })
      .then(() => 'success')
      .catch(() => 'error');
  };

  const deleteSession = () => {
    localStorage.removeItem('hone:session');
    localStorage.removeItem('hone:signedParamsCDN');
    localStorage.removeItem('hone:locationId');
    setUser(null);
    setSession(null);
  };

  const signOut = async () => {
    deleteSession();
  };

  // User management functions
  const addLocationUser = async (
    email: string,
    password: string,
    role: string,
    locationId?: string,
    emailOptOut?: boolean
  ) => {
    const location = locationId ?? currentLocationId;
    try {
      return doPost(`${reportingOrigin()}/addLocationUser`, {
        userId: user?.uid,
        email,
        password,
        locationId: location,
        emailOptOut: false,
        role,
      });
    } catch (error) {
      trackError({ error: error as Error });
      return { error: error };
    }
  };

  const updateLocationUser = async (email: string, role: string, emailOptOut: boolean, locationId?: string) => {
    const location = locationId ?? currentLocationId;
    try {
      return doPost(`${reportingOrigin()}/updateLocationUser`, {
        userId: user?.uid,
        email,
        locationId: location,
        role,
        emailOptOut,
      });
    } catch (error) {
      trackError({ error: error as Error });
      return { error: error };
    }
  };

  const removeLocationUser = async (email: string, locationId?: string) => {
    const location = locationId ?? currentLocationId;
    try {
      return doDelete(
        `${reportingOrigin()}/removeLocationUser?userId=${user?.uid}&locationId=${location}&email=${email}`
      );
    } catch (error) {
      trackError({ error: error as Error });
      return { error: error };
    }
  };

  // Initialize auth
  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      if (!localStorage.getItem('hone:session')) return;
      setLoading(true);
      try {
        const response = await doGet(`${reportingOrigin()}/users/firebaseUser`);
        setUser(response.data);
        setSession(localStorage.getItem('hone:session'));
        initializeAnalytics(response.data);
      } catch (e) {
        deleteSession();
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  // Update location users data
  useEffect(() => {
    if (!user || !user?.uid) {
      setCurrentLocation(undefined);
      setCurrentLocationAbilities([]);
      setCurrentLocationAbility([]);
      setAllLocationUsers([]);
      setUserLocationsForUser({});
    } else {
      if (status === 'success' && remoteAllLocationUsers && userLocations && currentLocationId) {
        setAllLocationUsers(remoteAllLocationUsers as HoneLocationUser[]);
        if (userLocations) {
          // Convert to record map for easier data access
          const ulMap: Record<string, HoneLocationUser> = {};
          (userLocations as HoneLocationUser[]).map((ul: HoneLocationUser) => (ulMap[ul.locationId] = ul));
          setUserLocationsForUser(ulMap);

          // Set current location permissions
          if (currentLocationId) {
            if (ulMap[currentLocationId]) {
              setCurrentLocation(ulMap[currentLocationId]);
              if (ulMap[currentLocationId].abilities) {
                const abilities = ulMap[currentLocationId].abilities ?? [];
                setCurrentLocationAbilities(abilities);
                setCurrentLocationAbility(defineAbilityFor(abilities));
              }
            }
          }
        }
      }
    }
  }, [user, status, remoteAllLocationUsers, userLocations, currentLocationId]);

  return (
    <AuthContext.Provider
      value={{
        // Auth state
        loading,
        user,
        userId: user?.uid ?? null,
        session,

        // Location users state
        status,
        isBookkeeperAdmin: currentLocation?.role === HoneUserRoles.Bookkeeper_Account_Admin,
        allLocationUsers,
        isOwner,
        hasAdminRole: userIsAdmin,
        userInfoHasLoaded,
        currentLocation,
        userLocationsForUser,
        currentLocationAbility,
        currentLocationAbilities,

        // Auth functions
        login,
        signOut,
        sendPasswordResetEmail,

        // User management functions
        setAllLocationUsers,
        addLocationUser,
        updateLocationUser,
        removeLocationUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use auth context
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// For backward compatibility
export const useAuthContext = useAuth;
export const useHoneLocationUsers = useAuth;
