import { HierarchicalAccountData } from '../DInsights';

// Utility functions to help with calculations
export const calculateMetrics = (plData: HierarchicalAccountData[]) => {
  // Pre-calculate common metrics to help Claude's analysis
  const metrics = plData.map((period, index) => {
    const prev = index > 0 ? plData[index - 1] : null;

    return {
      period: period.periodId,

      metrics: {
        // Revenue metrics
        totalRevenue: period.data.sales.total,
        revenueChange: prev ? ((period.data.sales.total - prev.data.sales.total) / prev.data.sales.total) * 100 : null,

        // Margin metrics
        grossMargin: ((period.data.sales.total - period.data.cogs.total) / period.data.sales.total) * 100,
        operatingMargin:
          ((period.data.sales.total - period.data.cogs.total - period.data.operatingExpenses.total) /
            period.data.sales.total) *
          100,

        // Cost ratios
        laborCostRatio: (period.data.labor.total / period.data.sales.total) * 100,
        cogRatio: (period.data.cogs.total / period.data.sales.total) * 100,
      },
    };
  });

  return metrics;
};

// Calculate statistical summaries for key metrics
export const calculateStatistics = (metrics: periodMetrics[]) => {
  // Get the most recent and previous periods
  const current = metrics[metrics.length - 1];
  const previous = metrics[metrics.length - 2];

  // Helper function to calculate average
  const calculateAverage = (metricName: keyof Metrics, metrics: periodMetrics[]) => {
    // First filter out any entries where metrics[metricName] is null
    const values: number[] = metrics.map(m => m.metrics[metricName]).filter((v): v is number => v !== null);

    if (values.length === 0) return 0;

    // Now TypeScript knows this is an array of numbers, not null
    const sum = values.reduce((acc, val) => acc + val, 0);
    return sum / values.length;
  };

  return {
    revenue: {
      currentValue: current.metrics.totalRevenue,
      previousValue: previous?.metrics.totalRevenue,
      changeAmount: previous ? current.metrics.totalRevenue - previous.metrics.totalRevenue : null,
      changePercent: current.metrics.revenueChange,
      average: calculateAverage('totalRevenue', metrics),
    },
    margins: {
      grossMargin: {
        currentValue: current.metrics.grossMargin,
        previousValue: previous?.metrics.grossMargin,
        changeAmount: previous ? current.metrics.grossMargin - previous.metrics.grossMargin : null,
        changePercent: previous
          ? ((current.metrics.grossMargin - previous.metrics.grossMargin) / previous.metrics.grossMargin) * 100
          : null,
        average: calculateAverage('grossMargin', metrics),
      },
      operatingMargin: {
        currentValue: current.metrics.operatingMargin,
        previousValue: previous?.metrics.operatingMargin,
        changeAmount: previous ? current.metrics.operatingMargin - previous.metrics.operatingMargin : null,
        changePercent: previous
          ? ((current.metrics.operatingMargin - previous.metrics.operatingMargin) / previous.metrics.operatingMargin) *
            100
          : null,
        average: calculateAverage('operatingMargin', metrics),
      },
    },
  };
};

// Function to identify significant changes
export const findSignificantChanges = (metrics: periodMetrics[], threshold = 5) => {
  return metrics.reduce((changes: PeriodChange[], current: periodMetrics, index) => {
    if (index === 0) return changes;

    const prev = metrics[index - 1];
    const significantChanges: SignificantChange[] = [];

    // Check each metric for significant changes
    Object.entries(current.metrics).forEach(([metric, value]) => {
      const metricKey = metric as keyof Metrics;
      if (typeof value === 'number' && typeof prev.metrics[metricKey] === 'number') {
        const prevValue = prev.metrics[metricKey];
        if (!prevValue) return;
        // Calculate percentage change for absolute values (like revenue)
        // Or absolute change for ratio metrics (like margins)
        const change =
          metricKey.includes('Margin') || metricKey.includes('Ratio') || metricKey === 'revenueChange'
            ? value - prevValue // For ratios, look at percentage point change
            : ((value - prevValue) / Math.abs(prevValue)) * 100; // For absolute values, look at percentage change

        const threshold = CHANGE_THRESHOLDS[metricKey] || 5; // Default to 5% if no specific threshold
        if (Math.abs(change) >= threshold) {
          significantChanges.push({
            metric: metricKey,
            change,
            changeType: metric.includes('Margin') || metric.includes('Ratio') ? 'percentage_points' : 'percent',
            from: prevValue,
            to: value,
          });
        }
      }
    });

    if (significantChanges.length > 0) {
      changes.push({
        period: current.period,
        changes: significantChanges,
      });
    }

    return changes;
  }, []);
};

// Find significant changes (threshold-based)
// Define thresholds for different metric types
export const CHANGE_THRESHOLDS = {
  // Revenue changes (5% change in total revenue)
  totalRevenue: 5,
  revenueChange: 5,

  // Margin changes (2 percentage points in margin metrics)
  grossMargin: 2,
  operatingMargin: 2,

  // Cost ratio changes (3 percentage points in cost ratios)
  laborCostRatio: 3,
  cogRatio: 3,
};
