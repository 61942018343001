import { prefixClass } from 'lib/utils';
import './STBTools.scss';
import { IconRefresh } from 'components/Icons';
import STBIcon from '../STBIcon';
import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import KSExport from 'presentation/components/KSExport';

export type ActionProps = {
  tooltip: string;
  handleClick: (e: SyntheticEvent<HTMLButtonElement, Event>) => void;
  icon: JSX.Element;
  disabled?: boolean;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};

type STBToolsProps = {
  reportHeaders: any;
  adminMode?: boolean;
  status?: HoneReportStatus;
  report?: NestedHoneReport;
  onRefresh?: (e: SyntheticEvent<HTMLButtonElement, Event>) => void;
  onDownload?: (e: SyntheticEvent<HTMLButtonElement, Event>) => void;
  adminTools?: ActionProps[];
};

const STBTools = ({ reportHeaders, adminMode = false, report, status = 'Published', ...events }: STBToolsProps) => {
  const prefix = prefixClass('STB-Tools');
  const { onRefresh, onDownload, adminTools } = events;
  const adminAllowed = adminMode && adminTools?.length;

  const drawCommonTools = () => (
    <div className={classNames(prefix('common'), { admin: adminAllowed })}>
      {onRefresh && (
        <STBIcon
          tooltip="Refresh Report"
          handleClick={(e: SyntheticEvent<HTMLButtonElement, Event>) => onRefresh(e)}
          icon={<IconRefresh />}
        />
      )}
      {onDownload && <KSExport report={report} />}
    </div>
  );

  const drawAdminTools = () => (
    <div className={prefix('admin')}>
      {adminTools &&
        adminTools.map((action, index) => (
          <STBIcon
            key={index}
            tooltip={action.tooltip}
            handleClick={action.handleClick}
            icon={action.icon}
            disabled={action.disabled}
            placement={action.placement}
          />
        ))}
    </div>
  );

  return (
    <>
      {report && (
        <div className={prefix()}>
          {drawCommonTools()}
          {adminAllowed && drawAdminTools()}
        </div>
      )}
    </>
  );
};
export default STBTools;
