import React, { forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { CreateRequestModalProps, CreateRequestModalRef } from './CreateRequestModal';
import { AskRequest, CreateAskRequest } from '@hone-automation/common';
import { useQueueContext } from '../Queue/QueueContext';
import { useCreateAskRequestMutation } from '../../../hooks/flaggedTransactions';
import { useEvent } from '../../../hooks/useEvent';
import { filterAndSplitRequests } from '../Queue/utils/filterAndSearchUtils';

export type WithQueueProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const withQueue = <P extends WithQueueProps>(
  WrappedComponent: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<CreateRequestModalProps> & React.RefAttributes<CreateRequestModalRef>
  >
) => {
  return forwardRef<CreateRequestModalRef, P>(function WithQueue(props, ref) {
    const createRequestModalRef = React.useRef<CreateRequestModalRef>(null);
    const { locationId } = useParams();
    const {
      state: { requests, filters, meId },
      dispatch,
    } = useQueueContext();
    const { isOpen, handleClose } = props;
    const { dispatch: emitEvent } = useEvent('onQueueRequestResolve');

    const { mutate } = useCreateAskRequestMutation(locationId!, data => {
      const newData = [...requests, data as unknown as AskRequest];
      dispatch({
        type: 'SET_REQUESTS',
        payload: newData,
      });
      dispatch({
        type: 'SET_FILTERS',
        payload: { ...filters, status: 'In Progress' },
      });
      dispatch({
        type: 'SET_SELECTED_ITEM',
        payload: data as unknown as AskRequest,
      });
      emitEvent({ field: 'In Progress', duration: 5000 });
      handleClose();
    });

    const createRequest = async (data: CreateAskRequest) => {
      mutate(data);
    };

    const wrappedComponentProps = {
      isOpen,
      onSubmit: createRequest,
      handleClose,
      resetState: handleClose,
      ref: createRequestModalRef,
      locationId,
      title: 'New Queue Request',
    } as CreateRequestModalProps;

    return <WrappedComponent {...wrappedComponentProps} />;
  });
};

export default withQueue;
