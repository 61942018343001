import { logger } from '../KSExport/reportNTParser/utils';
import classNames from 'classnames';
import { visualConfig } from './DashboardChart';

const ChartLegend = ({ weekly, handleClick }: { weekly: boolean; handleClick?: () => void }) => {
  return (
    <div className="ks-dashboard-chart__legend" onClick={handleClick}>
      {!weekly ? (
        Object.keys(visualConfig.legends).map((key, index) => (
          <div key={`${key}_${index}`} className="ks-dashboard-chart__legend-item">
            <div
              className="ks-dashboard-chart__legend-item-color"
              style={{ backgroundColor: visualConfig.legends[key as keyof typeof visualConfig.legends] }}
            />
            <div className="ks-dashboard-chart__legend-item-label">{key}</div>
          </div>
        ))
      ) : (
        <div className="ks-dashboard-chart__legend-contribution">
          <div className="ks-dashboard-chart__legend-contribution-color positive">+</div>
          <div className="ks-dashboard-chart__legend-contribution-label">Contribution Margin</div>
          <div className="ks-dashboard-chart__legend-contribution-color negative">-</div>
        </div>
      )}
    </div>
  );
};

export default ChartLegend;
