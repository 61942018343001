import { formatAmount, formatAmountCondensed } from '../../NTCell/ValueFormatters/ValueFormatters';
import { getCSSVariable } from '../../NTMiniMap/NTMiniMapUtils';
import { ChartDataColumn, ChartGroup, ChartProps, ChartContext, TooltipZone } from '../types';
import { calculateNiceScale, colorBlock, checkIfallValuesAreZero } from './chartUtils';
import { drawColumnsBackground, drawGridAndLabels, drawNoData, drawXLabels } from './drawTools';
import { chartContextDefault } from './chartCFG';
import { drawStackedHorBar } from './chartsTypes/stackedHorBar';
import { drawBars } from './chartsTypes/bars';
import { drawLineChart } from './chartsTypes/lines';
import { logger } from 'presentation/components/KSExport/reportNTParser/utils';

type DrawChartProps = {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  dimensions: { width: number; height: number };
  chartData: ChartProps | null;
  selectedPeriod: number;
  hoveredPeriod: number;
  chartFilters: {
    groups: ChartGroup[];
    money: boolean;
    percent: boolean;
  };
};

export const drawChart = ({
  canvasRef,
  dimensions,
  chartData,
  chartFilters,
  selectedPeriod,
  hoveredPeriod,
}: DrawChartProps) => {
  const canvas = canvasRef.current;
  if (!canvas || !chartData) return null;

  const ctx = canvas.getContext('2d');
  if (!ctx) return null;

  // Set Canvas size using devicePixelRatio for retina displays
  const dpr = window.devicePixelRatio || 1;
  canvas.width = dimensions.width * dpr;
  canvas.height = dimensions.height * dpr;
  ctx.scale(dpr, dpr);
  canvas.style.width = `${dimensions.width}px`;
  canvas.style.height = `${dimensions.height}px`;

  //console.time('drawChart');

  const { groups, money, percent } = chartFilters;
  // Check if all values to plot are zero
  const allValuesAreZero = checkIfallValuesAreZero(chartData, groups);

  // Initialize the context
  const chartContext: ChartContext = {
    ...chartContextDefault,
    ctx: ctx,
    data: chartData,
    groups: chartFilters.groups,
    chartFilters,
    dimensions,
    selectedPeriod,
    allValuesAreZero,
    hoveredPeriod,
  };

  const tooltips: (TooltipZone | null)[] = [];

  // Inmutable chart body context
  const { font, fontSize, margin } = chartContext.body;

  const isParentMargin = { ...margin, bottom: margin.bottom + 60 };

  // Clear the canvas
  try {
    ctx.clearRect(0, 0, dimensions.width, dimensions.height);
  } catch (error) {
    console.error('(Silent Error) NT: Canvas operation failed:', error);
    // Handle gracefully
    return null;
  }
  // If the data is malformed or all values are zero, exit and display a message
  if (
    !chartData.columns ||
    chartData.columns.length === 0 ||
    (allValuesAreZero && !chartData.isParent) ||
    (allValuesAreZero && chartData.isParent)
  ) {
    // Display a message if no data
    drawNoData({ context: chartContext, allZero: allValuesAreZero, isParent: chartData.isParent });
    return null;
  }

  // Maxticks defines the maximun number of vertical divisions in the chart
  const maxTicks = chartData.isParent ? 3 : 5;
  // Define chart body
  const {
    chartWidth,
    chartHeight,
    groupWidth,
    barWidth,
    groupGap,
    yMoneyScale,
    yPercentScale,
    zeroLineY,
    zeroPercentLineY,
  } = initializeContextParams(dimensions, chartData.isParent ? isParentMargin : margin, chartData, groups, maxTicks);

  chartContext.body = { ...chartContext.body, chartWidth, chartHeight, groupWidth, barWidth, groupGap };
  chartContext.structure = {
    ...chartContext.structure,
    yMoneyScale,
    yPercentScale,
    zeroLineY,
    zeroPercentLineY,
    maxTicks,
  };

  // ****  Draw Elements
  // Show Live Report and column marks if we have more than 1 group
  drawColumnsBackground(chartContext);
  // Money bars
  if (money && !allValuesAreZero) tooltips.push(drawBars(chartContext) || null);
  // Percentage line
  if (percent && !allValuesAreZero) tooltips.push(drawLineChart(chartContext) || null);
  // Determine if labels need to be hidden
  if (chartData.isParent) tooltips.push(drawStackedHorBar(chartContext) || null);
  else drawXLabels(chartContext);

  // Draw grid lines and Y-axis labels
  ctx.font = `300 ${fontSize.value}px ${font}`;
  drawGridAndLabels(chartContext);
  //console.log(tooltips);
  //console.timeEnd('drawChart');
  return tooltips;
};

//* Initialize context parameters and calculate scales
function initializeContextParams(
  dimensions: { width: number; height: number },
  margin: { left: number; right: number; top: number; bottom: number },
  chartData: ChartProps,
  groups: ChartGroup[],
  maxTicks: number
) {
  const chartWidth = dimensions.width - margin.left - margin.right;
  const chartHeightBasedOnParenting = chartData.isParent
    ? dimensions.height - margin.top - margin.bottom
    : dimensions.height - margin.top - margin.bottom;
  const chartHeight = chartHeightBasedOnParenting;

  // Find min and max values for scaling
  const moneyValues = chartData.columns.flatMap((col: ChartDataColumn) =>
    groups.map(group => col.data.money[group.toLowerCase() as keyof typeof col.data.money])
  );
  const minMoney = Math.min(0, ...moneyValues);
  const maxMoney = Math.max(0, ...moneyValues);

  const percentValues = chartData.columns.flatMap((col: ChartDataColumn) =>
    groups.map(group => col.data.percent[group.toLowerCase() as keyof typeof col.data.percent])
  );
  const minPercent = Math.min(0, ...percentValues);
  const maxPercent = Math.max(0, ...percentValues);

  // Calculate nice round numbers for Y-axis labels
  const yMoneyScale = calculateNiceScale(minMoney, maxMoney, maxTicks * 2);
  const yPercentScale = calculateNiceScale(minPercent, maxPercent, maxTicks * 2);

  // Calculate bar dimensions
  const groupGap = groups.length > 1 ? (chartData.columns.length > 8 ? 8 : 12) : 4;
  const barGap = 0;
  const groupWidth = (chartWidth - (chartData.columns.length - 1) * groupGap) / chartData.columns.length;
  const barWidth = (groupWidth - 2 * barGap) / groups.length;

  // Calculate the y-coordinate for the zero line
  const zeroLineY =
    dimensions.height - margin.bottom - ((0 - yMoneyScale.min) / (yMoneyScale.max - yMoneyScale.min)) * chartHeight;

  const zeroPercentLineY =
    dimensions.height -
    margin.bottom -
    ((0 - yPercentScale.min) / (yPercentScale.max - yPercentScale.min)) * chartHeight;
  return {
    chartWidth,
    chartHeight,
    groupWidth,
    barWidth,
    groupGap,
    yMoneyScale,
    yPercentScale,
    zeroLineY,
    zeroPercentLineY,
  };
}
