import { formatAmountCondensed } from '../../../NTCell/ValueFormatters/ValueFormatters';
import { getCSSVariable } from '../../../NTMiniMap/NTMiniMapUtils';
import { ChartContext, ChartDataColumnChildren, Measures, TooltipAreas, TooltipZone } from '../../types';
import { generateColorShades, colorBlock } from '../chartUtils';
import { positiveColors, negativeColors } from '../chartCFG';

const drawLocalStackedGroup = ({
  elements,
  ctx,
  initX,
  initY,
  colors,
  maxUnits,
  width,
  height,
  gap,
  props,
  sepColor = 'rgba(0,0,0,0.4)',
}: {
  elements: ChartDataColumnChildren[];
  ctx: CanvasRenderingContext2D;
  initX: number;
  initY: number;
  colors: string[];
  maxUnits: number;
  width: number;
  height: number;
  gap: number;
  props: ChartContext;
  sepColor?: string;
}) => {
  const tooltipAreas: TooltipAreas[] = [];
  const { font, fontSize } = props.body;
  const insideMargin = 10;
  let x = initX;
  elements.forEach((account, index) => {
    ctx.fillStyle = colors[index];
    const proportion = account.money / maxUnits;
    const segmentWidth = Math.abs(proportion) * width;

    // Setup the measures array. If we have OTHER we add the OTHER's accounts as meaures
    const measures: Measures[] = [];
    if (account.accounts) {
      account.accounts.forEach(account => {
        measures.push({
          name: 'Amount',
          note: account.title,
          value: account.money,
          unit: '$',
        });
      });
    } else {
      measures.push({
        name: 'Amount',
        value: account.money,
        unit: '$',
      });
    }
    // Adding value areas to tooltip
    tooltipAreas.push({
      rect: {
        x: x,
        y: initY,
        width: segmentWidth,
        height: height,
        fill: colors[index],
      },
      title: account.title,
      glCode: account.glCode,
      contribution: proportion,
      measures: measures,
      link: account.id,
    });
    ctx.fillRect(x, initY, segmentWidth, height);

    const segmentFontSize = fontSize.value - 1;
    const halfSizeText = segmentFontSize / 2;
    const minimumCharacters = 4;
    let label = account.title.toLowerCase();
    ctx.fillStyle = 'black';
    ctx.font = `400 ${segmentFontSize}px ${font}`;
    ctx.textAlign = 'left';
    const textFit = ctx.measureText(label).width < segmentWidth - insideMargin * 2;
    if (!textFit) {
      const ellipsis = '...';
      const charSpace = ctx.measureText(label).width / (label.length - 1);
      const allowedChars = Math.floor((segmentWidth - insideMargin * 2) / charSpace) - ellipsis.length;
      if (allowedChars > minimumCharacters) label = label.substring(0, allowedChars) + ellipsis;
      else label = '';
    }
    ctx.fillText(`${label}`, x + insideMargin, initY + (halfSizeText + insideMargin), segmentWidth - insideMargin * 2);

    if (index < elements.length - 1 && gap > 0) {
      ctx.beginPath();
      ctx.moveTo(x + segmentWidth - gap, initY);
      ctx.lineTo(x + segmentWidth - gap, initY + height);
      ctx.strokeStyle = sepColor;
      ctx.lineWidth = gap;
      ctx.stroke();
    }
    x += segmentWidth;
  });
  return { x, tooltipAreas };
};

export function drawStackedHorBar(props: ChartContext) {
  const { data: chartData, body, structure, groups, ctx, selectedPeriod, dimensions, allValuesAreZero } = props;
  const { margin, groupWidth, groupGap, barWidth, barGap, chartHeight, chartWidth, fontSize, font } = body;
  if (!ctx || !chartData) return null;

  const currentColumn = chartData.columns[selectedPeriod > -1 ? selectedPeriod : 0];
  if (!currentColumn) return null;

  ctx.fillStyle = 'black';
  const offsetChart = 70;
  const offsetControl = 40;
  const offsetBarTitle = 10;
  const stackedHeight = 28;
  const initY = allValuesAreZero ? 0 : chartHeight + margin.top;
  const actualControlY = initY + offsetControl;
  const actualStartY = initY + offsetChart;
  const actualStartYBar = actualStartY + offsetBarTitle;

  const x = margin.left;
  const maxValue = currentColumn.data.money.actual;

  // Filtering SubAccounts
  const filteredAccounts = currentColumn.childrenAccounts
    ?.filter(account => account.money !== 0)
    .sort((a, b) => b.money - a.money);

  if (filteredAccounts?.length === 0) {
    return;
  }

  //////////////

  const negative: ChartDataColumnChildren[] = [];
  const positive: ChartDataColumnChildren[] = [];
  let negativeTotal = 0;
  let positiveTotal = 0;
  let smallPositiveTotal = 0;
  let smallNegativeTotal = 0;

  const smallCriteria = 0.05;
  const smallContributionPositive: ChartDataColumnChildren[] = [];
  const smallContributionNegative: ChartDataColumnChildren[] = [];
  // Sorting accounts by negative/positive
  filteredAccounts?.forEach(account => {
    if (account.money < 0) {
      negativeTotal += account.money;
      if (Math.abs(account.money) < maxValue * smallCriteria) {
        smallNegativeTotal += account.money;
        smallContributionNegative.push(account);
      } else {
        negative.push(account);
      }
    } else {
      positiveTotal += account.money;
      if (Math.abs(account.money) < maxValue * smallCriteria) {
        smallPositiveTotal += account.money;
        smallContributionPositive.push(account);
      } else {
        positive.push(account);
      }
    }
  });
  const maxUnits = positiveTotal - negativeTotal;

  if (smallContributionNegative.length > 1) {
    const other: ChartDataColumnChildren = {
      title: 'OTHERS',
      money: smallNegativeTotal,
      percent: smallNegativeTotal / maxValue,
      accounts: smallContributionNegative,
      glCode: '',
    };
    negative.push(other);
  } else if (smallContributionNegative.length === 1) {
    negative.push(smallContributionNegative[0]);
  }
  if (smallContributionPositive.length > 1) {
    const other: ChartDataColumnChildren = {
      title: 'OTHERS',
      money: smallPositiveTotal,
      percent: smallPositiveTotal / maxValue,
      accounts: smallContributionPositive,
      glCode: '',
    };
    positive.push(other);
  } else if (smallContributionPositive.length === 1) {
    positive.push(smallContributionPositive[0]);
  }
  const gap = 1;

  // Prepare colors
  /*   const positiveColors = generateColorShades(
    colorBlock(groups[0], positiveTotal, true, true),
    filteredAccounts?.length || 1
  ); */
  //const negativeColors = generateColorShades(getCSSVariable('--ks-chart-redish'), filteredAccounts?.length || 1);

  ////////

  // Draw Period Control
  ctx.font = `500 ${fontSize.value + 2}px ${font}`;
  ctx.textAlign = 'center';
  const controlLabel = chartData.columns[selectedPeriod].name;
  ctx.fillText(`${controlLabel}`, x + chartWidth / 2, actualControlY, chartWidth);
  // Bar labels
  ctx.textAlign = 'left';
  ctx.font = `300 ${fontSize.value + 1}px ${font}`;
  ctx.fillText(`Sub-Accounts Contributing (${filteredAccounts!.length})`, x, actualStartY, chartWidth);
  ctx.textAlign = 'right';
  ctx.font = `500 ${fontSize.value + 1}px ${font}`;
  ctx.fillText(formatAmountCondensed(maxValue), x + chartWidth, actualStartY, 200);

  //Draw Negative Values
  const { x: newX, tooltipAreas: negativeAreas } = drawLocalStackedGroup({
    ctx,
    props,
    elements: negative,
    initX: x,
    initY: actualStartYBar,
    colors: negativeColors.reverse(),
    maxUnits,
    width: chartWidth,
    height: stackedHeight,
    gap,
  });

  // Draw Positive Values
  const { tooltipAreas: positiveAreas } = drawLocalStackedGroup({
    ctx,
    props,
    elements: positive,
    initX: newX,
    initY: actualStartYBar,
    colors: positiveColors,
    maxUnits,
    width: chartWidth,
    height: stackedHeight,
    gap,
  });

  // Draw the Zero Line
  ctx.beginPath();
  ctx.moveTo(newX, actualStartYBar);
  ctx.lineTo(newX, actualStartYBar + stackedHeight);
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 2;
  ctx.stroke();

  // Bar Amounts Labels
  ctx.textAlign = 'center';
  ctx.font = `300 ${fontSize.value}px ${font}`;
  ctx.fillText(`0`, newX, actualStartYBar + stackedHeight + 10);

  if (negativeTotal !== 0) {
    ctx.textAlign = 'right';
    ctx.fillText(formatAmountCondensed(negativeTotal), x, actualStartYBar + stackedHeight + 10);
  }
  if (positiveTotal !== 0) {
    ctx.textAlign = 'left';
    ctx.fillText(formatAmountCondensed(positiveTotal), x + chartWidth, actualStartYBar + stackedHeight + 10);
  }

  const tooltipZone: TooltipZone = {
    title: `Contribution to ${chartData.name}`,
    period: selectedPeriod > -1 ? chartData.columns[selectedPeriod].name : chartData.timeFrame,
    glCode: chartData.glCode,
    areas: [...negativeAreas, ...positiveAreas],
  };
  return tooltipZone;
}
