import { prefixClass } from 'lib/utils';
import './LabelSwitchItem.scss';
import classNames from 'classnames';

type LabelSwitchButtonProps = {
  label: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  pulse?: boolean;
  badge?: string;
};

const LabelSwitchItem = (props: LabelSwitchButtonProps) => {
  const { label, selected = false, disabled = false, onClick } = props;
  const prefix = prefixClass('label-switch-item');

  const handleClick = () => {
    if (disabled) return;
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={classNames(prefix())} onClick={handleClick}>
      <div className={classNames(prefix('label'), { 'lsi-selected': selected, 'lsi-disabled': disabled })}>
        <div
          className={classNames(prefix('label'), {
            'lsi-selected': selected,
            'lsi-disabled': disabled,
          })}
        >
          {label}
          {props.pulse && props.badge && <span className="pulse-badge">{props.badge}</span>}
        </div>
      </div>
    </div>
  );
};

export default LabelSwitchItem;
