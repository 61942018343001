import { useEffect, useCallback } from 'react';
import { useScrollProperties } from './useScrollProperties';
import { useNTUtilsContext } from '../NTUtilsContext';
import { useNTTableContext } from '../NTTableContext';

export function useTableState(enabled: boolean = true) {
  const { updateScrollProperties } = useScrollProperties();
  const { actions, setActions } = useNTUtilsContext();
  const { redimParent, setIsResizing, setTableWidth } = useNTTableContext();

  const handleResize = useCallback(
    (newWidth: number | null) => {
      if (!newWidth) return;
      setTableWidth(`${newWidth - 3}px`);
      // updateScrollProperties('x');
    },
    [setTableWidth, updateScrollProperties]
  );

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      setIsResizing(true);

      const startX = e.clientX;
      const startWidth = redimParent?.getBoundingClientRect().width || 0;
      let lastWidth = startWidth;
      let lastUpdate = 0;

      const doDrag = (e: MouseEvent) => {
        const now = Date.now();
        const newWidth = startWidth + e.clientX - startX;
        const handle = redimParent?.querySelector('.resize-handle');
        if (!handle?.classList.contains('resize-dragging')) {
          handle?.classList.add('resize-dragging');
        }
        // Only update if width changed significantly and enough time passed
        if (Math.abs(newWidth - lastWidth) > 1) {
          handleResize(newWidth);
          lastWidth = newWidth;
          lastUpdate = now;
        }
      };

      const stopDrag = () => {
        setIsResizing(false);
        const handle = redimParent?.querySelector('.resize-handle');
        handle?.classList.remove('resize-dragging');
        document.removeEventListener('mousemove', doDrag);
        document.removeEventListener('mouseup', stopDrag);
        updateScrollProperties('x');
      };

      document.addEventListener('mousemove', doDrag);
      document.addEventListener('mouseup', stopDrag);
    },
    [redimParent, setIsResizing, handleResize]
  );

  useEffect(() => {
    const existingHandle = redimParent?.querySelector('.resize-handle');

    // Remove handle if not enabled or parent not ready
    if (!enabled || !redimParent) {
      if (existingHandle) {
        existingHandle.removeEventListener('mousedown', handleMouseDown as EventListener);
        existingHandle.remove();
      }
      return;
    }

    // Create handle if it doesn't exist
    if (!existingHandle) {
      const handle = document.createElement('div');
      handle.className = 'resize-handle';
      redimParent.appendChild(handle);
      handle.addEventListener('mousedown', handleMouseDown as EventListener);
    }

    return () => {
      const handle = redimParent?.querySelector('.resize-handle');
      if (handle) {
        handle.removeEventListener('mousedown', handleMouseDown as EventListener);
        handle.remove();
      }
    };
  }, [enabled, redimParent, handleMouseDown]);

  return {
    handleResize,
    handleMouseDown,
  };
}
