import { Category, HoneReportTimeframes, ReportGraphDataSummary } from '../domain/models';
import { create } from 'zustand';
import { useActiveReportStore } from './useActiveReportStore';
import { getSummaryReportSales } from '../lib/honeTableUtils';
import { format, parse } from 'date-fns/fp';
import { template } from 'lodash';
import { subscribeWithSelector } from 'zustand/middleware';
import { ReportRequest, ReportType } from '@hone-automation/common';
import { useLocationsStore } from './useLocationsStore';
import { useReportsStore } from './useReportsStore';

interface ReportGraphState {
  extraReport: NestedHoneReport | undefined;
  summaryReport: ReportGraphDataSummary | undefined;
  extraReportId: string | undefined;
  extraReportSummary: ReportGraphDataSummary | undefined;
}

export const useReportGraphStore = create(
  subscribeWithSelector<ReportGraphState>(() => ({
    extraReport: undefined,
    summaryReport: undefined,
    extraReportId: undefined,
    extraReportSummary: undefined,
  }))
);

/**
 * subscribes to currentReport to calculate "summaryReport" used on ReportGraph
 * @param currentReport
 * @param activeCategory
 */

function calculateSummaryReport({
  currentReport,
  activeCategory,
  key,
}: {
  currentReport: NestedHoneReport | undefined;
  activeCategory: Category | undefined;
  key: string;
}) {
  const allCategories = useActiveReportStore.getState().allCategories;
  const smoothingEnabled = useActiveReportStore.getState().smoothingEnabled;

  if (!currentReport || !allCategories || !activeCategory) {
    return;
  }

  let columnHeaderTitle = '';
  let customHeaders: string[] = [];

  const reportDates = currentReport.dates;
  let dates;
  try {
    dates = reportDates.map(({ end }) => parse(new Date(), 'yyyy-MM-dd', end)).map(format('MM/dd/yyyy'));
  } catch {
    dates = reportDates.map(({ start }) => start);
  }

  if (currentReport.timeframe === HoneReportTimeframes.Weekly) {
    columnHeaderTitle = 'Week Ending';
  } else if (currentReport.timeframe === HoneReportTimeframes.Monthly) {
    customHeaders = ['', '', '', 'Difference'];
  }

  const _headers = [columnHeaderTitle, ...dates].map((header, index) => {
    const customHeader = customHeaders[index];
    // allow template custom headers
    if (customHeader) {
      const compiled = template(customHeader);
      return compiled({ value: header });
    }

    return header;
  });

  const headers: string[] =
    currentReport.timeframe === 'Monthly' ? currentReport.dates.map(item => item.end) : _headers || [];
  const selectedCategory = activeCategory ? activeCategory.name : allCategories[0];
  const summaryReport: ReportGraphDataSummary = getSummaryReportSales(
    currentReport,
    headers,
    selectedCategory,
    smoothingEnabled
  );

  useReportGraphStore.setState({ [key]: summaryReport });
}

useActiveReportStore.subscribe(
  ({ currentReport, activeCategory }) => ({ currentReport, activeCategory, key: 'summaryReport' }),
  calculateSummaryReport
);

async function fetchExtraReport(reportId: string | undefined) {
  try {
    if (!reportId || reportId === '') {
      useReportGraphStore.setState({ extraReportSummary: undefined, extraReportId: undefined, extraReport: undefined });
      return;
    }
    const activeCategory = useActiveReportStore.getState().activeCategory;

    const urlParams = new URLSearchParams(window.location.search);
    const locationId = useLocationsStore.getState().currentLocation?.id;
    const reportType = urlParams.get('type');
    const reportTypePayload = reportType || useReportsStore.getState().selectedReport?.type;
    const validate = urlParams.get('validate');

    if (!reportId || !locationId) {
      throw new Error('reportId or locationId not available');
    }
    useActiveReportStore.setState({ refreshingReport: true });
    const payload: ReportRequest = {
      baseId: reportId,
      locations: [String(locationId)],
      type: reportTypePayload as ReportType,
    };

    if (validate) {
      payload.postTransforms = { validate: true };
    }
    const response = await useActiveReportStore().getGroupReport(payload);
    if (response) {
      calculateSummaryReport({
        currentReport: response as NestedHoneReport,
        activeCategory,
        key: 'extraReportSummary',
      });
      useReportGraphStore.setState({ extraReport: response as NestedHoneReport });
    }
  } catch (e) {
    console.error(e);
  }
}

useReportGraphStore.subscribe(state => state.extraReportId, fetchExtraReport);

function calculateExtraReport(activeCategory: Category | undefined) {
  if (!activeCategory) return;
  const extraReport = useReportGraphStore.getState().extraReport;

  if (!extraReport) return;
  calculateSummaryReport({ currentReport: extraReport, activeCategory, key: 'extraReportSummary' });
}

useActiveReportStore.subscribe(({ activeCategory }) => activeCategory, calculateExtraReport);
