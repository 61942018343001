import './QueueDetails.scss';
import { prefixClass } from 'lib/utils';

import { useQueueContext } from '../QueueContext';
import TransactionDetails from '../TransactionDetails/TransactionDetails';
import CMComposer from '../CMComposer/CMComposer';
import Chat from '../Chat/Chat';
import TransactionHeader from '../TransactionHeader/TransactionHeader';
import { QueueMarkAsSolvedButton } from '../../Chat/MarkAsSolvedButton';
import { QueueReCategorizeButton } from '../../Chat/RecategorizeButton';
import React from 'react';
import { useAuth, useAuthContext } from 'context/useAuthContext';

const QueueDetails = () => {
  const { isBookkeeperAdmin } = useAuthContext();
  const prefix = prefixClass('queue-details-panel');

  const { state } = useQueueContext();
  const { selectedItem } = state;

  if (!selectedItem) return null;

  const { transaction } = selectedItem;

  const showMessageComposer =
    selectedItem.status !== 'Closed' || (selectedItem.status === 'Closed' && selectedItem.canReopen);

  return (
    <div className={prefix()}>
      <div className={prefix('header')}>
        <TransactionHeader request={selectedItem} />
      </div>
      <div className={prefix('info')}>
        {selectedItem && (
          <TransactionDetails request={selectedItem}>
            <div className={prefix('options')}>
              {isBookkeeperAdmin &&
                transaction?.id &&
                selectedItem.status !== 'Closed' &&
                selectedItem?.canRecategorize && (
                  <QueueReCategorizeButton
                    txId={selectedItem?.id}
                    locationId={selectedItem.locationId}
                    activeTab={selectedItem.status}
                  />
                )}
              {selectedItem.status !== 'Closed' && (
                <QueueMarkAsSolvedButton
                  activeTab={selectedItem.status}
                  locationId={selectedItem.locationId}
                  txId={selectedItem?.id}
                  isSideView={false}
                />
              )}
            </div>
          </TransactionDetails>
        )}
      </div>

      <div className={prefix('messages')}>{selectedItem?.comments && <Chat comments={selectedItem.comments} />}</div>
      <div className={prefix('composer-area')}>
        <div className={prefix('composer-area-container')}>
          <CMComposer request={selectedItem} />
        </div>
      </div>
    </div>
  );
};

export default QueueDetails;
