interface CategoryMatchers {
  gna: string[];
  rent: string[];
  sales: string[];
  cogs: string[];
  labor: string[];
  advertising: string[];
}

const accountMatchers: CategoryMatchers = {
  advertising: [
    'advertising',
    'marketing',
    'promotion',
    'media',
    'ad agency',
    'social media',
    'campaign',
    'billboard',
    'digital marketing',
    'seo',
    'search engine',
    'sponsored',
    'commercial',
    'branding',
  ],
  gna: [
    'administrative',
    'admin',
    'office',
    'legal',
    'professional fee',
    'insurance',
    'utilities',
    'bank fee',
    'depreciation',
    'travel',
    'entertainment',
    'supplies',
    'telephone',
    'communication',
    'training',
    'software',
    'it ',
    'technology',
    'subscription',
    'general expense',
    'corporate',
    'management fee',
    'consulting',
    'accounting',
    'dues',
  ],

  rent: ['rent', 'lease', 'occupancy', 'property tax', 'cam ', 'common area', 'real estate', 'facility'],
  // property expenses
  // direct operating expenses

  sales: [
    'revenue',
    'sales',
    'income',
    'gift card',
    'discount',
    'promotion',
    'catering',
    'delivery',
    'service charge',
    'surcharge',
  ],

  cogs: [
    'cost of goods',
    'cogs',
    'food cost',
    'beverage cost',
    'inventory',
    'purchases',
    'supplies',
    'ingredients',
    'freight',
    'shipping',
  ],

  labor: [
    'payroll',
    'salary',
    'wage',
    'labor',
    'commission',
    'bonus',
    'overtime',
    '401k',
    'health insurance',
    'worker comp',
    'workers comp',
    'benefits',
    'personnel',
    'tips',
    'tip credit',
    'fica',
    'futa',
    'mpfmla',
    'suta',
    'emp ben',
  ],
};

// Priority order for categorization
const categoryPriority = [
  'sales', // Revenue accounts should be identified first
  'cogs', // Direct costs second
  'labor', // Labor costs third
  'rent', // Rent/occupancy fourth
  'advertising', // Advertising/marketing fifth
  'gna', // G&A catches remaining overhead
];

interface GLRanges {
  [category: string]: Array<{
    start: number;
    end: number;
  }>;
}

const glCodeRanges: GLRanges = {
  sales: [{ start: 4000, end: 4999 }],
  cogs: [{ start: 5000, end: 5499 }],
  labor: [{ start: 5500, end: 5999 }],
  rent: [{ start: 6000, end: 6199 }],
  advertising: [{ start: 6100, end: 6199 }],
  gna: [{ start: 6200, end: 6999 }],
};

function isSyntheticAccount(title: string, glCode: number | null): boolean {
  // Check for GL code 0
  if (glCode === 0) {
    return true;
  }

  const lowerTitle = title.toLowerCase();
  const syntheticKeywords = ['total', 'subtotal', 'sum', 'summary', 'net', 'gross', 'grand total'];

  return syntheticKeywords.some(keyword => lowerTitle.includes(keyword));
}

export function identifyAccountType(
  title: string,
  glCode: number | null
): {
  category: string;
  confidence: 'high' | 'medium' | 'low';
  synthetic: boolean;
} {
  // First check if it's a synthetic account
  const synthetic = isSyntheticAccount(title, glCode);

  // If it's synthetic, we don't categorize it
  if (synthetic) {
    return {
      category: 'synthetic',
      confidence: 'high',
      synthetic: true,
    };
  }

  const lowerTitle = title.toLowerCase();
  let matchedCategory: string = 'uncategorized';
  let confidence: 'high' | 'medium' | 'low' = 'low';

  // Original categorization logic for non-synthetic accounts
  for (const category of categoryPriority) {
    if (accountMatchers[category as keyof typeof accountMatchers].some(keyword => lowerTitle.includes(keyword))) {
      matchedCategory = category;
      confidence = 'medium';
      break;
    }
  }

  // GL code validation remains the same
  if (glCode !== null) {
    const glCodeCategory = categoryPriority.find(category =>
      glCodeRanges[category as keyof typeof glCodeRanges].some(range => glCode >= range.start && glCode <= range.end)
    );

    if (glCodeCategory) {
      if (glCodeCategory === matchedCategory) {
        confidence = 'high';
      } else if (matchedCategory !== 'uncategorized') {
        confidence = 'low';
      }
    }
  }

  return {
    category: matchedCategory,
    confidence,
    synthetic: false,
  };
}
