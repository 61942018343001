import { prefixClass } from 'lib/utils';
import './AvatarContainer.scss';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { FilterKAI } from 'components/Icons';

// The number of defined colors to apply for the avatar background. The are defined in the SCSS file.

const avatarColorRules = 4; // Fallback to default number of colors

export const maxAllowedColors = avatarColorRules;

type ColorId = string | undefined;
type CustomColor = string | null;
type AvatarStyle = 'Initials' | 'Image' | 'Me' | 'Kit';
type AvatarSize = 'Default' | 'Small';

export type AvatarInfoProps = {
  userName: string;
  userId: string;
  title?: string;
  avatarUrl?: string;
  email?: string;
  colorId?: ColorId;
};

export type AvatarContainerProps = {
  small?: boolean;
  me?: boolean;
  userInfo: AvatarInfoProps | null;
  tooltip?: boolean;
  onClick?: (userId: string) => void;
};

const STYLE_TYPES = {
  INITIALS: 'Initials',
  IMAGE: 'Image',
  ME: 'Me',
  KIT: 'Kit',
} as const;

export const createInitials = (name: string) => {
  const initials =
    name.indexOf(' ') > -1
      ? name
          .split(' ')
          .map(name => name.charAt(0))
          .join('')
      : name.substring(0, 2);
  return initials;
};

export function createCustomColor(colorId: ColorId): CustomColor {
  if (!colorId) return null;
  const color = String(colorId).trim();
  return color.indexOf('#') === 0 ? color : null;
}

export function legalColorId(colorId: ColorId, customColor: CustomColor): number {
  if (!colorId) return 1;
  let legalColorId = !customColor && colorId && colorId !== '' ? parseInt(String(colorId)) + 1 : 1;
  if (isNaN(legalColorId)) legalColorId = 1;
  if (legalColorId > maxAllowedColors) {
    legalColorId = maxAllowedColors;
  }
  return legalColorId;
}

const AvatarContainer = ({ small = false, me = false, userInfo, tooltip = false, onClick }: AvatarContainerProps) => {
  // Exiting before the component is rendered (no hooks) if no user info is passed
  if (!userInfo || !userInfo.userName || !userInfo.userId) return null;

  // sTate variables
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  // Init component settings
  const size: AvatarSize = small ? 'Small' : 'Default';

  // Check if username is 'kit' or 'Kit' for the new KIT style
  const isKit = userInfo.userName.toLowerCase() === 'kit' || userInfo.userId === '4emD3RxpsUcnDgndL2vMbHryHJU2';

  const style: AvatarStyle = me
    ? STYLE_TYPES.ME
    : isKit
      ? STYLE_TYPES.KIT
      : userInfo.avatarUrl && userInfo.avatarUrl !== ''
        ? STYLE_TYPES.IMAGE
        : STYLE_TYPES.INITIALS;

  const initials = me ? 'Me' : isKit ? 'KAI' : createInitials(userInfo.userName);

  // If colorId exists and starts with # we assume it is a custom color.
  const customColor = createCustomColor(userInfo.colorId);

  // Color ID assignment. It cannot be bigger than maxAllowedColors
  const colorId = legalColorId(userInfo.colorId, customColor);

  // Additional styles
  const styleSettings: { background?: string; backgroundColor?: string } = {};
  if (style === STYLE_TYPES.IMAGE) styleSettings.background = `url(${userInfo.avatarUrl}) 50% / cover no-repeat`;
  if (customColor && style === STYLE_TYPES.INITIALS) styleSettings.backgroundColor = `${customColor}`;

  // Classes
  const prefix = prefixClass('avatar-container');
  const containerClass = prefix('style-' + style.toLowerCase());
  const styledSize = 'style-' + style.toLowerCase() + '_' + size.toLowerCase();

  // click handler
  const handleClick = useCallback(() => {
    if (onClick && userInfo.userId) onClick(userInfo.userId);
  }, [onClick, userInfo.userId]);

  // Avatar component
  const Avatar = () => (
    <div
      style={styleSettings}
      className={classNames(prefix(), {
        small: size === 'Small',
        ['me-avatar']: style === STYLE_TYPES.ME,
        ['kit-avatar']: style === STYLE_TYPES.KIT,
        ['no-avatar-image']: style !== STYLE_TYPES.IMAGE,
        ['avatar-color_' + colorId]: !customColor && style === STYLE_TYPES.INITIALS && colorId,
        ['clickable']: onClick && userInfo.userId,
      })}
      onClick={handleClick}
    >
      <div data-testid="avatar-container" className={classNames(containerClass, { [styledSize]: size === 'Small' })}>
        {style === 'Kit' ? <FilterKAI /> : style != 'Image' && initials}
      </div>
    </div>
  );

  // Tooltip wrapper -> to be moved to a separate component
  const AvatarWithTooltip = () => (
    <div
      className={prefix('tooltip-wrapper')}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Avatar />
      {tooltip && style !== STYLE_TYPES.ME && style !== STYLE_TYPES.KIT && (
        <div className={classNames(prefix('tooltip'), { 'style-tooltip_show': showTooltip })}>
          {userInfo.userName}
          {userInfo.title && <span>{userInfo.title}</span>}
        </div>
      )}
    </div>
  );

  return <AvatarWithTooltip />;
};

export default AvatarContainer;
