import { create } from 'zustand';
import { BALANCE_SHEET_PRESETS, showToast } from 'lib/utils';
import { subscribeWithSelector } from 'zustand/middleware';
import { getCategories, getDefaultCategory } from 'lib/honeTableUtils';
import { Category, DataCellState } from 'domain/models';
import { CategoryColorMapping } from 'presentation/pages/forecast-report/components/ForecastHistoricalGraph/utilities';
import { refreshReport, useReportsStore } from './useReportsStore';
import { useLocationsStore } from './useLocationsStore';
import {
  DismissedBalanceSheetPayload,
  extractReportIds,
  ReportRequest,
  ReportTimeframe,
  ReportType,
} from '@hone-automation/common';
import { makeRemoteDismissSparklines } from 'main/factories/usecases/remote-dismiss-spaklines';

import { makeRemotePostReport } from 'main/factories/usecases/reports/remote-post-report';

import * as Sentry from '@sentry/react';

import { differenceInHours } from 'date-fns';
import * as tz from 'date-fns-tz';

import { trackError } from '../lib/analytics';
import { queryClient } from '../lib/queryClient';
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';

interface ActiveReportState {
  abortController: null | AbortController;
  refreshingReport: boolean;
  setRefreshingReport: (nextState: boolean) => void;
  chartLoading: boolean;
  smoothingEnabled: boolean;
  activeCategory: Category | undefined;
  allCategories: string[];
  currentReport: NestedHoneReport | undefined;
  currentReportId: string | undefined;
  status: 'idle' | 'loading' | 'refreshing';
  setStatus: (status: 'idle' | 'loading') => void;
  yearPlotted: number | undefined;
  rangeNoData: boolean;
  multilocationParamsEnabled: boolean;
  setYearPlotted: (year: number) => void;
  toggleSmoothing: () => void;
  dismissSparklines: (dismissPayload: DismissedBalanceSheetPayload) => void;
  getGroupReport: (dateRangeParams: ReportRequest, allowRefresh?: boolean) => Promise<NestedHoneReport | 'error'>;
  enableMultiLocation: boolean;
  setEnableMultiLocation: (nextState: boolean) => void;
  modalCellState: DataCellState;
  setDataCellState: (nextState: DataCellState) => void;
  currentDataCell: DataCellState | undefined;
  setCurrentDataCell: (nextState: DataCellState) => void;
  expandedRows: string[];
  setExpandedRows: (nextState: string[]) => void;
  reportPayload: ReportRequest | undefined;
  setReportPayload: (nextState: ReportRequest) => void;
  updateDate: Date | undefined;
  resetUpdatedDate: () => void;
}

const postDismissSparklines = makeRemoteDismissSparklines();
const postReportGetByIdOrGrouped = makeRemotePostReport();

export const initialDataCellState: DataCellState = {
  modalOpen: false,
  audit: null,
  dataRows: [],
  date: {
    start: '',
    end: '',
  },
  title: '',
  parent: '',
  total: 0,
  loading: false,
  hasDataRowsOrAudit: false,
};

export const useActiveReportStore = create(
  subscribeWithSelector<ActiveReportState>((set, get) => {
    return {
      updateDate: undefined,
      abortController: null,
      refreshingReport: false,
      smoothingEnabled: false,
      activeCategory: undefined,
      allCategories: [],
      currentReport: undefined,
      currentReportId: undefined,
      status: 'idle',
      yearPlotted: new Date().getFullYear(),
      rangeNoData: false,
      chartLoading: false,
      enableMultiLocation: false,
      setEnableMultiLocation: (nextState: boolean) => set({ enableMultiLocation: nextState }),
      setRefreshingReport: (nextState: boolean) => set({ refreshingReport: nextState }),
      setStatus: (status: 'idle' | 'loading') => set({ status }),
      setYearPlotted: year => set({ yearPlotted: year }),
      toggleSmoothing: () => set(state => ({ smoothingEnabled: !state.smoothingEnabled })),
      multilocationParamsEnabled: false,
      modalCellState: initialDataCellState,
      setDataCellState: nextState => set({ modalCellState: nextState }),
      currentDataCell: undefined,
      expandedRows: [],
      setExpandedRows: nextState => set({ expandedRows: nextState }),
      setCurrentDataCell: nextState => set({ currentDataCell: nextState }),
      reportPayload: undefined,
      setReportPayload: nextState => set({ reportPayload: nextState }),
      setChartLoading: (nextState: boolean) => set({ chartLoading: nextState }),
      dismissSparklines: async (dismissPayload: DismissedBalanceSheetPayload) => {
        try {
          const { locationId, title } = dismissPayload;

          if (!title || !locationId) {
            throw new Error('title or locationId not available');
          }
          await postDismissSparklines.dismiss(dismissPayload);
        } catch (error) {
          Sentry.captureException(error);
        }
      },
      resetUpdatedDate: () => set({ updateDate: undefined }),
      getGroupReport: async (
        payload: ReportRequest,
        allowRefresh: boolean = true
      ): Promise<NestedHoneReport | 'error'> => {
        try {
          // Abort previous request if it exists
          const prevController = get().abortController;

          if (prevController) {
            prevController.abort();
          }

          // Create a new AbortController for the new request
          const controller = new AbortController();

          set({ abortController: controller });
          // useReportsStore.setState({selectedReport: undefined});
          useActiveReportStore.setState({ status: 'loading', rangeNoData: false });
          const urlParams = new URLSearchParams(window.location.search);

          const timeframe = urlParams.get('timeframe');
          const reverse = urlParams.get('reverse');
          const consolidated = urlParams.get('consolidated');
          const breakdownPeriods = urlParams.get('breakdownPeriods');
          const includeBudgetRequest = urlParams.get('budgetInclude');
          const validate = urlParams.get('validate');
          const compareLocations = urlParams.get('compareLocations');
          const type = urlParams.get('type');

          const { startDate, baseId } = payload;
          if (startDate === undefined && timeframe !== 'Year to Date' && !baseId) {
            throw new Error('startDate, endDate are required');
          }

          if (includeBudgetRequest === 'true') {
            payload.budgets = { include: includeBudgetRequest === 'true' };
          }
          if (timeframe) {
            payload.timeframe = timeframe as ReportTimeframe;
          }

          if (payload.comparison?.comparisonPeriods) {
            delete payload.endDate;
          }

          if (consolidated) {
            payload.consolidated = consolidated === 'true';
          }

          if (reverse === 'true') {
            payload.reverse = reverse === 'true';
          }

          if (timeframe === 'Year to Date') {
            delete payload.startDate;
            delete payload.endDate;
          }

          if (breakdownPeriods === 'true') {
            payload.breakdownPeriods = true;
          }

          if (type) {
            payload.type = type as ReportType;
          }
          const isAggrSideBySide =
            compareLocations && (!consolidated || consolidated === 'false')
              ? compareLocations?.split(',').length > 1
              : false;

          if (isAggrSideBySide) {
            delete payload.breakdownPeriods;
            delete payload.difference;
            delete payload.total;
            delete payload.reverse;
            delete payload.comparison;
          }

          if (validate) {
            payload.postTransforms = {
              validate: true,
            };
          }

          const response = (await postReportGetByIdOrGrouped.postReport(payload, {
            signal: controller.signal,
          })) as NestedHoneReport;
          if (controller.signal.aborted) return 'error';

          const nowUTC = tz.toDate(new Date(), { timeZone: 'UTC' });
          const updatedAtUTC = response.updatedAt ? tz.toDate(response.updatedAt, { timeZone: 'UTC' }) : undefined;
          const selectedSummaryReport = useReportsStore.getState().selectedReport;
          const selectedSummaryReportDate = selectedSummaryReport?.updatedAt
            ? tz.toDate(selectedSummaryReport.updatedAt, { timeZone: 'UTC' })
            : undefined;

          const compareUpdatedAt = updatedAtUTC || selectedSummaryReportDate || nowUTC;

          const hoursSinceLastUpdate = differenceInHours(nowUTC, compareUpdatedAt);
          if (hoursSinceLastUpdate > 6 && allowRefresh) {
            useActiveReportStore.setState({ status: 'loading' });

            let reportsIds = extractReportIds(response.dates);
            if (isEmpty(reportsIds) && baseId) {
              reportsIds = [baseId!];
            }
            const refresh = refreshReport(reportsIds, { signal: controller.signal })
              .then(async refreshedResponse => {
                if (controller.signal.aborted) return;
                useActiveReportStore.setState({
                  currentReport: { ...(response as NestedHoneReport), updatedAt: nowUTC.toISOString() },
                  status: 'idle',
                  updateDate: nowUTC,
                });
                await get().getGroupReport(payload, false);
                useActiveReportStore.setState({ refreshingReport: false, status: 'idle' });
                return response;
              })
              .catch(async error => {
                await get().getGroupReport(payload, false);
                useActiveReportStore.setState({ refreshingReport: false, status: 'idle' });
                trackError({ error: error as Error });
              });

            await toast.promise(refresh, {
              loading: 'Refreshing report, will take a few seconds to refresh',
              success: 'Report, successfully refreshed',
              error: 'Error refreshing report',
            });
          } else {
            useActiveReportStore.setState({
              currentReport: response as NestedHoneReport,
              status: 'idle',
            });
          }

          set({ abortController: null });
          return response as NestedHoneReport;
        } catch (error: any) {
          console.error('Error en getGroupReport:', error);
          useActiveReportStore.setState({ status: 'idle', currentReport: undefined });
          showToast(error.message, 'error', 5000);
          Sentry.captureException(error);
          return 'error';
        } finally {
          useActiveReportStore.setState({ status: 'idle' });
          set({ abortController: null });
        }
      },
    };
  })
);

/**
 Subscribes to currentReport, after every change will update categories for currentReport,
 and set the first one as active
 */
function getCategoriesFromReport(currentReport: NestedHoneReport | undefined) {
  if (!currentReport) return;

  const allCategories = getCategories(currentReport.sections);

  if (allCategories.length === 0) {
    allCategories[0] = getDefaultCategory(currentReport.sections);
  }

  const activeCategory = { name: allCategories[0], color: CategoryColorMapping[0] };
  useActiveReportStore.setState({ allCategories, activeCategory });
}

useActiveReportStore.subscribe(state => state.currentReport, getCategoriesFromReport);

type Overrides = { [key: string]: boolean };

function containsSubstring(largeString: string, substrings: string[]): boolean {
  const urlParams = new URLSearchParams(largeString);
  const reportType = urlParams.get('type');

  if (reportType === 'ytd--income-statement') return false;

  for (const substring of substrings) {
    if (largeString.includes(substring)) {
      return true;
    }
  }
  return false;
}

/**
 * Subscribes to currentReportId and selectedReport to fetch the full report by id
 * @param report
 */
export async function fetchReportById(report?: HoneReportSummary) {
  try {
    const prevReportPayload = JSON.stringify(useActiveReportStore.getState().reportPayload);
    const nbs = containsSubstring(window.location.href, Object.keys(BALANCE_SHEET_PRESETS));
    const refreshingReport = useActiveReportStore.getState().refreshingReport;

    const useActiveReportStoreState = useActiveReportStore.getState();

    if ((!report || nbs) && !refreshingReport) {
      return;
    }
    const reportId = report!.id;

    const urlParams = new URLSearchParams(window.location.search);
    const locationId = useLocationsStore.getState().currentLocation?.id;
    const reportType = urlParams.get('type');
    const reportTypePayload = reportType || useReportsStore.getState().selectedReport?.type;
    const validate = urlParams.get('validate');

    if (!reportId || !locationId) {
      throw new Error('reportId or locationId not available');
    }
    useActiveReportStore.setState({ refreshingReport: true, status: 'loading' });
    const payload: ReportRequest = {
      baseId: reportId,
      locations: [String(locationId)],
      type: reportTypePayload as ReportType,
    };

    if (JSON.stringify(payload) === prevReportPayload) {
      return;
    }

    if (validate) {
      payload.postTransforms = { validate: true };
    }
    await useActiveReportStoreState.getGroupReport(payload, true);
  } catch (error) {
    Sentry.captureException(error);
    trackError({ error: error as Error });
  } finally {
    useActiveReportStore.setState({ refreshingReport: false, status: 'idle' });
  }
}

// TODO review this for refactor
useReportsStore.subscribe(
  state => state.selectedReport,
  reportSummary => {
    if (reportSummary?.type === 'P&L Comparison') {
      useActiveReportStore.setState({ reportPayload: undefined });
      return;
    }
    fetchReportById(reportSummary);
  }
);

export const performReportUpdate = async (reportSummary: HoneReportSummary, reportsIds: string[]) => {
  // Abort previous request if it exists
  useActiveReportStore.setState({ status: 'loading' });
  const prevController = useActiveReportStore.getState().abortController;
  const prevPayload = useActiveReportStore.getState().reportPayload;
  if (prevController) {
    prevController.abort();
  }

  // Create a new AbortController for the new request
  const controller = new AbortController();

  useActiveReportStore.setState({ refreshingReport: true, abortController: controller });
  const currentLocation = useLocationsStore.getState().currentLocation;
  const currentLocationId =
    currentLocation?.link && currentLocation?.link !== '' ? currentLocation?.link : currentLocation?.id;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const params = new URL(document.location).searchParams;
  const allReports = !!params.get('allReports') || false;

  const reportPayload: ReportRequest =
    reportSummary?.type === 'P&L Comparison'
      ? prevPayload!
      : {
          baseId: reportSummary.id,
          locations: [currentLocationId!],
          type: reportSummary.type as ReportType,
        };

  if (JSON.stringify(reportPayload) === JSON.stringify(prevPayload) && prevPayload?.type !== 'P&L Comparison') {
    return;
  }
  useActiveReportStore.setState({
    reportPayload: reportPayload,
  });
  const refresh = refreshReport(reportsIds, { signal: controller.signal })
    .then(async (response: any) => {
      if (controller.signal.aborted) return;
      if (response.status === 200) {
        Promise.all([
          queryClient.refetchQueries({
            queryKey: ['reportsSummary', currentLocationId, allReports],
            exact: true,
          }),
          useActiveReportStore.getState().getGroupReport(reportPayload, false),
        ]).then(() => {
          const data: HoneReportSummary[] | undefined = queryClient.getQueryData([
            'reportsSummary',
            currentLocationId,
            allReports,
          ]);
          const nextSelectedReport = data?.find(({ id }) => id === reportSummary.id);
          useReportsStore.setState({ selectedReport: nextSelectedReport });
          useActiveReportStore.setState({ refreshingReport: false, abortController: null });
        });
      }
      return response;
    })
    .catch(error => {
      trackError({ error: error as Error });
    });
  useActiveReportStore.setState({ status: 'loading' });
  await toast.promise(
    refresh,
    {
      loading: 'Refreshing report, will take a few seconds to refresh',
      success: 'Report, successfully refreshed',
      error: 'Error refreshing report',
    },
    {
      style: {
        maxWidth: 'max(50vw, 350px)',
      },
      success: {
        duration: 4000,
      },
    }
  );
  useActiveReportStore.setState({ status: 'idle' });
};

function removeAuditReport() {
  useReportsStore.setState({ auditReport: undefined });
}

useActiveReportStore.subscribe(state => state.currentReportId, removeAuditReport);
