import classNames from 'classnames';

import { useAuthContext } from 'context/useAuthContext';
import { Tooltip } from 'rsuite';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useHoneGetUserInfo } from '../hooks/useHoneGetUserInfo';

interface UserCircleProps {
  closedNavigationMenu: boolean;
}

function UserCircle({ closedNavigationMenu }: UserCircleProps): JSX.Element {
  const { user } = useAuthContext();
  const navigateTo = useNavigate();
  const { locationId } = useParams();
  const { data: userData } = useHoneGetUserInfo(user, locationId);

  const userLocation = userData as HoneLocationUser;

  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <div className={classNames('user-container', { 'user-container-closed': closedNavigationMenu, v3: true })}>
        <button
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
          onClick={() => {
            navigateTo(`/app/location/${locationId}/profile`);
          }}
          className="user-circle"
        >
          {(userLocation?.name ? userLocation?.name : user?.email)?.substring(0, 2)}
        </button>
      </div>
      <Tooltip style={{ top: '45px' }} visible={visible} placement="bottomEnd">
        User Profile
      </Tooltip>
    </>
  );
}

interface UserDropdownMenuProps {
  closedNavigationMenu: boolean;
}

export default function UserDropdownMenu({ closedNavigationMenu }: UserDropdownMenuProps): JSX.Element {
  return <UserCircle closedNavigationMenu={closedNavigationMenu} />;
}
