export const computeAvailableFilters = (filters: any, capabilities: any, budgetAvailable: boolean = false) => {
  const distilledFilters = {
    money: capabilities.money && filters.money,
    percentage: capabilities.percentage && filters.percentage,
    transactions: capabilities.transactions && filters.transactions,
    actuals: filters.actuals,
    budget: budgetAvailable && capabilities.budget && filters.budget,
    variance: budgetAvailable && filters.actuals && capabilities.budget && filters.budget && filters.variance,
    oneLine: false,
  };

  const mainControls = [];

  if (distilledFilters.money) mainControls.push('money');
  if (distilledFilters.percentage) mainControls.push('percentage');
  if (distilledFilters.transactions) mainControls.push('transactions');
  if (mainControls.length === 1) distilledFilters.oneLine = true;

  return distilledFilters;
};

const evalWidthModule = (moduleName: string, filters: any, widths: any, trace: boolean = false): number => {
  let localWidth = 0;
  switch (moduleName) {
    case 'actual':
      if (filters.money) localWidth += widths.money;
      if (filters.percentage) localWidth += widths.percentage;
      if (filters.money && filters.percentage) localWidth += widths.gap_group;
      //if (trace) console.log('actual', localWidth);
      return localWidth;
    case 'budget':
      localWidth = evalWidthModule('actual', filters, widths);
      localWidth += widths.budget_margin;
      if (filters.actuals) {
        localWidth += widths.gap_cell;
        localWidth += 1;
      }
      //if (trace) console.log('budget', localWidth);
      return localWidth;
    case 'variance':
      localWidth = evalWidthModule('actual', filters, widths);
      localWidth +=
        widths.variance_margin_left +
        widths.variance_margin_right +
        widths.variance_padding -
        (widths.gap_group - widths.gap_cell);
      if (filters.budget) localWidth += widths.gap_cell;
      if (!filters.money || !filters.percentage) localWidth += 3;
      //if (trace) console.log('variance', localWidth);
      return localWidth;
    default:
      return 0;
  }
};
export const evalWidths = (filters: any, multilocation: boolean = false) => {
  const widths = {
    money: 80,
    percentage: 65,
    gap_group: 5,
    padding_left: 10,
    padding_right: 6,
    transactions: 16,
    gap_cell: 2,
    budget_margin: 8,
    variance_margin_left: 20,
    variance_margin_right: 15,
    variance_padding: 16,
  };

  let width = widths.padding_left + widths.padding_right;

  if (filters.actuals) width += evalWidthModule('actual', filters, widths);
  if (filters.budget) width += evalWidthModule('budget', filters, widths);
  if (filters.actuals && filters.budget && filters.variance) width += evalWidthModule('variance', filters, widths);

  //if (filters.transactions) width = width + widths.transactions;
  //if (filters.transactions && (filters.actuals || filters.budget)) width += widths.gap_cell;

  const calcWidth = width < 115 ? `${width}px` : !multilocation ? `${width}px` : 'auto';
  //console.log(width, filters);
  document.documentElement.style.setProperty('--ks-cell-header-width', calcWidth); // +1 compensates for the boder width //`${width + 1 }px`
  return width;
};

export const overflowShadowing = (): boolean => {
  const scrollableColumns = document.querySelectorAll('.nt-layout-column.scrollable');
  if (scrollableColumns.length > 0) {
    const hasHorizontalOverflow = scrollableColumns[0].scrollWidth > scrollableColumns[0].clientWidth;
    scrollableColumns.forEach(column => {
      column.classList.toggle('has-overflow', hasHorizontalOverflow);
    });
    document
      .querySelector('.new-table-wrapper-right-shadow-element')
      ?.classList.toggle('has-overflow', hasHorizontalOverflow);

    const columnBrowserArea = document.getElementById('columnBrowserArea');
    if (columnBrowserArea) {
      columnBrowserArea.classList.toggle('hidden', !hasHorizontalOverflow);
    }
    return hasHorizontalOverflow;
  }
  return false;
};
