import { filters } from '../NTContext';
import { cellStates } from '../NTSelectionContext';
import { actions, AccountVisualFiltersProps } from '../NTUtilsContext';
import { TableFilters, TableActions, TableSelection } from '../types';

export const readURLParams = (): {
  filter: TableFilters;
  actions: TableActions;
  accountVFilters: AccountVisualFiltersProps;
  selection: TableSelection;
  urlBudgetInclude: boolean;
} => {
  const urlParams = new URLSearchParams(window.location.search);
  const filter = {
    percentage: urlParams.get('percentage') !== 'false',
    money: urlParams.get('money') !== 'false',
    actuals: urlParams.get('actuals') !== 'false',
    transactions: urlParams.get('transactions') !== 'false',
    smoothing: urlParams.get('smoothing') === 'true',
    budget: urlParams.get('budget') !== 'false',
    variance: urlParams.get('variance') === 'true',
    anomalies: urlParams.get('anomalies') === 'true',
  };

  const actions = {
    detailsPanel: urlParams.get('detailsPanel') === 'true',
    reverseColumns: urlParams.get('reverse') === 'true',
    difference: urlParams.get('difference') === 'true',
    total: urlParams.get('total') === 'true',
  };

  const selection = {
    rowID: urlParams.get('rowID') || null,
  };

  if (!filter.budget && !filter.actuals) {
    filter.actuals = true;
  }

  if (!filter.percentage && !filter.money) {
    filter.money = true;
  }

  const accountVFilters = {
    accounts: urlParams.get('accounts') === 'true',
    percentageLegend: urlParams.get('percentageLegend') !== 'false',
    sparklines: urlParams.get('sparklines') !== 'false',
  };

  const urlBudgetInclude = urlParams.get('budgetInclude') === 'true';
  if (urlBudgetInclude) {
    filter.budget = urlBudgetInclude;
  }
  //console.log('filter from URL', window.location.search, filter, actions, selection);
  return { filter, actions, selection, urlBudgetInclude, accountVFilters };
};

export const saveURLParams = ({
  filters,
  actions,
  cellState,
  accountVisualFilters,
}: {
  filters: filters;
  actions: actions;
  cellState: cellStates;
  accountVisualFilters: AccountVisualFiltersProps;
}) => {
  //console.log('Filters on saving', filters);
  const urlParams = new URLSearchParams(window.location.search);
  Object.entries(filters).forEach(([key, value]) => {
    if (key === 'money') {
      urlParams.set('amounts', value.toString());
    }
    urlParams.set(key, value.toString());
  });

  const allowedActions = ['total', 'difference', 'detailsPanel', 'reverseColumns'];
  Object.entries(actions).forEach(([key, value]) => {
    if (allowedActions.includes(key)) {
      let targetKey = key;
      if (targetKey === 'reverseColumns') targetKey = 'reverse';
      urlParams.set(targetKey, value.toString());
    }
  });

  Object.entries(accountVisualFilters).forEach(([key, value]) => {
    urlParams.set(key, value.toString());
  });

  if (cellState.selected) urlParams.set('rowID', cellState.selected);

  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
  //console.log('Writting URL', urlParams.toString(), filters, actions, accountVisualFilters);
};
