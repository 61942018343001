import React, { forwardRef } from 'react';
import { useCreateAskRequestMutation } from 'hooks/flaggedTransactions';
import { useParams } from 'react-router-dom';
import { CreateRequestModalProps, CreateRequestModalRef } from './CreateRequestModal';
import { CreateAskRequest } from '@hone-automation/common';

export type WithFlaggedTransactionsProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const withFlaggedTransactions = <P extends WithFlaggedTransactionsProps>(
  WrappedComponent: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<CreateRequestModalProps> & React.RefAttributes<CreateRequestModalRef>
  >
) => {
  return forwardRef<CreateRequestModalRef, P>(function WithFlaggedTransactions(props, ref) {
    const createRequestModalRef = React.useRef<CreateRequestModalRef>(null);
    const { locationId } = useParams();
    const { isOpen, handleClose } = props;

    const { mutate } = useCreateAskRequestMutation(locationId!, () => {
      handleClose();
    });

    const createRequest = async (data: CreateAskRequest) => {
      mutate(data);
    };

    const wrappedComponentProps = {
      isOpen,
      onSubmit: createRequest,
      handleClose,
      resetState: handleClose,
      ref: createRequestModalRef,
      locationId,
    } as CreateRequestModalProps;

    return <WrappedComponent {...wrappedComponentProps} />;
  });
};

export default withFlaggedTransactions;
