import { useState } from 'react';
import './AccordionSelector.scss';
import { prefixClass } from 'lib/utils';
import classNames from 'classnames';
import { IconMoreH, IconCancel } from 'components/Icons';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';

type AccordionSelectorOption = {
  label: string;
  value: string;
  icon?: string | React.ReactNode;
  tooltip?: string;
  selected?: boolean;
};

type AccordionSelectorProps = {
  title?: string;
  tooltip?: string;
  options: AccordionSelectorOption[];
  vertical?: boolean;
  onChange: (value: string) => void;
};

const AccordionSelector = ({ title, options, tooltip, onChange }: AccordionSelectorProps) => {
  const prefix = prefixClass('accordion-selector');
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <div className={classNames(prefix())}>
      <div className={classNames(prefix('options-container'), { ['options-open']: isOpen })}>
        {options.map(option => (
          <TooltipWrapper
            key={option.value}
            tooltip={option.tooltip && (option.selected ? 'Hide ' : 'Show ') + option.tooltip}
            delay={300}
          >
            <div
              className={classNames(prefix('options-container-option'), {
                ['option-selected']: option.selected,
              })}
              onClick={() => handleChange(option.value)}
            >
              {option.icon && <div className={classNames(prefix('options-container-option-icon'))}>{option.icon}</div>}
              <div className={classNames(prefix('options-container-option-label'))}>{option.label}</div>
            </div>
          </TooltipWrapper>
        ))}
      </div>

      <TooltipWrapper tooltip={isOpen ? 'Close' : tooltip}>
        <div className={classNames(prefix('switch'), { ['options-open']: isOpen })} onClick={toggleOpen}>
          {isOpen ? <IconCancel /> : <IconMoreH />}
        </div>
      </TooltipWrapper>
    </div>
  );
};

export default AccordionSelector;
