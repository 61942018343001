import { makeApiUrl } from 'main/factories/http';

import { makeAuthorizeHttpClientDecorator } from 'main/factories/decorators';
import { GetPostReport } from 'domain/usecases';
import { RemotePostReport } from 'data/usecases';

export const makeRemotePostReport = (): GetPostReport => {
  const reportsUrl = `/report`;

  return {
    postReport: async (
      params: GetPostReport.Params,
      options?: { signal?: AbortSignal }
    ): Promise<GetPostReport.Model | HttpGenericErrorResponse> => {
      const remotePostReport = new RemotePostReport(makeApiUrl(reportsUrl), makeAuthorizeHttpClientDecorator());
      return remotePostReport.postReport(params, options);
    },
  };
};
