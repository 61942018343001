import { SplitedRequests } from './filterAndSearchUtils';

/**
 * Selects an element from either the "me" or "team" array based on updated date
 * @param {Object} data - Object containing "me" and "team" arrays
 * @param {string} sortOrder - Either "newest" or "oldest"
 * @returns {Object|null} - The selected element or null if both arrays are empty
 */
function selectElement(data: SplitedRequests, sortOrder = 'newest') {
  // Check if data and necessary properties exist
  if (!data || (!data.me && !data.team)) {
    return null;
  }

  // Determine which array to use (me if it has elements, otherwise team)
  const sourceArray =
    Array.isArray(data.me) && data.me.length > 0
      ? data.me
      : Array.isArray(data.team) && data.team.length > 0
        ? data.team
        : null;

  // Return null if both arrays are empty
  if (!sourceArray || sourceArray.length === 0) {
    return null;
  }

  // Sort the array by createdAt timestamp
  const sortedArray = [...sourceArray].sort((a, b) => {
    const dateA = new Date(a.updatedAt).valueOf();
    const dateB = new Date(b.updatedAt).valueOf();

    // For "newest", we want descending order (most recent first)
    // For "oldest", we want ascending order (oldest first)
    return sortOrder === 'newest'
      ? dateB - dateA // Newest first
      : dateA - dateB; // Oldest first
  });

  // Return the first element of the sorted array
  return sortedArray[0];
}

export default selectElement;
